import React, { Component } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import withMainLayout from "../../components/MainLayout";
import { Text } from "@ui-kitten/components";

import {  setLanguage } from "react-multi-lang";

export class SelectLanguageScreen extends Component {
  render() {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: this.props.maxWidth,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 30
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setLanguage("en");
              window.localStorage.setItem("lang", "en");
              this.props.navigation.navigate("Home");
            }}
          >
            <Image
              resizeMode={"contain"}
              style={{
                height: 100,
                width: 100,
              }}
              source={require(`../../assets/images/en.png`)}
            />
          </TouchableOpacity>
          <Text  style={{marginTop: 10}}>English Version</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 30
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setLanguage("fr");
              window.localStorage.setItem("lang", "fr");
              this.props.navigation.navigate("Home");
            }}
          >
            <Image
              resizeMode={"contain"}
              style={{
                height: 100,
                width: 100,
              }}
              source={require(`../../assets/images/fr.png`)}
            />
          </TouchableOpacity>
          <Text style={{marginTop: 10}}>Version Française</Text>
        </View>
      </View>
    );
  }
}

export default withMainLayout(SelectLanguageScreen);
