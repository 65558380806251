import React, { Component } from "react";
import { View } from "react-native";
import withMainLayout from "../../components/MainLayout";
import { withTranslation } from "react-multi-lang";
import LottieView from "react-native-web-lottie";
import { Text } from "@ui-kitten/components";
import axios from "axios";
import { API_TAKEOVER } from "../../constants/const";

export class AuthScreen extends Component {

  // constructor(props) {
  //   super(props);

  // }

  componentDidMount() {

    if (this.props.navigation.state.params) {
      console.log('this.props.navigation.state.params = ', this.props.navigation.state.params);
      let _token = this.props.navigation.state.params.access_token;
      let _userId = this.props.navigation.state.params.pan;
      if (_userId) {
        axios.get(`${API_TAKEOVER}/asse/auth?pan=${_userId}&access_token=${_token}`).then((res) => {
          console.log(res.data);
          if (res.data && res.data?.uid) {
            window.localStorage.setItem('_asse_user', JSON.stringify(res.data));
            this.props.navigation.navigate('SelectGame');
          }
        }).catch((_err) => {
          console.log(_err);
        });
      }
    }

  }

  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100%",
          width: this.props.maxWidth
        }}
      >
        <LottieView
          style={{
            height: 50,
            width: 50,
            alignSelf: "center",
          }}
          source={require("../../assets/anim/loader.json")}
          autoPlay
          loop
        />
        <Text> Chargement ...</Text>
      </View>
    );
  }
}

export default withMainLayout(withTranslation(AuthScreen));
