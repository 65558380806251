import { slide as Menu } from "react-burger-menu";
import React from "react";
import {
  Text,
  Button,
  Icon,
  Divider,
} from "@ui-kitten/components";
import { StyleSheet, Image } from "react-native";
import View from "react-native-web/dist/exports/View";
import {
  ONLY_ANONYMOUS,
  SHOW_RANKING,
  MENU_COLOR,
  THEME_MENU_LOGO,
  SHOW_CALENDER,
  THEME,
  APP_MULTILANG,
  APP_RANKING_TOTAL,
  APP_RANKING_QUIZ,
  APP_RANKING_FORECAST,
  THEME_MENU_LOGO_URL,
  THEME_TEXT_MENU,
  SHOW_GAMES,
} from "../../constants/const";
import { withTranslation, setLanguage, getLanguage, t } from "react-multi-lang";
import { TouchableOpacity } from "react-native-web";

// const GiftIcon = (props) => <Icon {...props} name="gift" />;
const RankingIcon = (props) => <Icon {...props} name="bar-chart" />;
const CircleIcon = (props) => <Icon {...props} name="radio-button-on" />;
const CalenderIcon = (props) => <Icon {...props} name="calendar" />;
const StarIcon = (props) => <Icon {...props} name="star" />;
const PersonIcon = (props) => <Icon {...props} name="person" />;
const HomeIcon = (props) => <Icon {...props} name="home-outline" />;
const LogoutIcon = (props) => <Icon {...props} name="log-out" />;
// const CloseIcon = (props) => <Icon {...props} name="close-outline" />;

class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: props.isOpen || false,
      modalVisible: false,
      // profile : profileJSON
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
    if (this.props.callbackOpened) {
      this.props.callbackOpened(state.isOpen);
    }
  }

  doLogout() {
    // keep _token:
    let _token = window.localStorage.getItem('_asse_token');
    window.localStorage.clear();
    window.localStorage.setItem('_asse_token', _token);
    this.props.firebase.doSignOut();
    this.setState({ modalVisible: false });
    this.props.navigation.navigate("Logout");
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu(callback) {
    this.setState({ menuOpen: false }, () => {
      if (callback) {
        setTimeout(() => {
          callback();
        }, 500);
      }
    });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  showSettings(event) { }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    // console.log("this.props.userData = ", this.state.profile);

    // console.log(THEME_TEXT_MENU());

    console.log('props.authUser = ', this.props.authUser);
    console.log('props.userData = ', this.props.userData);

    return (
      <Menu
        styles={burgerStyles}
        right={this.props.fromRight}
        pageWrapId={"root"}
        outerContainerId={"wrapper"}
        isOpen={this.props.fromRight ? this.props.isOpen : this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        customBurgerIcon={
          this.props.userData && !this.props.fromRight ? (
            <Image
              resizeMode={"contain"}
              source={require("../../assets/images/menu_text.svg")}
            />
          ) : (
            false
          )
        }
        customCrossIcon={
          <Icon fill="#fff" name="close-outline" />
          // <Image
          //   resizeMode={"contain"}
          //   source={require("../../assets/images/menu.svg")}
          // />
        }
      >
        <View style={styles.container}>
          <View style={styles.rowHeader}>
            <Button
              style={styles.buttonHeader}
              status="control"
              accessoryLeft={PersonIcon}
              appearance="ghost"
              onPress={() => {
                if (THEME !== 'assejeux') {
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_menu_burger_icon_profile", { name: 'button' });
                  }
                  if (!ONLY_ANONYMOUS) this.props.navigation.navigate("Profile");
                }
              }}
            ></Button>
            <Button
              style={styles.buttonHeader}
              status="control"
              appearance="ghost"
              onPress={() => {
                if (this.props.authUser && this.props.userData.displayName) {
                  if (THEME !== 'assejeux') {
                    if (!ONLY_ANONYMOUS)
                      this.props.navigation.navigate("Profile");
                  }
                } else {
                  if (THEME !== 'assejeux') {
                    this.props.navigation.navigate("SignIn");
                  } else {
                    this.props.navigation.navigate("Connexion");
                  }
                }
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_title_profile", { name: 'button' });
                }
              }}
            >
              {this.props.authUser && this.props.userData
                ? ONLY_ANONYMOUS
                  ? this.props.userData.displayName
                    ? this.props.userData.displayName.toUpperCase()
                    : t("signin")
                  : this.props.userData.displayName
                    ? this.props.userData.displayName.toUpperCase()
                    : t("signin")
                : t("signin")}
            </Button>
            {APP_MULTILANG && (
              <TouchableOpacity
                style={{ ...styles.buttonHeader, marginRight: 38, alignItems: 'center', justifyContent: 'center' }}
                // status="control"
                // appearance="ghost"
                onPress={() => {
                  if (getLanguage() === "fr") {
                    setLanguage("en");
                    window.localStorage.setItem("lang", "en");
                  } else {
                    setLanguage("fr");
                    window.localStorage.setItem("lang", "fr");
                  }
                  this.setState({});
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_menu_burger_language", { name: 'button' });
                  }
                }}
              >
                <Image
                  resizeMode={"contain"}
                  style={{
                    height: 24,
                    width: 24,
                  }}
                  source={
                    getLanguage() === "en"
                      ? require(`../../assets/images/fr.png`)
                      : require(`../../assets/images/en.png`)
                  }
                />
              </TouchableOpacity>
            )}
            {!APP_MULTILANG && (
              <Button status="control" appearance="ghost"></Button>
            )}
          </View>
          <Divider style={styles.devider} />
          <Button
            style={styles.button}
            status="control"
            accessoryLeft={HomeIcon}
            appearance={
              this.props.navigation.state.routeName === "HomeInside"
                ? "filled"
                : "ghost"
            }
            onPress={() => {
              if (this.props.firebase && this.props.firebase.analytics) {
                this.props.firebase.analytics.logEvent("button_menu_burger_home_inside", { name: 'button' });
              }
              this.props.navigation.navigate("HomeInside");
            }}
          >
            {t("program")}
            {THEME === "challengeca" ? " PJGS 2022" : ""}
          </Button>
          {/* <Button
            style={styles.button}
            status="control"
            accessoryLeft={GiftIcon}
            appearance="ghost"
          >
            MES CADEAUX
          </Button> */}
          {SHOW_GAMES && (<Button
            style={styles.button}
            status="control"
            accessoryLeft={StarIcon}
            appearance={
              this.props.navigation.state.routeName === "SelectGame"
                ? "filled"
                : "ghost"
            }
            onPress={() => {
              if (this.props.firebase && this.props.firebase.analytics) {
                this.props.firebase.analytics.logEvent("button_menu_burger_select_game", { name: 'button' });
              }
              this.props.navigation.navigate("SelectGame");
            }}
          >
            {t("events")}
          </Button>)}
          {SHOW_CALENDER && (
            <Button
              style={styles.button}
              status="control"
              accessoryLeft={CalenderIcon}
              appearance="ghost"
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_calendar", { name: 'button' });
                }
                this.props.navigation.navigate("Calender");
              }}
            >
              {t("calender")}
            </Button>
          )}
          {SHOW_RANKING && (
            <Button
              style={styles.button}
              status="control"
              accessoryLeft={RankingIcon}
              appearance="ghost"
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_ranking_total", { name: 'button' });
                }
                if (APP_RANKING_TOTAL) {
                  this.props.navigation.navigate("RankingTotal");
                } else if (APP_RANKING_QUIZ) {
                  this.props.navigation.navigate("RankingQuiz");
                } else if (APP_RANKING_FORECAST) {
                  this.props.navigation.navigate("RankingForecast");
                }
              }}
            >
              {t("rankings")}
            </Button>)}
          {APP_RANKING_TOTAL && (
            <Button
              style={styles.buttonDetail}
              status="control"
              accessoryLeft={CircleIcon}
              appearance={
                this.props.navigation.state.routeName === "RankingTotal"
                  ? "filled"
                  : "ghost"
              }
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_ranking_total", { name: 'button' });
                }
                this.props.navigation.navigate("RankingTotal");
              }}
            >
              {t("general")}
            </Button>
          )}
          {APP_RANKING_QUIZ && (
            <Button
              style={styles.buttonDetail}
              status="control"
              accessoryLeft={CircleIcon}
              appearance={
                this.props.navigation.state.routeName === "RankingQuiz"
                  ? "filled"
                  : "ghost"
              }
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_ranking_quiz", { name: 'button' });
                }
                this.props.navigation.navigate("RankingQuiz");
              }}
            >
              {"Questions pour un Chaudron"}
            </Button>
          )}
          {APP_RANKING_FORECAST && (
            <Button
              style={styles.buttonDetail}
              status="control"
              accessoryLeft={CircleIcon}
              appearance={
                this.props.navigation.state.routeName === "RankingForecast"
                  ? "filled"
                  : "ghost"
              }
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_menu_burger_ranking_pronostic", { name: 'button' });
                }
                this.props.navigation.navigate("RankingForecast");
              }}
            >
              {t("forecast")}
            </Button>
          )}

          <Divider style={styles.deviderBottom} />

          {THEME_TEXT_MENU() && (
            <Text>
              <div dangerouslySetInnerHTML={{ __html: THEME_TEXT_MENU() }}></div>
            </Text>
          )}

          {this.props.userData.displayName && (
            <>
              <Button
                style={styles.button}
                status="control"
                accessoryLeft={LogoutIcon}
                appearance="ghost"
                onPress={() => {
                  if (this.props.firebase && this.props.firebase.analytics) {
                    this.props.firebase.analytics.logEvent("button_menu_burger_logout", { name: 'button' });
                  }
                  this.setState({ modalVisible: true });
                  // confirm
                  if (window.confirm(`${t("logout")} ?`)) {
                    if (this.props.firebase && this.props.firebase.analytics) {
                      this.props.firebase.analytics.logEvent("button_logout_yes", { name: 'button' });
                    }
                    this.doLogout();
                  } else {
                    if (this.props.firebase && this.props.firebase.analytics) {
                      this.props.firebase.analytics.logEvent("button_logout_no", { name: 'button' });
                    }
                  }
                }}
              >
                {t("logout")}
              </Button>
              <Divider style={styles.deviderBottom} />
            </>
          )}
          {THEME_MENU_LOGO && THEME_MENU_LOGO !== "null" && <Text><center><a
            href={THEME_MENU_LOGO_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              style={{
                top: 50,
                height: 150,
                width: 180
              }}
              resizeMode={"contain"}
              source={THEME_MENU_LOGO}
            />
          </a></center></Text>}

          {/* <Modal
            style={styles.modal}
            visible={this.state.modalVisible}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => this.setState({ modalVisible: false })}
          >
            <Card disabled={true}>
              <Text>{t("logout")} ?</Text>
              <View style={styles.rowButtons}>
                <Button
                  style={{ margin: 5 }}
                  onPress={() => {
                    if (this.props.firebase && this.props.firebase.analytics) {
                      this.props.firebase.analytics.logEvent("button_logout_no", { name: 'button' });
                    }
                    this.setState({ modalVisible: false });
                  }}
                >
                  {t("no")}
                </Button>
                <Button
                  style={{ margin: 5 }}
                  status="danger"
                  onPress={() => {
                    if (this.props.firebase && this.props.firebase.analytics) {
                      this.props.firebase.analytics.logEvent("button_logout_yes", { name: 'button' });
                    }
                    this.doLogout();
                  }}
                >
                  {t("yes")}
                </Button>
              </View>
            </Card>
          </Modal> */}
        </View>
      </Menu>
    );
  }
}

const burgerStyles = {
  bmMenu: {
    background: MENU_COLOR,
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  modal: {},
  backdrop: {
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  rowHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowButtons: {
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-between",
  },
  devider: {
    backgroundColor: "#fff",
    marginTop: 5,
    marginBottom: 20,
  },
  deviderBottom: {
    backgroundColor: "#fff",
    marginTop: 5,
    marginBottom: 5,
  },
  textHeader: {
    color: "#ffffff",
  },
  button: {
    color: "white",
    justifyContent: "left",
    alignItems: "flex-start",
  },
  buttonDetail: {
    color: "white",
    justifyContent: "left",
    alignItems: "flex-start",
    left: 30,
  },
  buttonHeader: {
    color: "white",
    justifyContent: "space-between",
    activeOpacity: 0.5,
    backfaceVisibility: "visible",
  },
  textWrapper: {
    position: "fixed",
    flex: 1,
    bottom: 10,
    left: 10,
    right: "20%",
  },
  textFooter: {
    color: "white",
    fontSize: 8,
    textAlign: "center",
  },
});

export default withTranslation(BurgerMenu);
