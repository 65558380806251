import React, { Component } from "react";
import { View, StyleSheet, TouchableOpacity, Image } from "react-native";
import { isEn, TEXT_COLOR } from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import moment from "moment";
import { Text } from "@ui-kitten/components";

export class MenuSA extends Component {
  state = {
    showFocus: true,
  };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    if (this.props.currentSA) {
      var intervalId = setInterval(() => {
        this.checkTime();
      }, 1000);
      this.setState({ intervalId: intervalId });
    }
  }

  checkTime = () => {
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    const timeLeft = this.props.currentSA.start.seconds - now;
    if (timeLeft < 2 * 60) {
      if (timeLeft % 2 === 0) {
        if (this._isMounted) {
          // this.setState({ showFocus: false });
          this.setState({ showFocus: true });
          setTimeout(
            function () {
              if (this._isMounted) {
                this.setState({ showFocus: true });
              }
            }.bind(this),
            200
          );
        }
      }
    }
  };

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;
    const maxWidth = this.props.maxWidth;
    const styles = StyleSheet.create({
      moduleContainer: {
        flex: 1,
        height: 100,
        width: getWidthPercentMaxLimited(90, maxWidth),
        flexDirection: "row",
        marginTop: 5,
        marginBottom: 5,
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      moduleTimmer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
      },
      moduleButton: {
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        shadowRadius: 10,
        height: 100,
        width: 50,
        backgroundColor: "#F76C5E",
        borderRadius: 3,
        shadowColor: "#000",
        shadowOpacity: 0.5,
      },
      moduleButtonText: {
        color: TEXT_COLOR,
        fontSize: 20,
        backgroundColor: "#F76C5E",
        textAlign: "center",
        textTransform: "uppercase",
        textAlignVertical: "center",
      },
      separator: {
        width: getWidthPercentMaxLimited(0, maxWidth),
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        height: 1,
        alignSelf: "center",
      },
    });

    return (
      <View style={styles.moduleContainer}>
        <View style={{ width: 50, height: 100 }}>
          <TouchableOpacity
            onPress={() => {
              if (this.props.firebase && this.props.firebase.analytics) {
                this.props.firebase.analytics.logEvent("button_select_game_survey_async", {
                  data: this.state,
                });
              }
              if (this.props.mode === "nologin") {
                navigate("SignIn");
              } else {
                if (this.props.callbackClicked) {
                  this.props.callbackClicked(this.props.currentSA.id);
                }
              }
            }}
            underlayColor="transparent"
          >
            {this.state.showFocus && (
              <View style={styles.moduleButton}>
                <Image
                  style={{ width: 30, height: 30 }}
                  source={require("../../assets/images/menu/sondage.svg")}
                />
              </View>
            )}
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={{
            flex: 1,
            backgroundColor: "white",
            borderRadius: 5,
            height: 100,
            flexDirection: "column",
            marginLeft: 15,
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
          onPress={() => {
            if (this.props.mode === "nologin") {
              navigate("SignIn");
            } else {
              if (this.props.callbackClicked) {
                this.props.callbackClicked(this.props.currentSA.id);
              }
            }
          }}
          underlayColor="transparent"
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "white",
              borderRadius: 5,
              height: 100,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Text
              category="h6"
              style={{ color: "#505050", textAlign: "center" }}
            >
              {isEn() ? this.props.currentSA.name_en : this.props.currentSA.name}
            </Text>

            <View style={styles.moduleTimmer}>
              <Image
                style={{ width: 20, height: 20, marginRight: 5 }}
                source={require("../../assets/images/menu/start.svg")}
              />

              <Text style={{ fontSize: 10, color: "#505050" }}>
                {moment(this.props.currentSA.start.seconds * 1000)
                  .format("L LT")
                  .replace(":", "h")}
              </Text>

              <Image
                style={{
                  marginLeft: 10,
                  width: 20,
                  height: 20,
                  marginRight: 5,
                }}
                source={require("../../assets/images/menu/end.svg")}
              />
              <Text style={{ fontSize: 10, color: "#505050" }}>
                {moment(this.props.currentSA.end.seconds * 1000)
                  .format("L LT")
                  .replace(":", "h")}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default MenuSA;
