import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import Draw from "../../components/Draw";

export class DrawScreen extends Component {
  render() {
    return (
      <Draw {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(DrawScreen));
