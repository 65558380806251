import React, { Component } from "react";
import { View } from "react-native";
import withMainLayout from "../../components/MainLayout";
import { withTranslation } from "react-multi-lang";
import { ASSE_REDIRECT_URL } from "../../constants/const";

export class LogoutScreen extends Component {

  _token = '';
  _redirectUrl = `${ASSE_REDIRECT_URL}/Home`;

  constructor(props) {
    super(props);
    this._token = window.localStorage.getItem('_asse_token');
  }

  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100%",
          width: this.props.maxWidth
        }}
      >
        <iframe title="ASSE Connexion" src={`https://moncompte.asse.fr/ServiceLogout?token=${this._token}&client_id=5842&redirect_uri=${this._redirectUrl}&iframe=1`} style={{height: '100%', width: '100%'}}  ></iframe>
      </View>
    );
  }
}

export default withMainLayout(withTranslation(LogoutScreen));
