import React, { Component } from "react";
import { DISPLAY_NAME } from "../../constants/const";
import { Image, TouchableOpacity, View } from "react-native";
import TOButton from "../../libs/to_button";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";

export class ResponseDuel extends Component {
  constructor(props) {
    super(props);

    const lastReponse = window.localStorage.getItem("last_reponse_duel");
    var alreadyAnswered = false;
    var alreadyAnsweredId = 0;
    const anw = props.data;
    const disabled = props.disabled || false;
    this.state = { data: anw, answered: disabled };
    if (lastReponse) {
      const lastReponseJSON = JSON.parse(lastReponse);
      if (lastReponseJSON.duelId === anw.currentDuelId) {
        alreadyAnswered = true;
        alreadyAnsweredId = lastReponseJSON.answerId;
      }
    }
    this.state = {
      data: anw,
      answered: alreadyAnswered,
      answeredId: alreadyAnsweredId
    };
    if(alreadyAnswered){
      this.props.alreadyAnsweredCallback && this.props.alreadyAnsweredCallback({ answeredId: alreadyAnsweredId });
    }
  }

  onPressButtonSubmit = () => {
    if (!this.state || !this.state.answered) {
      const profile = window.localStorage.getItem("profile");
      var loge = "";
      var profileJson = {};
      if(profile){
        profileJson = JSON.parse(profile);
        loge = profileJson
          ? profileJson.logeNumber
            ? profileJson.logeNumber
            : ""
          : "";
      }
      const calDuelResult = this.props.firebase.functions.httpsCallable(
        "calculateDuelResult"
      );
      const id = this.state.answeredId;
      const savedData = {
        duelId: this.state.data.currentDuelId,
        answerId: id,
        userId: this.props.authUser.uid,
        logeNumber: loge,
        email: this.props.authUser.email,
        ...profileJson,
        displayName:
          DISPLAY_NAME === "SHORT"
            ? `${profileJson.firstName} ${profileJson.lastName
                .charAt(0)
                .toUpperCase()}.`
            : `${profileJson.firstName} ${profileJson.lastName}`
      };

      // Save for calcul ranking
      this.props.firebase
        .duel_lives(
          `${this.state.data.currentDuelId}/votes/a${id}/users/${this.props.authUser.uid}`
        )
        .set(savedData)
        .then(() => {
          // calcul
          calDuelResult(savedData).then(re => {
            console.log("calculateDuel done");
          });
        });

      //save in local last reponse
      window.localStorage.setItem(
        "last_reponse_duel",
        JSON.stringify(savedData)
      );

      this.setState({ answered: true, answeredId: id });

      this.props.alreadyAnsweredCallback && this.props.alreadyAnsweredCallback({ answeredId: id });
    }
  };

  componentDidMount() {
    if (this.props.data.withResult) {
      const last_response = window.localStorage.getItem("last_reponse_duel");
      if (last_response) {
        const lastReponseJSON = JSON.parse(last_response);
        if (lastReponseJSON) {
          if (lastReponseJSON.duelId === this.props.data.id) {
            this.setState({
              answeredId: lastReponseJSON.answerId,
              answered: true
            });
          }
        }
      }
    }
  }

  render() {
    const maxW = 500;
    // console.log(this.state);
    return (
      <View
        key={this.state.answeredId}
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flex: 1
        }}
      >
        <View
          style={{
            flex: 1,
            marginTop: 30,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({ answeredId: 1 });
            }}
            disabled={this.state.answered || this.props.disabled }
          >
            <Image
              style={{
                width: getWidthPercentMaxLimited(30, maxW),
                height: getWidthPercentMaxLimited(30, maxW),
                opacity: this.state.answeredId === 1 ? 1 : 0.5,
                marginRight: 30
              }}
              resizeMode={"contain"}
              source={this.props.currentDuel.image_1}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              this.setState({ answeredId: 2 });
            }}
            disabled={this.state.answered || this.props.disabled }
          >
            <Image
              style={{
                width: getWidthPercentMaxLimited(30, maxW),
                height: getWidthPercentMaxLimited(30, maxW),
                opacity: this.state.answeredId === 2 ? 1 : 0.5,
                marginLeft: 30
              }}
              resizeMode={"contain"}
              source={this.props.currentDuel.image_2}
            />
          </TouchableOpacity>
        </View>

        <TOButton
          style={{
            marginTop: 80
          }}
          onClick={() => this.onPressButtonSubmit()}
          disabled={this.state.answered || !this.state.answeredId || this.props.disabled }
        />
      </View>
    );
  }
}

export default ResponseDuel;
