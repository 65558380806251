import React, { Component } from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { isEn, MENU_V2_ENABLED, TEXT_COLOR } from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import moment from "moment";
import { Text } from "@ui-kitten/components";
export class MenuSurvey extends Component {
  state = {
    showFocus: true,
    disabled: false,
  };
  _isMounted = false;
  _currentSurvey = null;
  _arrSurveys = [];

  componentDidMount() {
    this._isMounted = true;
    console.log("this.props = ", this.props);

    this._arrSurveys = this._renderMenu();
    if (this._arrSurveys.length <= 1) {
      var intervalId = setInterval(() => {
        this.checkTime();
      }, 1000);
      this.setState({ intervalId: intervalId });
    }
  }

  _renderMenu = () => {
    var out = [];

    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.props.currentSurvey.start.seconds,
        data: this.props.currentSurvey,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({ type: "sa", start: a.start.seconds, data: a });
        }
      }
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    if (out.length > 0) {
      this._currentSurvey = out[0].data;
    }

    return out;
  };

  checkTime = () => {
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    const timeLeft = this._currentSurvey.start.seconds - now;
    if (timeLeft < 2 * 60) {
      if (timeLeft % 2 === 0) {
        if (this._isMounted) {
          // this.setState({ showFocus: false });
          this.setState({ showFocus: true });
          setTimeout(
            function () {
              if (this._isMounted) {
                this.setState({ showFocus: true });
              }
            }.bind(this),
            200
          );
        }
      }
    }
  };

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;

    const maxWidth = this.props.maxWidth;
    const styles = StyleSheet.create({
      moduleContainer: {
        flex: 1,
        height: 100,
        width: getWidthPercentMaxLimited(90, maxWidth),
        flexDirection: "row",
        marginTop: 5,
        marginBottom: 5,
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      moduleTimmer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
      },
      moduleButton: {
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        shadowRadius: 10,
        height: 100,
        width: 50,
        backgroundColor: "#F76C5E",
        borderRadius: 3,
        shadowColor: "#000",
        shadowOpacity: 0.5,
      },
      moduleButtonDisabled: {
        alignSelf: "center",
        justifyContent: "center",
        height: 70,
        width: 155,
        backgroundColor: "#F76C5E",
        borderRadius: 3,
      },
      moduleButtonText: {
        color: TEXT_COLOR,
        padding: 10,
        fontSize: 20,
        backgroundColor: "#F76C5E",
        textAlign: "center",
        textTransform: "uppercase",
        textAlignVertical: "center",
      },
      separator: {
        width: getWidthPercentMaxLimited(30, maxWidth),
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        height: 1,
        alignSelf: "center",
      },
    });

    return (
      MENU_V2_ENABLED ?
        <View style={{
          flex: 1,
          height: 80,
          width: maxWidth,
          flexDirection: "row",
          marginTop: 5,
          marginBottom: 5,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundImage: 'url("' + require("../../assets/images/menuv2/bg_sondage.png") + '")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}>
          <View style={{ width: 50, height: 80 }}>
            <TouchableOpacity
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_select_game_survey", {
                    data: this.state,
                  });
                }
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this._arrSurveys.length > 1) {
                    navigate("SurveyList");
                  } else {
                    if (this._currentSurvey.type === "survey_async") {
                      if (this.props.callbackClickedSA) {
                        this.props.callbackClickedSA(this._currentSurvey.id);
                      }
                    } else {
                      if (this.props.callbackClicked) {
                        this.props.callbackClicked();
                      }
                    }
                  }
                }
              }}
              underlayColor="transparent"
            >
              {this.state.showFocus && (
                <View style={{
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  width: 50,
                  backgroundColor: 'transparent'
                }}>
                  <Image
                    style={{ width: 50, height: 50, marginLeft: 50 }}
                    source={require("../../assets/images/menuv2/ico_sondage.png")}
                  />
                  {this._arrSurveys.length > 1 && (
                    <Text
                      style={{
                        position: "absolute",
                        bottom: 5,
                        backgroundColor: "transparent",
                        borderColor: "white",
                        borderRadius: 10,
                        borderWidth: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 2,
                      }}
                    >
                      {this._arrSurveys.length}
                    </Text>
                  )}
                </View>
              )}
            </TouchableOpacity>
          </View>
          {this._currentSurvey && (
            <TouchableOpacity
              style={{
                flex: 1,
                backgroundColor: "transparent",
                borderRadius: 5,
                height: 80,
                flexDirection: "column",
                marginLeft: 15,
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center"
              }}
              onPress={() => {
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this._currentSurvey.type === "survey_async") {
                    if (this.props.callbackClickedSA) {
                      this.props.callbackClickedSA(this._currentSurvey.id);
                    }
                  } else {
                    if (this.props.callbackClicked) {
                      this.props.callbackClicked();
                    }
                  }
                }
              }}
              underlayColor="transparent"
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: "transparent",
                  borderRadius: 5,
                  height: 80,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Text
                  style={{ color: 'white', adjustsFontSizeToFit: true, fontSize: 22}}
                  adjustsFontSizeToFit={true}
                >
                  {isEn() ? this._currentSurvey.name_en : this._currentSurvey.name}
                </Text>

              </View>
            </TouchableOpacity>
          )}
        </View>
        :
        <View style={styles.moduleContainer}>
          <View style={{ width: 50, height: 100 }}>
            <TouchableOpacity
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_select_game_survey", {
                    data: this.state,
                  });
                }
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this._arrSurveys.length > 1) {
                    navigate("SurveyList");
                  } else {
                    if (this._currentSurvey.type === "survey_async") {
                      if (this.props.callbackClickedSA) {
                        this.props.callbackClickedSA(this._currentSurvey.id);
                      }
                    } else {
                      if (this.props.callbackClicked) {
                        this.props.callbackClicked();
                      }
                    }
                  }
                }
              }}
              underlayColor="transparent"
            >
              {this.state.showFocus && (
                <View style={styles.moduleButton}>
                  <Image
                    style={{ width: 30, height: 30 }}
                    source={require("../../assets/images/menu/sondage.svg")}
                  />
                  {this._arrSurveys.length > 1 && (
                    <Text
                      style={{
                        position: "absolute",
                        bottom: 5,
                        backgroundColor: "red",
                        borderColor: "white",
                        borderRadius: 10,
                        borderWidth: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 2,
                      }}
                    >
                      {this._arrSurveys.length}
                    </Text>
                  )}
                </View>
              )}
            </TouchableOpacity>
          </View>
          {this._currentSurvey && (
            <TouchableOpacity
              style={{
                flex: 1,
                backgroundColor: "white",
                borderRadius: 5,
                height: 100,
                flexDirection: "column",
                marginLeft: 15,
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
              onPress={() => {
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this._currentSurvey.type === "survey_async") {
                    if (this.props.callbackClickedSA) {
                      this.props.callbackClickedSA(this._currentSurvey.id);
                    }
                  } else {
                    if (this.props.callbackClicked) {
                      this.props.callbackClicked();
                    }
                  }
                }
              }}
              underlayColor="transparent"
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 100,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Text
                  category="h6"
                  style={{ color: "#505050", textAlign: "center" }}
                >
                  {isEn() ? this._currentSurvey.name_en : this._currentSurvey.name}
                </Text>

                <View style={styles.moduleTimmer}>
                  <Image
                    style={{ width: 20, height: 20, marginRight: 5 }}
                    source={require("../../assets/images/menu/start.svg")}
                  />

                  <Text style={{ fontSize: 10, color: "#505050" }}>
                    {moment(this._currentSurvey.start.seconds * 1000)
                      .format("L LT")
                      .replace(":", "h")}
                  </Text>

                  <Image
                    style={{
                      marginLeft: 10,
                      width: 20,
                      height: 20,
                      marginRight: 5,
                    }}
                    source={require("../../assets/images/menu/end.svg")}
                  />
                  <Text style={{ fontSize: 10, color: "#505050" }}>
                    {moment(this._currentSurvey.end.seconds * 1000)
                      .format("L LT")
                      .replace(":", "h")}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          )}
        </View>
    );
  }
}

export default MenuSurvey;
