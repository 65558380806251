import React, { Component } from "react";
import { Animated, Text, Easing, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import { getHeightFromHD } from "../../../libs/list_picker/ratio";
import { BUTTON_COLOR, SHOW_MINUTER, TEXT_TV_COLOR } from "../../../constants/const";

export class TeleCountDown extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.springValue = new Animated.Value(200);
    this.startValue = props.timeLeft || 0;
    this.state = { currentCount: 0 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  _displayDate = timeLeft => {
    const secLeft = timeLeft % 60;
    const minLeft = Math.floor(timeLeft / 60);
    const hourLeft = Math.floor(minLeft / 60);
    const dayLeft = Math.floor(hourLeft / 24);
    const day = dayLeft > 0 ? dayLeft + "J  " : "";
    const hourDisplay = hourLeft % 24;
    const hour = hourDisplay < 10 ? "0" + hourDisplay : hourDisplay;
    const minDisplay = minLeft % 60;
    const min = minDisplay < 10 ? "0" + minDisplay : minDisplay;
    const sec = secLeft < 10 ? "0" + secLeft : secLeft;

    const hourText = ((hourLeft > 0) || (dayLeft > 0)) ? hour + " H " : "";
    const minText = ((minLeft > 0) || (hourLeft > 0)) ? min + " MN " : "";
    const secText = (dayLeft > 0) ? "" : ((secLeft >= 0) || (min > 0)) ? (sec + " S") : "";
    return day + hourText + minText + secText;
  };

  componentDidMount() {
    this._isMounted = true;
    var intervalId = setInterval(() => {
      if (this.props.callbackTimer) {
        this.props.callbackTimer(this.startValue);
      }
      if (this.startValue >= 0) {
        if (this._isMounted) {
          if (this.startValue <= 60 * 1) {
            this.setState({ currentCount: this.startValue }, () => {
              this.spring();
            });
          } else {
            this.setState({ currentCount: this.startValue });
          }
        }
      } else {
        clearInterval(this.state.intervalId);
        // this.props.navigation.navigate("Quizzes");
      }
      this.startValue = this.startValue - 1;
    }, 1000);
    this.setState({ intervalId: intervalId });
  }

  spring() {
    this.springValue.setValue(200);
    Animated.timing(this.springValue, {
      toValue: 255,
      easing: Easing.elastic(),
      duration: 800,
      delay: 100
    }).start();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      this.props.hideAll ? <></> :
        <Animated.View
          style={{
            height: this.props.modeBig ? 300 : getHeightFromHD(160),
            borderRadius: this.props.modeBig ? 150 : (getHeightFromHD(160) / 2),
            borderColor: SHOW_MINUTER ? "rgba(204, 204, 204, 0.5)" : "transparent",
            borderWidth: this.props.modeBig ? 0 : 4,
            alignContent: "center",
            alignItems: "center",
            flexDirection: this.props.modeBig ? "column" : "row",
            backgroundColor: SHOW_MINUTER ? this.springValue.interpolate({
              inputRange: [200, 255],
              outputRange: [BUTTON_COLOR, "rgba(255, 0, 0, 0.5)"]
            }) : "transparent",
            justifyContent: "space-between",
            ...this.props.style
          }}
        >
          <Text
            style={{
              color: SHOW_MINUTER ? TEXT_TV_COLOR : 'transparent',
              textAlign: "center",
              textShadowOffset: { width: 5, height: 5 },
              textShadowColor: SHOW_MINUTER ? "rgba(255, 255, 255, 0.5)" : 'transparent',
              textShadowRadius: 20,
              marginRight: this.props.modeBig ? 0 : 10,
              fontSize: this.props.modeBig ? RF(6) : RF(5),
              ...this.props.styleText
            }}
          >
            {this.props.modeBig
              ? "Temps restant pour répondre : "
              : this.props.type === 2
                ? ""
                : "DANS"}
          </Text>
          {(this.props.from !== 'admin' && SHOW_MINUTER) && <Image
            style={{
              width: RF(11),
              height: RF(11),
              marginLeft: 20
            }}
            resizeMode="contain"
            source={require("../../../assets/images/timer.png")}
          />}
          {this.state && (
            <Animated.Text
              style={{
                color: SHOW_MINUTER ? this.startValue > 60 ? "rgba(255, 255, 255, 1)" : this.springValue.interpolate({
                  inputRange: [200, 255],
                  outputRange: ["rgba(255, 0, 0, 1)", "rgba(255, 255, 255, 1)"]
                }) : 'transparent',
                textAlign: "center",
                textShadowOffset: { width: 5, height: 5 },
                textShadowColor: SHOW_MINUTER ? "rgba(255, 255, 255, 0.5)" : 'transparent',
                textShadowRadius: 20,
                fontSize: this.props.modeBig ? RF(8) : RF(6.5),
                flex: 1,
                ...this.props.styleText
              }}
            >
              {this._displayDate(this.state.currentCount)}
            </Animated.Text>
          )}
        </Animated.View>
    );
  }
}

export default TeleCountDown;
