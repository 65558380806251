import React from "react";
import { TIME_PAUSE, TIME_EXTRA_ANIM } from "../../constants/const";

export class BaseQuiz extends React.Component {
  _isMounted = false;
  timeoutId = null;
  constructor(props) {
    super(props);
    this.currentQuiz = null;
    this.currentQuestion = null;
    this.currentQuestionResult = null;
    this.currentResults = null;
    this.playersRanking = [];
    this.snapshotQuestion = null;
    this.state = {
      screen: "CountDown",
      shouldShow: true,
      currentQuiz: null,
      currentQuestion: null,
      currentResults: null,
      displayResult: false,
      timeLeft: 0,
      updateNum: 0
    };
  }

  checkQuizAndQuestion = () => {
    if (this.currentQuiz !== null && this.currentQuiz.start) {
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      const nowMs = Math.floor(
        Date.now() + parseInt(window.localStorage.getItem("delay"), 10)
      );
      const timeLeft = this.currentQuiz.start.seconds - now;

      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentQuiz;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentQuiz.start.seconds;
        this.currentQuestion = null;
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter(e => {
            return e.order === i + 1;
          })[0];
          if (question) {
            const qTime = question ? question.time : 0;
            if (i === 0) {
              timePast = timePast + qTime + TIME_EXTRA_ANIM;
            } else {
              timePast = timePast + TIME_PAUSE + qTime + TIME_EXTRA_ANIM;
            }
            const timePastWithResult = timePast + TIME_PAUSE + TIME_EXTRA_ANIM;
            const now = Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delay"), 10)) /
                1000
            );

            if (now <= timePastWithResult) {
              this.currentQuestion = question;
              this.currentQuestion.timeLeft = timePast - now; //>  this.currentQuestion.time ? this.currentQuestion.time : (timePast - now);
              this.currentQuestion.timeLeftMs = timePast * 1000 - nowMs;
              this.currentQuestion.displayResult = now > timePast;
              this.currentQuestion.displayResultTimeLeft =
                timePastWithResult - now;
              this.currentQuestion.timeEnd = timePast;
              this.currentQuestion.order = i + 1;
              if (this.state.currentQuestion === null) {
                this.bindData();
              } else if (
                this.state.currentQuestion !== null &&
                this.currentQuestion !== null
              ) {
                if (this.currentQuestion.id !== this.state.currentQuestion.id) {
                  this.bindData();
                } else if (
                  this.currentQuestion.id === this.state.currentQuestion.id &&
                  this.currentQuestion.displayResult !==
                    this.state.currentQuestion.displayResult
                ) {
                  this.bindData();
                }
              }
              break;
            }
          }
        }
        // console.log(this.currentQuestion);
        if (this.currentQuestion === null) {
          // this.currentQuestion = this.currentQuiz.questions[0];
          // console.log(this.props.navigation);
          if (this.props.navigation.state.routeName !== "Ranking") {
            this.props.navigation.navigate("Ranking");
          }
        }
      } else {
        console.log("set screen countdown");
        this.setState({
          screen: "CountDown",
          timeLeft: timeLeft,
          currentQuiz: this.currentQuiz,
          updateNum: this.state.updateNum + 1
        });
      }
    } else {
      // navigate to screen no QUIZ
      this.props.navigation.navigate("Home");
    }
  };

  bindData = () => {
    this.setState(
      {
        currentQuiz: this.currentQuiz,
        currentQuestion: this.currentQuestion,
        displayResult: this.currentQuestion.displayResult,
        updateNum: this.state.updateNum + 1,
        currentQuestionIndex: this.currentQuestionIndex,
        timeLeft: this.currentQuestion.displayResult
          ? this.currentQuestion.displayResultTimeLeft
          : this.currentQuestion.timeLeft
      },
      () => {
        // console.log("==== this.currentQuestion = ", this.currentQuestion);
      }
    );

    if (this.currentQuestion && this.props.firebase) {
      if (this.snapshotQuestion) {
        //unsnapshot
        this.snapshotQuestion();
      }
      // then snap again
      this.snapshotQuestion = this.props.firebase
        .currentQuestionQuizResults(
          `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`,
          this.currentQuestion.id
        )
        .onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              this.currentQuestionResult = snapshot.data();
              // window.localStorage.setItem("questionResults", JSON.stringify(this.currentQuestionResult));
            }
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
    }
  };

  updateRankingData = arrAnswers => {
    // NOTE : get the ranking final and ranking for each question
    if (arrAnswers !== null && this.currentQuiz !== null) {
      this.currentResults = arrAnswers;
      window.localStorage.setItem("currentResults", JSON.stringify(arrAnswers));
      // save and display ranking
      // console.log("update result from firebase : ", this.currentResults);
      // this.updateCurrentQuestionRanking();
    }
  };

  updateCurrentQuestionRanking = () => {
    if (this.currentQuestion !== null) {
      const currentResults = window.localStorage.getItem("currentResults");
      if (currentResults != null) {
        this.currentResults = JSON.parse(currentResults);
        var cResult = [...this.currentResults];
        cResult.sort((a, b) => {
          return (
            b.point - a.point ||
            a.timeRightMs - b.timeRightMs ||
            a.timeMs - b.timeMs
          );
        });
        this.playersRanking = cResult;

        //Do the right ans
        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          this.currentQuestion.answers[i].isRight =
            this.currentQuestion.answers[i].id ===
            this.currentQuestion.rightAnswer;
        }

        if (
          this.currentQuestionResult &&
          this.currentQuestionResult.id === this.currentQuestion.id
        ) {
          this.currentQuestion.total_answered =
            this.currentQuestionResult.total_answered || 0;
          for (let i = 0; i < this.currentQuestionResult.answers.length; i++) {
            const an = this.currentQuestionResult.answers[i];
            an.isRight = an.id === this.currentQuestionResult.rightAnswer;
            an.percent =
              this.currentQuestionResult.total_answered > 0
                ? this.currentQuestionResult[`total_answered_${an.id}`] /
                  this.currentQuestionResult.total_answered
                : 0;
            delete an.answered;
          }
          this.currentQuestion.answers = this.currentQuestionResult.answers;
        }

        if (this._isMounted) {
          this.setState({
            answersByTimes: this.playersRanking.slice(0, 50),
            currentQuestion: this.currentQuestion
          });
        }
        this.playersRanking.length = 0;
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.currentQuiz && this.props.currentQuiz.start) {
      this.currentQuiz = this.props.currentQuiz;
    } else {
      var cQ = window.localStorage.getItem("currentQuiz");
      if (cQ !== null) {
        var savedQ = JSON.parse(cQ);
        if (savedQ !== null) {
          this.currentQuiz = savedQ;
        }
      }
    }

    if (this.currentQuiz !== null) {
      this.checkQuizAndQuestion();
      this.props.firebase
        .currentAnswersQuery(
          `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`
        )
        .onSnapshot(
          snapshot => {
            // console.log('snapshot.size = ', snapshot.size);
            var arrRanking = [];
            snapshot.forEach(data => {
              arrRanking.push(data.data());
            });
            this.updateRankingData(arrRanking);
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );

      // precall the calcul function
      const calQuizRanking = this.props.firebase.functions.httpsCallable(
        "calculateQuizByRanking"
      );
      // calcul
      calQuizRanking({
        quizId: `${this.currentQuiz.id}`,
        startSecond: `${this.currentQuiz.start.seconds}`
      }).then(re => {
        console.log("calculateQuiz precall done");
      });

      // this.timeoutId = setTimeout(() => {
      // this.setState({ shouldShow: true });
      if (this.currentQuestion) {
        if (this.currentQuestion.displayResult) {
          this.updateCurrentQuestionRanking();
        }
      }
      // }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this._isMounted = false;
  }
}

export default BaseQuiz;
