import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import MoreElement from "../../components/Common/more";
import { withAuthorization } from "../../components/Session";

export class MoreScreen extends Component {
  render() {
    return <MoreElement {...this.props} />;
  }
}
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(MoreScreen));
