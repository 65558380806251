import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { View, StyleSheet } from "react-native";

import { withAuthorization } from "../../components/Session";

import { TEXT_MENU, TEXT_COLOR } from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import moment from "moment";

import { Calendar, NativeDateService, Text } from "@ui-kitten/components";
import { withTranslation, getLanguage, t } from "react-multi-lang";

export class CalenderScreen extends Component {
  state = {
    forecasted: false,
    finaldata: false,
    showGeoInfo: 0,
    snapData: [],
    updateNum: 0,
  };
  _isMounted = false;
  intervalId = null;
  timeoutId = null;

  currentQuiz = null;
  currentAsyncs = null;
  currentSAs = null;
  currentSurvey = null;
  currentMatch = null;
  currentDuel = null;

  selectedAsyncId = null;

  constructor(props) {
    super(props);
    if (props.authUser) {
      // get all events for calenders
      if (props.firebase) {
        props.firebase.currentEventsQuery(100).then((query) => {
          query.onSnapshot((snapshot) => {
            var snapData = [];
            snapshot.forEach((sn) => {
              var dataJson = sn.data();
              var dateEvent = moment(dataJson.start.seconds * 1000).format(
                "YYYY-MM-DD"
              );
              dataJson.dateText = dateEvent;
              snapData.push(dataJson);
            });

            this.setState({ snapData, updateNum: this.state.updateNum + 1 });
            console.log(snapData);
          });
        });
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.authUser) {
      // this.intervalId = setInterval(() => {
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _i18n = {
    dayNames: {
      short: ["Lun.", "Mar", "Mer.", "Jeu.", "Ven.", "Sam.", "Dim."],
    },
    monthNames: {
      short: [
        "Janv.",
        "Févr.",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juil.",
        "Août",
        "Sept.",
        "Oct.",
        "Nov.",
        "Déc.",
      ],
      long: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
    },
  };

  _localeDateService = new NativeDateService("fr", {
    i18n: this._i18n,
    startDayOfWeek: 0,
  });

  _dayCell = ({ date }, style) => {
    var dateStr = moment(date).format("YYYY-MM-DD");
    var arrEventType =[];
    return (
      <View style={[styles.dayContainer, style.container]}>
        <Text style={style.text}>{`${date.getDate()}`}</Text>
        <View style={{ flexDirection: "row" }}>
          {this.state.snapData
            .filter((v) => v.dateText === dateStr)
            .map((m) => {
              if(arrEventType.includes(m.type)){
                return "";
              }else{
                arrEventType.push(m.type);
                return (
                  <Text key={m.id} style={{ color: this._eventColor(m.type), fontSize: 12 }}>●</Text>
                );
              }
              
            })}
        </View>
      </View>
    );
  };

  _eventColor = (type) => {
    switch (type) {
      case "quiz":
        return "#44D7B6";
      case "survey":
        return "#F76C5E";
      case "duel":
        return "#860008";
      case "match":
        return "#0091FF";
      default:
        return "#44D7B6";
    }
  };

  render() {
    const maxWidth = this.props.maxWidth;
    return (
      <View
        style={{
          alignItems: "center",
        }}
        key={this.props.toString()}
      >
        <View
          style={{
            padding: 10,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: `${TEXT_MENU()}` }} />
          </Text>
          <Calendar
            key={this.state.updateNum}
            style={{
              marginTop: 20,
              backgroundColor: "#001d51",
            }}
            // date={date}
            // onSelect={(nextDate) => setDate(nextDate)}
            // min={new Date(Date.now())}
            dateService={getLanguage() === "fr" ? this._localeDateService :  new NativeDateService("en")}
            locale
            onVisibleDateChange={(date, mode) => {
              console.log("date= ", date);
              console.log("mode= ", mode);
            }}
            renderDay={this._dayCell}
          />

          <View
            style={{flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "stretch", alignContent:"space-between" }}
          >
            <View style={{width: 150, flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ color: this._eventColor("quiz") }}>●</Text>
                <Text>
                <i>{t("quiz")}</i>
                </Text>
              </View>
              <View style={{width: 150, flexDirection: "row" }}>
                <Text style={{ color: this._eventColor("duel") }}>●</Text>
                <Text>
                <i>{t("duel")}</i>
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "column" }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ color: this._eventColor("survey") }}>●</Text>
                <Text>
                <i>{t("survey")}</i>
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ color: this._eventColor("match") }}>●</Text>
                <Text>
                  <i>{t("forecast")}</i>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  dayContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: 1,
  },
  value: {
    fontSize: 12,
    fontWeight: "400",
  },
});

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(withMainLayout(withTranslation(CalenderScreen)));
