import React, { Component } from "react";
import { Image, View, Text, Dimensions } from "react-native";
import PropTypes from "prop-types";
import RF from "react-native-responsive-fontsize";
import { TEXT_COLOR } from "../../constants/const";

class PercentResultSurvey extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  };

  renderItem( ) {
    const backgroundColor = this.props.type === "right" ? "limegreen" : this.props.type === "yellow" ? "gold": "red";
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <View
        style={{
          backgroundColor,
          height: this.props.modeTele ? Math.round(screenHeight/16) : 40,
          width: this.props.modeTele ? Math.round(screenWidth/10) : 100,
          borderRadius: this.props.modeTele ? Math.round(screenHeight/32) : 20,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          shadowColor: "#999",
          shadowOffset: { width: 4, height: 2 },
          shadowOpacity: 0.5,
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Image
          style={{
            width: this.props.modeTele ? Math.round(screenHeight/16) : 25,
            height: this.props.modeTele ? Math.round(screenHeight/16) :  25,
            marginLeft: this.props.modeTele ? Math.round(screenHeight/40) : 10
          }}
          source={require("../../assets/images/result.png")}
        />

        <Text
          style={{
            color: TEXT_COLOR,
            fontSize: this.props.modeTele ? RF(3) : RF(2),
            marginRight: this.props.modeTele ? Math.round(screenHeight/40) : 10
          }}
        >
          {this.props.value}%
        </Text>
      </View>
    );
  }

  render() {
    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          ...this.props.style
        }}
      >
        {this.renderItem()}
      </View>
    );
  }

  static defaultProps = {
    value: 48,
    type: "right"
  };
}

export default PercentResultSurvey;
