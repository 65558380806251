import React, { Component } from "react";
import { Text, View, FlatList, TouchableOpacity, Image, Dimensions } from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeSlideView from "../Anim/FadeSlideView";
import PercentResult from "./percentResultSurvey";
import LottieView from "react-native-web-lottie";
import { TELE_MARGIN_TOP, TELE_MARGIN_RIGHT, TELE_MARGIN_BOTTOM, TELE_MARGIN_LEFT, TEXT_COLOR, isEn } from "../../constants/const";
import { getWidthFromHDMargin, getHeightFromHDMargin } from "../../libs/list_picker/ratio";

export class ResponseSurveyItem extends Component {


  constructor(props){
    super(props);
    this.data = props.data;
    // console.log(props);
    if(props.withResult){
      // console.log('props.data=',props.data);
      if(props.data){
        this.data = props.data.sort((a,b) => {
          return b.percent - a.percent;
        });
      }
    }

    this.state = { data: this.data };

  }
  _keyExtractor = (item, index) => item.id;

  _renderOptionText = index => {
    var optionText = "";
    switch (index) {
      case 2:
        optionText = "B";
        break;
      case 3:
        optionText = "C";
        break;
      case 4:
        optionText = "D";
        break;

      default:
        optionText = "A";
        break;
    }
    return optionText;
  };

  _renderItem = ({ item, index }) => {
    if (this.props.question.withImage) {
      return this._renderItemImage({ item, index });
    } else {
      return this._renderItemText({ item, index });
    }
  };

  _renderItemImage = ({ item, index }) => {
    const mtop = TELE_MARGIN_TOP;
    const mright = TELE_MARGIN_RIGHT;
    const mbottom = TELE_MARGIN_BOTTOM;
    const mleft = TELE_MARGIN_LEFT;

    const screenWidth = Math.round(Dimensions.get("window").width) - mleft - mright;
    
    return (
      <FadeSlideView
        delay={index * 50}
        style={{
          flex: 1,
          flexDirection: this.props.modeTele ? "row" : "column",
          width: this.props.modeTele
            ? this.props.withResult
              ? getWidthFromHDMargin(290, mtop, mright, mbottom, mleft)
              : getWidthFromHDMargin(340, mtop, mright, mbottom, mleft)
            : 160,
          marginLeft: this.props.modeTele ? Math.round(screenWidth / 60) : 0,
          marginRight: this.props.modeTele ? Math.round(screenWidth / 60) : 0,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            this.props.onPressButton(index, item.id);
            this.setState({ selected: true });
          }}
          disabled={this.props.answered || this.props.withResult || this.props.modeTele}
        >
          <View style={{ flex: 0 }}>
            <Image
              style={{
                width: this.props.modeTele
                  ? this.props.withResult
                    ? getWidthFromHDMargin(290, mtop, mright, mbottom, mleft)
                    : getWidthFromHDMargin(340, mtop, mright, mbottom, mleft)
                  : 150,
                height: this.props.modeTele
                  ? this.props.withResult
                    ? getHeightFromHDMargin(290, mtop, mright, mbottom, mleft)
                    : getHeightFromHDMargin(480, mtop, mright, mbottom, mleft)
                  : 150,
                borderRadius: this.props.modeTele ? 15 : 10,
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 5,
                borderColor: "transparent",
                borderWidth: 1,
                opacity: item.answered ? 0.5 : 1
              }}
              source={{ uri: this.props.modeTele ? item.answer : (item.mobile ? item.mobile : item.answer)}}
            />
            {this.props.withResult && (
              <PercentResult
                type={item.percent >= 0.5 ? "right" : item.percent >= 0.25 ? "yellow" : "wrong"}
                value={Number.parseFloat(item.percent * 100).toFixed(0)}
                modeTele={this.props.modeTele}
                style={{width: this.props.modeTele
                  ? this.props.withResult
                    ? getWidthFromHDMargin(290,mtop,mright,mbottom,mleft)
                    : getWidthFromHDMargin(340,mtop,mright,mbottom,mleft)
                  : 150}}
              />
            )}
                        {item.text && (
              <Text
              style={{
                textShadowOffset: { width: 5, height: 5 },
                textShadowColor: "rgba(0, 0, 0, 0.9)",
                textShadowRadius: 20,
                fontSize: this.props.modeTele ? RF(4) : RF(2),
                color: TEXT_COLOR,
                textAlign: "center",
                textTransform: "uppercase",
                marginBottom: 15,
                width: this.props.modeTele
                  ? this.props.withResult
                    ? getWidthFromHDMargin(290, mtop, mright, mbottom, mleft)
                    : getWidthFromHDMargin(340, mtop, mright, mbottom, mleft)
                  : 150,
              }}
              allowFontScaling={true}
              minimumFontScale={10}
              >
                {isEn() ?  item.text_en  : item.text}
              </Text>)}
            { this.props.answered && (this.props.answerId === item.id) && (
              <View style={{ position: "absolute", top: 30, left: 25 }}>
                <LottieView
                  style={{ height: 100, width: 100 }}
                  source={require("../../assets/anim/tick.json")}
                  autoPlay
                />
              </View>
            )}
            
          </View>
        </TouchableOpacity>
      </FadeSlideView> 
    );
  };

  _marginBottom = withResult => {
    const screenHeight = Math.round(Dimensions.get("window").height);
    return withResult ? this.props.modeTele ? Math.round(screenHeight/23) : 30 : 5;
  };

  _renderItemText = ({ item, index }) => {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    console.log('item = ', item);
    return (
      <FadeSlideView delay={index * 50}>
        <TouchableOpacity
          onPress={() => {
            this.props.onPressButton(index, item.id);
            this.setState({ selected: true });
          }}
          disabled={this.props.answered || this.props.withResult || this.props.modeTele}
        >
          <View style={{ flex: 1, alignItems: "stretch" }}>
            <View
              style={{
                flex: 1,
                height: this.props.modeTele ? Math.round(screenHeight/7) : 60,
                width: this.props.modeTele ? Math.round(screenWidth/2.2) : 310,
                borderRadius: 5,
                margin: 5,
                marginBottom: this._marginBottom(this.props.withResult),
                backgroundColor: "white",
                borderWidth: 1,
                justifyContent: "flex-start",
                alignItems: "center",
                alignSelf: "stretch",
                flexDirection: "row"
              }}
            >
              
              <View
                style={{
                  width: this.props.modeTele ? Math.round(screenHeight/10) : 30,
                  height: this.props.modeTele ?  Math.round(screenHeight/10) : 30,
                  borderRadius: this.props.modeTele ? Math.round(screenHeight/20) : 15,
                  backgroundColor: this.props.answeredanswered ? "white":"lightgrey",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: this.props.modeTele ? Math.round(screenHeight/40) : 9
                }}
              >
                <Text
                  style={{
                    fontSize: this.props.modeTele ? RF(4) : RF(2)
                  }}
                >
                  {this._renderOptionText(item.id)}
                </Text>
              </View>
              
              <Text
                style={{
                  fontSize: this.props.modeTele ?  RF(4) : RF(2)
                }}
              >
                {item.answer}
              </Text>
            </View>
            {this.props.answered && (this.props.answerId === item.id)  && (
              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                  position: "absolute",
                  bottom: this._marginBottom(this.props.withResult) - 20,
                  left: -20,
                  alignContent: "flex-end"
                }}
              >
                <LottieView
                  style={{ height: 100, width: 100, alignSelf: "flex-end" }}
                  source={require("../../assets/anim/tick.json")}
                  autoPlay
                />
              </View>
            )}
            {this.props.withResult && (
              <PercentResult
              type={item.percent >= 0.5 ? "right" : item.percent >= 0.25 ? "yellow" : "wrong"}
                value={Number.parseFloat(item.percent * 100).toFixed(0)}
                modeTele={this.props.modeTele}
              />
            )}
          </View>
        </TouchableOpacity>
      </FadeSlideView>
    );
  };

  _numColumns = withImage => {
    if (this.props.modeTele) {
      return withImage ? 4 : 2;
    }
    return withImage ? 2 : 1;
  };

  render() {
    return (
      <FlatList
      contentContainerStyle={{
        justifyContent: "center", alignContent: 'center', 
      }}
        style={{ paddingTop:5,  paddingBottom:5, maxWidth: "100%"}}
        showsHorizontalScrollIndicator={false}
        data={this.state.data}
        extraData={this.state}
        renderItem={this._renderItem}
        numColumns={this._numColumns(this.props.question.withImage)}
      />
    );
  }
}

export default ResponseSurveyItem;
