import React, { Component } from "react";
import {
  Text,
  View,
  ImageBackground,
  Animated,
  Easing,
  Dimensions,
  Image,
} from "react-native";
import {
  MAIN_COLOR,
  MAIN_URL,
  BG_TELE,
  THEME_BANNER_FULL,
  THEME_BACKGROUND_TELE,
  THEME_HEADER_LEFT,
  TEXT_TV_COLOR,
  SHOW_TUTO,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  QRCODE_FOREGROUND_COLOR,
  QRCODE_BACKGROUND_COLOR,
} from "../../constants/const";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import {
  getWidthFromHDMargin,
  getHeightFromHDMargin,
} from "../../libs/list_picker/ratio";

import TutorTele from "./Tutor/tutor";
import ScaleView from "../Anim/scaleView";
import FadeView from "../Anim/FadeView";

export class AsyncHome extends Component {
  springSize = new Animated.Value(3);
  setted = false;
  _isMounted = false;
  pronosticsOrigin = [];
  pronosticsSorted = [];
  lastFirst100s = [];
  winners = [];

  constructor(props) {
    super(props);
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
    );
    this.state = {
      timeLeft: props.currentAsync.start.seconds - now,
      timeLeftVote: props.timeLeft + parseInt(props.currentAsync.duration, 10),
      currentAsync: props.currentAsync,
      animate: false,
      showTuto: false,
      showFocus: false,
      blinkAnimation: new Animated.Value(0.2),
      animations: {
        bgWidth: new Animated.Value(80),
        bgMarginTop: new Animated.Value(100),
        bgMarginBottom: new Animated.Value(200),
        textMarginBottom: new Animated.Value(90),
        fadeOut: new Animated.Value(1),
        widthLeft: new Animated.Value(0),
        widthRight: new Animated.Value(0),
      },
      screen: "countdown",
      timeEnd: 0,
      liveData: {},
      updateNum: 0,
      totalVotes: 0,
      vote1: 0,
      vote2: 0,
      vote1percent: 0.5,
      vote2percent: 0.5,
    };
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut,
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
    ]);

  callbackTimerForEnd(val) {
    if (this.props.callbackTimerForEnd) {
      this.props.callbackTimerForEnd(val);
    }
  }

  callbackTimer(val) {
    // console.log("callbackTimer =>", val);
    //correct the timeLeft
    if (this.props.callbackTimer) {
      this.props.callbackTimer(val);
    }
    //Do animation
    if (val <= 0) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
          1000
      );
      this.setState({
        stared: true,
        updateNum: this.state.updateNum + 1,
        timeLeft: this.state.currentAsync.end.seconds  - now,
      });
    }

    if (val % 45 === 0 && val > 60) {
      this.setState({ showTuto: true });
      setTimeout(
        function () {
          this.setState({ showTuto: false });
        }.bind(this),
        30000
      );
    }

    if (val % 20 === 0 && val > 0) {
      if (this._isMounted) {
        this.setState({ showFocus: true });
        setTimeout(
          function () {
            Animated.sequence([
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
            ]).start();
          }.bind(this),
          1000
        );

        setTimeout(
          function () {
            if (this._isMounted) {
              this.setState({ showFocus: false });
            }
          }.bind(this),
          4000
        );
      }
    }
  }

  finishCountDownCallback = (val) => {
    console.log("finishCountDownCallback = ", val);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { animations, animate } = this.state;

const mtop = this.props.navigation ? (this.props.navigation.state.params
      ? this.props.navigation.state.params.mtop
        ? parseInt(this.props.navigation.state.params.mtop, 10)
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP) : TELE_MARGIN_TOP;
    const mright = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mright
        ? parseInt(this.props.navigation.state.params.mright, 10)
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT) : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mbottom
        ? parseInt(this.props.navigation.state.params.mbottom, 10)
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM) : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mleft
        ? parseInt(this.props.navigation.state.params.mleft, 10)
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT ) : TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    // console.log(this.state);
    return (
      <View
        style={{
          backgroundColor: MAIN_COLOR,
          flex: 1,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
      >
        <ImageBackground
          style={{
            flex: 1,
            flexDirection: "row",
            backgroundColor: this.props.navigation.state.params
              ? this.props.navigation.state.params.bgc
                ? this.props.navigation.state.params.bgc
                : BG_TELE
              : BG_TELE,
          }}
          source={
            this.props.navigation.state.params
              ? this.props.navigation.state.params.bgtele
                ? this.props.navigation.state.params.bgtele
                : THEME_BACKGROUND_TELE
              : THEME_BACKGROUND_TELE
          }
          resizeMode={"cover"}
        >
          {this.state.screen === "countdown" && (
            <View
              style={{
                flex: 1,
                alignSelf: "center",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  width: getWidthFromHDMargin(
                    510,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  height: screenHeight,
                }}
              >
                <Image
                  style={{
                    width: getWidthFromHDMargin(
                      350,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    height: getHeightFromHDMargin(
                      200,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                  }}
                  resizeMode={"contain"}
                  source={THEME_HEADER_LEFT}
                />
              </View>
              {this.props.currentAsync && (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    height: screenHeight,
                  }}
                >
                  <Animated.Text
                    style={{
                      color: TEXT_TV_COLOR,
                      textAlign: "center",
                      fontSize: getHeightFromHDMargin(
                        100,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.currentAsync.name}
                  </Animated.Text>

                  <Animated.View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",

                      height: getHeightFromHDMargin(
                        120,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      width: getWidthFromHDMargin(
                        290,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      justifyContent: "space-between",
                      zIndex: 1000,
                    }}
                  >

                  </Animated.View>                
                {/* QRcode */}
                    <Animated.View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        width: getWidthFromHDMargin(
                          740,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        height: getHeightFromHDMargin(
                          700,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            marginTop: getHeightFromHDMargin(
                              10,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            color: "#e02020",
                            fontSize:
                              (getWidthFromHDMargin(
                                700,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) /
                                MAIN_URL.length) *
                              1.65,
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                          numberOfLines={1}
                          ellipsizeMode="middle"
                        >
                          {MAIN_URL}
                        </Text>
                        <View
                          style={{
                            marginTop: getHeightFromHDMargin(
                              30,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            alignItems: "center",
                            justifyContent: "center",
                            paddingBottom: getHeightFromHDMargin(
                              30,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                          }}
                        >
                          {this.state.showFocus && (
                            <ScaleView
                              style={{
                                position: "absolute",
                                backgroundColor: "green",
                                opacity: this.state.blinkAnimation,
                              }}
                              delay={550}
                              duration={550}
                              size={
                                getWidthFromHDMargin(
                                  550,
                                  mtop,
                                  mright,
                                  mbottom,
                                  mleft
                                ) +
                                50 -
                                mtop -
                                mbottom
                              }
                            ></ScaleView>
                          )}
                          {this.state.showFocus && (
                            <FadeView
                              style={{ position: "absolute" }}
                              delay={1000}
                              duration={300}
                            >
                              <Image
                                style={{
                                  width:
                                    getWidthFromHDMargin(
                                      550,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ) +
                                    50 -
                                    mtop -
                                    mbottom,
                                  height:
                                    getWidthFromHDMargin(
                                      550,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ) +
                                    50 -
                                    mtop -
                                    mbottom,
                                }}
                                source={require("../../assets/tutor/focus.png")}
                                resizeMode="contain"
                              />
                            </FadeView>
                          )}

                          <QRCode
                            value={this.props.currentAsync.hasOwnProperty("quiz_id") ? "https://" + MAIN_URL + "/async?aid=" + this.props.currentAsync.id :  "https://" + MAIN_URL + "/sa?sid=" + this.props.currentAsync.id}
                            fgColor={QRCODE_FOREGROUND_COLOR}
                            bgColor={QRCODE_BACKGROUND_COLOR}
                            size={
                              getWidthFromHDMargin(
                                550,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) -
                              mtop -
                              mbottom
                            }
                          />
                        </View>
                      </View>
                    </Animated.View>
                  
                </View>
              )}
              <View
                style={{
                  flexDirection: "column",
                  width: getWidthFromHDMargin(
                    510,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  height: screenHeight,
                }}
              >
                <Animated.View
                  style={{
                    marginRight: getWidthFromHDMargin(
                      40,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    marginTop: getWidthFromHDMargin(
                      45,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    opacity: animate ? animations.fadeOut : 1,
                  }}
                >
                  <Text
                    key={`countdownText_${this.state.updateNum}`}
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: RF(3.5),
                      textAlign: "center",
                      textTransform: "uppercase",
                      marginBottom: 10,
                    }}
                  >
                    {this.state.stared ?  "Fin du quiz dans" : "Début du quiz dans"}
                  </Text>
                  <TeleCountDown
                    key={`countdown_${this.state.updateNum}`}
                    type={2}
                    timeLeft={this.state.stared ? this.state.timeLeft : this.props.timeLeft}
                    callbackTimer={this.callbackTimer}
                  />

                </Animated.View>
              </View>
            </View>
          )}
        </ImageBackground>
        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1,
          }}
          source={THEME_BANNER_FULL}
          resizeMode={"stretch"}
        />

        {this.state.showTuto &&
          (this.props.navigation.state.params
            ? this.props.navigation.state.params.stuto
              ? this.props.navigation.state.params.stuto === "true"
              : SHOW_TUTO
            : SHOW_TUTO) === true && (
            <View
              style={{
                width: screenWidth,
                height: screenHeight,
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TutorTele {...this.props} />
            </View>
          )}
      </View>
    );
  }
}

export default AsyncHome;
