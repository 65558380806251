import React, { Component } from "react";
import {
  Text,
  View,
  ImageBackground,
  Image,
  Animated,
  Easing,
  Dimensions,
} from "react-native";
import {
  MAIN_COLOR,
  MAIN_URL,
  BG_TELE,
  THEME_BANNER_FULL,
  THEME_BACKGROUND_TELE,
  THEME_HEADER_LEFT,
  TEXT_TV_COLOR,
  SHOW_NULBER_ONLINE,
  SHOW_TUTO,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  TIME_PAUSE,
  TIME_EXTRA_ANIM,
  HIDE_QRCODE,
  THEME,
  QRCODE_FOREGROUND_COLOR,
  QRCODE_BACKGROUND_COLOR,
  ONLY_ANONYMOUS,
  MAIN_DYNAMIC_LINK,
  SHOW_MINUTER,
} from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import TutorTele from "./Tutor/tutor";
import {
  getWidthFromHDMargin,
  getHeightFromHDMargin,
} from "../../libs/list_picker/ratio";
import FadeView from "../Anim/FadeView";
import ScaleView from "../Anim/scaleView";
import {
  OPTIONS_QUESTIONS_TV_AND_RESULTS,
  OPTIONS_QUESTIONS_TV,
  OPTIONS_NO_TV_RESULT_MOBILE,
  OPTIONS_NO_COUNTER,
} from "../../constants/routes";

export class TeleSurveyHome2 extends Component {
  springSize = new Animated.Value(3);
  setted = false;
  _isMounted = false;
  constructor(props) {
    super(props);

    // console.log("reinit HOME survey");
    this.state = {
      showTuto: false,
      timeLeft: props.timeLeft,
      currentSurvey: props.currentSurvey,
      animate: false,
      animations: {
        bgWidth: new Animated.Value(80),
        bgMarginTop: new Animated.Value(100),
        bgMarginBottom: new Animated.Value(200),
        textMarginBottom: new Animated.Value(90),
        fadeOut: new Animated.Value(1),
      },
      screen: "countdown",
      timeEnd: 0,
    };
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut,
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0,
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0,
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0,
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
    ]);

  callbackTimerForEnd(val) {
    if (this.props.callbackTimerForEnd) {
      this.props.callbackTimerForEnd(val);
    }
  }

  callbackTimer(val) {

    if (val % 45 === 0 && val > 60) {
      if (this._isMounted) {
        this.setState({ showTuto: true });
        setTimeout(
          function () {
            if (this._isMounted) {
              this.setState({ showTuto: false });
            }
          }.bind(this),
          30000
        );
      }
    }

    if (
      this.state.currentSurvey.display_type ===
      OPTIONS_QUESTIONS_TV_AND_RESULTS ||
      this.state.currentSurvey.display_type === OPTIONS_QUESTIONS_TV
    ) {
      if (val <= 0 && this.state.animate === false) {
        //set state animated
        this.setState({ animate: true, screen: "countdown" });
        const {
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut,
        } = this.state.animations;

        this.doAnimation({
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut,
        }).start(() => {
          // Do stuff after animations
        });
      }

      if (this.state.animate === true) {
        this.setState({ timeLeft: val });
        this.spring();
      }

      if (this.props.callbackTimer) {
        this.props.callbackTimer(val - 1);
      }
    } else {
      //Change the screen for showing not replay questions
      if (val <= 0 && this.state.animate === false) {
        const now = Math.floor(
          (Date.now() +
            parseInt(window.localStorage.getItem("delayTele"), 10)) /
          1000
        );
        var timeEnd = this.state.currentSurvey.start.seconds - now;
        var time_pause = 0;
        if (
          this.state.currentSurvey.display_type ===
          OPTIONS_QUESTIONS_TV_AND_RESULTS ||
          this.state.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
        ) {
          time_pause = TIME_PAUSE;
        }
        for (let i = 0; i < this.state.currentSurvey.questions.length; i++) {
          const q = this.state.currentSurvey.questions[i];
          timeEnd = timeEnd + q.time + TIME_EXTRA_ANIM + time_pause;
        }
        timeEnd = timeEnd - time_pause;

        if (this.state.currentSurvey.display_type === OPTIONS_NO_COUNTER) {
          timeEnd = this.state.currentSurvey.end.seconds - now;
        }

        if (timeEnd < 0) {
          if (this.props.callbackTimerForEnd) {
            this.props.callbackTimerForEnd(timeEnd);
          }
        }

        this.setState({ animate: true, screen: "countToEnd", timeEnd });

        const {
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut,
        } = this.state.animations;

        this.doAnimation({
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut,
        }).start(() => {
          // Do stuff after animations
        });
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;
    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;
    const should_hide_qrcode = this.props.navigation.state.params
      ? this.props.navigation.state.params.hasOwnProperty("hideqr")
        ? this.props.navigation.state.params.hideqr === "true"
        : HIDE_QRCODE
      : HIDE_QRCODE;
    return (
      <View
        style={{
          backgroundColor: this.props.navigation.state.params
            ? this.props.navigation.state.params.mc
              ? this.props.navigation.state.params.mc
              : MAIN_COLOR
            : MAIN_COLOR,
          flex: 1,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
      >
        <View
          style={{
            flex: 1,
            alignSelf: "center",
            width: "100%",
          }}
        >
          <ImageBackground
            style={{
              flex: 1,
              flexDirection: "row",
              backgroundColor: this.props.navigation.state.params
                ? this.props.navigation.state.params.bgc
                  ? this.props.navigation.state.params.bgc
                  : BG_TELE
                : BG_TELE,
            }}
            source={
              this.props.navigation.state.params
                ? this.props.navigation.state.params.bgtele
                  ? this.props.navigation.state.params.bgtele
                  : THEME_BACKGROUND_TELE
                : THEME_BACKGROUND_TELE
            }
            resizeMode={"cover"}
          >
            <View
              style={{
                flexDirection: "column",
                width: getWidthFromHDMargin(510, mtop, mright, mbottom, mleft),
                height: screenHeight,
              }}
            >
              <Image
                style={{
                  left: 100,
                  top: 100,
                  width: getWidthFromHDMargin(
                    350,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  height: getHeightFromHDMargin(
                    200,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                }}
                resizeMode={"contain"}
                source={
                  this.props.navigation.state.params
                    ? this.props.navigation.state.params.hl
                      ? this.props.navigation.state.params.hl
                      : THEME_HEADER_LEFT
                    : THEME_HEADER_LEFT
                }
              />
            </View>
            {this.props.currentSurvey && (
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                  height: screenHeight,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: getWidthFromHDMargin(
                      740,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                  }}
                >
                  <Animated.Text
                    style={{
                      color: TEXT_TV_COLOR,
                      textAlign: "center",
                      textAlignVertical: "center",
                      fontSize: RF(8),
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      maxWidth: getWidthFromHDMargin(
                        740,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                    }}
                  >
                    {this.props.currentSurvey.name}
                  </Animated.Text>
                </View>
                {should_hide_qrcode === true && (
                  <Animated.View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor:
                        THEME === "ogcnice-stade" ? "transparent" : "#fff",
                      borderRadius: 25,
                      width: getWidthFromHDMargin(
                        740,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      height: getHeightFromHDMargin(
                        350,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      marginBottom: getHeightFromHDMargin(
                        250,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "94%",
                        }}
                      >
                        <Text
                          style={{
                            color: MAIN_COLOR,
                            fontSize: RF(4),
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: 20,
                          }}
                        >
                          Connectez-vous sur :
                        </Text>
                        <Text
                          style={{
                            color: THEME === "ogcnice-stade" ? "#fff" : "#000",
                            fontSize:
                              (getWidthFromHDMargin(
                                700,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) /
                                MAIN_URL.length) *
                              1.65,
                            fontWeight: "bold",
                          }}
                        >
                          {MAIN_URL.toUpperCase()}
                        </Text>
                      </View>
                    </View>
                  </Animated.View>
                )}
                {should_hide_qrcode === false && (
                  <Animated.View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: 'transparent',
                      // THEME === "ogcnice-stade" ? "transparent" : "#fff",
                      borderTopLeftRadius: 25,
                      borderTopRightRadius: 25,
                      // width: getWidthFromHDMargin(
                      //   740,
                      //   mtop,
                      //   mright,
                      //   mbottom,
                      //   mleft
                      // ),
                      // height: getHeightFromHDMargin(
                      //   700,
                      //   mtop,
                      //   mright,
                      //   mbottom,
                      //   mleft
                      // ),
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {THEME !== 'challengeca' && <Text
                        style={{
                          color: "#fff",
                          fontSize: RF(6),
                          fontWeight: "bold",
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                        numberOfLines={1}
                        ellipsizeMode="middle"
                      >
                        {MAIN_URL}
                      </Text>}
                      <View
                        style={{
                          marginTop: 30,
                          marginBottom: 50,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {this.state.showFocus && (
                          <ScaleView
                            style={{
                              position: "absolute",
                              backgroundColor: "green",
                              opacity: this.state.blinkAnimation,
                            }}
                            delay={500}
                            duration={500}
                            size={
                              getWidthFromHDMargin(
                                700,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) + 50
                            }
                          ></ScaleView>
                        )}
                        {this.state.showFocus && (
                          <FadeView
                            style={{ position: "absolute" }}
                            delay={1000}
                            duration={300}
                          >
                            <Image
                              style={{
                                width:
                                  getWidthFromHDMargin(
                                    700,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ) + 50,
                                height:
                                  getWidthFromHDMargin(
                                    700,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ) + 50,
                              }}
                              source={require("../../assets/tutor/focus.png")}
                              resizeMode="contain"
                            />
                          </FadeView>
                        )}

                        <QRCode
                          value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (ONLY_ANONYMOUS ? "/quickSignUp" : "/SignUp"))}
                          fgColor={QRCODE_FOREGROUND_COLOR}
                          bgColor={QRCODE_BACKGROUND_COLOR}
                          size={getWidthFromHDMargin(
                            700,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )}
                        />
                      </View>
                    </View>
                  </Animated.View>
                )}
              </View>
            )}

            <View
              style={{
                flexDirection: "column",
                width: getWidthFromHDMargin(510, mtop, mright, mbottom, mleft),
                height: screenHeight,
              }}
            >
              <Animated.View
                style={{
                  marginRight: getWidthFromHDMargin(
                    40,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  marginTop: getWidthFromHDMargin(
                    45,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                }}
              >
                {this.props.currentSurvey && SHOW_MINUTER &&
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: "#F76C5E",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                        marginBottom: 5,
                      }}
                    >
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        source={require("../../assets/images/menu/sondage.svg")}
                      />
                    </View>

                    <Text
                      style={{
                        color: TEXT_TV_COLOR,
                        fontSize: RF(3.5),
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginBottom: 10,
                      }}
                    >
                      {this.state.screen === "countToEnd"
                        ? "Terminer Dans"
                        : "Début Du Sondage Dans"}
                    </Text>
                  </View>}
                {this.props.currentSurvey && 
                  <TeleCountDown
                    key={this.state.screen}
                    type={2}
                    timeLeft={
                      this.state.screen === "countToEnd"
                        ? this.state.timeEnd
                        : this.props.timeLeft
                    }
                    callbackTimer={
                      this.state.screen === "countToEnd"
                        ? this.callbackTimerForEnd
                        : this.callbackTimer
                    }
                  />}

                {this.props.currentSurvey &&
                  (this.props.navigation.getParam("for") === "wisslmedia" ||
                    (this.props.navigation.state.params
                      ? this.props.navigation.state.params.sonline
                        ? this.props.navigation.state.params.sonline === "true"
                        : SHOW_NULBER_ONLINE
                      : SHOW_NULBER_ONLINE) === true) && (
                    <Text
                      style={{
                        color: TEXT_TV_COLOR,
                        fontSize: RF(3.5),
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: getWidthFromHDMargin(
                          105,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        marginBottom: 10,
                      }}
                    >
                      {this.props.currentSurvey.subtitle}
                    </Text>
                  )}

                {this.props.currentSurvey &&
                  (this.props.navigation.getParam("for") === "wisslmedia" ||
                    (this.props.navigation.state.params
                      ? this.props.navigation.state.params.sonline
                        ? this.props.navigation.state.params.sonline === "true"
                        : SHOW_NULBER_ONLINE
                      : SHOW_NULBER_ONLINE) === true) && (
                    <NumberOnline
                      type="survey"
                      mode={2}
                      logeName={this.props.currentSurvey.subtitle}
                      {...this.props}
                    />
                  )}
              </Animated.View>
            </View>
          </ImageBackground>
        </View>

        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
          }}
          source={
            this.props.navigation.state.params
              ? this.props.navigation.state.params.bnf
                ? this.props.navigation.state.params.bnf
                : THEME_BANNER_FULL
              : THEME_BANNER_FULL
          }
          resizeMode={"stretch"}
        />

        {this.state.animate && (
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            {this.state.msg && (
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(8),
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20,
                  position: "absolute",
                  alignSelf: "center",
                }}
              >
                {this.state.msg}
              </Text>
            )}
          </View>
        )}

        {this.state.showTuto &&
          (this.props.navigation.state.params
            ? this.props.navigation.state.params.stuto
              ? this.props.navigation.state.params.stuto === "true"
              : SHOW_TUTO
            : SHOW_TUTO) === true && (
            <View
              style={{
                width: screenWidth,
                height: screenHeight,
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TutorTele {...this.props} />
            </View>
          )}
      </View>
    );
  }
}

export default TeleSurveyHome2;
