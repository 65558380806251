import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Animated,
} from "react-native";
import { isEn, MENU_V2_ENABLED, TEXT_COLOR, THEME } from "../../constants/const";
import {
  getHeightPercent,
  getWidthPercentMaxLimited,
} from "../../libs/list_picker/ratio";
import moment from "moment";
import "moment/locale/fr";
import { Text } from "@ui-kitten/components";
import { t } from "react-multi-lang";

export class MenuMatch extends Component {
  state = {
    screen: "forecast_available",
    disabled: false,
    showFocus: true,
    blinkAnimation: new Animated.Value(0.2),
  };
  _isMounted = false;
  constructor(props) {
    super(props);
    this.currentForecast = null;
    moment.locale("fr");
  }

  componentDidMount() {
    this._isMounted = true;
    const match_forecasts = window.localStorage.getItem("match_forecasts");
    if (match_forecasts !== null) {
      var _matchForecastsData = JSON.parse(match_forecasts);
      // find by id
      var arrThisMatch = _matchForecastsData.filter(e => e.pronosticId === this.props.currentMatch.id);
      if (arrThisMatch.length > 0) {
        this.currentForecast = arrThisMatch[0];
      }
      if (this.currentForecast && this.currentForecast.matchId === this.props.currentMatch.match_id) {
        if (this.props.mode !== "nologin") {
          this.setState({
            // screen: "forecasted",
            disabled: false,
            currentForecast: this.currentForecast,
          });
        }
      } else {
        // window.localStorage.removeItem("match_forecast");
      }
    } else {
      if (this._isClosed()) {
        this.setState({ disabled: true });
      }
    }

    if (this.props.currentMatch) {
      var intervalId = setInterval(() => {
        this.checkTime();
      }, 1000);
      this.setState({ intervalId: intervalId });
    }
  }

  checkTime = () => {
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    const timeLeft = this.props.currentMatch.start.seconds - now;
    if (timeLeft < 2 * 60) {
      if (timeLeft % 2 === 0) {
        if (this._isMounted) {
          // this.setState({ showFocus: false });
          this.setState({ showFocus: true });
          setTimeout(
            function () {
              if (this._isMounted) {
                this.setState({ showFocus: true });
              }
            }.bind(this),
            200
          );
        }
      }
    }
  };

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }

    this._isMounted = false;
  }

  displayMinute = (min) => {
    switch (min) {
      case "ex1":
        return "Tps add. 1er MT";

      case "ex2":
        return "Tps add. 2e MT";
      default:
        return `${min}'`;
    }
  };

  _isClosed = () => {
    return (
      Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      ) >= this.props.currentMatch.start.seconds ||
      Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      ) <
      this.props.currentMatch.start.seconds -
      this.props.currentMatch.open * 60 * 60
    );
  };

  _isLived = () => {
    return (
      Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      ) >= this.props.currentMatch.start.seconds
    );
  };

  render() {
    const { navigate } = this.props.navigation;
    const maxWidth = this.props.maxWidth;
    const styles = StyleSheet.create({
      moduleContainer: {
        flex: 1,
        height: 100,
        width: getWidthPercentMaxLimited(90, maxWidth),
        flexDirection: "row",
        marginTop: 5,
        marginBottom: 5,
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      moduleTimmer: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: 20,
      },
      moduleButton: {
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        shadowRadius: 10,
        height: 100,
        width: 50,
        backgroundColor: "#0091FF",
        borderRadius: 3,
        shadowColor: "#000",
        shadowOpacity: 0.5,
      },
      moduleButtonDisabled: {
        alignSelf: "center",
        justifyContent: "center",
        height: 70,
        width: 155,
        backgroundColor: "#0091FF",
        borderRadius: 3,
      },
      moduleButtonText: {
        color: TEXT_COLOR,
        fontSize: 20,
        backgroundColor: "#0091FF",
        textAlign: "center",
        textTransform: "uppercase",
        textAlignVertical: "center",
      },
      moduleButtonTextDisabled: {
        color: TEXT_COLOR,
        fontSize: 20,
        backgroundColor: "#0091FF",
        textAlign: "center",
        textAlignVertical: "center",
        textTransform: "uppercase",
      },
      separator: {
        width: getWidthPercentMaxLimited(30, maxWidth),
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        height: 1,
        alignSelf: "center",
      },
      forecastContainer: {
        flex: 1,
        minHeight: getHeightPercent(10),
        marginTop: 5,
        marginBottom: 5,
        justifyContent: "center",
        alignItems: "center",
      },
      forecastLine: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
      forecastText: {
        color: TEXT_COLOR,
        fontSize: getWidthPercentMaxLimited(5, maxWidth),
        fontWeight: "bold",
        textAlign: "center",
        marginLeft: getWidthPercentMaxLimited(2, maxWidth),
        marginRight: getWidthPercentMaxLimited(2, maxWidth),
      },
      forecastTextScorer: {
        color: TEXT_COLOR,
        fontSize: getWidthPercentMaxLimited(4, maxWidth),
        fontWeight: "bold",
        textAlign: "center",
        marginLeft: getWidthPercentMaxLimited(2, maxWidth),
        marginRight: getWidthPercentMaxLimited(2, maxWidth),
      },
    });

    return (
      MENU_V2_ENABLED ?
        <View style={{
          flex: 1,
          height: 80,
          width: maxWidth - 10,
          flexDirection: "row",
          margin: 5,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundImage: 'url("' + require("../../assets/images/menuv2/bg_prono.png") + '")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}>
          <View style={{ width: 50, height: 100 }}>
            <TouchableOpacity
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_select_game_pronostic", {
                    data: this.state,
                  });
                }
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this.props.callbackClicked) {
                    this.props.callbackClicked(this.props.currentMatch);
                  }
                }
              }}
              underlayColor="transparent"
            >
              {this.state.showFocus && (
                <View style={{
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  width: 50,
                  backgroundColor: 'transparent'
                }}>
                  <Image
                    style={this.props.currentMatch.type === "match_rugby" ? { width: 50, height: 50, marginLeft: 30 } : { width: 30, height: 30, marginLeft: 50 }}
                    source={this.props.currentMatch.type === "match_rugby" ? require("../../assets/images/menuv2/ico_rugby.png") : require("../../assets/images/menu/football_black.svg")}
                  />
                </View>
              )}
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={{
              flex: 1,
              backgroundColor: "transparent",
              borderRadius: 5,
              flexDirection: "column",
              marginLeft: 15,
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center"
            }}
            onPress={() => {
              if (this.props.mode === "nologin") {
                navigate("SignIn");
              } else {
                if (this.props.callbackClicked) {
                  this.props.callbackClicked(this.props.currentMatch);
                }
              }
            }}
            underlayColor="transparent"
          >
            <View style={{
              // height: 80,
              // flex: 1,
              backgroundColor: "transparent",
              marginTop: 18,
              // flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center"
            }}>
              <Text style={{ color: "#000", fontWeight: 'bold', fontSize: 20 }}>
                {t('pronostic')}
              </Text>

              <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: 'center' }} >
                <Image
                  style={{
                    width: 40,
                    height: 30,
                    margin: 5,
                  }}
                  source={this.props.currentMatch.team_home_logo}
                  resizeMode="contain"
                />
                <Text
                  style={{ color: "#505050", textAlign: "center", fontSize: 16 }}
                >
                  {THEME === "france23-skyboxes" ? (isEn() ? this.props.currentMatch.name_en : this.props.currentMatch.name).replace(" - ", " V ") : (isEn() ? this.props.currentMatch.name_en : this.props.currentMatch.name)}
                </Text>
                <Image
                  style={{
                    width: 40,
                    height: 30,
                    margin: 5,
                  }}
                  source={this.props.currentMatch.team_visitor_logo}
                  resizeMode="contain"
                />
              </View>

            </View>
          </TouchableOpacity>
        </View>

        :
        <View>
          {this.state.screen === "forecast_available" && (
            <View style={styles.moduleContainer}>
              <View style={{ width: 50, height: 100 }}>
                <TouchableOpacity
                  onPress={() => {
                    if (this.props.firebase && this.props.firebase.analytics) {
                      this.props.firebase.analytics.logEvent("button_select_game_pronostic", {
                        data: this.state,
                      });
                    }
                    if (this.props.mode === "nologin") {
                      navigate("SignIn");
                    } else {
                      if (this.props.callbackClicked) {
                        this.props.callbackClicked(this.props.currentMatch);
                      }
                    }
                  }}
                  underlayColor="transparent"
                >
                  {this.state.showFocus && (
                    <View style={styles.moduleButton}>
                      <Image
                        style={{ width: 30, height: 30 }}
                        source={this.props.currentMatch.type === "match_rugby" ? require("../../assets/images/menu/rugby.svg") : require("../../assets/images/menu/football.svg")}
                      />
                    </View>
                  )}
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: 100,
                  flexDirection: "column",
                  marginLeft: 15,
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onPress={() => {
                  if (this.props.mode === "nologin") {
                    navigate("SignIn");
                  } else {
                    if (this.props.callbackClicked) {
                      this.props.callbackClicked(this.props.currentMatch);
                    }
                  }
                }}
                underlayColor="transparent"
              >
                <View
                  style={{
                    flex: 1,
                    backgroundColor: "white",
                    borderRadius: 5,
                    height: 100,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <View style={{ flexDirection: "row", justifyContent: 'center', alignItems: 'center' }} >
                    <Image
                      style={{
                        width: 40,
                        height: 30,
                        margin: 5,
                      }}
                      source={this.props.currentMatch.team_home_logo}
                      resizeMode="contain"
                    />
                    <Text
                      category="h6"
                      style={{ color: "#505050", textAlign: "center" }}
                    >
                      {THEME === "france23-skyboxes" ? (isEn() ? this.props.currentMatch.name_en : this.props.currentMatch.name).replace(" - ", " V ") : (isEn() ? this.props.currentMatch.name_en : this.props.currentMatch.name)}
                    </Text>
                    <Image
                      style={{
                        width: 40,
                        height: 30,
                        margin: 5,
                      }}
                      source={this.props.currentMatch.team_visitor_logo}
                      resizeMode="contain"
                    />
                  </View>


                  <View style={styles.moduleTimmer}>
                    <Image
                      style={{ width: 20, height: 20, marginRight: 5 }}
                      source={require("../../assets/images/menu/start.svg")}
                    />

                    <Text style={{ fontSize: 10, color: "#505050" }}>
                      {moment((this.props.currentMatch.start.seconds - this.props.currentMatch.open * 60 * 60) * 1000)
                        .format("L LT")
                        .replace(":", "h")}
                    </Text>

                    <Image
                      style={{
                        marginLeft: 10,
                        width: 20,
                        height: 20,
                        marginRight: 5,
                      }}
                      source={require("../../assets/images/menu/end.svg")}
                    />
                    <Text style={{ fontSize: 10, color: "#505050" }}>
                      {moment(this.props.currentMatch.start.seconds * 1000)
                        .format("L LT")
                        .replace(":", "h")}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {this.state.screen === "forecasted" && (
            <View style={styles.forecastContainer}>
              <View style={styles.forecastLine}>
                <Text style={styles.forecastText}>
                  {this.props.currentMatch.team_home}
                </Text>
                <Image
                  style={{
                    width: getWidthPercentMaxLimited(9, maxWidth),
                    height: getWidthPercentMaxLimited(9, maxWidth),
                    margin: 5,
                  }}
                  source={this.props.currentMatch.team_home_logo}
                  resizeMode="contain"
                />
                <Text style={styles.forecastText}>
                  {this.state.currentForecast.scoreHome}
                </Text>
                <Text style={{ color: TEXT_COLOR }}>-</Text>
                <Text style={styles.forecastText}>
                  {this.state.currentForecast.scoreVisitor}
                </Text>
                <Image
                  style={{
                    width: getWidthPercentMaxLimited(9, maxWidth),
                    height: getWidthPercentMaxLimited(9, maxWidth),
                    margin: 5,
                  }}
                  source={this.props.currentMatch.team_visitor_logo}
                  resizeMode="contain"
                />
                <Text style={styles.forecastText}>
                  {this.props.currentMatch.team_visitor}
                </Text>
              </View>
              <View style={styles.forecastLine}>
                <Text style={styles.forecastTextScorer}>
                  {this.state.currentForecast.firstScorer.name}
                </Text>
                <Text style={styles.forecastTextScorer}>
                  {this.displayMinute(
                    this.state.currentForecast.firstScoreMinute
                  )}
                </Text>
              </View>
            </View>
          )}
        </View>
    );
  }
}

export default MenuMatch;
