import React from "react";
import {
  ImageBackground,
  View,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../Reponses";
import FadeSlideView from "../Anim/FadeSlideView";
import BaseAsync from "./baseAsync";
import {
  TEXT_COLOR,
  ASYNC_COUNT_DOWN,
  isEn,
  SHOW_GAMES,
  APP_NAVTAB,
  ASYNC_SKIP_NEXT,
  TIME_PAUSE,
  MENU_COLOR,
  BANNER_BOTTOM_ENABLE,
} from "../../constants/const";
import CountDown from "../CountDown";
import { withTranslation, t } from "react-multi-lang";
import { Button } from "@ui-kitten/components";

export class Async extends BaseAsync {
  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackStartTimer = this.callbackStartTimer.bind(this);
    this.callbackReponse = this.callbackReponse.bind(this);
  }

  getStateTimer = () => {
    if (this.state.currentAsync != null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      this.timeLeftSecs = this.state.currentAsync.start.seconds - now;
    }
  };

  callbackReponse = (pst) => {
    this.currentPointGap = pst;
    this.currentPoints += pst;

    if (ASYNC_SKIP_NEXT) {
      this.finishCountDownCallback();
    } else {
      setTimeout(() => {
        this.setState({
          answered: true,
        });
      }, 2000);
    }

  };

  callbackStartTimer(val) {
    //Quizzes started
    // console.log(val);
    if (val >= 0) {
      this.checkTimer(val);
    } else {
      console.log("checkQuizAndQuestions from callbackStartTimer");
      this.checkQuizAndQuestions();
      // if (this._isMounted) {
      //   if(!this.alreadyCount){
      //     this.setState({ screen: "Question" });
      //   }
      // }
    }
  }

  checkTimer = (val) => {
    this.getStateTimer();
    if (this.currentAction) {
      if (ASYNC_COUNT_DOWN && this.alreadyCount) {
        return true;
      }
      if (Math.abs(val - this.timeLeftSecs) > 0) {
        if (this._isMounted) {
          // console.log('checkTimer');
          this.setState({
            timeLeft: this.timeLeftSecs,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    }
  };

  callbackTimer(val) {
    // check again the question
    // this.checkQuizAndQuestions();
    // check timer and current question time
  }

  finishCountDownCallback = () => {
    this.setState({
      answered: false,
    });
    // console.log("finishCountDownCallback ", this.state);
    //Show result and time response
    if (this.state.displayResult === true) {
      // Next question
      console.log("checkQuizAndQuestions from finishCountDownCallback");
      this.checkQuizAndQuestions(true);
      this.currentPointGap = 0;
      // if (this._isMounted) {
      //   this.setState({ displayResult: false, updateNum: this.state.updateNum + 1 });
      // }
    } else {
      if (this._isMounted) {
        this.setState({
          displayResult: true,
          updateNum: this.state.updateNum + 1,
        });
      }
    }
  };

  render() {
    //console.log(this.state);
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: this.props.maxWidth,
        }}
      >
        {this.state.screen === "CountDown" && this.state.currentAsync && (
          <CountDown
            mode="insider"
            header_text={`${t("start_the_quiz")}
"${isEn() ? this.state.currentAsync.name_en : this.state.currentAsync.name}"`}
            header_text_font={RF(3)}
            msgWaiting={`${isEn() ? this.state.currentAsync?.msg_waiting_en : this.state.currentAsync?.msg_waiting}` || ''}
            key={this.state.updateNum}
            {...this.props}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackStartTimer}
          />
        )}

        {(this.state.screen === "nomore" ||
          this.state.screen === "finished_for_today" ||
          this.state.screen === "doesnotexist") && (
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  textAlign: "center",
                  textAlignVertical: "center",
                  flex: 1,
                  marginTop: 50,
                  marginLeft: 20,
                  marginRight: 20,
                  fontSize: RF(2.6),
                  paddingBottom: 5,
                  textTransform: "uppercase",
                }}
              >
                {this.state.screen === "nomore"
                  ? t("played_all_questions")
                  : this.state.screen === "finished_for_today"
                    ? t("you_played_all_today")
                    : t("the_quiz_is_not_available")}
              </Text>
              {(this.state.screen === "nomore" || this.state.screen === "finished_for_today") && (
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 18,
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${this.state.currentAsync.msg_finished}`,
                    }}
                  ></div>
                </Text>
              )}
              <View style={{
                position: 'fixed',
                bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
                width: '100%',
                backgroundColor: MENU_COLOR,
                padding: 15
              }}>
                <TouchableOpacity
                  onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate("SelectGame") : navigate("Home")}
                >
                  <View style={{
                    flexDirection: "row",
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Image
                      style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                      source={require("../../assets/images/back.svg")}
                      resizeMode="contain"
                    />
                    <Text
                      style={{
                        color: TEXT_COLOR,
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: 'bold'
                      }}
                    >
                      {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          )}

        {this.state.screen === "finished" && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                textAlign: "center",
                textAlignVertical: "center",
                flex: 1,
                marginTop: 30,
                fontSize: RF(2.6),
                paddingBottom: 5,
              }}
            >
              QUIZ TERMINÉ
            </Text>
            <Text
              style={{
                color: TEXT_COLOR,
                textAlign: "center",
                textAlignVertical: "center",
                flex: 1,
                fontSize: RF(2.2),
                paddingBottom: 5,
              }}
            >
              POINTS: {this.currentPoints}
            </Text>

            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: 18,
                paddingLeft: 5,
                paddingRight: 5,
                textAlign: "center",
                marginTop: 10,
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `${this.state.currentAsync.msg_finished}`,
                }}
              ></div>
            </Text>

            <View style={{
              position: 'fixed',
              bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
              width: '100%',
              backgroundColor: MENU_COLOR,
              padding: 15
            }}>
              <TouchableOpacity
                onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate("SelectGame") : navigate("Home")}
              >
                <View style={{
                  flexDirection: "row",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Image
                    style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                    source={require("../../assets/images/back.svg")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 'bold'
                    }}
                  >
                    {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )}

        {this.state.screen === "Question" &&
          this.state.currentQuestion &&
          this.state.currentQuestion && (
            <View>
              <View
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                }}
              >
                <ImageBackground
                  key={this.state.displayResult}
                  style={{
                    width: 66,
                    height: 66,
                    marginLeft: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/circle.png")}
                >
                  <CountDownCircle
                    key={this.state.timeLeft}
                    seconds={
                      this.state.displayResult
                        ? TIME_PAUSE
                        : this.state.timeLeft || 10
                    }
                    radius={28}
                    borderWidth={8}
                    color={this.state.displayResult ? "limegreen" : "#ff003f"}
                    bgColor="#fff"
                    textStyle={{ fontSize: 18 }}
                    finishCallback={() => this.finishCountDownCallback()}
                    callbackTimer={this.callbackTimer}
                  />
                </ImageBackground>

                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center",
                    }}
                  >
                    {isEn()
                      ? this.state.currentQuestion.question_en
                      : this.state.currentQuestion.question}
                  </Text>
                </View>
              </View>
              {this.state.currentAsync.questions && (
                <PagingControl
                  key={this.state.updateNum}
                  style={{ marginTop: 15 }}
                  max={this.state.currentAsync.questions.length}
                  width={320}
                  current={this.state.currentQuestionIndex + 1}
                />
              )}

              <View
                style={{
                  top: 10,
                  paddingBottom: 150,
                }}
                key={this.state.displayResult}
              >
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {this.state.currentAsync.play_sound === "all" &&
                    !this.state.displayResult &&
                    this.state.currentQuestion.sound && (
                      <audio autoPlay>
                        <source src={this.state.currentQuestion.sound} />
                      </audio>
                    )}

                  {this.state.currentQuestion.image &&
                    (this.state.currentQuestion.type === "5" || this.state.currentQuestion.type === "8" ||
                      this.state.currentQuestion.type === "6") && (
                      <Image
                        style={{
                          width:
                            this.state.currentQuestion.type === "6" ? 320 : 200,
                          height:
                            this.state.currentQuestion.type === "6" ? 180 : 200,
                          borderRadius: 10,
                          marginLeft: 5,
                          marginRight: 5,
                          marginBottom: 5,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          borderWidth: 1,
                          filter: this.state.displayResult ? "" : (this.state.currentQuestion.type === "8" ? "blur(2px)" : ""),
                        }}
                        // source={{ uri: this.state.currentQuestion.image }}
                        source={this.state.displayResult && this.state.currentQuestion.image_result ? { uri: this.state.currentQuestion.image_result } : { uri: this.state.currentQuestion.image }}
                      />
                    )}

                  <FadeSlideView delay={600} direction="up">
                    <View
                      style={{
                        paddingBottom: 5,
                      }}
                    >
                      <Response
                        {...this.props}
                        key={this.state.displayResult}
                        mode="async"
                        data={{
                          answers: this.state.currentQuestion.answers,
                          currentQuestionId: this.state.currentQuestion.id,
                          currentQuestionTimeLeft:
                            this.state.currentQuestion.timeLeft,
                          currentQuestionTimeLeftMs:
                            this.state.currentQuestion.timeLeftMs,
                          currentQuestionTimeEnd:
                            this.state.currentQuestion.timeEnd,
                          currentAsyncId: this.state.currentAsync.id,
                          currentAsyncSeconds:
                            this.state.currentAsync.start.seconds,
                          competitionId: this.state.currentAsync.competition_id,
                          withImage: this.state.currentQuestion.withImage,
                          withResult: this.state.displayResult,
                          currentQuestion: this.state.currentQuestion,
                        }}
                        callbackReponse={this.callbackReponse}
                      />

                      {this.state.answered && (
                        <Button
                          style={{
                            marginHorizontal: 60,
                            marginVertical: 10,
                            borderRadius: 25,
                          }}
                          onPress={() => {
                            if (this.props.firebase && this.props.firebase.analytics) {
                              this.props.firebase.analytics.logEvent("button_async_next", { name: 'button_async_next' });
                            }
                            console.log("next question");
                            this.finishCountDownCallback();
                          }}
                        >
                          {t("next")}
                        </Button>
                      )}
                    </View>
                  </FadeSlideView>

                  {this.state.displayResult && (
                    <View
                      style={{
                        paddingBottom: 100,
                      }}
                    >
                      <Text
                        style={{
                          color: TEXT_COLOR,
                          textAlign: "center",
                          textAlignVertical: "center",
                          flex: 1,
                          marginLeft: 8,
                          fontSize: RF(2.2),
                          paddingBottom: 5,
                        }}
                      >
                        POINTS: {this.currentPoints} (+{this.currentPointGap})
                      </Text>

                      {this.state.currentQuestion.explication && <Text
                        style={{
                          color: TEXT_COLOR,
                          textAlign: "center",
                          textAlignVertical: "center",
                          flex: 1,
                          marginLeft: 8,
                          fontSize: 16,
                          paddingBottom: 5,
                        }}
                      >
                        {this.state.currentQuestion.explication}
                      </Text>}
                    </View>
                  )}
                </View>
              </View>
            </View>
          )}
      </View>
    );
  }
}

export default withTranslation(Async);
