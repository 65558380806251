import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { View, TouchableOpacity, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeView from "../../components/Anim/FadeView";
import { withAuthorization } from "../../components/Session";
import LottieView from "react-native-web-lottie";
import {
  TEXT_MENU,
  TEXT_COLOR,
  GEOLOCATION,
  GEOLOCATION_ONE_LAT,
  GEOLOCATION_ONE_LON,
  GEOLOCATION_OBLIGATION_TYPE,
  GEOLOCATION_DISTANCE,
  LOGE_NUMBERS,
  GEOLOCATION_TIMEOUT,
  MAIN_URL,
  TEXT_UNDER_MENU,
  THEME,
  MENU_COLOR,
  BANNER_BOTTOM_ENABLE,
} from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import MenuMatch from "../../components/Menu/menu_match";
import MenuQuiz from "../../components/Menu/menu_quiz";
import MenuAsync from "../../components/Menu/menu_async";
import MenuDuel from "../../components/Menu/menu_duel";
import MenuDraw from "../../components/Menu/menu_draw";
import MenuSurvey from "../../components/Menu/menu_survey";
import TOButton from "../../libs/to_button";
import Geolocation from "react-geolocation";
import moment from "moment";
import MenuSA from "../../components/Menu/menu_sa";
import { Layout, Text } from "@ui-kitten/components";
import { withTranslation, t } from "react-multi-lang";
import MenuPrediction from "../../components/Menu/menu_prediction";

export class SelectGameScreen extends Component {
  state = { forecasted: false, finaldata: false, showGeoInfo: 0 };
  _isMounted = false;
  intervalId = null;
  timeoutId = null;

  currentQuiz = null;
  currentDraw = null;
  currentAsyncs = null;
  currentSAs = null;
  currentSurvey = null;
  currentMatch = null;
  currentMatchs = null;
  currentPredictions = null;
  currentDuel = null;

  selectedAsyncId = null;

  constructor(props) {
    super(props);
    if (props.authUser) {
      // TODO: check option if get all or get only ids
      if (THEME !== "parisjoue-la" || THEME !== "meetingparis2022") {
        if (props.getAsyncAll()) {
          props.getAsyncAll();
          console.log("load all async async");
        }
        if (THEME === "parisjoue-la2" || THEME === "paris23-jeux") {
          props.navigation.navigate("QuizList");
        }
      } else {
        if (props.getAsyncByIds()) {
          // already called
          console.log("already async call ");
        }
      }

      if (props.getSAAll()) {
        props.getSAAll();
        console.log("load all sa async");
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.authUser) {
      // TODO: check option if get all or get only ids
      // if (THEME !== "parisjoue-la" || THEME !== "meetingparis2022") {
      //   if (this.props.getAsyncAll) {
      //     this.props.getAsyncAll();
      //     console.log("load all async async");
      //   }
      //   if (THEME === "parisjoue-la2") {
      //     this.props.navigation.navigate("QuizList");
      //   }
      // } else {
      //   if (this.props.getAsyncByIds()) {
      //     // already called
      //     console.log("already async call ");
      //   }
      // }

      // if (this.props.getSAAll) {
      //   this.props.getSAAll();
      //   console.log("load all sa async");
      // }


      // this.intervalId = setInterval(() => {
      this.checkTimeDisplay();
      // }, 1000);
      this.timeoutId = setTimeout(() => {
        const SAmsg = window.localStorage.getItem("SAmsg");
        if (SAmsg) {
          this.setState(
            {
              msgSA: SAmsg,
            },
            () => {
              setTimeout(() => {
                window.localStorage.removeItem("SAmsg");
              }, 3000);
            }
          );
        }
        if (this._isMounted) {
          this.setState({ finaldata: true });
        }
      }, 500);
    }


  }

  componentWillUnmount() {
    // if(this.intervalId){
    //   clearInterval(this.intervalId);
    // }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this._isMounted = false;
  }

  checkTimeDisplay() {
    // console.log("checkTimeDisplay");
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    var cQ = window.localStorage.getItem("currentQuiz");
    if (cQ !== null) {
      var savedQ = JSON.parse(cQ);
      if (savedQ !== null) {
        this.currentQuiz = savedQ;
      }
    }

    var cS = window.localStorage.getItem("currentSurvey");
    if (cS !== null) {
      var savedS = JSON.parse(cS);
      if (savedS !== null) {
        this.currentSurvey = savedS;
      }
    }
    var cM = window.localStorage.getItem("currentMatch");
    if (cM !== null) {
      var savedM = JSON.parse(cM);
      if (savedM !== null) {
        this.currentMatch = savedM;
      }
    }
    var cMs = window.localStorage.getItem("currentMatchs");
    if (cMs !== null) {
      var savedMs = JSON.parse(cMs);
      if (savedMs !== null) {
        this.currentMatchs = savedMs;
      }
    }
    var cPs = window.localStorage.getItem("currentPredictions");
    if (cPs !== null) {
      var savedPs = JSON.parse(cPs);
      if (savedPs !== null) {
        this.currentPredictions = savedPs;
      }
    }
    var cD = window.localStorage.getItem("currentDuel");
    if (cD !== null) {
      var savedD = JSON.parse(cD);
      if (savedD !== null) {
        this.currentDuel = savedD;
      }
    }
    var cT = window.localStorage.getItem("currentDraw");
    if (cT !== null) {
      var savedT = JSON.parse(cT);
      if (savedT !== null) {
        this.currentDraw = savedT;
      }
    }
    // const currentQuiz = window.localStorage.getItem("currentQuiz");
    if (this.props.currentQuiz !== null && this.props.currentQuiz.start) {
      this.currentQuiz = this.props.currentQuiz;
    }
    if (this.props.currentAsyncs) {
      this.currentAsyncs = this.props.currentAsyncs;
    }
    if (this.props.currentSAs) {
      this.currentSAs = this.props.currentSAs;
    }
    // const currentSurvey = window.localStorage.getItem("currentSurvey");
    if (this.props.currentSurvey !== null && this.props.currentSurvey.start) {
      this.currentSurvey = this.props.currentSurvey;
    }

    if (this.props.currentDuel !== null && this.props.currentDuel.start) {
      this.currentDuel = this.props.currentDuel;
    }

    if (this.props.currentDraw !== null && this.props.currentDraw.start) {
      this.currentDraw = this.props.currentDraw;
    }

    // const currentMatch = window.localStorage.getItem("currentMatch");
    if (this.props.currentMatch) {
      this.currentMatch = this.props.currentMatch;
      // console.log("currentMatch = ", this.props.currentMatch);
      const match_forecast = window.localStorage.getItem("match_forecast");
      if (match_forecast !== null) {
        this.currentForecast = JSON.parse(match_forecast);
        if (this.currentForecast.matchId === this.currentMatch.match_id) {
          // Same match and already forecasted
          if (this._isMounted) {
            this.setState({ forecasted: true });
          }
        } else {
          // not the same match, delete the old one
          // if (this._isMounted) {
          //   this.setState({ forecasted: false });
          // }
        }
      }
    }

    if (this.currentQuiz) {
      if (now > this.currentQuiz.end.seconds) {
        console.log("looking for next quiz");
        window.localStorage.removeItem("currentQuiz");
        window.location.reload(true);
      }
    }

    if (this.currentSurvey) {
      // console.log(now - this.currentSurvey.end.seconds);
      if (now > this.currentSurvey.end.seconds) {
        console.log("looking for next survey");
        window.localStorage.removeItem("currentSurvey");
        window.location.reload(true);
      }
    }

    if (this.currentDuel) {
      if (now > this.currentDuel.end.seconds) {
        console.log("looking for next DUEL");
        window.localStorage.removeItem("currentDuel");
        window.location.reload(true);
      }
    }

    if (this.currentDraw) {
      if (now > this.currentDraw.end.seconds) {
        console.log("looking for next DRAW");
        window.localStorage.removeItem("currentDraw");
        window.location.reload(true);
      }
    }

    if (this.currentMatch && this.currentMatch.end_time) {
      // console.log(now - this.currentSurvey.end.seconds);
      if (now > this.currentMatch.end_time.seconds) {
        console.log("looking for next match");
        window.localStorage.removeItem("currentMatch");
        window.location.reload(true);
      }
    }
    // console.log("ended checkTimeDisplay");
  }

  calculDistance = (lat1, lon1, lat2, lon2) => {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344 * 1000; // m
      return dist;
    }
  };

  isGoodDistance(lat1, lon1) {
    var distance = 0;
    if (GEOLOCATION_OBLIGATION_TYPE === "BY_GROUP") {
      var arrList = LOGE_NUMBERS();
      for (let i = 0; i < arrList.length; i++) {
        var loge = arrList[i];
        //Get from data with geolocation
        var arrloge = loge.split(";");
        var logeLat = 0;
        var logeLon = 0;
        var logeName = arrloge[0];
        if (arrloge.length > 1) {
          logeLat = arrloge[1];
        }
        if (arrloge.length > 2) {
          logeLon = arrloge[2];
        }
        distance = this.calculDistance(lat1, lon1, logeLat, logeLon);
        console.log(`${logeName} distance == `, distance);
        if (distance <= GEOLOCATION_DISTANCE) {
          const { firebase } = this.props;
          firebase
            .user(this.props.authUser.uid)
            .set(
              {
                logeNumber: logeName,
                logeNumberDate: moment().format("YYYY-MM-DD"),
                lat: lat1,
                lon: lon1,
                geoDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              },
              { merge: true }
            )
            .catch((error) => {
              console.log(error);
            });
          return true;
        }
      }
    } else {
      // BY_ONE
      distance = this.calculDistance(
        lat1,
        lon1,
        GEOLOCATION_ONE_LAT,
        GEOLOCATION_ONE_LON
      );
      console.log("distance == ", distance);
      if (distance > GEOLOCATION_DISTANCE) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  _renderMenuQuiz = (type) => {
    return (
      <MenuQuiz
        type={type}
        key="quiz"
        {...this.props}
        callbackClicked={this._callbackQuizClicked}
        callbackClickedAsync={this._callbackAsyncClicked}
      />
    );
  };

  _renderMenuAsync = (async) => {
    return (
      <MenuAsync
        key={async.id}
        currentAsync={async}
        navigation={this.props.navigation}
        callbackClicked={this._callbackAsyncClicked}
      />
    );
  };

  _renderMenuSA = (async) => {
    return (
      <MenuSA
        key={async.id}
        currentSA={async}
        navigation={this.props.navigation}
        callbackClicked={this._callbackSAClicked}
      />
    );
  };

  _renderMenuSurvey = (type) => {
    return (
      <MenuSurvey
        type={type}
        key="survey"
        {...this.props}
        callbackClicked={this._callbackSurveyClicked}
        callbackClickedSA={this._callbackSAClicked}
      />
    );
  };

  _renderMenuDuel = () => {
    return (
      <MenuDuel
        key="duel"
        {...this.props}
        callbackClicked={this._callbackDuelClicked}
      />
    );
  };

  _renderMenuDraw = () => {
    return (
      <MenuDraw
        key="draw"
        {...this.props}
        callbackClicked={this._callbackDrawClicked}
      />
    );
  };

  _renderMenuMatch = (_match) => {
    return (
      <MenuMatch
        key={`match_${_match.id}`}
        {...this.props}
        currentMatch={_match}
        callbackClicked={this._callbackMatchClicked}
      />
    );
  };

  _renderMenuPrediction = (_pred) => {
    return (
      <MenuPrediction
        key={`prediction_${_pred.id}`}
        {...this.props}
        currentPrediction={_pred}
        callbackClicked={this._callbackPredictionClicked}
      />
    );
  };

  _isGeoUnderTimeoutAndGood = () => {
    const lp = window.localStorage.getItem("lp");
    if (lp) {
      const lpJson = JSON.parse(lp);
      if (lpJson) {
        const lastGeo = lpJson.date || 0;
        const lastLat = lpJson.lat || 0;
        const lastLon = lpJson.lon || 0;
        const timeout = parseInt(GEOLOCATION_TIMEOUT, 10) || 0;
        // console.log('lastGeo + (timeout * 60 * 1000) - Date.now() = ', lastGeo + (timeout * 60 * 1000) - Date.now());
        if (lastGeo + timeout * 60 * 1000 >= Date.now()) {
          if (this.isGoodDistance(lastLat, lastLon)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  _callbackMatchClicked = (_match) => {
    console.log("Match clicked = ", _match);
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectMatch(_match);
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "match" });
      }
    } else {
      this._redirectMatch(_match);
    }
  };

  _callbackPredictionClicked = (_pred) => {
    console.log("Prediction clicked = ", _pred);
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectPrediction(_pred);
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "prediction" });
      }
    } else {
      this._redirectPrediction(_pred);
    }
  };

  _redirectMatch = (_match) => {
    const { navigate } = this.props.navigation;
    if (_match.type === "match_rugby") {
      navigate("Rugby", { mid: _match.id });
    } else {
      navigate("Forecast", { mid: _match.id });
    }
  };

  _redirectPrediction = (_pred) => {
    const { navigate } = this.props.navigation;
    navigate("Prediction", { pid: _pred.id });
  };

  _callbackAsyncClicked = (aid) => {
    console.log("Async clicked = ", aid);
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectAsync(aid);
      } else {
        this.selectedAsyncId = aid;
        this.setState({ showGeoInfo: 1, shouldOpen: "async" });
      }
    } else {
      this._redirectAsync(aid);
    }
  };

  _callbackSAClicked = (aid) => {
    console.log("SA clicked = ", aid);
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectSA(aid);
      } else {
        this.selectedSAId = aid;
        this.setState({ showGeoInfo: 1, shouldOpen: "sa" });
      }
    } else {
      this._redirectSA(aid);
    }
  };

  _callbackQuizClicked = () => {
    console.log("Quiz clicked");
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectQuiz();
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "quiz" });
      }
    } else {
      this._redirectQuiz();
    }
  };

  _callbackDuelClicked = () => {
    console.log("Duel clicked");
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectDuel();
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "duel" });
      }
    } else {
      this._redirectDuel();
    }
  };

  _callbackDrawClicked = () => {
    console.log("Draw clicked");
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectDraw();
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "draw" });
      }
    } else {
      this._redirectDraw();
    }
  };

  _redirectQuiz = () => {
    console.log("_redirectQuiz clicked");
    const { navigate } = this.props.navigation;
    navigate("Quizzes");
  };

  _redirectAsync = (aid) => {
    // window.location.replace(`http://localhost:3000/async/?aid=${aid}`);
    window.location.replace(`https://${MAIN_URL}/async/?aid=${aid}`);
  };

  _redirectSA = (aid) => {
    // window.location.replace(`http://localhost:3000/sa/?sid=${aid}`);
    window.location.replace(`https://${MAIN_URL}/sa/?sid=${aid}`);
  };

  _redirectDuel = () => {
    const { navigate } = this.props.navigation;
    navigate("Duel");
  };

  _redirectDraw = () => {
    const { navigate } = this.props.navigation;
    navigate("Draw");
  };

  _callbackSurveyClicked = () => {
    console.log("Survey clicked");
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectSurvey();
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "survey" });
      }
    } else {
      this._redirectSurvey();
    }
  };

  _redirectSurvey = () => {
    const { navigate } = this.props.navigation;
    navigate("Surveys");
  };

  _renderMenu = () => {
    var out = [];
    var outRender = [];
    console.log("this.props _renderMenu = ", this.props);
    if (this.props.currentMatchs) {
      for (let _i = 0; _i < this.props.currentMatchs.length; _i++) {
        const _m = this.props.currentMatchs[_i];
        out.push({
          type: "match",
          start:
            _m.start.seconds -
            _m.open * 60 * 60,
          data: _m
        });
      }
    }

    if (this.props.currentPredictions) {
      for (let _i = 0; _i < this.props.currentPredictions.length; _i++) {
        const _m = this.props.currentPredictions[_i];
        out.push({
          type: "prediction",
          start:
            _m.start.seconds -
            _m.open * 60 * 60,
          data: _m
        });
      }
    }

    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({ type: "quiz", start: this.props.currentQuiz.start.seconds });
    }
    if (this.props.currentAsyncs) {
      for (let i = 0; i < this.props.currentAsyncs.length; i++) {
        const a = this.props.currentAsyncs[i];
        if (a.id) {
          out.push({ type: "async", start: a.start.seconds, data: a });
        }
      }
    }

    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.props.currentSurvey.start.seconds,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({ type: "sa", start: a.start.seconds, data: a });
        }
      }
    }
    if (this.props.currentDuel && this.props.currentDuel.name !== "") {
      out.push({ type: "duel", start: this.props.currentDuel.start.seconds });
    }

    if (this.props.currentDraw) {
      out.push({ type: "draw", start: this.props.currentDraw.start.seconds });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    // Array for SA + QA
    var alreadyHaveS = false;
    var alreadyHaveQ = false;

    console.log("menu = ", out);

    if (out.length > 0) {
      for (let i = 0; i < out.length; i++) {
        const event = out[i];
        switch (event.type) {
          case "prediction":
            outRender.push(this._renderMenuPrediction(event.data));
            break;
          case "match":
            outRender.push(this._renderMenuMatch(event.data));
            break;
          case "quiz":
            if (!alreadyHaveQ) {
              outRender.push(this._renderMenuQuiz("quiz"));
              alreadyHaveQ = true;
            }
            break;
          case "async":
            if (!alreadyHaveQ) {
              // outRender.push(this._renderMenuAsync(event.data));
              outRender.push(this._renderMenuQuiz("async"));
              alreadyHaveQ = true;
            }
            break;
          case "duel":
            outRender.push(this._renderMenuDuel());
            break;
          case "draw":
            outRender.push(this._renderMenuDraw());
            break;
          case "survey":
            if (!alreadyHaveS) {
              console.log("will show Survey");
              outRender.push(this._renderMenuSurvey("survey"));
              alreadyHaveS = true;
            }
            break;
          case "sa":
            if (!alreadyHaveS) {
              console.log("will show Survey Async");
              outRender.push(this._renderMenuSurvey("sa"));
              alreadyHaveS = true;
            }
            break;
          default:
            break;
        }
      }
    }

    return outRender;
  };

  render() {
    const maxWidth = this.props.maxWidth;
    // console.log('SelectGAME props = ', this.props);
    return (
      <View
        style={{
          alignItems: "center",
        }}
        key={this.props.toString()}
      >
        <View
          style={{
            padding: 10,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state.msgSA && (
            <Text
              style={{
                color: this.props.navigation.state.params
                  ? this.props.navigation.state.params.tc
                    ? this.props.navigation.state.params.tc
                    : TEXT_COLOR
                  : TEXT_COLOR,
                fontSize: 16,
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              {this.state.msgSA}
            </Text>
          )}

          <Text
            style={{
              color: this.props.navigation.state.params
                ? this.props.navigation.state.params.tc
                  ? this.props.navigation.state.params.tc
                  : TEXT_COLOR
                : TEXT_COLOR,
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `${this.props.navigation.state.params
                  ? this.props.navigation.state.params.mt
                    ? this.props.navigation.state.params.mt
                    : TEXT_MENU()
                  : TEXT_MENU()
                  }`,
              }}
            />
          </Text>
        </View>
        <FadeView
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
          }}
          duration={200}
        >
          {this._renderMenu()}
          {this.state.finaldata === false && (
            <LottieView
              style={{
                position: "fixed",
                height: 50,
                width: 50,
                bottom: 80,
                alignSelf: "center",
              }}
              source={require("../../assets/anim/loader.json")}
              autoPlay
              loop
            />
          )}

          <Layout style={{ margin: 10, color: "#fff" }}>
            <div dangerouslySetInnerHTML={{ __html: `${TEXT_UNDER_MENU()}` }} />
          </Layout>

          {this.state.finaldata &&
            !this.props.currentMatch &&
            !this.props.currentDuel &&
            !this.props.currentDraw &&
            !(this.props.currentQuiz && this.props.currentQuiz.name !== "") &&
            !this.props.currentAsyncs &&
            !this.props.currentSAs &&
            !this.props.currentNote &&
            !(
              this.props.currentSurvey && this.props.currentSurvey.name !== ""
            ) && (
              <Text
                style={{
                  fontSize: RF(2),
                  textAlign: "center",
                  color: this.props.navigation.state.params
                    ? this.props.navigation.state.params.tc
                      ? this.props.navigation.state.params.tc
                      : TEXT_COLOR
                    : TEXT_COLOR,
                }}
              >
                {t("no_events_come_back_soon")}
              </Text>
            )}
        </FadeView>

        {/* SHOW INFO GEOLOCATION */}
        {this.state.showGeoInfo === 1 && (
          <Geolocation
            lazy
            onSuccess={(res) => {
              // console.log("Success = ", res);
              // Check the distance
              window.localStorage.setItem(
                "lp",
                JSON.stringify({
                  lat: res.coords.latitude,
                  lon: res.coords.longitude,
                  date: Date.now(),
                })
              );

              if (
                this.isGoodDistance(res.coords.latitude, res.coords.longitude)
              ) {
                // After ok should open the target
                switch (this.state.shouldOpen) {
                  case "match":
                    this._redirectMatch();
                    break;
                  case "survey":
                    this._redirectSurvey();
                    break;
                  case "quiz":
                    this._redirectQuiz();
                    break;
                  case "async":
                    this._redirectAsync(this.selectedAsyncId);
                    break;
                  case "sa":
                    this._redirectSA(this.selectedSAId);
                    break;
                  case "duel":
                    this._redirectDuel();
                    break;
                  case "draw":
                    this._redirectDraw();
                    break;
                  default:
                    break;
                }
              } else {
                this.setState({ showGeoInfo: 5 });
              }
            }}
            onError={(err) => {
              console.log("Error = ", err);
              if (err) {
                if (err.code === 1) {
                  // PERMISSION_DENIED
                  this.setState({ showGeoInfo: 2 });
                } else {
                  // POSITION_UNAVAILABLE
                  this.setState({ showGeoInfo: 4 });
                }
              }
            }}
            render={({ getCurrentPosition, fetchingPosition }) => (
              <View
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(0,0,0, 0.8)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    flexDirection: "column",
                    width: 300,

                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!fetchingPosition && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          textAlignVertical: "center",
                          padding: 10,
                          fontSize: 18,
                          color: "#555",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        {THEME.includes("villagerugby") ? t('geo_info_rugby') : t('geo_info')}
                      </Text>
                      <View style={{ flexDirection: "row" }}>
                        <TOButton
                          style={{ width: 120 }}
                          onClick={() => {
                            this.setState({ showGeoInfo: 0 });
                            // console.log(this.state);
                          }}
                          text={t('later')}
                        />
                        <TOButton
                          style={{ width: 120 }}
                          onClick={getCurrentPosition}
                          text={t('yes')}
                        />
                      </View>
                    </View>
                  )}

                  {fetchingPosition && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          textAlignVertical: "center",
                          padding: 10,
                          fontSize: 18,
                          color: "#555",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        {t('geo_loading')}
                      </Text>
                      <Image
                        style={{
                          width: 100,
                          height: 100,
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                        source={require("../../assets/images/pin.svg")}
                      ></Image>
                      <Text
                        style={{
                          textAlign: "center",
                          textAlignVertical: "center",
                          padding: 10,
                          fontSize: RF(2),
                          color: "#555",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        {t('geo_waiting')}
                      </Text>
                    </View>
                  )}

                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      left: 10,
                      top: 10,
                    }}
                    onPress={() => {
                      this.setState({ showGeoInfo: 0 });
                    }}
                  >
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      source={require("../../libs/list_picker/android-close.png")}
                    ></Image>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          />
        )}
        {/* GEOLOCATION PERMISSION_DENIED */}
        {this.state.showGeoInfo === 2 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: 320,

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: 18,
                    color: "#555",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {`GÉOLOCALISATION INDISPENSABLE

Pour participer, il est indispensable de vous trouver dans un lieu où se déroule l’événement.

Pour permettre la géolocalisation, vous devez désormais l’autoriser depuis les réglages systeme.`}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <TOButton
                  onClick={() => {
                    this.setState({ showGeoInfo: 0 });
                    // console.log(this.state);
                  }}
                  text="OK"
                />
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                  // console.log(this.state);
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* GIF HELP */}
        {this.state.showGeoInfo === 3 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: 320,

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 320,
                    height: 400,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  source={require("../../assets/images/chien.gif")}
                ></Image>
              </View>

              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                  // console.log(this.state);
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* ERROR CAN NOT GET LOCATION */}
        {this.state.showGeoInfo === 4 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: 320,

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: 18,
                    color: "#555",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {`GÉOLOCALISATION IMPOSSIBLE

Nous ne parvenons pas à vous géolocaliser. Nous vous invitons à ressayer plus tard ou depuis un autre lieu.`}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <TOButton
                  onClick={() => {
                    this.setState({ showGeoInfo: 0 });
                  }}
                  text="FERMER"
                />
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* FAR FROM SPOT */}
        {this.state.showGeoInfo === 5 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: 320,

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: 18,
                    color: "#555",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {t('geo_too_far')}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <TOButton
                  onClick={() => {
                    this.setState({ showGeoInfo: 0 });
                  }}
                  text="FERMER"
                />
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}

        <View style={{
          position: 'fixed',
          bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
          width: '100%',
          backgroundColor: MENU_COLOR,
          padding: 15
        }}>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate("HomeInside")}
          >
            <View style={{
              flexDirection: "row",
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Image
                style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                source={require("../../assets/images/back.svg")}
                resizeMode="contain"
              />
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  textAlign: "center",
                  fontWeight: 'bold'
                }}
              >
                {t("back_to_home")}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(
  withMainLayout(withTranslation(SelectGameScreen))
);
