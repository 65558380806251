import React, { Component } from "react";
import {
  Text,
  View
} from "react-native";
import RF from "react-native-responsive-fontsize";
import {
  TEXT_COLOR,
  SECRET_CODE
} from "../../constants/const";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import TOButton from "../../libs/to_button";
import {
  withTranslation
} from "react-multi-lang";
import { Input } from "@ui-kitten/components";

export class SecretCodeScreen extends Component {
  validInput = () => {
    if (this.state === null) {
      return false;
    }

    if (!this.state.codeSecret || this.state.codeSecret === "") {
      return false;
    }

    if (this.state.codeSecret !== SECRET_CODE) {
      return false;
    }

    return true;
  };
  onValidateClicked = () => {
    const errMessage = "Code secret invalid ou manquant";
    if (this.validInput()) {
      this.setState({ error: null });
      window.localStorage.setItem('code', this.state.codeSecret);
      // Go to notice screen for checking email
      this.props.navigation.navigate("SelectGame");
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };
 

  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf:"center",
          height: "100%",
          width: this.props.maxWidth
        }}
      >
        <View
          style={{
            padding: 20,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: 16,
              fontWeight: "normal",
              lineHeight: 24,
              margin: 10,
              textAlign: "center",
              // textTransform: "uppercase"
            }}
          >
            {"Pour participer à Questions pour un Chaudron spécial Arbre de Noël de l'AS Saint-Étienne, saisissez le code (présent sur la feuille à côté de la TV)"}
          </Text>

          {this.state && this.state.error && (
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(1.5),
                margin: 10,
                textAlign: "center"
              }}
            >
              * {this.state.error.message}
            </Text>
          )}

          
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                alignContent: 'center',
                justifyContent: 'center',
                marginTop: 20,
                marginBottom: 20
              }}
            >
              <Input
                status="control"
                autoComplete="false"
                placeholder={'Code secret'}
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ codeSecret: text });
                }}
                value={(this.state && this.state.codeSecret) || ""}
                style={{ marginTop: 15 }}
              />
            </View>
          

          <TOButton onClick={() => this.onValidateClicked()}></TOButton>
          
        </View>
      </View>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(withTranslation(SecretCodeScreen)));
