import React from "react";
import { Animated, Easing } from "react-native";

export class ScaleView extends React.Component {
  valueStart = this.props.out ? (this.props.size || 100) : 0;
  valueEnd = this.props.out ? 0 : (this.props.size || 100);
  state = {
    scaleAnim: new Animated.Value(this.valueStart) // Initial value for opacity: 0
  };

  componentDidMount() {
    setTimeout(() => {
      Animated.timing(
        // Animate over time
        this.state.scaleAnim, // The animated value to drive
        {
          easing: Easing.elastic(1.2),
          toValue: this.valueEnd, // Animate to opacity: 1 (opaque)
          duration: this.props.duration || 1000 // Make it take a while
        }
      ).start(); // Starts the animation
    }, this.props.delay || 0);
  }

  render() {
    let { scaleAnim } = this.state;

    return (
      <Animated.View // Special animatable View
        style={{
          ...this.props.style,
          height: scaleAnim,
          width: scaleAnim // Bind opacity to animated value
        }}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

export default ScaleView;
