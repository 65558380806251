import React, { Component } from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { isEn, MENU_V2_ENABLED, TEXT_COLOR } from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import moment from "moment";
import "moment/locale/fr";
import { Text } from "@ui-kitten/components";

export class MenuQuiz extends Component {
  state = {
    showFocus: true,
  };
  _isMounted = false;
  _currentQuiz = null;
  _arrQuizzes = [];

  componentDidMount() {
    this._isMounted = true;

    this._arrQuizzes = this._renderMenu();

    if (this._arrQuizzes.length <= 1) {
      var intervalId = setInterval(() => {
        this.checkTime();
      }, 1000);
      this.setState({ intervalId: intervalId });
    }
  }

  _renderMenu = () => {
    var out = [];

    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({
        type: "quiz",
        start: this.props.currentQuiz.start.seconds,
        data: this.props.currentQuiz,
      });
    }
    this.currentAsyncs = this.props.currentAsyncs;
    if (this.currentAsyncs) { } else {
      const jca = window.localStorage.getItem("currentAsyncs");
      if (jca) {
        this.currentAsyncs = JSON.parse(jca);
      }
    }
    if (this.currentAsyncs) {
      for (let i = 0; i < this.currentAsyncs.length; i++) {
        const a = this.currentAsyncs[i];
        if (a.id) {
          out.push({ type: "async", start: a.start.seconds, data: a });
        }
      }
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    if (out.length > 0) {
      this._currentQuiz = out[0].data;
    }

    return out;
  };

  checkTime = () => {
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    const timeLeft = this._currentQuiz.start.seconds - now;
    if (timeLeft < 2 * 60) {
      if (timeLeft % 2 === 0) {
        if (this._isMounted) {
          this.setState({ showFocus: false });
          setTimeout(
            function () {
              if (this._isMounted) {
                this.setState({ showFocus: true });
              }
            }.bind(this),
            200
          );
        }
      }
    }
  };

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;
    const maxWidth = this.props.maxWidth;
    const styles = StyleSheet.create({
      moduleContainer: {
        flex: 1,
        height: 100,
        width: getWidthPercentMaxLimited(90, maxWidth),
        flexDirection: "row",
        marginTop: 5,
        marginBottom: 5,
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      moduleTimmer: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: 20,
      },
      moduleButton: {
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        shadowRadius: 10,
        height: 100,
        width: 50,
        backgroundColor: "#44D7B6",
        borderRadius: 3,
        shadowColor: "#000",
        shadowOpacity: 0.5,
      },
      moduleButtonText: {
        color: TEXT_COLOR,
        fontSize: 20,
        backgroundColor: "#44D7B6",
        textAlign: "center",
        textTransform: "uppercase",
        textAlignVertical: "center",
      },
      separator: {
        width: getWidthPercentMaxLimited(0, maxWidth),
        borderBottomColor: "#ccc",
        borderBottomWidth: 1,
        height: 1,
        alignSelf: "center",
      },
    });

    if (this._currentQuiz == null) {
      if (this._arrQuizzes.length > 0) {
        this._currentQuiz = this._arrQuizzes[0];
      }
    }
    return (
      MENU_V2_ENABLED ?
        <View style={{
          flex: 1,
          height: 80,
          width: maxWidth,
          flexDirection: "row",
          marginTop: 5,
          marginBottom: 5,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          backgroundImage: 'url("' + require("../../assets/images/menuv2/bg_quiz.png") + '")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}>
          <View style={{ width: 50, height: 80 }}>
            <TouchableOpacity
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_select_game_quizz", {
                    data: this.state,
                  });
                }
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this._arrQuizzes.length > 1) {
                    navigate("QuizList");
                  } else {
                    if (this._currentQuiz.type === "async") {
                      if (this.props.callbackClickedAsync) {
                        this.props.callbackClickedAsync(this._currentQuiz.id);
                      }
                    } else {
                      if (this.props.callbackClicked) {
                        this.props.callbackClicked();
                      }
                    }
                  }
                }
              }}
              underlayColor="transparent"
            >
              {this.state.showFocus && (
                <View style={{
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  width: 50,
                  backgroundColor: 'transparent'
                }}>
                  <Image
                    style={{ width: 50, height: 50, marginLeft: 50 }}
                    source={require("../../assets/images/menuv2/ico_quiz.png")}
                  />

                  {this._arrQuizzes.length > 1 && (
                    <Text
                      style={{
                        position: "absolute",
                        top: 0,
                        right: -30,
                        backgroundColor: "red",
                        borderColor: "white",
                        borderRadius: 10,
                        borderWidth: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 2,
                      }}
                    >
                      {this._arrQuizzes.length}
                    </Text>
                  )}
                </View>
              )}
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            style={{
              flex: 1,
              height: 80,
              flexDirection: "column",
              marginLeft: 15,
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center"
            }}
            onPress={() => {
              if (this.props.mode === "nologin") {
                navigate("SignIn");
              } else {
                if (this._currentQuiz.type === "async") {
                  if (this.props.callbackClickedAsync) {
                    this.props.callbackClickedAsync(this._currentQuiz.id);
                  }
                } else {
                  if (this.props.callbackClicked) {
                    this.props.callbackClicked();
                  }
                }
              }
            }}
            underlayColor="transparent"
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "transparent",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                height: 80
              }}
            >
              {this._currentQuiz && (
              <Text
                style={{ color: 'white', adjustsFontSizeToFit: true, fontSize: 22}}
                adjustsFontSizeToFit={true}
              >
                {`${isEn() ? this._currentQuiz.name_en : this._currentQuiz.name}`}
              </Text>

               )}
            </View>
          </TouchableOpacity>
        </View>
        :
        <View style={styles.moduleContainer}>
          <View style={{ width: 50, height: 100 }}>
            <TouchableOpacity
              onPress={() => {
                if (this.props.firebase && this.props.firebase.analytics) {
                  this.props.firebase.analytics.logEvent("button_select_game_quizz", {
                    data: this.state,
                  });
                }
                if (this.props.mode === "nologin") {
                  navigate("SignIn");
                } else {
                  if (this._arrQuizzes.length > 1) {
                    navigate("QuizList");
                  } else {
                    if (this._currentQuiz.type === "async") {
                      if (this.props.callbackClickedAsync) {
                        this.props.callbackClickedAsync(this._currentQuiz.id);
                      }
                    } else {
                      if (this.props.callbackClicked) {
                        this.props.callbackClicked();
                      }
                    }
                  }
                }
              }}
              underlayColor="transparent"
            >
              {this.state.showFocus && (
                <View style={styles.moduleButton}>
                  <Image
                    style={{ width: 30, height: 30 }}
                    source={require("../../assets/images/menu/quiz.svg")}
                  />

                  {this._arrQuizzes.length > 1 && (
                    <Text
                      style={{
                        position: "absolute",
                        bottom: 5,
                        backgroundColor: "red",
                        borderColor: "white",
                        borderRadius: 10,
                        borderWidth: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 2,
                      }}
                    >
                      {this._arrQuizzes.length}
                    </Text>
                  )}
                </View>
              )}
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            style={{
              flex: 1,
              backgroundColor: "white",
              borderRadius: 5,
              height: 100,
              flexDirection: "column",
              marginLeft: 15,
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
            onPress={() => {
              if (this.props.mode === "nologin") {
                navigate("SignIn");
              } else {
                if (this._currentQuiz.type === "async") {
                  if (this.props.callbackClickedAsync) {
                    this.props.callbackClickedAsync(this._currentQuiz.id);
                  }
                } else {
                  if (this.props.callbackClicked) {
                    this.props.callbackClicked();
                  }
                }
              }
            }}
            underlayColor="transparent"
          >
            <View
              style={{
                flex: 1,
                backgroundColor: "white",
                borderRadius: 5,
                height: 100,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {this._currentQuiz && (
                <Text
                  category="h6"
                  style={{ color: "#505050", textAlign: "center" }}
                >
                  {isEn() ? this._currentQuiz.name_en : this._currentQuiz.name}
                </Text>
              )}
              <View style={styles.moduleTimmer}>
                <Image
                  style={{ width: 20, height: 20, marginRight: 5 }}
                  source={require("../../assets/images/menu/start.svg")}
                />
                {this._currentQuiz && (
                  <Text style={{ fontSize: 10, color: "#505050" }}>
                    {moment(this._currentQuiz.start.seconds * 1000)
                      .format("L LT")
                      .replace(":", "h")}
                  </Text>
                )}
                <Image
                  style={{
                    marginLeft: 10,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                  source={require("../../assets/images/menu/end.svg")}
                />
                {this._currentQuiz && (
                  <Text style={{ fontSize: 10, color: "#505050" }}>
                    {moment(this._currentQuiz.end.seconds * 1000)
                      .format("L LT")
                      .replace(":", "h")}
                  </Text>
                )}
              </View>
            </View>
          </TouchableOpacity>
        </View>
    );
  }
}

export default MenuQuiz;
