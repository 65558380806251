import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
} from "react-native";
import { withFirebase } from "../Firebase";
import {
  BORDER_COLOR,
  BORDER_WIDTH,
  TEXT_COLOR,
  MAIN_URL,
  THEME,
  URL_RULES,
  TEXT_LOGIN,
  SHOW_GAMES,
  GO_HOME_INSIDE
} from "../../constants/const";
import {
  withTranslation, t
} from "react-multi-lang";

import { Input, Button, Icon } from "@ui-kitten/components";

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.currentUser = null;
    if (props.firebase) {
      props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser != null) {
          // console.log("authUser = ", authUser);
          // console.log("provider = ", authUser.provider);
          //Save prono if needed
          this.currentUser = authUser;
          // check profile
          props.firebase
            .user(authUser.uid)
            .get()
            .then((u) => {
              var uData = u.data();
              console.log("uData = ", uData);
              if (uData) {
                // exist user. does not need update
                // just redirect
                this.savePronoIfNeed();
                this.saveSAIfNeeded(authUser);
                this.gotoAsyncIfNeeded();
              } else {
                // New user, need save.
                const userData = {
                  displayName: authUser.displayName,
                  email: authUser.email,
                  theme: THEME,
                  date: authUser.metadata.creationTime,
                  lastLogin: new Date(Date.now()),
                  agreement: true,
                  uid: authUser.uid,
                };
                if (authUser.displayName) {
                  var lastName = authUser.displayName.split(" ");
                  lastName = lastName[lastName.length - 1];
                  var firstName = authUser.displayName
                    .replace(lastName, "")
                    .trim();
                  userData.firstName = firstName;
                  userData.lastName = lastName;
                }

                props.firebase
                  .user(authUser.uid)
                  .set(userData, { merge: true })
                  .then(() => {
                    // then redirect
                    // should check obligation

                    if (this.checkDidObligation(userData)) {
                      this.savePronoIfNeed();
                      this.saveSAIfNeeded(authUser);
                      this.gotoAsyncIfNeeded();
                    } else {
                      props.navigation.navigate("Profile");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
        }
      });
    }
  }

  checkDidObligation = (uData) => {
    return false;
  };

  savePronoIfNeed = () => {
    const toSave = window.localStorage.getItem("match_forecast_to_save");
    console.log(toSave);
    if (toSave) {
      const dataToSave = JSON.parse(toSave);
      // Save to firestore
      this.props.firebase
        .pronostics_responses(
          `${this.currentUser.uid}_${dataToSave.pronosticId}`
        )
        .set({
          userId: this.currentUser.uid,
          displayName: this.currentUser.displayName,
          email: this.currentUser.email,
          ...dataToSave,
        })
        .then(() => {
          window.localStorage.removeItem("match_forecast_to_save");
          window.localStorage.setItem(
            "match_forecast",
            JSON.stringify(dataToSave)
          );
        });
    }
  };

  gotoAsyncIfNeeded = () => {
    const needOpenDataString = window.localStorage.getItem("needOpenAsync");
    // console.log("needOpenDataString = ", needOpenDataString);
    if (needOpenDataString) {
      const needOpenData = JSON.parse(needOpenDataString);
      switch (needOpenData.type) {
        case "sa":
          window.location.replace(
            `https://${MAIN_URL}/sa/?sid=${needOpenData.id}`
          );
          return;
        case "async":
          window.location.replace(
            `https://${MAIN_URL}/async/?aid=${needOpenData.id}`
          );
          return;
        default:
          break;
      }
    }
    if (GO_HOME_INSIDE) {
      this.props.navigation.navigate("HomeInside");
    } else {
      if (SHOW_GAMES) {
        this.props.navigation.navigate("SelectGame");
      } else {
        this.props.navigation.navigate("HomeInside");
      }
    }
  };

  saveSAIfNeeded = (userData) => {
    const needSaveSAString = window.localStorage.getItem("needSaveSA");
    if (needSaveSAString) {
      const needSaveSA = JSON.parse(needSaveSAString);
      const calculateSurvey =
        this.props.firebase.functions.httpsCallable("calculateSondage");
      for (let i = 0; i < needSaveSA.questions.length; i++) {
        const questionData = needSaveSA.questions[i];
        if (userData) {
          const savedData = {
            ...questionData.savedData,
            userId: userData.uid,
            uid: userData.uid,
            displayName: userData.displayName,
            email: userData.email,
          };
          const calculData = questionData.calculData;
          calculData.userData = savedData;

          console.log(
            "collection = ",
            `${questionData.collection}${userData.uid}`
          );
          console.log("savedData = ", savedData);
          // Save for calcul results
          this.props.firebase
            .surveys_result(`${questionData.collection}${userData.uid}`)
            .set(savedData)
            .then(() => {
              calculateSurvey(calculData).then((re) => {
                console.log("calculateSurvey done");
              });
            });
        }
      }
      //delete needSaveSA
      window.localStorage.removeItem("needSaveSA");
      window.localStorage.setItem(
        "SAmsg",
        t("your_participation_saved")
      );
    }
  };

  validInput = () => {
    if (!this.state || !this.state.email) {
      return false;
    }
    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onGoogleLogin = () => {
    var firebase = this.props.firebase;
    var provider = new firebase.GoogleAuthProvider();
    firebase.auth.signInWithRedirect(provider);

    firebase.auth
      .getRedirectResult()
      .then(function (result) {
        // This gives you a Google Access Tokefn. You can use it to access the Google API.
        console.log("logged in with Google");
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        console.log("google error = ", error);
      });
  };

  onFacebookLogin = () => {
    var firebase = this.props.firebase;
    var provider = new firebase.FacebookAuthProvider();
    firebase.auth.signInWithRedirect(provider);
    firebase.auth
      .getRedirectResult()
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.log("logged in with Facebook = ", result);
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        console.log("Facebook error = ", error);
      });
  };

  onValidateClicked = (firebase) => {
    const errMessage = t('email_invalid');
    if (this.validInput()) {
      this.setState({ error: null });

      const email = this.state.email;
      const password = this.state.password;
      const that = this;
      this.props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(function (result) {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          // do animation
          that.setState({ sentLink: true });
        })
        .catch(function (err) {
          // Some error occurred, you can inspect the code: error.code
          console.log(err);
          that.setState({ error: { message: err.code } });
        });

      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };

  FacebookIcon = (props) => <Icon {...props} name="facebook" />;
  GoogleIcon = (props) => <Icon {...props} name="google" />;

  render() {
    const { navigate } = this.props.navigation;

    return (
      <View style={styles.container}>
        {(!this.state || !this.state.sentLink) && (
          <>
            <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
              <TouchableOpacity onPress={() => navigate("SignUp")} >
                <View style={{ borderBottomWidth: 2, borderColor: TEXT_COLOR, opacity: 0.6 }}>
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 15,
                      margin: 10,
                      textAlign: "center",
                      textTransform: "uppercase"
                    }}
                  >
                    {t('create_account')}
                  </Text>
                </View>
              </TouchableOpacity>
              <View style={{ borderBottomWidth: 2, borderColor: TEXT_COLOR, opacity: 1 }}>
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 15,
                    fontWeight: "bold",
                    margin: 10,
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {t('login')}
                </Text>
              </View>
            </View>
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: 15,
                margin: 10,
                textAlign: "center",
              }}
            >
              {this.props.navigation.state.params
                ? this.props.navigation.state.params.tlogin
                  ? this.props.navigation.state.params.tlogin
                  : TEXT_LOGIN()
                : TEXT_LOGIN()}
            </Text>
            {THEME === 'assejeux' &&
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 15,
                  margin: 10,
                  textAlign: "center",
                  fontStyle: 'italic'
                }}
              >
                *Votre compte ASSE ne vous permet pas de  vous connecter automatiquement
              </Text>
            }
          </>
        )}

        {this.state && this.state.error && (
          <Text
            style={{
              margin: 10,
              textAlign: "center",
              color: TEXT_COLOR
            }}
          >
            --{" "}
            {this.state.error.message === "auth/user-not-found"
              ? t('email_not_found')
              : this.state.error.message === "auth/wrong-password"
                ? t('wrong_password')
                : this.state.error.message}{" "}
            --
          </Text>
        )}

        {(!this.state || !this.state.sentLink) && (
          <View style={{ flexDirection: "column" }}>
            <View style={styles.formContainer}>
              <Input
                placeholder={t("email")}
                status="control"
                onChangeText={(text) => {
                  this.setState({ email: text });
                }}
                value={(this.state && this.state.email) || ""}
              />
              <Input
                style={styles.passwordInput}
                secureTextEntry={true}
                placeholder={t("password")}
                status="control"
                onChangeText={(text) => {
                  this.setState({ password: text });
                }}
                value={(this.state && this.state.password) || ""}
              />
              <TouchableHighlight
                style={{ alignSelf: "flex-end", marginBottom: 20 }}
                onPress={() => navigate("ForgotPassword")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "right",
                    textDecorationLine: "underline",
                  }}
                >
                  {t("forgot_password")}
                </Text>
              </TouchableHighlight>
              <Button
                style={{
                  marginHorizontal: 60,
                  marginVertical: 10,
                  borderRadius: 25,
                  textTransform: 'uppercase'
                }}
                size="large"
                onPress={() => this.onValidateClicked()}
              >
                {t("sign_in")}
              </Button>

              <Text
                style={{
                  flex: 1,
                  textAlignVertical: "center",
                  textAlign: "center",
                  color: TEXT_COLOR,
                  fontWeight: "normal",
                  marginTop: 5,
                }}
              >
                
                {THEME === 'asmonaco' ? <span>
                Je certifie avoir plus de 18 ans, avoir lu et accepté&nbsp;
                  <a
                    style={{ color: TEXT_COLOR }}
                    rel="noopener noreferrer"
                    href={URL_RULES}
                    target="_blank"
                  >
                    le règlement de participation.
                  </a>{" "}
                </span> :
                <span>
                  {t("accept_and_confirm_read")}&nbsp;
                  <a
                    style={{ color: TEXT_COLOR }}
                    rel="noopener noreferrer"
                    href={URL_RULES}
                    target="_blank"
                  >
                    {t("agreement")}
                  </a>{" "}
                </span>}
              </Text>

            </View>

            <View style={{}}>
              <Text
                style={{
                  color: TEXT_COLOR,
                  textAlign: "center",
                }}
              >
                {t('or_sign_in_with')}
              </Text>
              <View style={styles.socialAuthButtonsContainer}>
                <Button
                  appearance="ghost"
                  size="giant"
                  status="control"
                  accessoryLeft={this.GoogleIcon}
                  onPress={() => this.onGoogleLogin()}
                />
                {/* <Button
                  appearance="ghost"
                  size="giant"
                  status="control"
                  accessoryLeft={this.FacebookIcon}
                  onPress={() => this.onFacebookLogin()}
                /> */}
              </View>
              <TouchableOpacity
                style={{ alignSelf: "center" }}
                onPress={() => navigate("SignUp")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    textAlign: "center",
                    padding: 8,
                    textDecorationLine: "underline",
                  }}
                >
                  {t('create_new_account')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputBox: {
    height: 30,
    width: "90%",
    borderWidth: BORDER_WIDTH,
    borderRadius: 3,
    borderColor: BORDER_COLOR,
    backgroundColor: "white",
    padding: 5,
    margin: 3,
    marginBottom: 20,
  },
  container: {
    // flex: 1,
    flexDirection: "column",
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  signInContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
  },
  socialAuthContainer: {
    marginTop: 20,
  },
  evaButton: {
    maxWidth: 72,
    paddingHorizontal: 0,
  },
  formContainer: {
    flex: 1,
    marginTop: 10,
  },
  passwordInput: {
    marginTop: 10,
  },
  signInLabel: {
    flex: 1,
  },
  signUpButton: {
    flexDirection: "row-reverse",
    paddingHorizontal: 0,
  },
  socialAuthButtonsContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  socialAuthHintText: {
    alignSelf: "center",
    marginBottom: 16,
  },
});

export default withFirebase(withTranslation(SignIn));
