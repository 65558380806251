import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import FadeSlideView from "../Anim/FadeSlideView";
import RF from "react-native-responsive-fontsize";
import { APP_NAVTAB, BANNER_BOTTOM_ENABLE, MENU_COLOR, SHOW_GAMES, TEXT_COLOR } from "../../constants/const";
import {
  withTranslation,
  t
} from "react-multi-lang";
import { Image } from "react-native-web";

export class Thankyou extends Component {
  _isMounted = false;
  state = { data: null, screen: "winner" };

  // checkTimeDisplay = () =>{

  //     console.log("new data received");
  //     console.log(this.currentSurvey);
  //     console.log(this.currentQuiz);
  //     const now = Math.floor(
  //       (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
  //     );
  //     if (this.currentQuiz.name === "" && this.currentSurvey.name !== "") {
  //       console.log("navigate to Surveys");
  //       this.props.navigation.navigate("Surveys");
  //     } else if (this.currentSurvey.name === "" && this.currentQuiz.name !== "") {
  //       console.log("navigate to Quizzes");
  //       this.props.navigation.navigate("Quizzes");
  //     } else if (this.currentQuiz.name !== "" && this.currentSurvey.name !== "") {
  //       if (
  //         this.currentSurvey.start.seconds < this.currentQuiz.start.seconds &&
  //         now < this.currentSurvey.end.seconds
  //       ) {
  //         console.log("navigate to Surveys");
  //         this.props.navigation.navigate("Surveys");
  //       } else if (
  //         this.currentQuiz.start.seconds < this.currentSurvey.start.seconds &&
  //         now < this.currentQuiz.end.seconds
  //       ) {
  //         console.log("navigate to Quizzes");
  //         this.props.navigation.navigate("Quizzes");
  //       }
  //     }

  // }

  // componentDidUpdate(){
  //   this.currentSurvey = this.props.currentSurvey;
  //   this.currentQuiz = this.props.currentQuiz;
  //   this.checkTimeDisplay();
  // }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          width: this.props.maxWidth,
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {this.props.currentSurvey && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 30,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: 24,
                fontWeight: "bold",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {this.props.currentSurvey.name}
            </Text>
            <Text
              style={{
                color: "darkgrey",
                textTransform: "uppercase",
                fontSize: RF(2),
                fontWeight: "100",
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              {this.props.currentSurvey.desc}
            </Text>
            <FadeSlideView
              style={{ justifyContent: "center", alignItems: "center" }}
              direction="down"
              delay={1000}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: 30
                }}
              >
                {this.props.currentSurvey.thankyou_msg}
              </Text>
            </FadeSlideView>
          </View>
        )}
        <View style={{
              position: 'fixed',
              bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
              width: '100%',
              backgroundColor: MENU_COLOR,
              padding: 15
            }}>
              <TouchableOpacity
                onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate("SelectGame") : navigate("Home")}
              >
                <View style={{
                  flexDirection: "row",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Image
                    style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                    source={require("../../assets/images/back.svg")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 'bold'
                    }}
                  >
                    {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
      </View>
    );
  }
}

export default withTranslation(Thankyou);
