import React, { Component } from "react";
import { ImageBackground, View, Text, Dimensions } from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../ReponsesSurvey";
import FadeSlideView from "../Anim/FadeSlideView";
import {
  TIME_PAUSE,
  BG_TELE,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT
} from "../../constants/const";
import TeleHeader from "./Elements/header";
import {
  OPTIONS_QUESTIONS_TV_AND_RESULTS,
  OPTIONS_QUESTIONS_TV
} from "../../constants/routes";
import {
  getHeightFromHDMargin,
  getWidthFromHDMargin
} from "../../libs/list_picker/ratio";

export class TeleSurveys extends Component {
  _isMounted = false;
  state = { displayResult: false };
  currentSurvey = null;
  currentQuestion = null;
  currentResults = null;

  constructor(props) {
    super(props);
    if (props.currentSurvey) {
      this.currentSurvey = props.currentSurvey;
    }
    if (props.currentQuestion) {
      this.currentQuestion = props.currentQuestion;
    } else {
      if (this.props.checkSurveyAndQuestion) {
        this.props.checkSurveyAndQuestion();
      }
    }
  }

  finishCountDownCallback = () => {
    //Show result and time response
    if (this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS) {
      if (this.state.displayResult === true) {
        // Next question
        if (this.props.checkSurveyAndQuestion) {
          this.props.checkSurveyAndQuestion();
        }
        if (this._isMounted) {
          this.setState({ displayResult: false });
        }
      } else {
        const currentResults = window.localStorage.getItem(
          "currentSurveyResults"
        );
        var currentQuestionResult = this.currentQuestion;
        if (currentResults != null) {
          this.currentResults = JSON.parse(currentResults);

          currentQuestionResult = this.currentResults.filter(q => {
            return q.id === this.currentQuestion.id;
          });

          if (currentQuestionResult.length > 0) {
            currentQuestionResult = currentQuestionResult[0];
            currentQuestionResult.displayResult = true;
            //re-calculate the percent
            for (let i = 0; i < currentQuestionResult.answers.length; i++) {
              const res = currentQuestionResult.answers[i];
              currentQuestionResult.answers[i].withResult = true;
              currentQuestionResult.answers[i].percent =
                currentQuestionResult.total_answered > 0
                  ? parseInt(currentQuestionResult[`total_answered_${res.id}`], 10) /
                    currentQuestionResult.total_answered
                  : 0;
            }
          } else {
            currentQuestionResult = this.currentQuestion;
            currentQuestionResult.displayResult = true;
            for (let i = 0; i < currentQuestionResult.answers.length; i++) {
              currentQuestionResult.answers[i].withResult = true;
              currentQuestionResult.answers[i].percent = 0;
            }
          }
        }

        // console.log("currentQuestion = ", currentQuestionResult);
        this.currentQuestion = currentQuestionResult;
        if (this._isMounted) {
          this.setState({
            displayResult: true
          });
        }
      }
    } else if (this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV) {
      // Next question
      if (this.props.checkSurveyAndQuestion) {
        this.props.checkSurveyAndQuestion();
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
const mtop = this.props.navigation ? (this.props.navigation.state.params
      ? this.props.navigation.state.params.mtop
        ? parseInt(this.props.navigation.state.params.mtop, 10)
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP) : TELE_MARGIN_TOP;
    const mright = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mright
        ? parseInt(this.props.navigation.state.params.mright, 10)
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT) : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mbottom
        ? parseInt(this.props.navigation.state.params.mbottom, 10)
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM) : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mleft
        ? parseInt(this.props.navigation.state.params.mleft, 10)
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT ) : TELE_MARGIN_LEFT;
    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;
    return (
      <ImageBackground
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: BG_TELE,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop
        }}
        source={THEME_BACKGROUND_TELE}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            top: 0,
            position: "absolute"
          }}
          texts={this.props.currentSurvey}
        />

        <View
          style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
        >
          {this.props.currentQuestion && (
            <View
              style={{
                flex: 1,
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                width: this.state.displayResult
                  ? Math.round(screenWidth / 2)
                  : Math.round(screenWidth),
                height: Math.round(screenHeight)
              }}
            >
              <FadeSlideView
                direction="down"
                style={{
                  position: "fixed",
                  top:
                    getHeightFromHDMargin(20, mtop, mright, mbottom, mleft) +
                    mtop,
                  left: Math.round(
                    screenWidth / 2 -
                      (this.state.displayResult
                        ? getWidthFromHDMargin(75, mtop, mright, mbottom, mleft)
                        : getWidthFromHDMargin(
                            90,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )) +
                      mleft
                  )
                }}
              >
                <ImageBackground
                  key={this.state.displayResult}
                  style={{
                    width: this.state.displayResult
                      ? getWidthFromHDMargin(150, mtop, mright, mbottom, mleft)
                      : getWidthFromHDMargin(180, mtop, mright, mbottom, mleft),
                    height: this.state.displayResult
                      ? getWidthFromHDMargin(150, mtop, mright, mbottom, mleft)
                      : getWidthFromHDMargin(180, mtop, mright, mbottom, mleft),
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/circle.png")}
                >
                  <CountDownCircle
                    seconds={
                      this.state.displayResult
                        ? TIME_PAUSE
                        : this.props.currentQuestion.timeLeft || 10
                    }
                    radius={
                      this.state.displayResult
                        ? getWidthFromHDMargin(68, mtop, mright, mbottom, mleft)
                        : getWidthFromHDMargin(80, mtop, mright, mbottom, mleft)
                    }
                    borderWidth={getWidthFromHDMargin(
                      20,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    )}
                    color={this.state.displayResult ? "limegreen" : "red"}
                    bgColor="#fff"
                    textStyle={{
                      fontSize: this.state.displayResult ? RF(4) : RF(5)
                    }}
                    finishCallback={() => this.finishCountDownCallback()}
                  />
                </ImageBackground>
              </FadeSlideView>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  height: Math.round((screenHeight * 5) / 6),
                  top: Math.round(screenHeight / 6)
                }}
              >
                <FadeSlideView delay={150}>
                  <Text
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: this.state.displayResult ? RF(5) : RF(8),
                      fontWeight: "bold",
                      textAlign: "center",
                      maxWidth: this.state.displayResult
                        ? getWidthFromHDMargin(
                            690,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )
                        : getWidthFromHDMargin(
                            1680,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )
                    }}
                  >
                    {this.props.currentQuestion.question}
                  </Text>
                </FadeSlideView>
                {(this.props.currentSurvey.play_sound === "tv" ||
                  this.props.currentSurvey.play_sound === "all") &&
                  !this.state.displayResult &&
                  this.props.currentQuestion.sound && (
                    <audio autoPlay>
                      <source src={this.props.currentQuestion.sound} />
                    </audio>
                  )}

                <FadeSlideView delay={500} direction="up">
                  <View
                    style={{
                      marginTop: Math.round(screenHeight / 42),
                      flex: 1,
                      alignItems: "stretch",
                      alignContent: "stretch",
                      alignSelf: "stretch"
                    }}
                  >
                    <Response
                      {...this.props}
                      key={this.state.displayResult}
                      data={{
                        answers: this.currentQuestion.answers,
                        currentQuestionId: this.currentQuestion.id,
                        currentQuestionTimeLeft: this.currentQuestion.timeLeft,
                        currentQuestionTimeEnd: this.currentQuestion.timeEnd,
                        currentSurveyId: this.currentSurvey.id,
                        withImage: this.currentQuestion.withImage,
                        withResult: this.state.displayResult
                      }}
                      modeTele={true}
                    />
                  </View>
                </FadeSlideView>
                {this.state.displayResult === false && (
                  <FadeSlideView
                    delay={800}
                    direction="right"
                    style={{
                      position: "fixed",
                      bottom:
                        getHeightFromHDMargin(
                          20,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ) + mbottom,
                      left:
                        Math.round(screenWidth / 2 - screenWidth / 4 / 2) +
                        mleft
                    }}
                  >
                    <PagingControl
                      style={{ marginTop: Math.round(screenHeight / 30) }}
                      max={this.props.currentSurvey.questions.length}
                      width={Math.round(screenWidth / 4)}
                      current={this.props.currentQuestion.order}
                      modeTele={true}
                    />
                  </FadeSlideView>
                )}
              </View>
            </View>
          )}
        </View>
      </ImageBackground>
    );
  }
}

export default TeleSurveys;
