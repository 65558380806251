import React, { Component } from "react";
import { Text, View, FlatList, Image } from "react-native";
import { getWidthPercent } from "../../libs/list_picker/ratio";
import { FORECAST_FOOT_SCORE, FORECAST_FOOT_WINNER, FORECAST_RUGBY_WINNER, FORECAST_RUGBY_SCORE_GAP, FORECAST_FOOT_TOTAL_SCORE, FORECAST_RUGBY_TOTAL_SCORE, FORECAST_FOOT_FIRST_SCORE_MINUTE, FORECAST_RUGBY_FIRST_SCORE, FORECAST_FOOT_FIRST_SCORER } from "../../constants/const";

export class MatchPronostic extends Component {
  _keyExtractor = (item, index) => {
    return `prono_${index}`;
  };

  _renderItem = ({ item, index }) => {
    // console.log(item);
    return (
      <View
        style={{
          width: Math.round(getWidthPercent(90) / this._numColumns()),
          height: this._getItemHeight(),
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginLeft: Math.round(this._getItemHeight() / 12),
            marginBottom: Math.round(this._getItemHeight() / 12),
            marginTop: Math.round(this._getItemHeight() / 12),
            marginRight: Math.round(this._getItemHeight() / 12),
            flex: 1,
          }}
        >
          <View
            style={{
              backgroundColor: this._getColor(item.color),
              opacity: this._getOpacityBg(item.color),
              position: "absolute",
              height: "100%",
              width: "100%",
              borderRadius: Math.round(this._getItemHeight() / 20),
              borderWidth: 1,
              borderColor: this._getColor(item.color),
            }}
          />

          <View
            style={{
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: Math.round(this._getItemHeight() / 4),
                opacity: this._getOpacityText(item.color),
                color: this._getColorText(item.color),
                textAlign: "center",
              }}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {item.displayName}
            </Text>
            {!item.scoreGap && (FORECAST_FOOT_SCORE && item.type === "match")  && (
              <Text
                style={{
                  fontSize: Math.round(this._getItemHeight() / 6),
                  color: this._getColorText(item.color),
                  opacity: this._getOpacityText(item.color),
                  textAlign: "center",
                }}
              >
                {item.scoreHome} - {item.scoreVisitor}
              </Text>
            )}

            {item.teamWonName && ((FORECAST_FOOT_WINNER && item.type === "match" ) || (FORECAST_RUGBY_WINNER && item.type.includes("rugby"))) && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: this._getItemHeight() / 7,
                    height: this._getItemHeight() / 7,
                    marginRight: 5,
                    opacity: this._getOpacityText(item.color),
                  }}
                  resizeMode={"contain"}
                  source={
                    this._getColorText(item.color) === "#FFFFFF"
                      ? require("../../assets/images/vainqueur.svg")
                      : require("../../assets/images/vainqueur_dark.svg")
                  }
                />
                <Text
                  style={{
                    fontSize: Math.round(this._getItemHeight() / 6),
                    color: this._getColorText(item.color),
                    opacity: this._getOpacityText(item.color),
                    textAlign: "center",
                    maxWidth: Math.round(
                      (2 * (getWidthPercent(90) / this._numColumns())) / 3
                    ),
                    maxHeight: this._getItemHeight() / 5,
                    overflow: "hidden",
                  }}
                  ellipsizeMode="tail"
                  numberOfLines={1}
                >
                  {item.teamWonName}
                </Text>
              </View>
            )}

            <View
              style={{
                flexDirection: "row",
                width: "90%",
                justifyContent: "space-between",
              }}
            >
              {item.scoreGap >= 0 && ((FORECAST_RUGBY_SCORE_GAP && item.type.includes("rugby"))) && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      width: this._getItemHeight() / 8,
                      height: this._getItemHeight() / 8,
                      marginRight: 8,
                      opacity: this._getOpacityText(item.color),
                    }}
                    resizeMode={"contain"}
                    source={
                      this._getColorText(item.color) === "#FFFFFF"
                        ? require("../../assets/images/gap.svg")
                        : require("../../assets/images/gap_dark.svg")
                    }
                  />
                  <Text
                    style={{
                      fontSize: Math.round(this._getItemHeight() / 8),
                      color: this._getColorText(item.color),
                      opacity: this._getOpacityText(item.color),
                    }}
                  >
                    {item.scoreGap}
                  </Text>
                </View>
              )}
              {item.totalScore >= 0 && ((FORECAST_FOOT_TOTAL_SCORE && item.type === "match" ) || (FORECAST_RUGBY_TOTAL_SCORE && item.type.includes("rugby"))) && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.type === "match" && (
                    <Image
                      style={{
                        width: this._getItemHeight() / 8,
                        height: this._getItemHeight() / 8,
                        marginRight: 8,
                        opacity: this._getOpacityText(item.color),
                      }}
                      resizeMode={"contain"}
                      source={
                        this._getColorText(item.color) === "#FFFFFF"
                          ? require("../../assets/images/total_foot.svg")
                          : require("../../assets/images/total_foot_dark.svg")
                      }
                    />
                  )}
                  {item.type.includes("rugby") && (
                    <Image
                      style={{
                        width: this._getItemHeight() / 8,
                        height: this._getItemHeight() / 8,
                        marginRight: 8,
                        opacity: this._getOpacityText(item.color),
                      }}
                      resizeMode={"contain"}
                      source={
                        this._getColorText(item.color) === "#FFFFFF"
                          ? require("../../assets/images/total.svg")
                          : require("../../assets/images/total_dark.svg")
                      }
                    />
                  )}
                  <Text
                    style={{
                      fontSize: Math.round(this._getItemHeight() / 8),
                      color: this._getColorText(item.color),
                      opacity: this._getOpacityText(item.color),
                      textAlign: "left",
                    }}
                  >
                    {item.totalScore}
                  </Text>
                </View>
              )}
              {item.firstScoreName && ( (FORECAST_RUGBY_FIRST_SCORE && item.type.includes("rugby"))) && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: Math.round(this._getItemHeight() / 8),
                      color: this._getColorText(item.color),
                      opacity: this._getOpacityText(item.color),
                      textAlign: "right",
                      maxWidth: Math.round(
                        getWidthPercent(90) / this._numColumns() / 2
                      ),
                      maxHeight: this._getItemHeight() / 6,
                      overflow: "hidden",
                    }}
                    ellipsizeMode="tail"
                    numberOfLines={1}
                  >
                    {item.firstScoreName}
                  </Text>
                  <Image
                    style={{
                      width: this._getItemHeight() / 8,
                      height: this._getItemHeight() / 8,
                      marginLeft: 5,
                      opacity: this._getOpacityText(item.color),
                    }}
                    resizeMode={"contain"}
                    source={
                      this._getColorText(item.color) === "#FFFFFF"
                        ? require("../../assets/images/firstScoreRugbyWhite.svg")
                        : require("../../assets/images/firstScoreRugbyDark.svg")
                    }
                  />
                </View>
              )}
              {item.firstScorer && (FORECAST_FOOT_FIRST_SCORER && item.type === "match" )  && (
                <Text
                  style={{
                    fontSize: Math.round(this._getItemHeight() / 8),
                    color: this._getColorText(item.color),
                    opacity: this._getOpacityText(item.color),
                    textAlign: "center",
                  }}
                >
                  {item.firstScorer.name}
                </Text>
              )}
              {item.firstScoreMinute > 0 && ((FORECAST_FOOT_FIRST_SCORE_MINUTE && item.type === "match" )) && (
                <Text
                  style={{
                    fontSize: Math.round(this._getItemHeight() / 8),
                    color: this._getColorText(item.color),
                    opacity: this._getOpacityText(item.color),
                    textAlign: "right",
                  }}
                >
                  {this._displayMinute(item.firstScoreMinute)}
                </Text>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  };

  _displayMinute = (min) => {
    switch (min) {
      case "ex1":
        return "Tps add. 1er MT";

      case "ex2":
        return "Tps add. 2e MT";
      default:
        return `${min}'`;
    }
  };

  _getColor = (statusColor) => {
    switch (statusColor) {
      case "1st":
        return "#F7D300";
      case "2nd":
        return "#4D5762";
      case "3rd":
        return "#CE8351";
      case "out":
        return "#E02020";
      default:
        return "#E5E5E5";
    }
  };

  _getColorText = (statusColor) => {
    switch (statusColor) {
      case "1st":
        return "#635602";
      case "2nd":
        return "#FFFFFF";
      case "3rd":
        return "#635602"; //"#431F07";
      case "out":
        return "#FFFFFF";
      default:
        return "#FFFFFF";
    }
  };

  _getOpacityText = (statusColor) => {
    switch (statusColor) {
      case "1st":
        return 1;
      case "2nd":
        return 1;
      case "3rd":
        return 1;
      case "out":
        return 0.5;
      default:
        return 0.3;
    }
  };

  _getOpacityBg = (statusColor) => {
    switch (statusColor) {
      case "1st":
        return 1;
      case "2nd":
        return 1;
      case "3rd":
        return 1;
      case "out":
        return 0.3;
      default:
        return 0.05;
    }
  };

  _getItemHeight = () => {
    return Math.round(this.props.tall / this._numRows()) <
      Math.round(this.props.tall / 7)
      ? Math.round(this.props.tall / 7)
      : Math.round(this.props.tall / this._numRows());
  };

  _numColumns = () => {
    var tmpC =
      this.props.data.length < 18 ? Math.ceil(this.props.data.length / 3) : 6;
    if (tmpC > Math.ceil(this.props.data.length / tmpC)) {
      tmpC = Math.ceil(this.props.data.length / tmpC);
    }
    return tmpC;
  };

  _numRows = () => {
    return Math.ceil(this.props.data.length / this._numColumns());
  };

  _getItemLayout = (data, index) => {
    return {
      length: this._getItemHeight(),
      offset: this._getItemHeight() * index,
      index,
    };
  };

  render() {
    // console.log(this.props);
    return (
      <FlatList
        style={{
          width: getWidthPercent(90),
          ...this.props.style,
        }}
        showsHorizontalScrollIndicator={false}
        data={this.props.data}
        extraData={this.state}
        renderItem={this._renderItem}
        numColumns={this._numColumns()}
        keyExtractor={this._keyExtractor}
        getItemLayout={this._getItemLayout}
      />
    );
  }
}

export default MatchPronostic;
