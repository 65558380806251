import React, { Component } from "react";
import { Text, TouchableOpacity } from "react-native";
import { BUTTON_COLOR, TEXT_COLOR } from "../constants/const";
import {
  withTranslation,
  t
} from "react-multi-lang";
export class TOButton extends Component {
  render() {
    return (
      <TouchableOpacity
        style={
          this.props.disabled
            ? {
                backgroundColor: this.props.navigation? this.props.navigation.state.params
                ? this.props.navigation.state.params.bc
                  ? this.props.navigation.state.params.bc
                  : BUTTON_COLOR: BUTTON_COLOR
                : BUTTON_COLOR,
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 30,
                width: 150,
                height: 50,
                borderRadius: 25,
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
                ...this.props.style
              }
            : {
                backgroundColor: this.props.navigation? this.props.navigation.state.params
                ? this.props.navigation.state.params.bc
                  ? this.props.navigation.state.params.bc
                  : BUTTON_COLOR : BUTTON_COLOR
                : BUTTON_COLOR,
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 30,
                width: 150,
                height: 50,
                borderRadius: 25,
                justifyContent: "center",
                alignItems: "center",
                shadowColor: "#000",
                shadowOpacity: 0.5,
                shadowRadius: 10,
                ...this.props.style
              }
        }
        disabled={this.props.disabled || false}
        onPress={() => {
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
      >
        <Text
          style={{
            color: this.props.navigation? this.props.navigation.state.params
            ? this.props.navigation.state.params.tc
              ? this.props.navigation.state.params.tc
              : TEXT_COLOR
            : TEXT_COLOR : TEXT_COLOR,
            fontSize: 14,
            textAlign: "center",
            textTransform: 'uppercase'
          }}
        >
          {this.props.text ? this.props.text : t("submit")}
        </Text>
      </TouchableOpacity>
    );
  }
}
export default withTranslation(TOButton);
