import React, { Component } from "react";
import { ImageBackground, View, Text, Dimensions, Image } from "react-native";
import CountDownCircle from "../CountDown/circle";
import FadeSlideView from "../Anim/FadeSlideView";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../Reponses";

import {
  TIME_PAUSE,
  BG_TELE,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  THEME,
} from "../../constants/const";
import TeleHeader from "./Elements/header";
import Ranking from "../Ranking";
import FadeView from "../Anim/FadeView";
import {
  getHeightFromHDMargin,
  getWidthFromHDMargin,
} from "../../libs/list_picker/ratio";

export class TeleQuizzes extends Component {
  _isMounted = false;
  state = { displayResult: false, displayResultRanking: false };
  currentQuiz = null;
  currentQuestion = null;
  currentQuestionResult = null;
  currentResults = null;
  playersRanking = [];

  constructor(props) {
    super(props);
    if (props.currentQuiz) {
      this.currentQuiz = props.currentQuiz;
    }
    if (props.currentQuestion) {
      this.currentQuestion = props.currentQuestion;
      this.state = { displayResult: this.currentQuestion.displayResult };
    } else {
      if (this.props.checkQuizAndQuestion) {
        this.props.checkQuizAndQuestion();
      }
    }
  }

  callbackTimer = (value) => {
    console.log("callbackTimer = ", value);
    if (this.state.displayResult) {
      // not show ranking
      if (THEME !== "ogcnice-stade" && THEME !== "staderennais") {
        if (value <= TIME_PAUSE / 2) {
          this.setState({ displayResultRanking: this.props.currentQuiz.show_classement_tv !== 'not_display' });
        }
      }
    }
  };

  finishCountDownCallback = () => {
    //Show result and time response
    if (this.state.displayResult === true) {
      // Next question
      if (this.props.checkQuizAndQuestion) {
        this.props.checkQuizAndQuestion();
      }
      if (this._isMounted) {
        this.setState({ displayResult: false, displayResultRanking: false });
      }
    } else {
      this._getRankingData();
    }
  };

  _getRankingData = () => {
    const currentResults = window.localStorage.getItem("currentResults");
    if (currentResults != null) {
      this.currentResults = JSON.parse(currentResults);
      this.currentResults.sort((a, b) => {
        return (
          b.point - a.point ||
          a.timeRightMs - b.timeRightMs ||
          a.timeMs - b.timeMs
        );
      });
      this.playersRanking = this.currentResults;
    }

    const currentResultsQuestion =
      window.localStorage.getItem("questionResults");
    this.currentQuestionResult = JSON.parse(currentResultsQuestion);
    //Do the right ans
    for (let i = 0; i < this.currentQuestion.answers.length; i++) {
      this.currentQuestion.answers[i].isRight =
        this.currentQuestion.answers[i].id === this.currentQuestion.rightAnswer;
    }

    if (
      this.currentQuestionResult &&
      this.currentQuestionResult.id === this.currentQuestion.id
    ) {
      this.currentQuestion.total_answered =
        this.currentQuestionResult.total_answered || 0;

      for (let i = 0; i < this.currentQuestionResult.answers.length; i++) {
        const an = this.currentQuestionResult.answers[i];
        an.isRight = an.id === this.currentQuestionResult.rightAnswer;
        an.percent =
          this.currentQuestionResult.total_answered > 0
            ? this.currentQuestionResult[`total_answered_${an.id}`] /
            this.currentQuestionResult.total_answered
            : 0;
        delete an.answered;
      }
      this.currentQuestion.answers = this.currentQuestionResult.answers;
    }

    if (this._isMounted) {
      this.setState({
        displayResult: true,
        currentQuestion: this.currentQuestion,
        answersByTimes: this.playersRanking.splice(0, 5), // TOP 5
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.currentQuestion) {
      if (this.currentQuestion.displayResult) {
        this._getRankingData();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;
    // console.log(this.props.currentQuiz);
    // console.log(this.props.currentQuestion);
    // console.log(this.props.currentQuiz.play_sound);
    // console.log(this.props.currentQuestion.sound);
    // console.log((this.props.currentQuiz.play_sound === "tv" || this.props.currentQuiz.play_sound === "all") && !this.state.displayResult && this.props.currentQuestion.sound );
    return (
      <ImageBackground
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: BG_TELE,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
        source={THEME_BACKGROUND_TELE}
        resizeMode={"cover"}
      >
        {this.props.currentQuiz !== null && (
          <View
            style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
          >
            <TeleHeader
              style={{
                flex: 1,
                flexDirection: "row",
                width: screenWidth,
                maxHeight: "20%",
                position: "absolute",
              }}
            />
            {this.props.currentQuestion && (
              <FadeSlideView
                direction="down"
                style={{
                  position: "fixed",
                  top:
                    getHeightFromHDMargin(20, mtop, mright, mbottom, mleft) +
                    mtop,
                  left:
                    THEME === "jeutop14"
                      ? Math.round(
                        screenWidth / 2 -
                        (this.state.displayResult
                          ? getWidthFromHDMargin(
                            75,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )
                          : getWidthFromHDMargin(
                            90,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )) +
                        mleft
                      ) + 180
                      : Math.round(
                        screenWidth / 2 -
                        (this.state.displayResult
                          ? getWidthFromHDMargin(
                            75,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )
                          : getWidthFromHDMargin(
                            90,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          )) +
                        mleft
                      ),
                }}
              >
                <ImageBackground
                  key={this.state.displayResult}
                  style={{
                    width: getWidthFromHDMargin(
                      120,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    height: getWidthFromHDMargin(
                      120,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/circle.png")}
                >
                  <CountDownCircle
                    seconds={
                      this.state.displayResult
                        ? this.props.currentQuestion.displayResultTimeLeft >
                          TIME_PAUSE
                          ? TIME_PAUSE
                          : this.props.currentQuestion.displayResultTimeLeft
                        : this.props.currentQuestion.timeLeft || 10
                    }
                    radius={getWidthFromHDMargin(
                      55,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    )}
                    borderWidth={getWidthFromHDMargin(
                      10,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    )}
                    color={this.state.displayResult ? "limegreen" : "red"}
                    bgColor="#fff"
                    textStyle={{
                      fontSize: this.state.displayResult ? RF(4) : RF(5),
                    }}
                    callbackTimer={this.callbackTimer}
                    finishCallback={() => this.finishCountDownCallback()}
                  />
                </ImageBackground>
              </FadeSlideView>
            )}
            <FadeView
              delay={500}
              style={{
                position: "fixed",
                bottom:
                  getHeightFromHDMargin(20, mtop, mright, mbottom, mleft) +
                  mbottom,
                left: Math.round(screenWidth / 2 - screenWidth / 4 / 2) + mleft,
              }}
            >
              <PagingControl
                style={{
                  marginTop: Math.round(screenHeight / 30),
                  width: Math.round(screenWidth / 4),
                }}
                max={this.props.currentQuiz.questions.length}
                current={this.props.currentQuestion.order}
                modeTele={true}
              />
            </FadeView>
            {this.props.currentQuestion && !this.state.displayResultRanking && (
              <View
                style={{
                  flex: 1,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: Math.round(screenWidth),
                  height: Math.round(screenHeight),
                }}
              >
                {!this.state.displayResultRanking && (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      height: Math.round((screenHeight * 5) / 6),
                      top: Math.round(screenHeight / 8),
                    }}
                  >
                    {/* {((this.currentQuestion.type !== "6" &&
                      this.currentQuestion.type !== "5") ||
                      this.state.displayResult) && ( */}
                    <FadeSlideView delay={150}>
                      <Text
                        style={{
                          color: TEXT_TV_COLOR,
                          fontSize: RF(6),
                          fontWeight: "bold",
                          textAlign: "center",
                          maxWidth: getWidthFromHDMargin(
                            1900,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                        }}
                      >
                        {this.props.currentQuestion.question}
                      </Text>
                    </FadeSlideView>
                    {/* )} */}

                    <FadeSlideView delay={500} direction="up">
                      <View
                        style={{
                          marginTop: Math.round(screenHeight / 42),
                          flex: 1,
                          alignItems: ["6", "5", "7", "8"].includes(this.currentQuestion.type) ? "center" : "stretch",
                          alignContent: "stretch",
                          alignSelf: "stretch",
                          justifyContent: ["6", "5", "7", "8"].includes(this.currentQuestion.type) ? "space-between" : "auto",
                          width: getWidthFromHDMargin(
                            1680,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                          flexDirection: ["6", "5", "7", "8"].includes(this.currentQuestion.type) && "row",
                        }}
                      >
                        {this.currentQuestion.image &&
                          ["6", "5", "8"].includes(this.currentQuestion.type) && (
                            <Image
                              style={{
                                width:
                                  this.currentQuestion.type === "6"
                                    ? getWidthFromHDMargin(
                                      1100,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    )
                                    : getWidthFromHDMargin(
                                      600,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ),
                                height:
                                  this.currentQuestion.type === "6"
                                    ? getHeightFromHDMargin(
                                      645,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    )
                                    : getWidthFromHDMargin(
                                      600,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ),
                                borderRadius: 10,
                                marginBottom: 5,
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                                borderWidth: 1,
                                filter: this.state.displayResult ? "" : (this.currentQuestion.type === "8" ? "blur(2px)" : ""),
                              }}
                              source={this.state.displayResult && this.currentQuestion.image_result ? { uri: this.currentQuestion.image_result } : { uri: this.currentQuestion.image }}
                            />
                          )}
                        {this.currentQuestion.image &&
                          (this.currentQuestion.type === "7") && (
                            (this.props.currentQuiz.play_sound === "tv" ||
                              this.props.currentQuiz.play_sound === "all") ?
                              <video preload autoPlay
                                src={this.state.displayResult && this.currentQuestion.image_result ? this.currentQuestion.image_result : this.currentQuestion.image}
                                style={{
                                  width: getWidthFromHDMargin(
                                    1000,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ),
                                  marginBottom: 5,
                                }} /> :
                              <video preload autoPlay muted
                                src={this.state.displayResult && this.currentQuestion.image_result ? this.currentQuestion.image_result : this.currentQuestion.image}
                                style={{
                                  width: getWidthFromHDMargin(
                                    1000,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ),
                                  marginBottom: 5,
                                }} />
                          )}
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Response
                            {...this.props}
                            key={this.state.displayResult}
                            data={{
                              answers: this.currentQuestion.answers,
                              currentQuestionId: this.currentQuestion.id,
                              currentQuestionTimeLeft:
                                this.currentQuestion.timeLeft,
                              currentQuestionTimeEnd:
                                this.currentQuestion.timeEnd,
                              currentQuizId: this.currentQuiz.id,
                              withImage: this.currentQuestion.withImage,
                              withResult: this.state.displayResult,
                              currentQuestion: this.currentQuestion,
                            }}
                            modeTele={true}
                          />

                          {/* {this.state.displayResult && this.currentQuestion.explication && <Text
                            style={{
                              color: TEXT_TV_COLOR,
                              fontSize: RF(3.5),
                              textAlign: "center"
                            }}
                          >
                            {this.currentQuestion.explication}
                          </Text>} */}
                        </View>
                      </View>
                    </FadeSlideView>
                  </View>
                )}

              </View>
            )}
            {/* // no need !this.state.displayResult && */}
            {(this.props.currentQuiz.play_sound === "tv" ||
              this.props.currentQuiz.play_sound === "all") &&
              this.props.currentQuestion.sound && (
                <View
                  style={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <audio autoPlay>
                    <source src={this.props.currentQuestion.sound} />
                  </audio>
                </View>
              )}

            {this.state.displayResult && this.state.displayResultRanking && (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  height: Math.round((screenHeight * 5) / 6),
                  top: Math.round(screenHeight / 6),
                }}
              >
                <FadeSlideView
                  delay={500}
                  direction="up"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: RF(4.5),
                      fontWeight: "bold",
                      textAlign: "center"
                    }}
                  >
                    CLASSEMENT INTERMÉDIAIRE
                  </Text>
                  <Ranking
                    currentQuestionId={this.currentQuestion.id}
                    data={this.state.answersByTimes}
                    modeTele={true}
                    final={false}
                  />
                </FadeSlideView>
              </View>
            )}
          </View>
        )}
      </ImageBackground>
    );
  }
}

export default TeleQuizzes;
