import React, { Component } from "react";
import { Text, View, ImageBackground, Dimensions } from "react-native";
import TeleHeader from "./Elements/header";
import FadeSlideView from "../Anim/FadeSlideView";

import RF from "react-native-responsive-fontsize";
import {
  BG_TELE,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  EMAILS_RESULTS,
  PROFILE_LOGE_NUMBER,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  PROFILE_JOB,
  PROFILE_DOB,
  PROFILE_ADDRESS,
  ONLY_ANONYMOUS
} from "../../constants/const";

export class TeleSurveyResults extends Component {
  _isMounted = false;
  state = { data: null, screen: "winner" };

  componentDidMount() {
    this._isMounted = true;

    const sendMailSurvey = this.props.firebase.functions.httpsCallable(
      "sendMailSurvey"
    );
    //send Email of results
    const dataMail = {
      currentSurveyResultId: `${this.props.currentSurvey.id}`,
      currentSurveyName: this.props.currentSurvey.name,
      to: EMAILS_RESULTS,
      info : {
        rapid : ONLY_ANONYMOUS,
        loge : PROFILE_LOGE_NUMBER,
        phone : PROFILE_PHONE,
        company   : PROFILE_COMPANY,
        job  : PROFILE_JOB,
        dob : PROFILE_DOB,
        address : PROFILE_ADDRESS
      }
    };
    // console.log(dataMail);
    sendMailSurvey(dataMail)

  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
const mtop = this.props.navigation ? (this.props.navigation.state.params
      ? this.props.navigation.state.params.mtop
        ? parseInt(this.props.navigation.state.params.mtop, 10)
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP) : TELE_MARGIN_TOP;
    const mright = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mright
        ? parseInt(this.props.navigation.state.params.mright, 10)
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT) : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mbottom
        ? parseInt(this.props.navigation.state.params.mbottom, 10)
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM) : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation ?  (this.props.navigation.state.params
      ? this.props.navigation.state.params.mleft
        ? parseInt(this.props.navigation.state.params.mleft, 10)
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT ) : TELE_MARGIN_LEFT;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    return (
      <ImageBackground
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: BG_TELE,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop
        }}
        source={THEME_BACKGROUND_TELE}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            top: 0,
            position: "absolute"
          }}
        />
        {this.state.screen === "winner" && this.props.currentSurvey && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              padding: 50,
              width: "80%"
            }}
          >
            <Text
              style={{
                color: TEXT_TV_COLOR,
                fontSize: RF(6),
                fontWeight: "bold",
                textTransform: "uppercase"
              }}
            >
              {this.props.currentSurvey.name}
            </Text>
            <Text
              style={{
                color: "darkgrey",
                textTransform: "uppercase",
                fontSize: RF(3),
                fontWeight: "100",
                marginBottom: 50
              }}
            >
              {this.props.currentSurvey.desc}
            </Text>
            <FadeSlideView direction="down" delay={1000}>
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(10),
                  margin: 20,
                  textAlign: "center"
                }}
              >
                {this.props.currentSurvey.thankyou_msg}
              </Text>
            </FadeSlideView>
          </View>
        )}
      </ImageBackground>
    );
  }
}

export default TeleSurveyResults;
