import React, { Component } from "react";
import IntroInside from "../../components/Intro/inside";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";

class HomeInsideScreen extends Component {
  
  render() {
    
    return <IntroInside {...this.props} />;
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withMainLayout(HomeInsideScreen));
