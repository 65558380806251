import React, { Component } from "react";
import { View } from "react-native";
import RF from "react-native-responsive-fontsize";
import { Text, Input, Button } from "@ui-kitten/components";
import { withFirebase } from "../Firebase";
import {
  THEME,
  TEXT_COLOR,
  MAIN_URL,
} from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import { withTranslation, t } from "react-multi-lang";

export class AnonymousSignUp extends Component {
  currentUser = null;
  validInput = () => {
    if (this.state === null) {
      return false;
    }

    if (!this.state.username || this.state.username === false) {
      return false;
    }

    if (!this.state.phone || this.state.phone === false) {
      return false;
    }

    return true;
  };

  componentDidMount() {}

  onValidateClicked = () => {
    const errMessage = "Paramètre d'entrée invalide ou manquant";
    console.log(this.state);
    if (this.validInput()) {
      this.setState({ error: null });
      const { firebase } = this.props;
      const that = this;
      console.log("onValidateClicked");
      firebase.auth.onAuthStateChanged((result) => {
        console.log("that.state = ", that.state);
        console.log("result = ", result);
        if (that.state) {
          const { username, phone } = that.state;

          if (result) {
            const userData = {
              anonymous: true,
              email: "",
              firstName: username,
              lastName: " ",
              displayName: username,
              company: "",
              phone: phone,
              newsletter: false,
              newsletter_commercial: false,
              agreement: true,
              theme: THEME,
              date: result.metadata.creationTime,
              uid: result.uid,
            };
            console.log("userData = ", userData);
            firebase
              .user(result.uid)
              .set(userData, { merge: true })
              .then(() => {
                that.setState({ error: null });
                firebase.auth.currentUser
                  .updateProfile({
                    displayName: `${username}`,
                  })
                  .then(function () {
                    //save prono if need
                    const _displayName = `${username}`;
                    that.currentUser = {
                      uid: result.uid,
                      email: "",
                      phone: phone,
                      displayName: _displayName,
                    };
                    userData.displayName = _displayName;
                    that.savePronoIfNeed(_displayName);
                    that.gotoAsyncIfNeeded();
                    that.saveSAIfNeeded(userData);
                  })
                  .catch(function (error) {
                    that.setState({ error });
                    console.log(error);
                  });
              })
              .catch((error) => {
                that.setState({ error });
                console.log(error);
              });
          }
        }
      });

      firebase.doSignInAnonymously().catch(function (err) {
        // Some error occurred, you can inspect the code: error.code
        console.log(err);
        that.setState({ error: { message: err.code } });
      });

      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };

  savePronoIfNeed = (_displayName) => {
    const { navigate } = this.props.navigation;
    const toSave = window.localStorage.getItem("match_forecast_to_save");
    if (toSave) {
      const dataToSave = JSON.parse(toSave);
      // Save to firestore
      this.props.firebase
        .pronostics_responses(
          `${this.currentUser.uid}_${dataToSave.pronosticId}`
        )
        .set({
          userId: this.currentUser.uid,
          displayName: _displayName,
          email: this.currentUser.email,
          ...dataToSave,
        });
      window.localStorage.removeItem("match_forecast_to_save");
      window.localStorage.setItem("match_forecast", JSON.stringify(dataToSave));
    }
    navigate("Home");
  };

  gotoAsyncIfNeeded = () => {
    const needOpenDataString = window.localStorage.getItem("needOpenAsync");
    if (needOpenDataString) {
      const needOpenData = JSON.parse(needOpenDataString);
      switch (needOpenData.type) {
        case "sa":
          window.location.replace(
            `https://${MAIN_URL}/sa/?sid=${needOpenData.id}`
          );
          break;

        case "async":
          window.location.replace(
            `https://${MAIN_URL}/async/?aid=${needOpenData.id}`
          );
          break;

        default:
          break;
      }
    }
  };

  saveSAIfNeeded = (userData) => {
    const needSaveSAString = window.localStorage.getItem("needSaveSA");
    if (needSaveSAString) {
      const needSaveSA = JSON.parse(needSaveSAString);
      const calculateSurvey =
        this.props.firebase.functions.httpsCallable("calculateSondage");
      for (let i = 0; i < needSaveSA.questions.length; i++) {
        const questionData = needSaveSA.questions[i];
        if (userData) {
          const savedData = {
            ...questionData.savedData,
            userId: userData.uid,
            ...userData,
          };
          const calculData = questionData.calculData;
          calculData.userData = savedData;

          // Save for calcul results
          this.props.firebase
            .surveys_result(`${questionData.collection}${userData.uid}`)
            .set(savedData)
            .then(() => {
              calculateSurvey(calculData).then((re) => {
                console.log("calculateSurvey done");
              });
            });
        }
      }
      //delete needSaveSA
      window.localStorage.removeItem("needSaveSA");
      window.localStorage.setItem("SAmsg", t("your_participation_saved"));
    }
  };

  render() {
    // const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: getWidthPercentMaxLimited(96, 500),
        }}
      >
        <View
          style={{
            flexDirection: "column",
            padding: 20,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: RF(2),
              fontWeight: "normal",
              margin: 10,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Indiquez votre pseudo :
          </Text>
          <View
            style={{
              width: getWidthPercentMaxLimited(70, 300),
              height: 1,
              backgroundColor: "#fff",
              opacity: 0.5,
              marginBottom: 30,
            }}
          ></View>
          {this.state && this.state.error && (
            <Text
              style={{
                color: "red",
                fontSize: RF(1.5),
                margin: 10,
                textAlign: "center",
              }}
            >
              * {this.state.error.message}
            </Text>
          )}

          <Input
            autoFocus={true}
            placeholder={"Pseudo *"}
            placeholderTextColor={"#ddd"}
            onChangeText={(text) => {
              this.setState({ username: text });
            }}
            value={(this.state && this.state.username) || ""}
          />
          <Input
            autoFocus={true}
            placeholder={`${t("phone")} *`}
            placeholderTextColor={"#ddd"}
            keyboardType="phone-pad"
            onChangeText={(text) => {
              this.setState({ phone: text });
            }}
            value={(this.state && this.state.phone) || ""}
          />

          <Button
            style={{ margin: 60, borderRadius: 25, textTransform: 'uppercase' }}
            onPress={() => {
              this.onValidateClicked();
            }}
          >
            {t("submit")}
          </Button>

         
        </View>
      </View>
    );
  }
}

export default withFirebase(withTranslation(AnonymousSignUp));
