import React, { Component } from "react";
import { Text, View, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import { TEXT_TV_COLOR } from "../../../constants/const";

export class ClassementPhone extends Component {
  render() {
    return (
      <View
        style={{
          borderColor: "gray",
          height: 60,
          borderRadius: 30,
          borderWidth: 1,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 5,
          paddingRight: 10,
          marginBottom: 30,
          ...this.props.style
        }}
      >
        <Image
          style={{
            width: 60,
            height: 60,
            marginLeft: 5,
          }}
          resizeMode={"contain"}
          source={require("../../../assets/images/phone.svg")}
        />
        <Text
          style={{
            color: TEXT_TV_COLOR,
            textAlignVertical: "center",
            textAlign: "center",
            fontSize: RF(2)
          }}
        >
          Classement intégral sur votre mobile
        </Text>
      </View>
    );
  }
}

export default ClassementPhone;
