import React from "react";
import { ImageBackground, View, Text, Image } from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../Reponses";
import BaseQuiz from "./baseQuiz";
import { TIME_PAUSE, TIME_EXTRA_ANIM, TEXT_COLOR, isEn } from "../../constants/const";
import Ranking from "../Ranking";
import CountDown from "../CountDown";

export class Question extends BaseQuiz {
  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackStartTimer = this.callbackStartTimer.bind(this);
  }

  getStateTimer = () => {
    if (this.state.currentQuiz != null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      this.timeLeftSecs = this.state.currentQuiz.start.seconds - now;
    }
  };

  callbackStartTimer(val) {
    //Quizzes started
    // console.log(val);
    if (val >= 0) {
      this.checkTimer(val);
    } else {
      this.checkQuizAndQuestion();
      if (this._isMounted) {
        this.setState({ screen: "Question" });
      }
    }
  }

  checkTimer = (val) => {
    this.getStateTimer();
    if (this.currentAction !== null) {
      if (Math.abs(val - this.timeLeftSecs) > 0) {
        if (this._isMounted) {
          // console.log('checkTimer');
          this.setState({
            timeLeft: this.timeLeftSecs,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    }
  };

  callbackTimer(val) {
    // check again the question
    this.checkQuizAndQuestion();
    // check timer and current question time
    if (this.state.currentQuestion != null) {
      if (this.state.currentQuestion.displayResult === false) {
        var timePast = this.state.currentQuiz.start.seconds;
        // console.log(timePast);
        // console.log(val);
        for (let i = 0; i < this.state.currentQuestion.order; i++) {
          const question = this.state.currentQuiz.questions.filter((e) => {
            return e.order === i + 1;
          })[0];
          if (i === 0) {
            timePast = timePast + question.time + TIME_EXTRA_ANIM;
          } else {
            timePast = timePast + TIME_PAUSE + question.time + TIME_EXTRA_ANIM;
          }
        }
        const now = Math.floor(
          (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) /
          1000
        );
        const timeLeft = timePast - now;
        if (Math.abs(timeLeft - val) > 0) {
          if (this._isMounted) {
            console.log("callbackTimer");
            this.setState({
              timeLeft: timeLeft,
              updateNum: this.state.updateNum + 1,
            });
          }
        }
      }
    }
  }

  finishCountDownCallback = () => {
    //Show result and time response
    if (this.state.currentQuestion.displayResult === true) {
      this.updateCurrentQuestionRanking();
      // Next question
      this.checkQuizAndQuestion();
      if (this._isMounted) {
        this.setState({ displayResult: false });
      }
    } else {
      if (this._isMounted) {
        this.setState({ displayResult: true });
      }
    }
  };

  render() {
    // if(this.state.currentQuestion){
    //   console.log("this.state.currentQuestion.order = ", this.state.currentQuestion.order);
    // }
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: this.props.maxWidth,
        }}
      >
        {this.state.screen === "CountDown" && (
          <CountDown
            mode="insider"
            key={this.state.updateNum}
            {...this.props}
            msgWaiting={`${isEn() ? this.state.currentQuiz?.msg_waiting_en : this.state.currentQuiz?.msg_waiting}` || ''}
            quizId={this.state.currentQuiz ? this.state.currentQuiz.quiz_id : false}
            showBanner={this.state.currentQuiz ? ['474', '479'].includes(this.state.currentQuiz.quiz_id) : false}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackStartTimer}
          />
        )}

        {this.state.screen === "Question" &&
          this.state.currentQuestion &&
          this.state.currentQuestion && (
            <View>
              <View
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                }}
              >
                <ImageBackground
                  key={this.state.currentQuestion.displayResult}
                  style={{
                    width: 66,
                    height: 66,
                    marginLeft: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/circle.png")}
                >
                  <CountDownCircle
                    key={this.state.timeLeft}
                    seconds={
                      this.state.currentQuestion.displayResult
                        ? this.state.currentQuestion.displayResultTimeLeft
                        : this.state.timeLeft || 10
                    }
                    radius={28}
                    borderWidth={8}
                    color={
                      this.state.currentQuestion.displayResult
                        ? "limegreen"
                        : "#ff003f"
                    }
                    bgColor="#fff"
                    // textStyle={{ fontSize: 18 }}
                    finishCallback={() => this.finishCountDownCallback()}
                    callbackTimer={this.callbackTimer}
                  />
                </ImageBackground>

                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center",
                    }}
                  >
                    {isEn() ? this.state.currentQuestion.question_en : this.state.currentQuestion.question}
                  </Text>
                </View>
              </View>
              {this.state.currentQuiz.questions && (
                <PagingControl
                  key={this.state.currentQuestion.order}
                  style={{ marginTop: 15 }}
                  max={this.state.currentQuiz.questions.length}
                  width={320}
                  current={this.state.currentQuestion.order}
                />
              )}

              <View
                style={{
                  top: 10,
                  paddingBottom: 150,
                }}
                key={this.state.currentQuestion.displayResult}
              >
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {this.state.currentQuiz.play_sound === "all" &&
                    !this.state.currentQuestion.displayResult &&
                    this.state.currentQuestion.sound && (
                      <audio autoPlay loop controls>
                        <source src={this.state.currentQuestion.sound} />
                      </audio>
                    )}

                  {this.state.currentQuestion.image &&
                    (this.state.currentQuestion.type === "5" || this.state.currentQuestion.type === "8" ||
                      this.state.currentQuestion.type === "6") && (
                      <Image
                        style={{
                          width:
                            this.state.currentQuestion.type === "6" ? 320 : 200,
                          height:
                            this.state.currentQuestion.type === "6" ? 180 : 200,
                          borderRadius: 10,
                          marginLeft: 5,
                          marginRight: 5,
                          marginBottom: 5,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          borderWidth: 1,
                          filter: this.state.currentQuestion.displayResult ? "" : (this.state.currentQuestion.type === "8" ? "blur(2px)" : ""),
                        }}
                        source={this.state.currentQuestion.displayResult && this.state.currentQuestion.image_result ? { uri: this.state.currentQuestion.image_result_mobile ? this.state.currentQuestion.image_result_mobile : this.state.currentQuestion.image_result } : { uri: this.state.currentQuestion.image_mobile ? this.state.currentQuestion.image_mobile : this.state.currentQuestion.image }}
                      />
                    )}

                  {this.currentQuestion.image &&
                    (this.currentQuestion.type === "7") && (
                      this.state.currentQuiz.play_sound === 'all' ?
                        <video autoPlay loop
                          src={this.state.currentQuestion.displayResult && this.state.currentQuestion.image_result ? { uri: this.state.currentQuestion.image_result_mobile ? this.state.currentQuestion.image_result_mobile : this.state.currentQuestion.image_result } : { uri: this.state.currentQuestion.image_mobile ? this.state.currentQuestion.image_mobile : this.state.currentQuestion.image }}
                          style={{
                            width: 320,
                            marginBottom: 5,
                          }} /> :
                        <video autoPlay loop muted
                          src={this.state.currentQuestion.displayResult && this.state.currentQuestion.image_result ? { uri: this.state.currentQuestion.image_result_mobile ? this.state.currentQuestion.image_result_mobile : this.state.currentQuestion.image_result } : { uri: this.state.currentQuestion.image_mobile ? this.state.currentQuestion.image_mobile : this.state.currentQuestion.image }}
                          style={{
                            width: 320,
                            marginBottom: 5,
                          }} />
                    )}

                  <View style={{ alignContent: 'center' }}>
                    <View
                      style={{
                        paddingBottom: 5,
                      }}
                    >
                      <Response
                        {...this.props}
                        key={this.state.currentQuestion.displayResult}
                        data={{
                          answers: this.state.currentQuestion.answers,
                          currentQuestionId: this.state.currentQuestion.id,
                          currentQuestionTimeLeft: this.state.currentQuestion
                            .timeLeft,
                          currentQuestionTimeLeftMs: this.state.currentQuestion
                            .timeLeftMs,
                          currentQuestionTimeEnd: this.state.currentQuestion
                            .timeEnd,
                          currentQuizId: this.state.currentQuiz.id,
                          currentQuizSeconds: this.state.currentQuiz.start
                            .seconds,
                          competitionId: this.state.currentQuiz.competition_id,
                          withImage: this.state.currentQuestion.withImage,
                          withResult: this.state.currentQuestion.displayResult,
                          currentQuestion: this.state.currentQuestion,
                        }}
                      />
                    </View>

                  </View>

                  <Image
                    style={{ height: 120, width: 300 }}
                    source={'https://storage.googleapis.com/take-over-loges.appspot.com/Logo_E_Kart_in_blanc_418c1328c2/Logo_E_Kart_in_blanc_418c1328c2.png'}
                    resizeMode="contain"
                  />

                  {this.state.currentQuestion.displayResult && (
                    <View
                      style={{
                        paddingBottom: 100,
                      }}
                    >
                      {this.state.currentQuestion.explication && <Text
                        style={{
                          color: TEXT_COLOR,
                          textAlign: "center",
                          textAlignVertical: "center",
                          flex: 1,
                          marginLeft: 8,
                          fontSize: 16,
                          paddingBottom: 5,
                        }}
                      >
                        {this.state.currentQuestion.explication}
                      </Text>}



                      <View
                        style={{
                          height: 1,
                          width: "70%",
                          backgroundColor: "#fff",
                          opacity: 0.5,
                          alignSelf: "center",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      ></View>

                      <Text
                        style={{
                          color: TEXT_COLOR,
                          textAlign: "center",
                          textAlignVertical: "center",
                          flex: 1,
                          marginLeft: 8,
                          fontSize: RF(2.2),
                          padding: 10,
                        }}
                      >
                        CLASSEMENT TOP 50
                      </Text>
                      <Ranking
                        currentQuestionId={this.state.currentQuestion.id}
                        data={this.state.answersByTimes}
                        final={false}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
          )}
      </View>
    );
  }
}

export default Question;
