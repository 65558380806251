import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { Text, View } from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeView from "../../components/Anim/FadeView";
import { TEXT_MENU, TEXT_COLOR } from "../../constants/const";
import { getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";
import MenuMatch from "../../components/Menu/menu_match";
import MenuQuiz from "../../components/Menu/menu_quiz";
import MenuSurvey from "../../components/Menu/menu_survey";
import { withFirebase } from "../../components/Firebase";
import { withTranslation, t } from "react-multi-lang";

export class MenuNoLogin extends Component {
  state = { forecasted: false, finaldata: false };
  _isMounted = false;
  intervalId = null;
  timeoutId = null;
  constructor(props) {
    super(props);
    this.currentQuiz = null;
    this.currentSurvey = null;
    this.currentMatch = null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.intervalId = setInterval(() => {
      this.checkTimeDisplay();
    }, 1000);
    this.timeoutId = setTimeout(() => {
      if (this._isMounted) {
        this.setState({ finaldata: true });
      }
    }, 800);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this._isMounted = false;
  }

  checkTimeDisplay() {
    // console.log("checkTimeDisplay");
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    const currentQuiz = window.localStorage.getItem("currentQuiz");
    if (currentQuiz !== null) {
      this.currentQuiz = JSON.parse(currentQuiz);
    }
    const currentSurvey = window.localStorage.getItem("currentSurvey");
    if (currentSurvey !== null) {
      this.currentSurvey = JSON.parse(currentSurvey);
    }
    const currentMatch = window.localStorage.getItem("currentMatch");
    if (currentMatch !== null) {
      this.currentMatch = JSON.parse(currentMatch);
      const match_forecast = window.localStorage.getItem("match_forecast");
      if (match_forecast !== null) {
        this.currentForecast = JSON.parse(match_forecast);
        if (this.currentForecast.matchId === this.currentMatch.match_id) {
          // Same match and already forecasted
          if (this._isMounted) {
            this.setState({ forecasted: true });
          }
        } else {
          // not the same match, delete the old one
          // if (this._isMounted) {
          //   this.setState({ forecasted: false });
          // }
        }
      }
    }

    if (this.currentQuiz !== null) {
      // console.log(now - this.currentQuiz.end.seconds);
      if (now > this.currentQuiz.end.seconds) {
        console.log("looking for next quiz");
        window.location.reload(true);
      }
    }

    if (this.currentSurvey !== null) {
      // console.log(now - this.currentSurvey.end.seconds);
      if (now > this.currentSurvey.end.seconds) {
        console.log("looking for next survey");
        window.location.reload(true);
      }
    }

    if (this.currentMatch !== null) {
      // console.log(now - this.currentSurvey.end.seconds);
      if (now > this.currentMatch.end_time.seconds) {
        console.log("looking for next match");
        window.location.reload(true);
      }
    }
    // console.log("ended checkTimeDisplay");
  }
  _renderMenuQuiz = () => {
    return <MenuQuiz key="quiz" {...this.props} mode="nologin" />;
  };

  _renderMenuSurvey = () => {
    return <MenuSurvey key="survey" {...this.props} mode="nologin" />;
  };

  _renderMenuMatch = () => {
    return <MenuMatch key="match" {...this.props} mode="nologin" />;
  };

  _renderMenu = () => {
    var out = [];
    var outRender = [];
    if (this.props.currentMatch) {
      out.push({ type: "match", start: this.props.currentMatch.start.seconds });
    }
    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({ type: "quiz", start: this.props.currentQuiz.start.seconds });
    }
    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.props.currentSurvey.start.seconds,
      });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    if (out.length > 0) {
      for (let i = 0; i < out.length; i++) {
        const event = out[i];
        switch (event.type) {
          case "match":
            outRender.push(this._renderMenuMatch());
            break;
          case "quiz":
            outRender.push(this._renderMenuQuiz());
            break;
          case "survey":
            outRender.push(this._renderMenuSurvey());
            break;
          default:
            break;
        }
      }
    }
    return outRender;
  };

  render() {
    const maxWidth = this.props.maxWidth;

    return (
      <View
        style={{
          alignItems: "center",
          height: "100%",
        }}
        key={this.props.toString()}
      >
        <View
          style={{
            padding: 10,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            {TEXT_MENU()}
          </Text>
        </View>
        {this.state.finaldata && (
          <FadeView
            style={{
              padding: 20,
              width: getWidthPercentMaxLimited(100, maxWidth) - 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* {this.props.currentNote && <MenuNote {...this.props} />} */}

            {this._renderMenu()}

            {!this.props.currentMatch &&
              !(this.props.currentQuiz && this.props.currentQuiz.name !== "") &&
              !this.props.currentNote &&
              !(
                this.props.currentSurvey && this.props.currentSurvey.name !== ""
              ) && (
                <Text style={{ fontSize: RF(2.5), color: TEXT_COLOR }}>
                  {t("no_events_come_back_soon")}
                </Text>
              )}
          </FadeView>
        )}
      </View>
    );
  }
}

export default withFirebase(withMainLayout(withTranslation(MenuNoLogin)));
