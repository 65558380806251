import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ImageBackground,
  Dimensions,
} from "react-native";
import TeleHeader from "./Elements/header";
import FadeSlideView from "../Anim/FadeSlideView";
import Confetti from "react-confetti";
import RF from "react-native-responsive-fontsize";
import {
  EMAILS_RESULTS,
  BG_TELE,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  PROFILE_JOB,
  PROFILE_LOGE_NUMBER,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  PROFILE_DOB,
  PROFILE_ADDRESS,
  ONLY_ANONYMOUS,
  FORECAST_FOOT_FIRST_SCORE_MINUTE,
  FORECAST_FOOT_TOTAL_SCORE,
  THEME,
} from "../../constants/const";
import { getWidthPercent } from "../../libs/list_picker/ratio";
import MatchWinnerRandom from "./matchWinnerRandom";

export class MatchWinner extends Component {
  _isMounted = false;
  _isSaving = false;
  resorted = null;
  _displayWinnersByLoges =
    THEME === "salonstade" || THEME === "takeover" ? true : false;
  state = {
    winner: null,
    screen: "",
    winnersList: [],
    groupWinners: [],
    numUpdate: 0,
  };
  ranCount = 0;

  constructor(props) {
    super(props);

    // console.log("constructor --- ");
    console.log(props);

    if (props.currentMatch.winner) {
      if (this._displayWinnersByLoges) {
        this._groupByLogeNumber(props.pronosticsSorted, true);
      } else {
        this.state = {
          winner: props.currentMatch.winner,
          numUpdate: this.state.numUpdate + 1,
          screen: "winner",
        };
      }
    } else {
      if (props.matchWinners.length > 1 ) {
        //random
        this.state = {
          screen: "random",
          winnersList: props.matchWinners,
          numUpdate: this.state.numUpdate + 1,
        };

        // Do stuff after animations
        var intervalId = setInterval(() => {
          this.doRandom();
        }, 800);

        this.state = {
          intervalId: intervalId,
          randomNum: 10 //Math.floor(Math.random() * props.matchWinners.length * 10) + 2 * props.matchWinners.length,
        };
      } else { //if (props.matchWinners.length === 1) {
        if (this._displayWinnersByLoges) {
          this._groupByLogeNumber(props.pronosticsSorted, true);
        } else {
          this.state = {
            winner: props.matchWinners[0],
            screen: "winner",
            numUpdate: this.state.numUpdate + 1,
          };
        }
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  doRandom = () => {
    const index = this.ranCount % this.props.matchWinners.length;
    var l = this.props.matchWinners.map((el) => {
      el.color = "default";
      return el;
    });
    l[index].color = "green";
    this.setState(
      {
        winnersList: this.props.matchWinners,
        winner: l[0],//l[index],
        screen: "random",
      },
      () => {
        if (this.ranCount >= this.state.randomNum) {
          clearInterval(this.state.intervalId);
          // Resort the list base on point
          var resortList = this.props.pronosticsSorted.splice(0);
          for (let i = 0; i < resortList.length; i++) {
            const r = resortList[i];
            if (r.userId === this.state.winner.userId) {
              resortList[i].point = resortList[i].point + 1;
            }
          }
          this.resorted = resortList.sort((a, b) => {
            return b.point - a.point;
          });

          console.log(this.resorted);

          //Save batch for < 500 each time
          const toSaveLength = this.resorted.length;
          var lastIndex = 0;
          var theRestLength = toSaveLength;
          var max = 450;
          var loopDone = 0;
          while (theRestLength > 0) {
            var batch = this.props.firebase.db.batch();
            var match = this.props.firebase.match_winner(
              this.props.currentMatch.id +
              "_" +
              this.props.currentMatch.match_id
            );
            batch.set(match, this.props.currentMatch);
            for (let i = lastIndex; i < lastIndex + max; i++) {
              if (i < toSaveLength) {
                const el = this.resorted[i];
                var doc = this.props.firebase.match_winner_ranking(
                  this.props.currentMatch.id +
                  "_" +
                  this.props.currentMatch.match_id,
                  i + 1
                );
                batch.set(doc, el);
              }
            }
            loopDone += 1;
            theRestLength = theRestLength - max;
            lastIndex = loopDone * max;
            const restLength = theRestLength;
            // Commit the batch
            const that = this;
            batch.commit().then(() => {
              console.log("updated winner");
              if (restLength <= 0) {
                that._callSendEmailRanking();
              }
            });
          }

          // check if need show group winner
          if (this._displayWinnersByLoges) {
            this._groupByLogeNumber(this.resorted, false);
          } else {
            this.setState({
              screen: "winner",
              numUpdate: this.state.numUpdate + 1,
            });
          }
        }
      }
    );
    this.ranCount = this.ranCount + 1;
  };

  _groupByLogeNumber = (listRankUserData, fromInit = false) => {
    var arrGroups = [];
    for (let i = 0; i < listRankUserData.length; i++) {
      const r = listRankUserData[i];
      if (arrGroups.indexOf(r.logeNumber) < 0) {
        arrGroups.push(r.logeNumber);
      }
    }

    var groupWinners = [];

    for (let j = 0; j < arrGroups.length; j++) {
      const g = arrGroups[j];
      var _arrResorted = listRankUserData.filter((el) => {
        return el.logeNumber === g;
      });
      if (_arrResorted.length > 0) {
        groupWinners.push(_arrResorted[0]);
      }
    }
    if (this._isMounted) {
      //show winners
      this.setState({
        groupWinners: groupWinners,
        screen: "groupWinners",
        numUpdate: this.state.numUpdate + 1,
      });
    } else if (fromInit) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        groupWinners: groupWinners,
        screen: "groupWinners",
        numUpdate: this.state.numUpdate + 1,
      };
    }
  };

  _callSendEmailRanking = () => {
    //send email:
    const sendMailMatchWinners = this.props.firebase.functions.httpsCallable(
      "sendMailMatchWinners"
    );
    sendMailMatchWinners({
      info: {
        theme: THEME,
        rapid: ONLY_ANONYMOUS,
        loge: PROFILE_LOGE_NUMBER,
        phone: PROFILE_PHONE,
        company: PROFILE_COMPANY,
        job: PROFILE_JOB,
        dob: PROFILE_DOB,
        address: PROFILE_ADDRESS,
      },
      currentMatch: this.props.currentMatch,
      to: EMAILS_RESULTS,
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  _getColorScoreTotal = (win) => {
    if (win) {
      if (win.correct_total_score === true) {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };

  _getColorMinute = (win) => {
    if (win) {
      if (
        win.correct_score === true &&
        win.correct_scorer === true &&
        win.correct_minute === true
      ) {
        return "transparent";
      }
      if (
        win.correct_minute === true ||
        (win.scoreGapDistant === 0 && parseInt(win.scoreGap, 10) >= 0)
      ) {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };
  _getColorScorer = (win) => {
    if (win) {
      if (
        win.correct_score === true &&
        win.correct_scorer === true &&
        win.correct_minute === true
      ) {
        return "transparent";
      }
      if (win.correct_scorer === true || win.correctTeamWon === true) {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };
  _getColorScore = (win) => {
    if (win) {
      if (
        win.correct_score === true &&
        win.correct_scorer === true &&
        win.correct_minute === true
      ) {
        return "transparent";
      }
      if (win.correct_score === true || win.type === "rugby") {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };

  _displayMinute = (min) => {
    switch (min) {
      case "ex1":
        return "Tps add. 1er MT";

      case "ex2":
        return "Tps add. 2e MT";
      default:
        return `${min}'`;
    }
  };

  render() {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    // console.log(this.props);
    // console.log(this.state);
    return (
      <ImageBackground
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: BG_TELE,
        }}
        source={THEME_BACKGROUND_TELE}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            top: 0,
            position: "absolute",
          }}
        />
        <Confetti
          width={screenWidth}
          height={screenHeight}
          colors={
            THEME === "jeutop14"
              ? ["#ffd700", "#000000"]
              : THEME === "cupraquizz"
                ? ["#FFFFFF"] : [
                  "#f44336",
                  "#e91e63",
                  "#9c27b0",
                  "#673ab7",
                  "#3f51b5",
                  "#2196f3",
                  "#03a9f4",
                  "#00bcd4",
                  "#009688",
                  "#4CAF50",
                  "#8BC34A",
                  "#CDDC39",
                  "#FFEB3B",
                  "#FFC107",
                  "#FF9800",
                  "#FF5722",
                  "#795548",
                ]
          }
        />

        {this.state.screen === "winner" && this.props.currentMatch && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: BG_TELE,
              borderRadius: 10,
              padding: 50,
              width: "80%",
            }}
            key={this.state.numUpdate}
          >
            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveText}>
                {this.props.liveData.home_score
                  ? this.props.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveText}>-</Text>
              <Text style={styles.liveText}>
                {this.props.liveData.visitor_score
                  ? this.props.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(2.5),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center",
                }}
              >
                Vainqueur
              </Text>
            </View>

            <FadeSlideView direction="down" delay={1000}>
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(9),
                  fontWeight: "bold",
                  margin: 20,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {this.state.winner != null
                  ? this.state.winner.displayName
                  : "Pas de vainqueur"}
              </Text>
            </FadeSlideView>

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                maxWidth: getWidthPercent(80),
                borderBottomWidth: 1,
                borderBottomColor: "#ccc",
                backgroundColor: this._getColorScore(this.state.winner),
              }}
            >
              <Text style={styles.pronoTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.pronoLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.pronoText}>
                {this.state.winner ? this.state.winner.scoreHome : 0}
              </Text>
              <Text style={styles.pronoText}>-</Text>
              <Text style={styles.pronoText}>
                {this.state.winner ? this.state.winner.scoreVisitor : 0}
              </Text>
              <Image
                style={styles.pronoLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.pronoTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View style={styles.pronoLine}>
              <View
                style={{
                  height: getWidthPercent(4),
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  borderBottomColor: "#ccc",
                  borderRightWidth: 1,
                  borderRightColor: "#ccc",
                  backgroundColor: this._getColorScorer(this.state.winner),
                }}
              >
                {this.state.winner.firstScorer && (
                  <Text style={styles.pronoScorerTextLeft}>
                    {this.state.winner
                      ? this.state.winner.firstScorer.name
                      : ""}
                  </Text>
                )}
                {this.state.winner.teamWonName && (
                  <Text style={styles.pronoScorerTextLeft}>
                    {this.state.winner ? this.state.winner.teamWonName : ""}
                  </Text>
                )}
              </View>
              <View
                style={{
                  height: getWidthPercent(4),
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  borderBottomColor: "#ccc",
                  borderLeftWidth: 1,
                  borderLeftColor: "#ccc",
                  backgroundColor: this._getColorMinute(this.state.winner),
                }}
              >
                {this.state.winner.scoreGap && (
                  <Text style={styles.pronoScorerTextRight}>
                    {this.state.winner
                      ? this._displayMinute(this.state.winner.scoreGap)
                      : ""}
                  </Text>
                )}
                {this.state.winner.firstScoreMinute &&
                  FORECAST_FOOT_FIRST_SCORE_MINUTE && (
                    <Text style={styles.pronoScorerTextRight}>
                      {this.state.winner
                        ? this._displayMinute(
                          this.state.winner.firstScoreMinute
                        )
                        : ""}
                    </Text>
                  )}
              </View>
              <View
                style={{
                  height: getWidthPercent(4),
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  borderBottomColor: "#ccc",
                  borderLeftWidth: 1,
                  borderLeftColor: "#ccc",
                  backgroundColor: this._getColorScoreTotal(this.state.winner),
                }}
              >
                {this.state.winner.totalScore >= 0 &&
                  FORECAST_FOOT_TOTAL_SCORE && (
                    <Text style={styles.pronoScorerTextRight}>
                      {this.state.winner
                        ? `# ${this.state.winner.totalScore}`
                        : ""}
                    </Text>
                  )}
              </View>
            </View>
          </View>
        )}

        {this.state.screen === "groupWinners" && this.props.currentMatch && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: BG_TELE,
              borderRadius: 10,
              padding: 50,
              width: "86%",
            }}
            key={this.state.numUpdate}
          >

            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeftSmall}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogoSmall}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTextSmall}>
                {this.props.liveData.home_score
                  ? this.props.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveTextSmall}>-</Text>
              <Text style={styles.liveTextSmall}>
                {this.props.liveData.visitor_score
                  ? this.props.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogoSmall}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRightSmall}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(2.5),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center",
                }}
              >
                Vainqueurs
              </Text>
            </View>

            <FadeSlideView direction="down" delay={1000} style={{ width: '80%', }}>
              {this.state.groupWinners.slice(0, 10).map((winner, index) => (
                <View key={`_winner_${index}`} style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                  <View key={`_winner_${index}`} style={{ flexDirection: "row" }}>
                    <Image
                      style={{
                        width: RF(8),
                        height: RF(8),
                        marginRight: RF(2),
                      }}
                      source={require("../../assets/images/first.png")}
                      resizeMode="contain"
                    />
                    <Text
                      style={{
                        color: TEXT_TV_COLOR,
                        fontSize: RF(6.5),
                        fontWeight: "bold",

                      }}
                    >
                      {winner.displayName}
                    </Text>
                  </View>
                  <Text
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: RF(5),
                    }}
                  >
                    {winner.logeNumber}
                  </Text>
                </View>
              ))}
            </FadeSlideView>
          </View>
        )}

        {this.state.screen === "random" && this.props.currentMatch && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: BG_TELE,
              borderRadius: 10,
              padding: 50,
              width: "80%",
            }}
            key={this.state.numUpdate}
          >
            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveText}>
                {this.props.liveData.home_score
                  ? this.props.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveText}>-</Text>
              <Text style={styles.liveText}>
                {this.props.liveData.visitor_score
                  ? this.props.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 30,
              }}
            >
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(2.5),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center",
                }}
              >
                Ex Aequo
              </Text>
            </View>

            <FadeSlideView direction="up">
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(9),
                  fontWeight: "bold",
                  margin: 20,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Tirage au sort
              </Text>
            </FadeSlideView>

            <MatchWinnerRandom
              data={this.state.winnersList}
              key={this.state.numUpdate}
            />
          </View>
        )}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: BG_TELE,
    flex: 1,
  },
  liveContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  liveLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: getWidthPercent(100),
  },
  liveLogo: {
    width: getWidthPercent(7),
    height: getWidthPercent(7),
    margin: getWidthPercent(1),
  },
  liveLogoSmall: {
    width: getWidthPercent(3),
    height: getWidthPercent(3),
    margin: getWidthPercent(1),
  },
  liveText: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(5),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },
  liveTextSmall: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },
  liveTeamTextLeft: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(20),
  },
  liveTeamTextLeftSmall: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(30),
  },
  liveTeamTextRight: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(20),
  },
  liveTeamTextRightSmall: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(30),
  },
  liveTextScorer: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(4),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },

  pronoLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: getWidthPercent(80),
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  pronoLogo: {
    width: getWidthPercent(2),
    height: getWidthPercent(2),
    margin: getWidthPercent(1),
  },
  pronoText: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },
  pronoTeamTextLeft: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(15),
  },
  pronoTeamTextRight: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(15),
  },
  pronoTextScorer: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },
  pronoScorerTextLeft: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(15),
    marginRight: getWidthPercent(1),
  },
  pronoScorerTextRight: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(15),
    marginLeft: getWidthPercent(1),
  },
});

export default MatchWinner;
