import React from "react";
import { TIME_PAUSE, TIME_EXTRA_ANIM, ASYNC_COUNT_DOWN } from "../../constants/const";
import moment from "moment";

export class BaseAsync extends React.Component {
  _isMounted = false;
  screen = ASYNC_COUNT_DOWN ? "CountDown" : "Question";

  constructor(props) {
    super(props);

    console.log("constructor BaseAsync");
    this.alreadyCount = false;
    this.currentAsyncId = null;
    this.currentAsync = null;
    this.currentQuestion = null;
    this.currentQuestionIndex = 0;
    this.currentPoints = 0;
    this.currentPointGap = 0;
    this.currentSetQuestions = null;
    this.currentQuestionResult = null;
    this.currentResults = null;
    this.playersRanking = [];
    this.snapshotQuestion = null;
    this.timeStartQA = null;
    this.updatedPlayed = false;
    this.playerInfo = null;
    this.state = {
      screen: this.screen,
      shouldShow: true,
      currentAsync: null,
      currentQuestion: null,
      currentSetQuestions: null,
      currentResults: null,
      displayResult: false,
      timeLeft: ASYNC_COUNT_DOWN ? 5 : 0,
      answered: false,
      updateNum: 0,
    };
  }

  checkTheParams = () => {
    console.log("called checkTheParams");
    if (this.props.navigation) {
      if (
        this.props.navigation.state.params &&
        this.props.navigation.state.params.aid
      ) {
        this.saveAsycnId();
        if (this.props.currentAsyncs) {
          const ca = this.props.currentAsyncs.filter((e) => {
            return e.id === this.currentAsyncId;
          });
          if (ca && ca.length > 0) {
            this.currentAsync = ca[0];
            this.checkCurrentAsync();
          }
        } else {
          if (this.props.getAsyncByIds) {
            if (this.props.getAsyncByIds()) {
              if (!this.props.currentAsyncs) {
                this.setState({
                  screen: "doesnotexist",
                  updateNum: this.state.updateNum + 1,
                });
              }
            }
          }
        }
        return true;
      } else {
        this.props.navigation.navigate("Home");
      }
    }
    return false;
  };

  saveAsycnId = () => {
    this.currentAsyncId = this.props.navigation.state.params.aid;
    var savedIdsJSON = [];
    //save the id loaded:
    const savedIds = window.localStorage.getItem("aids");
    if (savedIds) {
      savedIdsJSON = JSON.parse(savedIds);
      if (savedIdsJSON.indexOf(this.currentAsyncId) < 0) {
        savedIdsJSON.push(this.currentAsyncId);
      }
    } else {
      savedIdsJSON = [this.currentAsyncId];
    }
    window.localStorage.setItem("aids", JSON.stringify(savedIdsJSON));
    window.localStorage.setItem("needOpenAsync", JSON.stringify({
      type: 'async',
      id: this.currentAsyncId
    }));
  };

  checkQuizAndQuestions = (isNext) => {
    if (
      this.currentAsync !== null &&
      this.currentAsync.start &&
      this.currentAsync.quiz_per_day &&
      this.props.authUser &&
      this.playerInfo
    ) {
      console.log("checkQuizAndQuestions with isNext = ", isNext);
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      const nowMs = Math.floor(
        Date.now() + parseInt(window.localStorage.getItem("delay"), 10)
      );
      const timeLeft = this.currentAsync.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {


        // check time played in a day
        if (this.playerInfo.lastUpdate === moment().format("YYYY-MM-DD")) {
          if (
            this.playerInfo.played >=
            parseInt(this.currentAsync.quiz_per_day, 10)
          ) {
            if (this._isMounted) {
              console.log("shoud show the finished_for_today");
              this.setState({
                screen: "finished_for_today",
                currentAsync: this.currentAsync,
                updateNum: this.state.updateNum + 1,
              });
            }
            return; // finish for today
          }
        } else {
          if (this.props.authUser) {
            this.props.firebase
              .quiz(
                `${this.currentAsync.id}/players/${this.props.authUser.uid}`
              )
              .set(
                {
                  played: 0,
                  lastUpdate: moment().format("YYYY-MM-DD"),
                },
                { merge: true }
              );
          }
        }
        // Process questions base on time
        if (this.currentSetQuestions === null && this.updatedPlayed === false) {
          var { questions } = this.currentAsync;
          // TODO: option repeat questions ?
          // const currentSetQuestions = questions;
          const currentSetQuestions = questions
          .filter((q) => {
            if (this.playerInfo.questionsPlayed) {
              return this.playerInfo.questionsPlayed.indexOf(q.id) < 0;
            } else {
              return true;
            }
          });

          if (currentSetQuestions.length > 0) {
            // Shuffle array
            const shuffled = currentSetQuestions.sort(
              () => 0.5 - Math.random()
            );
            // Get sub-array of first n elements after shuffled
            this.currentSetQuestions = shuffled.slice(
              0,
              parseInt(this.currentAsync.questions_per_quiz, 10)
            );
            // console.log("currentSetQuestions = ", this.currentSetQuestions);
            this.currentAsync.questions = this.currentSetQuestions;
            this.timeStartQA = now;
            // update played
          } else {
            if (this._isMounted) {
              this.setState({
                screen: "nomore",
                currentAsync: this.currentAsync,
                updateNum: this.state.updateNum + 1,
              });
            }
            return;
          }
        }
        //check the count down before start
        if (ASYNC_COUNT_DOWN) {
          console.log("this.alreadyCount = ", this.alreadyCount);
          if (!this.alreadyCount) {
            if (this._isMounted) {
              this.setState({
                screen: "CountDown",
                timeLeft: 5,
                currentAsync: this.currentAsync,
                updateNum: this.state.updateNum + 1,
              }, () => {
                this.alreadyCount = true;
              });
            }
            return;
          }
        }
        // console.log("this.currentSetQuestions = ", this.currentSetQuestions);
        if (isNext) {
          this.currentQuestionIndex += 1;
        }
        if (this.currentQuestionIndex < this.currentSetQuestions.length) {
          this.currentQuestion = this.currentSetQuestions[
            this.currentQuestionIndex
          ];

          const qTime = this.currentQuestion ? this.currentQuestion.time : 0;
          this.timeStartQA = now;
          if (this.currentQuestionIndex === 0) {
            this.timeStartQA = this.timeStartQA + qTime;
          } else {
            this.timeStartQA =
              // TIME_EXTRA_ANIM +
              this.timeStartQA +
              // TIME_PAUSE +
              qTime
            // + TIME_EXTRA_ANIM;
          }
          const timePastWithResult =
            this.timeStartQA + TIME_PAUSE + TIME_EXTRA_ANIM;

          this.currentQuestion.timeLeft = this.timeStartQA - now;
          this.currentQuestion.timeLeftMs = this.timeStartQA * 1000 - nowMs;
          this.currentQuestion.displayResult = now > this.timeStartQA;
          this.currentQuestion.displayResultTimeLeft = timePastWithResult - now;
          this.currentQuestion.timeEnd = this.timeStartQA;
          // update question played

          this.bindData();
        } else {
          // quiz END
          console.log("QA ended");
          // update the points
          // this.props.firebase
          //   .user(this.props.authUser.uid)
          //   .set(
          //     {
          //       pointTotal: this.props.firebase.FieldValue.increment(this.currentPoints),
          //       pointQuiz: this.props.firebase.FieldValue.increment(this.currentPoints)
          //     },
          //     { merge: true }
          //   )
          //   .then((s) => {
          //     console.log(
          //       "update user ",
          //       this.props.authUser.uid,
          //       " points ",
          //       this.currentPoints
          //     );
          //   });
          if (this._isMounted) {
            this.setState({
              screen: "finished",
              updateNum: this.state.updateNum + 1,
            });
          }
        }
      } else {
        console.log("set screen countdown");
        if (this._isMounted) {
          this.setState({
            screen: "CountDown",
            timeLeft: timeLeft,
            currentAsync: this.currentAsync,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    } else {
      // navigate to screen no QUIZ
      this.props.navigation.navigate("Home");
    }
  };

  bindData = () => {
    if (this._isMounted) {
      this.setState(
        {
          screen: 'Question',
          currentAsync: this.currentAsync,
          currentQuestion: this.currentQuestion,
          currentQuestionIndex: this.currentQuestionIndex,
          displayResult: this.currentQuestion.displayResult,
          updateNum: this.state.updateNum + 1,
          timeLeft: this.currentQuestion.displayResult
            ? this.currentQuestion.displayResultTimeLeft
            : this.currentQuestion.timeLeft,
        },
        () => {
          //console.log("==== timeLeft = ", this.state.timeLeft);
          console.log(
            "==== allowUpdatePlayed this.updatedPlayed = ",
            this.updatedPlayed
          );
          if (this.props.authUser) {
            if (this.updatedPlayed === false) {
              this.updatedPlayed = true;
              this.props.firebase
                .quiz(
                  `${this.currentAsync.id}/players/${this.props.authUser.uid}`
                )
                .set(
                  {
                    played: this.props.firebase.FieldValue.increment(1),
                    questionsPlayed: this.props.firebase.FieldValue.arrayUnion(this.currentQuestion.id),
                    lastUpdate: moment().format("YYYY-MM-DD"),
                  },
                  { merge: true }
                );
            } else {
              this.props.firebase
                .quiz(
                  `${this.currentAsync.id}/players/${this.props.authUser.uid}`
                )
                .set(
                  {
                    questionsPlayed: this.props.firebase.FieldValue.arrayUnion(
                      this.currentQuestion.id
                    ),
                  },
                  { merge: true }
                );
            }
          }
        }
      );
    }
  };

  componentDidMount() {
    // Get questions disponible
    this._isMounted = true;
    if (this.props.authUser !== null) {
      this.checkTheParams();
    } else {
      this.saveAsycnId();
    }
  }

  checkCurrentAsync = () => {
    if (
      this.currentAsync !== null &&
      this.props.currentAsyncs &&
      this.playerInfo === null
    ) {
      //Check the questions déjà jouer
      //get info played questions
      console.log("componentDidMount called");
      this.props.firebase
        .quiz(`${this.currentAsync.id}/players/${this.props.authUser.uid}`)
        .get()
        .then((player) => {
          if (player.exists) {
            this.playerInfo = player.data();
          } else {
            this.playerInfo = { played: 0, questionsPlayed: [], lastUpdate: moment().format("YYYY-MM-DD") };
            this.props.firebase
              .quiz(
                `${this.currentAsync.id}/players/${this.props.authUser.uid}`
              )
              .set(
                {
                  played: 0,
                  questionsPlayed: [],
                  lastUpdate: moment().format("YYYY-MM-DD"),
                },
                { merge: true }
              );
          }
          console.log("checkQuizAndQuestions from get player");
          this.checkQuizAndQuestions();
        })
        .catch((err) => {
          console.log("Can not get player info ", err);
          // this.checkQuizAndQuestions();
        });
    }
  }



  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default BaseAsync;
