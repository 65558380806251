import React, { Component } from "react";
import ResponseSurveyItem from "./reponsSurveyItem";
import { DISPLAY_NAME } from "../../constants/const";

export class ResponseSurvey extends Component {
  constructor(props) {
    super(props);
    const lastReponse = window.localStorage.getItem("last_reponse");
    var alreadyAnswered = false;
    var alreadyAnsweredId = 0;
    const anw = props.data;
    if (lastReponse) {
      const lastReponseJSON = JSON.parse(lastReponse);
      if (lastReponseJSON.surveyId === anw.currentSurveyId) {
        const currentVer = lastReponseJSON.version
          ? lastReponseJSON.version
          : 0;
        if (currentVer < anw.currentSurveyVersion) {
          // remove last reponse
          window.localStorage.removeItem("last_reponse");
          window.localStorage.removeItem("surveyTempo");
        } else {
          if (lastReponseJSON.questions) {
            for (let i = 0; i < lastReponseJSON.questions.length; i++) {
              const q = lastReponseJSON.questions[i];
              if (q.questionId === anw.currentQuestionId) {
                alreadyAnswered = true;
                anw.answers[q.answerIndex].answered = true;
                alreadyAnsweredId = q.answerId;
                break;
              }
            }
          }
        }
      }
    }
    this.state = {
      data: anw,
      answered: alreadyAnswered,
      answerId: alreadyAnsweredId,
    };
  }

  onPressButton = (key, id) => {
    if (!this.state || !this.state.answered) {
      const profile = window.localStorage.getItem("profile");
      const profileJson = JSON.parse(profile);
      const calculateSurvey = this.props.firebase.functions.httpsCallable(
        "calculateSondage"
      );
      var savedData = {
        surveyId: this.state.data.currentSurveyId,
        questionId: this.state.data.currentQuestionId,
        answerId: id,
        time:
          this.state.data.currentQuestionTimeLeft -
          (this.state.data.currentQuestionTimeEnd -
            Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delay"), 10)) /
                1000
            )),
      };

      var calculData = {
        surveyId: this.state.data.currentSurveyId,
        questionId: this.state.data.currentQuestionId,
        startSecond: this.state.data.currentSurveySeconds,
        currentQuestion: this.state.data.currentQuestion,
        userData: savedData,
      };

      if (this.props.authUser) {
        if (profileJson) {
          savedData = {
            ...savedData,
            userId: this.props.authUser.uid,
            ...profileJson,
            displayName:
              DISPLAY_NAME === "SHORT"
                ? `${profileJson.firstName} ${profileJson.lastName
                    .charAt(0)
                    .toUpperCase()}.`
                : `${profileJson.firstName} ${profileJson.lastName}`,
          };
        }
        calculData.userData = savedData;
        // Save for calcul results
        this.props.firebase
          .surveys_result(
            `${this.state.data.currentSurveyId}/q${this.state.data.currentQuestionId}/a${id}/users/${this.props.authUser.uid}`
          )
          .set(savedData)
          .then(() => {
            calculateSurvey(calculData).then((re) => {
              console.log("calculateSurvey done");
            });
          });
      } else {
        // Save tempo for sync later
        var needSaveSA = window.localStorage.getItem("needSaveSA");
        if (needSaveSA) {
          var needSaveSAJSON = JSON.parse(needSaveSA);
          needSaveSAJSON.questions.push({
            collection: `${this.state.data.currentSurveyId}/q${this.state.data.currentQuestionId}/a${id}/users/`,
            savedData: savedData,
            calculData: calculData,
          });

          window.localStorage.setItem(
            "needSaveSA",
            JSON.stringify(needSaveSAJSON)
          );
        } else {
          window.localStorage.setItem(
            "needSaveSA",
            JSON.stringify({
              surveyId: this.state.data.currentSurveyId,
              questions: [
                {
                  collection: `${this.state.data.currentSurveyId}/q${this.state.data.currentQuestionId}/a${id}/users/`,
                  savedData: savedData,
                  calculData: calculData,
                },
              ],
            })
          );
        }
      }
      savedData.startSecond = `${this.state.data.currentSurveySeconds}`;
      savedData.answerIndex = key;
      //save in local last reponse
      const lastReponse = window.localStorage.getItem("last_reponse");
      if (lastReponse) {
        const lastReponseJSON = JSON.parse(lastReponse);
        if (lastReponseJSON) {
          var arrQ = [];
          if (lastReponseJSON.surveyId === this.state.data.currentSurveyId) {
            // same survey
            // check the questions
            if (lastReponseJSON.questions) {
              if (lastReponseJSON.questions.length > 0) {
                lastReponseJSON.questions.push(savedData);
              }
            } else {
              arrQ.push(savedData);
              lastReponseJSON.questions = arrQ;
            }
          } else {
            // Not the same survey
            lastReponseJSON.surveyId = this.state.data.currentSurveyId;
            arrQ.push(savedData);
            lastReponseJSON.questions = arrQ;
          }
          window.localStorage.setItem(
            "last_reponse",
            JSON.stringify(lastReponseJSON)
          );
        }
      } else {
        window.localStorage.setItem(
          "last_reponse",
          JSON.stringify({
            version: this.state.data.currentSurveyVersion,
            surveyId: this.state.data.currentSurveyId,
            questions: [savedData],
          })
        );
      }

      const anw = this.props.data;
      // anw.answers[key].answered = true;

      if (this.props.data.lastQuestion) {
        //done
        window.localStorage.setItem(
          "surveyTempo",
          JSON.stringify({
            surveyId: this.state.data.currentSurveyId,
            status: "done",
            version: this.state.data.currentSurveyVersion
          })
        );
      } else {
        window.localStorage.setItem(
          "surveyTempo",
          JSON.stringify({
            surveyId: this.state.data.currentSurveyId,
            last_order: this.state.data.currentQuestion.order,
            version: this.state.data.currentSurveyVersion
          })
        );
      }

      this.setState({
        data: anw,
        answered: true,
        answerId: id,
      });

      if (this.props.callBackFromAnswered) {
        this.props.callBackFromAnswered();
      }
    }
  };

  componentDidMount() {
    if (this.props.data.withResult) {
      const last_response = window.localStorage.getItem("last_reponse");
      if (last_response) {
        const lastReponseJSON = JSON.parse(last_response);
        if (lastReponseJSON) {
          if (
            lastReponseJSON.questionId === this.props.data.currentQuestionId
          ) {
            this.setState({
              answeredId: lastReponseJSON.answerId,
              answered: true,
            });
          }
        }
      }
    }
  }

  render() {
    // console.log("this.props.data.answers = ", this.props.data.answers);
    return (
      <ResponseSurveyItem
        {...this.props}
        data={this.props.data.answers}
        question={this.props.data}
        onPressButton={this.onPressButton}
        answered={this.state.answered}
        answerId={this.state.answerId}
        modeTele={this.props.modeTele || false}
        withResult={this.props.data.withResult}
      />
    );
  }
}

export default ResponseSurvey;
