import React, { Component } from "react";
import ResponseTextItem from "./reponseTextItem";
import { DISPLAY_NAME, QUIZ_EASY_POINT, QUIZ_MEDIUM_POINT, QUIZ_HARD_POINT, THEME, ONLY_ANONYMOUS } from "../../constants/const";

export class Response extends Component {
  constructor(props) {
    super(props);

    const lastReponse = window.localStorage.getItem("last_reponse");
    var alreadyAnswered = false;
    var alreadyAnsweredId = 0;
    const anw = props.data;
    if (lastReponse) {
      const lastReponseJSON = JSON.parse(lastReponse);
      if (
        lastReponseJSON.quizId === anw.currentQuizId &&
        lastReponseJSON.questionId === anw.currentQuestionId &&
        lastReponseJSON.startSecond === `${anw.currentQuizSeconds}`
      ) {
        alreadyAnswered = true;
        anw.answers[lastReponseJSON.answerIndex].answered = true;
        alreadyAnsweredId = lastReponseJSON.answerId;
      }
    }
    this.state = { data: anw, answered: alreadyAnswered, answerId: alreadyAnsweredId };
  }
  onPressButton = (key, id) => {
    if (this.props.mode === 'async') {
      this.onPressButtonAsync(key, id);
    } else if (this.props.mode === 'prediction') {
      this.onPressButtonPrediction(key, id);
    } else {
      this.onPressButtonQuiz(key, id);
    }
  };

  onPressButtonPrediction = (key, id) => {
    if (!this.state || !this.state.answered) {
      const profile = window.localStorage.getItem("profile");
      var loge = "";
      var profileJson = {};
      if (profile) {
        profileJson = JSON.parse(profile);
        loge = profileJson
          ? profileJson.logeNumber
            ? profileJson.logeNumber
            : ""
          : "";
      }

      let _responeData = {};

      const savedInfoUser = {
        predictionId: this.state.data.currentPredictionId,
        userId: this.props.authUser?.uid || '',
        logeNumber: loge,
        email: this.props.authUser?.email,
        ...profileJson,
        displayName: ONLY_ANONYMOUS ? this.props.authUser?.displayName : (
          DISPLAY_NAME === "SHORT"
            ? `${profileJson.firstName} ${profileJson.lastName
              .charAt(0)
              .toUpperCase()}.`
            : `${profileJson.firstName} ${profileJson.lastName}`)
      };

      _responeData[`q_${this.state.data.currentQuestionId}`] = id;

      this.props.firebase
        .predictionsResults(
          `${this.state.data.currentPredictionId}/results/${this.props.authUser.uid}`
        ).set({...savedInfoUser, ..._responeData}, { merge: true });

      const anw = this.props.data;
      anw.answers[key].answered = true;
      this.setState({ data: anw, answered: true, answerId: key });

      this.props.callbackReponse(this.state.data.currentQuestionId, id);

    }
  };

  onPressButtonAsync = (key, id) => {
    if (!this.state || !this.state.answered) {
      const profile = window.localStorage.getItem("profile");
      var loge = "";
      var profileJson = {};
      if (profile) {
        profileJson = JSON.parse(profile);
        loge = profileJson
          ? profileJson.logeNumber
            ? profileJson.logeNumber
            : ""
          : "";
      }
      const calQuizRanking = this.props.firebase.functions.httpsCallable(
        "calculateQuizByRanking"
      );
      const savedData = {
        quizId: this.state.data.currentAsyncId,
        questionId: this.state.data.currentQuestionId,
        answerId: id,
        time:
          this.state.data.currentQuestionTimeLeft -
          (this.state.data.currentQuestionTimeEnd -
            Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delay"), 10)) /
              1000
            )),
        timeMs:
          this.state.data.currentQuestionTimeLeftMs -
          (this.state.data.currentQuestionTimeEnd * 1000 -
            Math.floor(
              Date.now() + parseInt(window.localStorage.getItem("delay"), 10)
            )),
        userId: this.props.authUser?.uid || '',
        logeNumber: loge,
        email: this.props.authUser?.email,
        ...profileJson,
        displayName: ONLY_ANONYMOUS ? this.props.authUser?.displayName : (
          DISPLAY_NAME === "SHORT"
            ? `${profileJson.firstName} ${profileJson.lastName
              .charAt(0)
              .toUpperCase()}.`
            : `${profileJson.firstName} ${profileJson.lastName}`)
      };

      // Save for calcul ranking
      this.props.firebase
        .quiz_ranking(
          `${this.state.data.currentAsyncId}_${this.state.data.currentAsyncSeconds}/q${this.state.data.currentQuestionId}/a${id}/users/${this.props.authUser?.uid}`
        )
        .set(savedData)
        .then(() => {
          var _dataResponse = {
            quizId: `${this.state.data.currentAsyncId}`,
            questionId: `${this.state.data.currentQuestionId}`,
            startSecond: `${this.state.data.currentAsyncSeconds}`,
            currentQuestion: this.state.data.currentQuestion,
            currentQuizResultId: `${this.state.data.currentAsyncId}_${this.state.data.currentAsyncSeconds}`,
            userData: savedData,
            domain: THEME,
            configPoints: {
              easy: QUIZ_EASY_POINT,
              medium: QUIZ_MEDIUM_POINT,
              hard: QUIZ_HARD_POINT
            }
          };
          if (this.state.data.competitionId) {
            _dataResponse.competitionId = this.state.data.competitionId;
          }

          console.log("saved data = ", _dataResponse);

          // calcul
          calQuizRanking(_dataResponse).then(re => {
            console.log("calculateQuiz done");
          });
        });
      //save in local last reponse
      savedData.startSecond = `${this.state.data.currentAsyncSeconds}`;
      savedData.answerIndex = key;
      window.localStorage.setItem("last_reponse", JSON.stringify(savedData));

      const anw = this.props.data;
      anw.answers[key].answered = true;
      this.setState({ data: anw, answered: true, answerId: key });

      if (this.props.callbackReponse) {
        var pst = 0;
        const difficulty = this.state.data.currentQuestion.difficulty;
        if (this.state.data.currentQuestion.rightAnswer === id) {

          switch (difficulty) {
            case "1":
              pst = QUIZ_EASY_POINT;
              break;
            case "2":
              pst = QUIZ_MEDIUM_POINT;
              break;
            case "3":
              pst = QUIZ_HARD_POINT;
              break;
            default:
              pst = QUIZ_EASY_POINT;
              break;
          }
        }
        this.props.callbackReponse(pst);
      }
    }
  };

  onPressButtonQuiz = (key, id) => {
    if (!this.state || !this.state.answered) {
      const profile = window.localStorage.getItem("profile");
      var loge = "";
      var profileJson = {};
      if (profile) {
        profileJson = JSON.parse(profile);
        loge = profileJson
          ? profileJson.logeNumber
            ? profileJson.logeNumber
            : ""
          : "";
      }

      const calQuizRanking = this.props.firebase.functions.httpsCallable(
        "calculateQuizByRanking"
      );
      const savedData = {
        quizId: this.state.data.currentQuizId,
        questionId: this.state.data.currentQuestionId,
        answerId: id,
        time:
          this.state.data.currentQuestionTimeLeft -
          (this.state.data.currentQuestionTimeEnd -
            Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delay"), 10)) /
              1000
            )),
        timeMs:
          this.state.data.currentQuestionTimeLeftMs -
          (this.state.data.currentQuestionTimeEnd * 1000 -
            Math.floor(
              Date.now() + parseInt(window.localStorage.getItem("delay"), 10)
            )),
        userId: this.props.authUser?.uid || '',
        logeNumber: loge,
        email: this.props.authUser?.email || '',
        ...profileJson,
        displayName: ONLY_ANONYMOUS ? this.props.authUser?.displayName : (
          DISPLAY_NAME === "SHORT"
            ? `${profileJson.firstName} ${profileJson.lastName?.charAt(0).toUpperCase()}.`
            : `${profileJson.firstName} ${profileJson.lastName}`)
      };

      // Save for calcul ranking
      this.props.firebase
        .quiz_ranking(
          `${this.state.data.currentQuizId}_${this.state.data.currentQuizSeconds}/q${this.state.data.currentQuestionId}/a${id}/users/${this.props.authUser.uid}`
        )
        .set(savedData)
        .then(() => {
          var _dataResponse = {
            quizId: `${this.state.data.currentQuizId}`,
            questionId: `${this.state.data.currentQuestionId}`,
            startSecond: `${this.state.data.currentQuizSeconds}`,
            currentQuestion: this.state.data.currentQuestion,
            currentQuizResultId: `${this.state.data.currentQuizId}_${this.state.data.currentQuizSeconds}`,
            userData: savedData,
            domain: THEME,
            configPoints: {
              easy: QUIZ_EASY_POINT,
              medium: QUIZ_MEDIUM_POINT,
              hard: QUIZ_HARD_POINT
            }
          };
          if (this.state.data.competitionId) {
            _dataResponse.competitionId = this.state.data.competitionId;
          }
          // calcul
          calQuizRanking(_dataResponse).then(re => {
            console.log("calculateQuiz done");
          });
        });

      //save in local last reponse
      savedData.startSecond = `${this.state.data.currentQuizSeconds}`;
      savedData.answerIndex = key;
      window.localStorage.setItem("last_reponse", JSON.stringify(savedData));

      const anw = this.props.data;
      anw.answers[key].answered = true;
      this.setState({ data: anw, answered: true, answerId: key });
    }
  };

  componentDidMount() {
    if (this.props.data.withResult) {
      const last_response = window.localStorage.getItem("last_reponse");
      if (last_response) {
        const lastReponseJSON = JSON.parse(last_response);
        if (lastReponseJSON) {
          if (
            lastReponseJSON.questionId === this.props.data.currentQuestionId
          ) {
            this.setState({
              answeredId: lastReponseJSON.answerId,
              answered: true
            });
          }
        }
      }
    }
  }

  render() {
    return (
      <ResponseTextItem
        {...this.props}
        data={this.props.data.answers}
        question={this.props.data}
        onPressButton={this.onPressButton}
        answered={this.state.answered}
        answeredId={this.state.answeredId}
        modeTele={this.props.modeTele || false}
        mode={this.props.mode}
        withResult={this.props.data.withResult}
        type={this.props.data.currentQuestion.type}
        rightId={this.props.data.currentQuestion.rightAnswer}
      />
    );
  }
}

export default Response;
