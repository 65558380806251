import React, { Component } from "react";
import { Text, View, ImageBackground, Dimensions } from "react-native";
import TeleHeader from "./Elements/header";
import ClassementPhone from "./Elements/classementonyourphone";
import Ranking from "../Ranking";
import Confetti from "react-confetti";
import RF from "react-native-responsive-fontsize";
import {
  BG_TELE,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  THEME,
} from "../../constants/const";

export class TeleRankingGlobal extends Component {
  _isMounted = false;
  _isSaving = false;
  state = { data: null, screen: "list" };

  componentDidMount() {
    this._isMounted = true;
    var playersRanking = [];

    // snapshot for ranking total
    if (this.props.firebase) {
      this.props.firebase
        .rankingTotal(10)
        .then((queryRanking) => {
          queryRanking.onSnapshot((snapshot) => {
            playersRanking = [];
            for (let i in snapshot.docs) {
              playersRanking.push(snapshot.docs[i].data());
            }
            if (playersRanking.length > 0) {
              //Only get last 10
              playersRanking = playersRanking.splice(0, 5);
            }
            if (this._isMounted) {
              this.setState({
                data: playersRanking,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    return (
      <ImageBackground
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: BG_TELE,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
        source={THEME_BACKGROUND_TELE}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            top: 0,
            position: "absolute",
          }}
        />
        {this.props.navigation.state.params.confetti !== 'no' && <Confetti
          width={screenWidth}
          height={screenHeight}
          colors={
            THEME === "racing92"
              ? ["#BF9A56", "#C2E0F3", "#FFFFFF"]
              : THEME === "challengeca"
                ? ["#FFD700"]
                : THEME === "cupraquizz"
                  ? ["#FFFFFF"] : [
                    "#f44336",
                    "#e91e63",
                    "#9c27b0",
                    "#673ab7",
                    "#3f51b5",
                    "#2196f3",
                    "#03a9f4",
                    "#00bcd4",
                    "#009688",
                    "#4CAF50",
                    "#8BC34A",
                    "#CDDC39",
                    "#FFEB3B",
                    "#FFC107",
                    "#FF9800",
                    "#FF5722",
                    "#795548",
                  ]
          }
        />}
        {this.state.screen === "list" && this.state.data && (
          <View
            style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: screenHeight,
                maxHeight: screenHeight,
                top: THEME === "jeutop14" ? 150 : 0,
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: TEXT_TV_COLOR,
                    fontSize: RF(8),
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginLeft: Math.round(screenWidth / 30),
                    marginRight: Math.round(screenWidth / 30),
                    textAlign: "center",
                    width: Math.round((2 * screenWidth) / 3),
                  }}
                >
                  {this.props.ranking_title ? this.props.ranking_title :  (THEME === 'stadedefrance' ? 'CLASSEMENT CHALLENGE LOGES' : (THEME === 'salonstade' ? 'CLASSEMENT CHALLENGE SALONS' : 'CLASSEMENTS') )}
                </Text>
              </View>
              <View
                delay={500}
                direction="up"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Ranking
                  {...this.state}
                  modeTele={true}
                  final={false}
                  pointField="pointTotal"
                  type="global"
                />
                <ClassementPhone />
              </View>
            </View>
          </View>
        )}
      </ImageBackground>
    );
  }
}

export default TeleRankingGlobal;
