import React, { Component } from "react";
import { Text, View, FlatList, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import { getWidthFromHD, getWidthPercent } from "../../libs/list_picker/ratio";
import {
  TEXT_COLOR,
  PROFILE_LOGE_NUMBER,
  THEME,
  APP_RANKING_3_WINNER_TV,
} from "../../constants/const";
import { withTranslation, setDefaultLanguage, t } from "react-multi-lang";

const fontSizeTele = 6;
export class Ranking extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    setDefaultLanguage("fr");
    if (this.props.firebase && this.props.type !== "global") {
      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          if (this._isMounted) {
            this.setState({ authUser });
          }
        }
      });
    }
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  _keyExtractor = (item, index) => `_${index}`;

  _renderHeader = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "stretch",
          alignItems: "flex-start",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
        }}
      >
        <Text
          style={{
            fontSize: this.props.modeTele ? RF(4) : RF(2),
            textAlign: "center",
            width: this.props.modeTele ? getWidthFromHD(80) : 30,
            textAlignVertical: "center",
            color: "lightgray",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        />
        <Text
          style={{
            color: "lightgray",
            textAlign: "left",
            textAlignVertical: "center",
            flex: 1,
            marginLeft: 8,
            fontSize: this.props.modeTele ? RF(3) : RF(1.5),
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          {/* JOUEURS */}
        </Text>

        <Text
          style={{
            fontSize: this.props.modeTele ? RF(3) : RF(1.5),
            color: "lightgray",
            textAlign: "center",
            width: this.props.modeTele ? getWidthFromHD(200) : 80,
            paddingBottom: 5,
            paddingTop: 5,
          }}
        ></Text>
        {this.props.type !== "global" && (
          <Text
            style={{
              fontSize: this.props.modeTele ? RF(3) : RF(1.5),
              color: "lightgray",
              textAlign: "center",
              width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 50,
              borderLeftColor: "gray",
              borderLeftWidth: 0.5,
              borderRightColor: "gray",
              borderRightWidth: 0.5,
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            {`✅`}
          </Text>
        )}


        <Text
          style={{
            color: "lightgray",
            fontSize: this.props.modeTele ? RF(3) : RF(1.5),
            textAlignVertical: "center",
            textAlign: "center",
            marginLeft: 5,
            width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 50,
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          Points
        </Text>

        {this.props.type !== "global" && (
          <Text
            style={{
              color: "lightgray",
              fontSize: this.props.modeTele ? RF(3) : RF(1.5),
              textAlignVertical: "center",
              textAlign: "center",
              marginLeft: 5,
              width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 60,
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            Tps
          </Text>
        )}

      </View>
    );
  };

  _renderEmpty = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignContent: "stretch",
          alignItems: "flex-start",
          width: this.props.modeTele
            ? getWidthPercent(90)
            : this.props.maxWidth,
          justifyContent: "space-between",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
          paddingBottom: 5,
          paddingTop: 5,
        }}
      >
        <Text
          style={{
            color: TEXT_COLOR,
            textAlign: "center",
            textAlignVertical: "center",
            flex: 1,
            fontSize: this.props.modeTele ? RF(4) : RF(2),
          }}
        >
          {this.props.modeTele
            ? ""
            : t("no_participants")}
        </Text>
      </View>
    );
  };

  _renderItem = ({ item, index }) => {
    // console.log(item);
    
    return (
      <View
        style={{
          justifyContent: "space-between",
          alignContent: "stretch",
          alignItems: "flex-start",
        }}
      >
        {this.state &&
          this.state.authUser &&
          this.state.authUser.uid === item.userId && (
            <View
              style={{
                backgroundColor: "lightgray",
                position: "absolute",
                height: "100%",
                width: "100%",
                opacity: 0.4,
              }}
            />
          )}
        <View
          direction={index % 2 === 0 ? "left" : "right"}
          style={{
            flex: 1,
            flexDirection: "row",
            alignContent: "stretch",
            alignItems: "flex-start",
            width: this.props.modeTele
              ? getWidthPercent(90)
              : this.props.maxWidth,
            justifyContent: "space-between",
            borderBottomColor: "gray",
            borderBottomWidth: 0.5,
          }}
        >
          {index === 0 && (
            <Image
              style={{
                width: this.props.modeTele ? RF(fontSizeTele) : 30,
                height: this.props.modeTele ? RF(fontSizeTele) : RF(2),
                paddingBottom: 5,
                paddingTop: 5,
                alignSelf: "center",
              }}
              source={require("../../assets/images/first.png")}
              resizeMode="contain"
            />
          )}
          {index === 1 && APP_RANKING_3_WINNER_TV && (
            <Image
              style={{
                width: this.props.modeTele ? RF(fontSizeTele) : 30,
                height: this.props.modeTele ? RF(fontSizeTele) : RF(2),
                paddingBottom: 5,
                paddingTop: 5,
                alignSelf: "center",
              }}
              source={require("../../assets/images/second.png")}
              resizeMode="contain"
            />
          )}
          {index === 2 && APP_RANKING_3_WINNER_TV && (
            <Image
              style={{
                width: this.props.modeTele ? RF(fontSizeTele) : 30,
                height: this.props.modeTele ? RF(fontSizeTele) : RF(2),
                paddingBottom: 5,
                paddingTop: 5,
                alignSelf: "center",
              }}
              source={require("../../assets/images/third.png")}
              resizeMode="contain"
            />
          )}
          {index > 2 && (
            <Text
              style={{
                fontSize: this.props.modeTele ? RF(fontSizeTele) : RF(2),
                textAlign: "center",
                width: this.props.modeTele ? RF(fontSizeTele) : 30,
                textAlignVertical: "center",
                color: "lightgray",
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {index + 1}
            </Text>
          )}
          <Text
            style={{
              color: TEXT_COLOR,
              textAlign: "left",
              textAlignVertical: "center",
              fontWeight: "bold",
              flex: 1,
              fontSize: this.props.modeTele
                ? RF(fontSizeTele)
                : RF(2),
              alignSelf: "center",
            }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.displayName ||
              `${item.firstName} ${(item.lastName || " ")
                .substring(0, 1)
                .toUpperCase()}.`}
          </Text>

          {PROFILE_LOGE_NUMBER && THEME !== 'cupraquizz' && THEME !== 'taratata' && (
            <Text
              style={{
                fontSize: this.props.modeTele ? RF(fontSizeTele - 2) : RF(1),
                color: TEXT_COLOR,
                textAlign: "center",
                textAlignVertical: "center",
                width: this.props.modeTele ? getWidthFromHD(400) : 80,
                paddingBottom: 5,
                paddingTop: 5,
                alignSelf: "center",
              }}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {item.logeNumber ? item.logeNumber : ""}
            </Text>
          )}

          {this.props.type !== "global" && (
            <Text
              style={{
                fontSize: this.props.modeTele ? RF(fontSizeTele) : RF(2.2),
                color: TEXT_COLOR,
                textAlign: "center",
                width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 50,
                // borderLeftColor: "gray",
                // borderLeftWidth: 0.5,
                // borderRightColor: "gray",
                // borderRightWidth: 0.5,
                paddingBottom: 5,
                paddingTop: 5,
                fontWeight: this.props.final === true ? "bold" : "auto",
              }}
            >
              {this.props.totalQuestions != null
                ? `${item.correct}/${this.props.totalQuestions}`
                : item.currentRight >= 1 &&
                  `${this.props.currentQuestionId}` ===
                  `${item.currentQuestionIdAnswered}`
                  ? "+1"
                  : "-"}
            </Text>
          )}
          

          {this.props.type !== "global" && (
            <Text
              style={{
                fontSize: this.props.modeTele ? RF(fontSizeTele) : RF(2.2),
                color: TEXT_COLOR,
                textAlignVertical: "bottom",
                textAlign: "center",
                width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 50,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {item.point}
            </Text>
          )}

          {item.point > 0 && this.props.type !== "global" && (
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: this.props.modeTele ? RF(fontSizeTele - 1) : RF(1.8),
                textAlignVertical: "bottom",
                textAlign: "center",
                alignSelf: "center",
                marginLeft: 5,
                width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 60,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {Math.floor(item.timeRightMs / 1000).toFixed(0)}'
              {Math.floor(
                (item.timeRightMs -
                  Math.floor(item.timeRightMs / 1000).toFixed(0) * 1000) /
                10
              ) < 10
                ? "0" +
                Math.floor(
                  (item.timeRightMs -
                    Math.floor(item.timeRightMs / 1000).toFixed(0) * 1000) /
                  10
                )
                : Math.floor(
                  (item.timeRightMs -
                    Math.floor(item.timeRightMs / 1000).toFixed(0) * 1000) /
                  10
                )}
              s
            </Text>
          )}

          {item.point <= 0 && this.props.type !== "global" && (
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: this.props.modeTele ? RF(fontSizeTele - 1) : RF(1.8),
                textAlignVertical: "bottom",
                textAlign: "center",
                alignSelf: "center",
                marginLeft: 5,
                width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 60,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {this.props.data[0].point === 0
                ? this._displayTimeMs(item.timeMs)
                : "-"}
            </Text>
          )}

          {this.props.type === "global" && (
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: this.props.modeTele ? RF(fontSizeTele - 1) : RF(1.8),
                textAlignVertical: "bottom",
                textAlign: "center",
                alignSelf: "center",
                marginLeft: 5,
                width: this.props.modeTele ? getWidthFromHD(40*fontSizeTele) : 60,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {item[this.props.pointField]}
            </Text>
          )}
        </View>
      </View>
    );
  };

  _displayTimeMs = (timeMs) => {
    var displayT = "";
    displayT += Math.floor(timeMs / 1000).toFixed(0) + "'";
    if (
      Math.floor((timeMs - Math.floor(timeMs / 1000).toFixed(0) * 1000) / 10) <
      10
    ) {
      displayT +=
        "0" +
        Math.floor((timeMs - Math.floor(timeMs / 1000).toFixed(0) * 1000) / 10);
    } else {
      displayT +=
        Math.floor(
          (timeMs - Math.floor(timeMs / 1000).toFixed(0) * 1000) / 10
        ) + "s";
    }
    return displayT;
  };

  render() {
    return (
      <FlatList
        style={{ marginBottom: 33 }}
        data={this.props.data}
        extraData={this.props.type === "global" ? {} : this.state}
        renderItem={this._renderItem}
        ListHeaderComponent={this._renderHeader}
        numColumns={1}
        keyExtractor={this._keyExtractor}
        ListEmptyComponent={this._renderEmpty}
      />
    );
  }
}

export default withTranslation(Ranking);
