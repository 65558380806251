import React, { Component } from "react";
import { Text, View } from "react-native";
import RF from "react-native-responsive-fontsize";
import { MAIN_URL, TEXT_COLOR } from "../../constants/const";

import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import LottieView from "react-native-web-lottie";
import { Button } from "@ui-kitten/components";

class VerifyEmailScreen extends Component {


  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: this.props.maxWidth
        }}
      >
        <View
          style={{
            padding: 20,
            width: this.props.maxWidth,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state && this.state.screen === "sent" && (
          <LottieView
            style={{ height: 80 }}
            source={require("../../assets/anim/email.json")}
            autoPlay
            loop
          />
          )}

          <Text
            style={{
              color: TEXT_COLOR,
              fontSize: RF(2),
              fontWeight: "normal",
              margin: 10,
              textAlign: "center",
            }}
          >
            {this.state &&  this.state.screen === "sent" ? `Un nouvel e-mail de confirmation vous a été envoyé. 
            
            Cliquez sur le lien pour confirmer votre inscription`:
            `Un e-mail vous a été envoyé 
(boîte de réception ou spams), 
cliquez sur le lien pour confirmer 
votre inscription. 
            
Si vous ne l'avez pas reçu, 
cliquez sur le bouton ci-dessous`}
          </Text>
        </View>

        <View
          style={{
            top: 50,
            padding: 20,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
          style={{borderRadius: 25, textTransform: 'uppercase'}}
            onPress={() => {
              const that = this;
              this.props.authUser
                .sendEmailVerification({
                  url: `https://${MAIN_URL}/AuthAction`,
                  handleCodeInApp: true,
                })
                .then(function () {
                  // Email sent.
                  // console.log("sent email to : ", email);
                  that.setState({ screen: "sent" });
                })
                .catch(function (err) {
                  // An error happened.
                  that.setState({ error: { message: err.code } });
                });
            }}
          >
            RENVOYER L'E-MAIL DE VÉRIFICATION
          </Button>
        </View>
      </View>
    );
  }
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(withMainLayout(VerifyEmailScreen));
