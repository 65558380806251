import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import { Async } from "../../components/Async";

export class AsyncScreen extends Component {
  render() {
    return (
      <Async {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(AsyncScreen));
