import React, { Component } from "react";
import { Text, View, Image, ImageBackground, Dimensions } from "react-native";
// import TeleHeader from "./Elements/header";
import FadeSlideView from "../Anim/FadeSlideView";
import ClassementPhone from "./Elements/classementonyourphone";
import Ranking from "../Ranking";
import Confetti from "react-confetti";
import RF from "react-native-responsive-fontsize";
import {
  EMAILS_RESULTS,
  BG_TELE,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  PROFILE_LOGE_NUMBER,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  PROFILE_JOB,
  PROFILE_DOB,
  PROFILE_ADDRESS,
  ONLY_ANONYMOUS,
  THEME_BACKGROUND_TELE_WINNER,
  THEME,
  QUIZ_EASY_POINT,
  QUIZ_MEDIUM_POINT,
  QUIZ_HARD_POINT,
  APP_RANKING_3_WINNER_TV,
  isEn,
} from "../../constants/const";
import {
  getHeightFromHDMargin,
  getWidthFromHDMargin,
} from "../../libs/list_picker/ratio";

export class TeleRanking extends Component {
  _isMounted = false;
  _isSaving = false;
  _timeoutId = null;
  _timeoutId2 = null;
  _timeoutId3 = null;
  _display3winners = APP_RANKING_3_WINNER_TV; // (THEME === "vivapp" || THEME === "takeover" || THEME === "challengefff") ? true : false;
  state = { data: null, screen: "" };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.currentQuiz.ranking_competition === "only_final_ranking") {
      this._callSendEmailRanking();
      if (this._isMounted) {
        window.localStorage.setItem('ranking_competition', this.props.currentQuiz.competition_name || '');
      }
    } else {
      this._timeoutId = setTimeout(
        function () {
          if (this._isMounted) {
            this.setState({ screen: "list" }, () => {
              this._callSendEmailRanking();
              if (this.props.currentQuiz.ranking_competition === 'display') {
                this._timeoutId2 = setTimeout(
                  function () {
                    if (this._isMounted) {
                      window.localStorage.setItem('ranking_competition', this.props.currentQuiz.competition_name || '');
                    }
                  }.bind(this),
                  5000
                );
              }
            });
          }
        }.bind(this),
        8000
      );
    }
    const currentResults = window.localStorage.getItem("currentResults");
    var playersRanking = [];
    if (currentResults != null) {
      const arrResults = JSON.parse(currentResults);
      var winnerData = null;
      var winner2Data = null;
      var winner3Data = null;
      var answeredList = [];
      // console.log(arrResults);
      arrResults.sort((a, b) => {
        return (
          b.point - a.point ||
          a.timeRightMs - b.timeRightMs ||
          a.timeMs - b.timeMs
        );
      });
      playersRanking = arrResults;

      if (playersRanking.length > 0) {
        //Only get last 10
        playersRanking = playersRanking.splice(0, 10);
        winnerData = playersRanking[0];
        if (playersRanking.length > 1 && this._display3winners)
          winner2Data = playersRanking[1];
        if (playersRanking.length > 2 && this._display3winners)
          winner3Data = playersRanking[2];
        const listBoolean = [];
        var questions = this.props.currentQuiz.questions;
        questions.sort((a, b) => {
          return a.order - b.order;
        });
        for (let i = 0; i < questions.length; i++) {
          const q = questions[i];
          const isCorrected = winnerData.answered.filter((a) => {
            return a.qid === `${q.id}` && a.correct === "1";
          });
          listBoolean.push(isCorrected.length > 0);
        }
        winnerData.answeredList = listBoolean;
        answeredList = this.renderAnswerList(winnerData.answeredList);
      }
      if (this.props.currentQuiz.ranking_competition === "not_display") {
        window.localStorage.removeItem("ranking_competition");
      }
      this.setState({
        winner: winnerData,
        winner2: winner2Data,
        winner3: winner3Data,
        data: playersRanking,
        totalQuestions: this.props.currentQuiz.questions.length,
        answeredList: answeredList,
        screen: this.props.currentQuiz.ranking_competition === "only_final_ranking" ? "" : "winner"
      });
    }


  }

  _callSendEmailRanking = async (id) => {
    if (this.props.firebase) {
      const sendMailQuizRanking = this.props.firebase.functions.httpsCallable(
        "sendMailQuizRankingV2"
      );
      //send Email of results

      const dataMail = {
        currentQuizResultId: this.props.navigation?.state?.params?.result ? `${this.props.navigation.state.params.result}` : `${this.props.currentQuiz.id}_${this.props.currentQuiz.start.seconds}`,
        currentQuizId: this.props.currentQuiz.id,
        currentQuizName: this.props.currentQuiz.name,
        rewards: this.props.currentQuiz.rewards,
        totalQuestions: this.props.currentQuiz.questions.length,
        sent_result: this.props.currentQuiz.sent_result || false,
        to: EMAILS_RESULTS,
        info: {
          theme: THEME,
          rapid: ONLY_ANONYMOUS,
          loge: PROFILE_LOGE_NUMBER,
          phone: PROFILE_PHONE,
          company: PROFILE_COMPANY,
          job: PROFILE_JOB,
          dob: PROFILE_DOB,
          address: PROFILE_ADDRESS,
        },
        configPoints: {
          easy: QUIZ_EASY_POINT,
          medium: QUIZ_MEDIUM_POINT,
          hard: QUIZ_HARD_POINT,
        },
      };
      // console.log(dataMail);
      sendMailQuizRanking(dataMail)
        .then((re) => {
          this._timeoutId3 = setTimeout(function () {
            window.localStorage.removeItem("sendingEmail");
          }, 5000);
        })
        .catch((er) => {
          console.log(er);
          this._timeoutId3 = setTimeout(function () {
            window.localStorage.removeItem("sendingEmail");
          }, 5000);
        });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    if (this._timeoutId2) {
      clearTimeout(this._timeoutId2);
    }
    if (this._timeoutId3) {
      clearTimeout(this._timeoutId3);
    }
  }

  renderAnsweredItem = (anw, i) => {
    return (
      <FadeSlideView direction="left" key={i}>
        <Image
          style={{
            width: 30,
            height: 30,
            margin: 10,
          }}
          resizeMode={"contain"}
          source={
            anw
              ? require("../../assets/images/right_answered.png")
              : require("../../assets/images/wrong_answered.png")
          }
        />
      </FadeSlideView>
    );
  };

  renderAnswerList = (arrList) => {
    const out = [];
    for (let i = 0; i < arrList.length; i++) {
      const anw = arrList[i];
      out.push(this.renderAnsweredItem(anw, i));
    }
    return out;
  };

  render() {
    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    return this._isMounted ? <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BG_TELE,
        marginBottom: mbottom,
        marginLeft: mleft,
        marginRight: mright,
        marginTop: mtop,
        backgroundImage: 'url("' + THEME_BACKGROUND_TELE + '")',
        backgroundSize: 'contain'
      }}
    >
      {/* <TeleHeader
        style={{
          flex: 1,
          flexDirection: "row",
          width: screenWidth,
          maxHeight: "20%",
          top: 0,
          position: "absolute",
        }}
      /> */}
      {this.props.currentQuiz.show_classement_tv !== 'not_display' && (this.state.screen === "winner" || this.state.screen === "list") && this.props.navigation.state.params.confetti !== 'no' && <Confetti
        width={screenWidth}
        height={screenHeight}
        colors={
          THEME === "racing92"
            ? ["#BF9A56", "#C2E0F3", "#FFFFFF"]
            : THEME === "challengeca"
              ? ["#FFD700"]
              : THEME === "cupraquizz"
                ? ["#FFFFFF"] : [
                  "#f44336",
                  "#e91e63",
                  "#9c27b0",
                  "#673ab7",
                  "#3f51b5",
                  "#2196f3",
                  "#03a9f4",
                  "#00bcd4",
                  "#009688",
                  "#4CAF50",
                  "#8BC34A",
                  "#CDDC39",
                  "#FFEB3B",
                  "#FFC107",
                  "#FF9800",
                  "#FF5722",
                  "#795548",
                ]
        }
      />}

      {this.props.currentQuiz.show_classement_tv !== 'not_display' && this.state.screen === "winner" && this.props.currentQuiz !== null && (
        <View
          style={{
            backgroundColor: "transparent",
            borderRadius: 50,
            width: getWidthFromHDMargin(1344, mtop, mright, mbottom, mleft),
            height: getHeightFromHDMargin(756, mtop, mright, mbottom, mleft),
          }}
          key={this.state.numUpdate}
        >
          <ImageBackground
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "transparent",
            }}
            source={
              this.props.navigation.state.params
                ? this.props.navigation.state.params.bgtele
                  ? this.props.navigation.state.params.bgtele
                  : THEME_BACKGROUND_TELE_WINNER
                : THEME_BACKGROUND_TELE_WINNER
            }
            resizeMode="contain"
          >

            <Text
              style={{
                color: TEXT_TV_COLOR,
                textTransform: "uppercase",
                fontSize: RF(4),
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              {this.props.currentQuiz.subtitle}
            </Text>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(6),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center",
                }}
              >
                Vainqueur{this._display3winners ? "s" : ""}
              </Text>
            </View>

            <FadeSlideView direction="down" delay={1000}>
              {!this._display3winners && (
                <Text
                  style={{
                    color: TEXT_TV_COLOR,
                    fontSize: RF(15),
                    fontWeight: "bold",
                  }}
                >
                  {this.state.winner != null
                    ? this.state.winner.displayName
                    : ""}
                </Text>
              )}
              {this._display3winners && (
                <View style={{ flexDirection: "row" }}>
                  <Image
                    style={{
                      width: RF(8),
                      height: RF(8),
                      marginRight: RF(2),
                    }}
                    source={require("../../assets/images/first.png")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: RF(13),
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.winner != null
                      ? this.state.winner.displayName
                      : ""}
                  </Text>
                </View>
              )}
              {this.state.winner2 && this._display3winners && (
                <View style={{ flexDirection: "row" }}>
                  <Image
                    style={{
                      width: RF(8),
                      height: RF(8),
                      marginRight: RF(2),
                    }}
                    source={require("../../assets/images/second.png")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: RF(9),
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.winner2.displayName}
                  </Text>
                </View>
              )}
              {this.state.winner3 && this._display3winners && (
                <View style={{ flexDirection: "row" }}>
                  <Image
                    style={{
                      width: RF(8),
                      height: RF(8),
                      marginRight: RF(2),
                    }}
                    source={require("../../assets/images/third.png")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_TV_COLOR,
                      fontSize: RF(9),
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.winner3.displayName}
                  </Text>
                </View>
              )}
            </FadeSlideView>
            {!this._display3winners && (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: TEXT_TV_COLOR,
                    fontSize: RF(3),
                  }}
                >
                  Score:{" "}
                  {this.state.winner != null ? this.state.winner.point : 0}
                </Text>
                <Text
                  style={{
                    color: TEXT_TV_COLOR,
                    fontSize: RF(3),
                    marginLeft: 100,
                  }}
                >
                  Temps:{" "}
                  {this.state.winner != null
                    ? Math.floor(
                      this.state.winner.timeRightMs / 1000
                    ).toFixed(0) +
                    "'" +
                    (Math.floor(
                      (this.state.winner.timeRightMs -
                        Math.floor(
                          this.state.winner.timeRightMs / 1000
                        ).toFixed(0) *
                        1000) /
                      10
                    ) < 10
                      ? "0" +
                      Math.floor(
                        (this.state.winner.timeRightMs -
                          Math.floor(
                            this.state.winner.timeRightMs / 1000
                          ).toFixed(0) *
                          1000) /
                        10
                      )
                      : Math.floor(
                        (this.state.winner.timeRightMs -
                          Math.floor(
                            this.state.winner.timeRightMs / 1000
                          ).toFixed(0) *
                          1000) /
                        10
                      )) +
                    "s"
                    : 0}
                </Text>
              </View>
            )}
            {!this._display3winners && this.state.answeredList && (
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "dimgrey",
                  borderBottomWidth: 1,
                  borderTopWidth: 1,
                  width: "90%",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                {this.state.answeredList}
              </View>
            )}
          </ImageBackground>
        </View>
      )}
      {this.props.currentQuiz.show_classement_tv !== 'not_display' && this.state.screen === "list" && this.props.currentQuiz !== null && (
        <View
          style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              height: screenHeight,
              maxHeight: screenHeight,
              top: THEME === "jeutop14" ? 150 : 0,
            }}
          >
            <FadeSlideView
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(8),
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginLeft: Math.round(screenWidth / 30),
                  marginRight: Math.round(screenWidth / 30),
                  textAlign: "center",
                  width: Math.round((2 * screenWidth) / 3),
                }}
              >
                {this.props.currentQuiz.name}
              </Text> */}

              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  textTransform: "uppercase",
                  fontSize: RF(5),
                  fontWeight: "bold",
                  marginBottom: 30,
                }}
              >
                {this.props.currentQuiz.subtitle}
              </Text>
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(5),
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginLeft: Math.round(screenWidth / 30),
                  marginRight: Math.round(screenWidth / 30),
                  textAlign: "center",
                  width: Math.round((2 * screenWidth) / 3),
                }}
              >
                CLASSEMENT GÉNÉRAL
              </Text>
            </FadeSlideView>
            <FadeSlideView
              delay={500}
              direction="up"
              style={{
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Ranking {...this.state} modeTele={true} final={true} />
              <ClassementPhone />
            </FadeSlideView>

          </View>
        </View>
      )}
      {this.props.currentQuiz.show_classement_tv === 'not_display' && this.props.currentQuiz !== null && (
        <View
          style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              height: screenHeight,
              maxHeight: screenHeight,
              top: THEME === "jeutop14" ? 150 : 0,
            }}
          >
            <FadeSlideView
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(8),
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginLeft: Math.round(screenWidth / 30),
                  marginRight: Math.round(screenWidth / 30),
                  textAlign: "center",
                  width: Math.round((2 * screenWidth) / 3),
                }}
              >
                {this.props.currentQuiz.name}
              </Text>
              <Text
                style={{
                  color: "darkgrey",
                  textTransform: "uppercase",
                  fontSize: RF(5),
                  fontWeight: "100",
                  marginBottom: 30,
                }}
              >
                {this.props.currentQuiz.subtitle}
              </Text>
              <Text
                style={{
                  color: TEXT_TV_COLOR,
                  fontSize: RF(8)
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${isEn() ? this.props.currentQuiz?.msg_waiting_en : this.props.currentQuiz?.msg_waiting}`,
                  }}
                ></div>
              </Text>
            </FadeSlideView>

          </View>
        </View>
      )}
      <Image
        style={{
          height: getHeightFromHDMargin(
            200,
            mtop,
            mright,
            mbottom,
            mleft
          ), width: getHeightFromHDMargin(
            400,
            mtop,
            mright,
            mbottom,
            mleft
          )
        }}
        source={'https://storage.googleapis.com/take-over-loges.appspot.com/Logo_E_Kart_in_blanc_418c1328c2/Logo_E_Kart_in_blanc_418c1328c2.png'}
        resizeMode="contain"
      />
    </View>
      : <></>
      ;
  }
}

export default TeleRanking;
