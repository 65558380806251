import React, { Component } from "react";
import {
  Image,
  Picker,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RF from "react-native-responsive-fontsize";
import {
  TEXT_COLOR,
  BUTTON_COLOR,
  FORECAST_FOOT_WINNER,
  FORECAST_FOOT_SCORE,
  FORECAST_FOOT_TOTAL_SCORE,
  FORECAST_FOOT_FIRST_SCORER,
  FORECAST_FOOT_FIRST_SCORE_MINUTE,
  THEME,
  SHOW_GAMES,
  APP_NAVTAB,
  MENU_COLOR,
  BANNER_BOTTOM_ENABLE,
} from "../../constants/const";
import ListPicker from "../../libs/list_picker/ListPicker";
import {
  getHeightPercent,
  getWidthPercentMaxLimited,
} from "../../libs/list_picker/ratio";
import moment from "moment";
import "moment/locale/fr";
import TOButton from "../../libs/to_button";
import { Button, Icon } from "@ui-kitten/components";
import { withTranslation, getLanguage, t } from "react-multi-lang";

const BackIcon = (props) => <Icon {...props} name="close-outline" />;

export class Forecast extends Component {
  state = {
    screen: "select",
    teamWon: "",
    scoreSelected: false,
    scoreHome: 0,
    scoreVisitor: 0,
    totalScore: 0,
    totalScoreSelected: false,
    minuteSelected: false,
    minute: 1,
    playerSelected: false,
    dataSelected: 0,
    step: 0,
    validated: false,
    showAlert: true,
  };
  _isMounted = false;
  dataList = null;
  totalStep = 1;
  currentMatch = null;
  currentForecast = null;

  steps = [];

  constructor(props) {
    super(props);
    if (FORECAST_FOOT_WINNER) {
      this.steps.push("teamwon");
    }
    if (FORECAST_FOOT_SCORE) {
      this.steps.push("score");
    }
    if (FORECAST_FOOT_TOTAL_SCORE) {
      this.steps.push("totalScore");
    }
    if (FORECAST_FOOT_FIRST_SCORER) {
      this.steps.push("firstScorer");
    }
    if (FORECAST_FOOT_FIRST_SCORE_MINUTE) {
      this.steps.push("minute");
    }

    this.totalStep = this.steps.length;
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkTheParams();
    this._checkTiming();
    if (this.currentMatch) {
      this.dataList = this.currentMatch.team_home_players
        ? this.currentMatch.team_home_players.map((el) => {
          return {
            name: el.name,
            icon: el.image,
            letter: el.name,
            player_id: el.player_id,
          };
        })
        : [];
    }

  }

  checkTheParams = () => {
    console.log("called checkTheParams = ", this.props.navigation.state.params.mid);
    if (this.props.navigation) {
      if (
        this.props.navigation.state.params &&
        this.props.navigation.state.params.mid
      ) {
        if (this.props.currentMatchs) {
          const ca = this.props.currentMatchs.filter((e) => {
            return e.id === this.props.navigation.state.params.mid;
          });
          if (ca && ca.length > 0) {
            this.currentMatch = ca[0];
            return true;
          }
        }
      }
    }
    this.currentMatch = this.props.currentMatch;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  syncDataLive = () => {
    if (this.props.firebase && this.currentMatch) {
      this.props.firebase
        .match_lives(this.currentMatch.match_id)
        .onSnapshot((docSnapshot) => {
          // Actually the data should be only 1
          var lData = docSnapshot.data();
          // bind data
          if (lData) {
            if (this._isMounted) {
              this.checkColor(lData);
            }
          }
        });
    }
  };

  checkColor = (lData) => {
    // console.log('LIVE = ', lData);

    const homeScore = lData.home_score || 0;
    const visitorScore = lData.visitor_score || 0;
    const minute = lData.first_score_minute || 0;
    const scorer = lData.first_scorer.player_name || "";

    const valScore = homeScore - visitorScore;
    var teamWonValue = valScore > 0 ? "home" : valScore < 0 ? "visitor" : "nil";

    const selectedPlayer = this.state.pickData ? this.state.pickData.name : "";

    this.setState({
      liveData: lData,
      correctTeamWon: teamWonValue === this.state.teamWon,
      correctMinute: `${this.state.minute}` === `${minute}`,
      correctScorer:
        `${selectedPlayer.toLowerCase()}` === `${scorer.toLowerCase()}`,
      correctTotalScore:
        parseInt(this.state.totalScore, 10) ===
        parseInt(homeScore, 10) + parseInt(visitorScore, 10),
      correctScore:
        parseInt(this.state.scoreHome, 10) === parseInt(homeScore, 10) &&
        parseInt(this.state.scoreVisitor, 10) === parseInt(visitorScore, 10),
    });
  };

  _checkTiming = () => {
    if (this.currentMatch) {
      const match_forecasts = window.localStorage.getItem("match_forecasts");
      if (match_forecasts !== null) {
        var _matchForecastsData = JSON.parse(match_forecasts);
        // find by id
        var arrThisMatch = _matchForecastsData.filter(e => e.pronosticId === this.currentMatch.id);
        if (arrThisMatch.length > 0) {
          this.currentForecast = arrThisMatch[0];
        }

        if (this.currentForecast && this.currentForecast.matchId === this.currentMatch.match_id) {
          // Same match and already forecasted
          // if (this.props.mode !== "preforecast") {
          //   this.props.navigation.navigate("Home");
          // }
          this.setState({
            teamWon: this.currentForecast.teamWon,
            scoreSelected: true,
            scoreHome: this.currentForecast.scoreHome,
            scoreVisitor: this.currentForecast.scoreVisitor,
            totalScore: this.currentForecast.totalScore,
            totalScoreSelected: true,
            minuteSelected: true,
            minute: this.currentForecast.firstScoreMinute,
            playerSelected: true,
            pickData: this.currentForecast.firstScorer,
            validated: true,
            showAlert: false,
            step: this.totalStep,
          });
        } else {
          // not the same match, delete the old one
          // window.localStorage.removeItem("match_forecast");
        }
      } else {
        const match_forecast_tempo = window.localStorage.getItem(
          "match_forecast_tempo"
        );
        if (match_forecast_tempo !== null) {
          var tempJSON = JSON.parse(match_forecast_tempo);
          if (tempJSON.matchId === this.currentMatch.match_id) {
            this.setState({
              ...tempJSON,
              showAlert: true,
              screen: "select",
            });
          } else {
            window.localStorage.removeItem("match_forecast_tempo");
          }
        }
      }

      if (this._isClosed()) {
        // Do not let play
        this.setState({
          showAlert: false,
        });
      }

      if (this._isLived()) {
        //   console.log("LIVE should started")
        this.syncDataLive();
      }
    }
  };

  _isClosed = () => {
    return (
      Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      ) >= this.currentMatch.start.seconds ||
      Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      ) <
      this.currentMatch.start.seconds -
      this.currentMatch.open * 60 * 60
    );
  };

  _isLived = () => {
    return (
      Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      ) >= this.currentMatch.start.seconds
    );
  };

  onTotalScoreChangesValue = (itemValue, itemIndex) => {
    // console.log("onTotalScoreChangesValue", itemValue);
    this.setState({
      totalScore: itemValue,
    });
  };

  onScoreHomeChangesValue = (itemValue, itemIndex) => {
    // console.log("onChange Home", itemValue);
    this.setState({
      scoreHome: itemValue,
    });
  };

  onScoreVisitorChangesValue = (itemValue, itemIndex) => {
    //   console.log("onChange Visitor", itemValue);
    this.setState({
      scoreVisitor: itemValue,
    });
  };

  onMinuteChangesValue = (itemValue, itemIndex) => {
    //   console.log("onMinuteChangesValue", itemValue);
    this.setState({
      minute: itemValue,
    });
  };

  onValidateClicked = (ev) => {
    // console.log(this.state);
    var shouldSubmit = false;
    var currentStep = 0;
    if (this.state.screen === "teamwon") {
      currentStep = this.steps.indexOf("teamwon");
      if (currentStep + 1 >= this.steps.length) {
        shouldSubmit = true;
      } else {
        this.setState(
          {
            screen: this.steps[currentStep + 1],
            scoreSelected: true,
            step: currentStep + 1,
          },
          () => {
            window.localStorage.setItem(
              "match_forecast_tempo",
              JSON.stringify({
                matchId: this.currentMatch.match_id,
                ...this.state,
              })
            );
          }
        );
      }
    } else if (this.state.screen === "score") {
      currentStep = this.steps.indexOf("score");
      if (currentStep + 1 >= this.steps.length) {
        shouldSubmit = true;
      } else {
        if (this.currentMatch) {
          this.dataList = this.currentMatch.team_home_players.map(
            (el) => {
              return {
                name: el.name,
                icon: el.image,
                letter: el.name,
                player_id: el.player_id,
              };
            }
          );
        }
        this.setState(
          {
            screen: this.steps[currentStep + 1],
            scoreSelected: true,
            step: currentStep + 1,
          },
          () => {
            window.localStorage.setItem(
              "match_forecast_tempo",
              JSON.stringify({
                matchId: this.currentMatch.match_id,
                ...this.state,
              })
            );
          }
        );
      }
    } else if (this.state.screen === "totalScore") {
      currentStep = this.steps.indexOf("totalScore");
      if (currentStep + 1 >= this.steps.length) {
        shouldSubmit = true;
      } else {
        this.setState(
          {
            screen: this.steps[currentStep + 1],
            totalScoreSelected: true,
            step: currentStep + 1,
          },
          () => {
            window.localStorage.setItem(
              "match_forecast_tempo",
              JSON.stringify({
                matchId: this.currentMatch.match_id,
                ...this.state,
              })
            );
          }
        );
      }
    } else if (this.state.screen === "firstScorer") {
      currentStep = this.steps.indexOf("firstScorer");
      if (currentStep + 1 >= this.steps.length) {
        shouldSubmit = true;
      } else {
        this.setState(
          {
            screen: this.steps[currentStep + 1],
            playerSelected: true,
            step: currentStep + 1,
          },
          () => {
            window.localStorage.setItem(
              "match_forecast_tempo",
              JSON.stringify({
                matchId: this.currentMatch.match_id,
                ...this.state,
              })
            );
          }
        );
      }
    } else if (this.state.screen === "minute") {
      // NOTE: always submit
      shouldSubmit = true;
    }
    //   console.log("shouldSubmit = ", shouldSubmit);

    if (shouldSubmit) {
      if (this.props.mode === "preforecast") {
        window.localStorage.removeItem("match_forecast_tempo");
        // save and go to home
        const dataToSave = {
          teamWon: this.state.teamWon,
          scoreHome: parseInt(this.state.scoreHome, 10),
          scoreVisitor: parseInt(this.state.scoreVisitor, 10),
          totalScore: this.state.totalScore,
          firstScorer: this.state.pickData ? this.state.pickData : false,
          firstScoreMinute: this.state.minute,
          matchId: this.currentMatch.match_id,
          pronosticId: this.currentMatch.id,
        };
        window.localStorage.setItem(
          "match_forecast_to_save",
          JSON.stringify(dataToSave)
        );
        this.props.navigation.navigate("SignUp");
      } else {
        window.localStorage.removeItem("match_forecast_tempo");
        // save and go to home
        const dataToSave = {
          teamWon: this.state.teamWon,
          scoreHome: this.state.scoreHome,
          scoreVisitor: this.state.scoreVisitor,
          totalScore: this.state.totalScore,
          firstScorer: this.state.pickData ? this.state.pickData : false,
          firstScoreMinute: this.state.minute,
          matchId: this.currentMatch.match_id,
          pronosticId: this.currentMatch.id,
        };
        // Save to firestore
        this.props.firebase
          .pronostics_responses(
            `${this.props.authUser.uid}_${this.currentMatch.id}`
          )
          .set({
            userId: this.props.authUser.uid,
            displayName: this.props.authUser.displayName,
            email: this.props.authUser.email,
            ...dataToSave,
          });
        const match_forecasts = window.localStorage.getItem("match_forecasts");
        if (match_forecasts !== null) {
          var _matchForecastsData = JSON.parse(match_forecasts);
          if (_matchForecastsData.length > 0) {
            var _addedData = false;
            for (let _i = 0; _i < _matchForecastsData.length; _i++) {
              const _e = _matchForecastsData[_i];
              if (_e.pronosticId === this.currentMatch.match_id) {
                _matchForecastsData[_i] = dataToSave
                _addedData = true;
              }
            }
            if (!_addedData) {
              _matchForecastsData.push(dataToSave)
            }
            window.localStorage.setItem(
              "match_forecasts",
              JSON.stringify(_matchForecastsData)
            );
          }
        } else {
          window.localStorage.setItem(
            "match_forecasts",
            JSON.stringify([dataToSave])
          );
        }

        // this.props.navigation.navigate("Home");
        this.setState({
          screen: "select",
          minuteSelected: true,
          totalScoreSelected: true,
          playerSelected: true,
          scoreSelected: true,
          step: this.totalStep,
          validated: true,
        });
      }
    }
  };

  _renderMinuteItems = (max) => {
    const arrItems = [];
    for (let i = 1; i < max; i++) {
      arrItems.push(this._renderMinuteItem(i));
    }
    return arrItems;
  };

  _renderMinuteItem = (index) => {
    return (
      <Picker.Item
        label={index < 10 ? `0${index}` : `${index}`}
        value={index}
      />
    );
  };

  render() {
    // console.log("this.totalStep = ", this.totalStep);
    // console.log("this.steps = ", this.steps);
    //   console.log("this.state = ", this.state);

    const maxW = this.props.maxWidth;

    const styles = StyleSheet.create({
      pickerStyleNoMargin: {
        fontSize: 40,
        borderRadius: 10,
        width: getWidthPercentMaxLimited(95, maxW),
        height: getWidthPercentMaxLimited(30, maxW),
        marginTop: getHeightPercent(2),
        marginBottom: getHeightPercent(2),
        backgroundColor: "white",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      },
      pickerStyleNoMarginShort: {
        fontSize: 50,
        borderRadius: 10,
        width: getWidthPercentMaxLimited(40, maxW),
        height: getWidthPercentMaxLimited(30, maxW),
        marginTop: getHeightPercent(2),
        marginBottom: getHeightPercent(2),
        backgroundColor: "white",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      },
      itemStyle: {
        textAlign: "center",
      },
      pickerStyleLeft: {
        fontSize: 50,
        borderRadius: 10,
        minWidth: getWidthPercentMaxLimited(30, maxW),
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: "white",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",

        marginRight: getWidthPercentMaxLimited(7, maxW),
      },
      pickerStyleRight: {
        fontSize: 50,
        borderRadius: 10,
        minWidth: getWidthPercentMaxLimited(30, maxW),
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: "white",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",

        marginLeft: getWidthPercentMaxLimited(7, maxW),
      },
    });

    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: maxW,
        }}
      >
        {this.state.showAlert === false &&
          this.currentMatch &&
          this.state.screen === "select" && (
            <View style={{ width: "100%", flexDirection: "column" }}>
              <View
                style={{
                  height: 120,
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: 300,
                    height: 100,
                  }}
                >
                  <View
                    style={{
                      height: 40,
                      margin: 3,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      resizeMode={"contain"}
                      source={this.currentMatch.team_home_logo}
                    />
                    {this._isLived() === false && (
                      <View style={{ flexDirection: "column" }}>
                        <Text style={{ fontSize: RF(1.5), color: TEXT_COLOR }}>
                          {moment(this.currentMatch.start.seconds * 1000)
                            .locale(getLanguage())
                            .format("ddd")}{" "}
                          {moment(
                            this.currentMatch.start.seconds * 1000
                          ).format("DD")}{" "}
                          {moment(this.currentMatch.start.seconds * 1000)
                            .locale(getLanguage())
                            .format("MMM")}
                        </Text>

                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            color: TEXT_COLOR,
                          }}
                        >
                          {moment(
                            this.currentMatch.start.seconds * 1000
                          ).format("HH")}
                          h
                          {moment(
                            this.currentMatch.start.seconds * 1000
                          ).format("mm")}
                        </Text>
                      </View>
                    )}
                    {this._isLived() === true && this.state.liveData && (
                      <View style={{ flexDirection: "column" }}>
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 16,
                            color: TEXT_COLOR,
                          }}
                        >
                          {this.state.liveData.home_score
                            ? this.state.liveData.home_score
                            : 0}{" "}
                          -{" "}
                          {this.state.liveData.visitor_score
                            ? this.state.liveData.visitor_score
                            : 0}
                        </Text>
                      </View>
                    )}
                    <Image
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      resizeMode={"contain"}
                      source={this.currentMatch.team_visitor_logo}
                    />
                  </View>

                  <View
                    style={{
                      marginTop: 20,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        margin: 3,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/ball.svg")}
                      />
                      <Text
                        style={{
                          marginLeft: 5,
                          fontSize: 16,
                          color: TEXT_COLOR,
                        }}
                      >
                        {`${this._isLived() === true && this.state.liveData
                          ? this.state.liveData.first_scorer
                            ? this.state.liveData.first_scorer.player_name
                              ? this.state.liveData.first_scorer.player_name
                              : "..."
                            : "..."
                          : "..."
                          }`}
                      </Text>
                    </View>
                    <View
                      style={{
                        margin: 3,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/minuteur.svg")}
                      />
                      <Text
                        style={{
                          marginLeft: 5,
                          fontSize: 16,
                          color: TEXT_COLOR,
                        }}
                      >
                        {`${this._isLived() === true && this.state.liveData
                          ? this.state.liveData.first_score_minute
                            ? this.state.liveData.first_score_minute
                            : "..."
                          : "..."
                          }`}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: maxW,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* winner */}
                {FORECAST_FOOT_WINNER && (
                  <View
                    style={{
                      width: 155,
                      height: 155,
                      marginTop: 10,
                      borderRadius: 5,
                      backgroundColor: "#FFF",
                      borderColor: this._isLived()
                        ? this.state.correctTeamWon
                          ? "lime"
                          : "red"
                        : "transparent",
                      borderWidth: this._isLived() ? 4 : 0,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <View style={{ flexDirection: "row", top: 5 }}>
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          left: 5,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/first.png")}
                      />

                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "#666",
                        }}
                      >
                        {t("winner")}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        height: this.state.teamWon === "" ? 40 : 90,
                        width: this.state.teamWon === "" ? "70%" : "90%",
                        top: this.state.teamWon === "" ? 30 : 20,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      disabled={this.state.validated || this._isClosed()}
                      onPress={() => {
                        //   console.log("VAINQUEUR clicked ");
                        this.setState({ screen: "teamwon" });
                      }}
                    >
                      <View>
                        {this.state && this.state.teamWon === "" && (
                          <View
                            style={
                              this._isClosed()
                                ? {
                                  height: 40,
                                  borderRadius: 5,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 0.5,
                                }
                                : {
                                  height: 40,
                                  borderRadius: 5,
                                  shadowColor: "#000",
                                  shadowOpacity: 0.5,
                                  shadowRadius: 10,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                }
                            }
                          >
                            <Text
                              style={{ textAlign: "center", color: TEXT_COLOR }}
                            >
                              {t("go_forecast")}
                            </Text>
                          </View>
                        )}
                        {this.state && this.state.teamWon !== "" && (
                          <View
                            style={{
                              margin: getHeightPercent(2),
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              style={{
                                width: 80,
                                height: 80,
                              }}
                              resizeMode={"contain"}
                              source={
                                this.state.teamWon === "home"
                                  ? this.currentMatch.team_home_logo
                                  : this.state.teamWon === "visitor"
                                    ? this.currentMatch.team_visitor_logo
                                    : require("../../assets/images/nil_blue.svg")
                              }
                            />
                            <Text
                              style={{
                                marginTop: 10,
                                color: "#666",
                                textTransform: "uppercase",
                                textAlign: "center",
                                fontSize: 9,
                              }}
                            >
                              {this.state.teamWon === "home"
                                ? this.currentMatch.team_home
                                : this.state.teamWon === "visitor"
                                  ? this.currentMatch.team_visitor
                                  : t("draw")}
                            </Text>
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>
                  </View>
                )}

                {/* score */}
                {FORECAST_FOOT_SCORE && (
                  <View
                    style={{
                      width: 155,
                      height: 155,
                      marginTop: 10,
                      borderRadius: 5,
                      backgroundColor: "#FFF",
                      borderColor: this._isLived()
                        ? this.state.correctScore
                          ? "lime"
                          : "red"
                        : "transparent",
                      borderWidth: this._isLived() ? 4 : 0,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <View style={{ flexDirection: "row", top: 5 }}>
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          left: 5,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/sifflet.svg")}
                      />

                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "#666",
                        }}
                      >
                        {t("final_score")}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        height: this.state.scoreSelected ? 90 : 40,
                        width: this.state.scoreSelected ? "90%" : "70%",
                        top: this.state.scoreSelected ? 15 : 30,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      disabled={
                        this.state.validated ||
                        this.state.step < this.steps.indexOf("score") ||
                        this._isClosed()
                      }
                      onPress={() => {
                        //   console.log("SCORE FINAL clicked ");
                        this.setState({ screen: "score" });
                      }}
                    >
                      <View>
                        {!this.state.scoreSelected && (
                          <View
                            style={
                              this.state.step < this.steps.indexOf("score") ||
                                this.state.validated ||
                                this._isClosed()
                                ? {
                                  height: 40,
                                  borderRadius: 5,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 0.5,
                                }
                                : {
                                  height: 40,
                                  borderRadius: 5,
                                  shadowColor: "#000",
                                  shadowOpacity: 0.5,
                                  shadowRadius: 10,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 1,
                                }
                            }
                          >
                            <Text
                              style={{ textAlign: "center", color: TEXT_COLOR }}
                            >
                              {t("go_forecast")}
                            </Text>
                          </View>
                        )}
                        {this.state.scoreSelected && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                height: 70,
                                width: "33%",
                                borderRadius: 10,
                                borderColor: "#999",
                                shadowColor: "#999",
                                shadowOpacity: 0.8,
                                shadowRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 35,
                                  fontWeight: "800",
                                  color: "#666",
                                }}
                              >
                                {this.state.scoreHome}
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                height: 70,
                                width: "20%",
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: RF(3),
                                  fontWeight: "800",
                                  color: "#666",
                                }}
                              >
                                •
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                height: 70,
                                width: "33%",
                                borderRadius: 10,
                                borderColor: "#999",
                                shadowColor: "#999",
                                shadowOpacity: 0.8,
                                shadowRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 35,
                                  fontWeight: "800",
                                  color: "#666",
                                }}
                              >
                                {this.state.scoreVisitor}
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>
                  </View>
                )}

                {/* total score */}
                {FORECAST_FOOT_TOTAL_SCORE && (
                  <View
                    style={{
                      width: 155,
                      height: 155,
                      marginTop: 10,
                      borderRadius: 5,
                      backgroundColor: "#FFF",
                      borderColor: this._isLived()
                        ? this.state.correctTotalScore
                          ? "lime"
                          : "red"
                        : "transparent",
                      borderWidth: this._isLived() ? 4 : 0,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <View style={{ flexDirection: "row", top: 5 }}>
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          left: 5,
                          right: 5,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/Number.svg")}
                      />

                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "#666",
                        }}
                      >
                        {t("total_goals")}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        height: this.state.totalScoreSelected ? 90 : 40,
                        width: this.state.totalScoreSelected ? "90%" : "70%",
                        top: this.state.totalScoreSelected ? 15 : 30,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      disabled={
                        this.state.validated ||
                        this.state.step < this.steps.indexOf("totalScore") ||
                        this._isClosed()
                      }
                      onPress={() => {
                        //   console.log("TOTAL SCORE clicked ");
                        this.setState({ screen: "totalScore" });
                      }}
                    >
                      <View>
                        {!this.state.totalScoreSelected && (
                          <View
                            style={
                              this.state.step <
                                this.steps.indexOf("totalScore") ||
                                this.state.validated ||
                                this._isClosed()
                                ? {
                                  height: 40,
                                  borderRadius: 5,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 0.5,
                                }
                                : {
                                  height: 40,
                                  borderRadius: 5,
                                  shadowColor: "#000",
                                  shadowOpacity: 0.5,
                                  shadowRadius: 10,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 1,
                                }
                            }
                          >
                            <Text
                              style={{ textAlign: "center", color: TEXT_COLOR }}
                            >
                              {t("go_forecast")}
                            </Text>
                          </View>
                        )}
                        {this.state.totalScoreSelected && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                height: 70,
                                borderRadius: 10,
                                borderColor: "#999",
                                shadowColor: "#999",
                                shadowOpacity: 0.8,
                                shadowRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 35,
                                  fontWeight: "800",
                                  color: "#666",
                                  marginLeft: 5,
                                  marginRight: 5,
                                }}
                              >
                                {this.state.totalScore}
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>
                  </View>
                )}

                {/* scorer */}
                {FORECAST_FOOT_FIRST_SCORER && (
                  <View
                    style={{
                      width: 155,
                      height: 155,
                      marginTop: 10,
                      borderRadius: 5,
                      backgroundColor: "#FFF",
                      borderColor: this._isLived()
                        ? this.state.correctScorer
                          ? "lime"
                          : "red"
                        : "transparent",
                      borderWidth: this._isLived() ? 4 : 0,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <View style={{ flexDirection: "row", top: 5 }}>
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          left: 5,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/ball.svg")}
                      />

                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "#666",
                        }}
                      >
                        {t("first_scorer")}
                      </Text>
                    </View>

                    <TouchableOpacity
                      style={{
                        height: this.state.playerSelected ? 90 : 40,
                        width: this.state.playerSelected ? "90%" : "70%",
                        top: this.state.playerSelected ? 10 : 30,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      disabled={
                        this.state.validated ||
                        this.state.step < this.steps.indexOf("firstScorer") ||
                        this._isClosed()
                      }
                      onPress={() => {
                        //   console.log("1ER BUTEUR clicked ");
                        this.setState({ screen: "firstScorer" });
                      }}
                    >
                      <View>
                        {!this.state.playerSelected && (
                          <View
                            style={
                              this.state.step <
                                this.steps.indexOf("firstScorer") ||
                                this.state.validated ||
                                this._isClosed()
                                ? {
                                  height: 40,
                                  borderRadius: 5,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 0.5,
                                }
                                : {
                                  height: 40,
                                  borderRadius: 5,
                                  shadowColor: "#000",
                                  shadowOpacity: 0.5,
                                  shadowRadius: 10,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 1,
                                }
                            }
                          >
                            <Text
                              style={{ textAlign: "center", color: TEXT_COLOR }}
                            >
                              {t("go_forecast")}
                            </Text>
                          </View>
                        )}

                        {this.state.playerSelected && (
                          <View
                            style={{
                              margin: getHeightPercent(2),
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Image
                            style={{
                              width: 80,
                              height: 80,
                              borderRadius: 40
                            }}
                            source={this.state.pickData.icon}
                            resizeMode={"contain"}
                          /> */}
                            <Text
                              style={{
                                marginTop: 2,
                                color: "#666",
                                textTransform: "uppercase",
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {this.state.pickData.name}
                            </Text>
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>
                  </View>
                )}

                {/* minute */}
                {FORECAST_FOOT_FIRST_SCORE_MINUTE && (
                  <View
                    style={{
                      width: 155,
                      height: 155,
                      marginTop: 10,
                      borderRadius: 5,
                      backgroundColor: "#FFF",
                      borderColor: this._isLived()
                        ? this.state.correctMinute
                          ? "lime"
                          : "red"
                        : "transparent",
                      borderWidth: this._isLived() ? 4 : 0,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <View style={{ flexDirection: "row", top: 5 }}>
                      <Image
                        style={{
                          width: 15,
                          height: 15,
                          left: 5,
                        }}
                        resizeMode={"contain"}
                        source={require("../../assets/images/minuteur.svg")}
                      />

                      <Text
                        style={{
                          textAlign: "center",
                          width: "100%",
                          fontSize: 16,
                          fontWeight: "bold",
                          color: "#666",
                        }}
                        Ò
                      >
                        {t("min_of_first_goal")}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        height: this.state.minuteSelected ? 90 : 40,
                        width: this.state.minuteSelected ? "90%" : "70%",
                        top: this.state.minuteSelected ? 15 : 30,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                      disabled={
                        this.state.validated ||
                        this.state.step < this.steps.indexOf("minute") ||
                        this._isClosed()
                      }
                      onPress={() => {
                        //   console.log("MN DU 1ER BUT clicked ");
                        this.setState({ screen: "minute" });
                      }}
                    >
                      <View>
                        {this.state.minuteSelected && (
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                height: 60,
                                width:
                                  this.state.minute !== "ex1" &&
                                    this.state.minute !== "ex2"
                                    ? "0%"
                                    : "30%",
                                marginRight:
                                  this.state.minute !== "ex1" &&
                                    this.state.minute !== "ex2"
                                    ? 0
                                    : 5,
                                borderRadius: 10,
                                borderColor: "#999",
                                shadowColor: "#999",
                                shadowOpacity: 0.8,
                                shadowRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: RF(7),
                                  fontWeight: "800",
                                  color: "#666",
                                }}
                              >
                                {this.state.minute === "ex1" ? "4" : ""}
                                {this.state.minute === "ex2" ? "9" : ""}
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                height: 60,
                                marginRight: 5,
                                width: "30%",
                                borderRadius: 10,
                                borderColor: "#999",
                                shadowColor: "#999",
                                shadowOpacity: 0.8,
                                shadowRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: RF(7),
                                  fontWeight: "800",
                                  color: "#666",
                                }}
                              >
                                {this.state.minute !== "ex1" &&
                                  this.state.minute !== "ex2"
                                  ? parseInt(this.state.minute, 10) < 10
                                    ? "0"
                                    : Math.floor(
                                      parseInt(this.state.minute, 10) / 10
                                    )
                                  : ""}
                                {this.state.minute === "ex1" ? "5" : ""}
                                {this.state.minute === "ex2" ? "0" : ""}
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                height: 60,
                                width: "30%",
                                borderRadius: 10,
                                borderColor: "#999",
                                shadowColor: "#999",
                                shadowOpacity: 0.8,
                                shadowRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: RF(7),
                                  color: "#666",
                                  fontWeight: "800",
                                }}
                              >
                                {this.state.minute !== "ex1" &&
                                  this.state.minute !== "ex2"
                                  ? parseInt(this.state.minute, 10) % 10
                                  : ""}

                                {this.state.minute === "ex1" ? "+" : ""}
                                {this.state.minute === "ex2" ? "+" : ""}
                              </Text>
                            </View>
                          </View>
                        )}

                        {!this.state.minuteSelected && (
                          <View
                            style={
                              this.state.step < this.steps.indexOf("minute") ||
                                this.state.validated ||
                                this._isClosed()
                                ? {
                                  height: 40,
                                  borderRadius: 5,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 0.5,
                                }
                                : {
                                  height: 40,
                                  borderRadius: 5,
                                  shadowColor: "#000",
                                  shadowOpacity: 0.5,
                                  shadowRadius: 10,
                                  backgroundColor: BUTTON_COLOR,
                                  justifyContent: "center",
                                  opacity: 1,
                                }
                            }
                          >
                            <Text
                              style={{ textAlign: "center", color: TEXT_COLOR }}
                            >
                              {t("go_forecast")}
                            </Text>
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              <View
                style={{
                  height: 20,
                  margin: 20,
                  flex: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 15,
                    height: 15,
                    marginBottom: 2,
                    marginRight: 20,
                  }}
                  source={
                    this._isClosed()
                      ? require("../../assets/images/clock_red.svg")
                      : require("../../assets/images/clock_opened.svg")
                  }
                  resizeMode="contain"
                />
                {this.state.liveData && (
                  <Text style={{ textAlign: "center", color: TEXT_COLOR }}>
                    {this._isLived()
                      ? this.state.liveData.status === "FT"
                        ? t("full_time")
                        : this.state.liveData.status === "HT"
                          ? t("half_time")
                          : this.state.liveData.status === "NS"
                            ? t("not_start")
                            : "LIVE"
                      : this._isClosed()
                        ? t("closed")
                        : t("open")}
                  </Text>
                )}
                {!this.state.liveData && (
                  <Text style={{ textAlign: "center", color: TEXT_COLOR }}>
                    {this._isClosed() ? t("closed") : t("open")}
                  </Text>
                )}
              </View>
              {!this.state.showAlert && this.state.validated && (
                <Text
                  style={{
                    textAlign: "center",
                    color: TEXT_COLOR,
                    fontSize: 16,
                  }}
                >
                  {t("forecast_confirmation")}
                </Text>
              )}

              <View style={{
                position: 'fixed',
                bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
                width: '100%',
                backgroundColor: MENU_COLOR,
                padding: 15
              }}>
                <TouchableOpacity
                  onPress={() => SHOW_GAMES || APP_NAVTAB ? this.props.navigation.navigate("SelectGame") : this.props.navigation.navigate("Home")}
                >
                  <View style={{
                    flexDirection: "row",
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Image
                      style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                      source={require("../../assets/images/back.svg")}
                      resizeMode="contain"
                    />
                    <Text
                      style={{
                        color: TEXT_COLOR,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          )}

        {this.state.showAlert && (
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                marginTop: 30,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/ball.svg")}
                />
                <Text
                  style={{
                    margin: 20,
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: RF(3),
                    color: "#fff",
                  }}
                >
                  {parseInt(this.state.step, 10) === 0
                    ? THEME === "challengefff"
                      ? t("do_x_forecast_to_validate_fff", { numSteps: this.totalStep })
                      : (THEME === "co-vip" ? t("do_x_forecast_to_validate_co_vip", { numSteps: this.totalStep }) : t("do_x_forecast_to_validate", { numSteps: this.totalStep }))
                    : THEME === "challengefff"
                      ? t("rest_x_forecast_to_validate_fff", { numSteps: this.totalStep - parseInt(this.state.step, 10) })
                      : THEME === "co-vip" ? t("rest_x_forecast_to_validate_co_vip", { numSteps: this.totalStep - parseInt(this.state.step, 10) }) : t("rest_x_forecast_to_validate", { numSteps: this.totalStep - parseInt(this.state.step, 10) })}
                </Text>
                <Button
                  style={{
                    borderRadius: 25,
                    textTransform: 'uppercase'
                  }}
                  size="large"
                  onPress={() => {
                    var toScreen = this.steps[this.state.step];
                    this.setState({
                      showAlert: false,
                      screen: toScreen,
                    });
                  }}
                >
                  {t("do_forecast")}
                </Button>
              </View>

              <Button
                status="control"
                size="small"
                style={{ position: "absolute", left: 10, top: 1, borderRadius: 30 }}
                onPress={() => {
                  this.setState({ showAlert: false });
                }}
                accessoryRight={BackIcon}
              ></Button>
            </View>
          </View>
        )}

        {this.currentMatch && this.state.screen === "teamwon" && (
          <View
            style={{
              padding: getHeightPercent(2),
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(3),
                fontWeight: "bold",
                margin: getHeightPercent(2),
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("which_team_win")}
            </Text>

            <View
              style={{
                height: 1,
                width: "60%",
                alignSelf: "center",
                backgroundColor: "lightgray",
              }}
            />
            <View
              style={{
                marginTop: 30,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 40,
                  marginRight: 40,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                <i>{t("click_to_select_winner")}</i>
              </Text>
            </View>

            <View
              style={{
                flex: 1,
                marginTop: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  //   console.log("state = ", this.state);
                  this.setState({ teamWon: "home" });
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      width: getWidthPercentMaxLimited(30, maxW),
                      height: getWidthPercentMaxLimited(30, maxW),
                      opacity: this.state.teamWon === "home" ? 1 : 0.5,
                    }}
                    resizeMode={"contain"}
                    source={this.currentMatch.team_home_logo}
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {this.currentMatch.team_home}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ teamWon: "nil" });
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      width: getWidthPercentMaxLimited(25, maxW),
                      height: getWidthPercentMaxLimited(25, maxW),
                      marginLeft: getWidthPercentMaxLimited(5, maxW),
                      marginRight: getWidthPercentMaxLimited(5, maxW),
                      opacity: this.state.teamWon === "nil" ? 1 : 0.5,
                    }}
                    resizeMode={"contain"}
                    source={require("../../assets/images/nil_white.svg")}
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 14,
                      fontWeight: "bold",
                      marginTop: 20,
                    }}
                  >
                    {t("draw")}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ teamWon: "visitor" });
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      width: getWidthPercentMaxLimited(30, maxW),
                      height: getWidthPercentMaxLimited(30, maxW),
                      opacity: this.state.teamWon === "visitor" ? 1 : 0.5,
                    }}
                    resizeMode={"contain"}
                    source={this.currentMatch.team_visitor_logo}
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {this.currentMatch.team_visitor}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={{ marginTop: getHeightPercent(5) }}>
              <TOButton
                onClick={() => this.onValidateClicked()}
                disabled={this.state.teamWon === ""}
              />
            </View>
          </View>
        )}

        {this.currentMatch && this.state.screen === "score" && (
          <View
            style={{
              padding: getHeightPercent(2),
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(3),
                width: "70%",
                fontWeight: "bold",
                margin: getWidthPercentMaxLimited(2, maxW),
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("what_is_score_final")}
            </Text>

            <View
              style={{
                height: 1,
                width: "60%",
                margin: getWidthPercentMaxLimited(1, maxW),
                alignSelf: "center",
                backgroundColor: "lightgray",
              }}
            />
            <View
              style={{
                marginTop: 30,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 40,
                  marginRight: 40,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                <i>{t("spin_to_select")}</i>
              </Text>
            </View>

            <View
              style={{
                marginTop: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: getWidthPercentMaxLimited(30, maxW),
                  height: getWidthPercentMaxLimited(30, maxW),
                  marginTop: getWidthPercentMaxLimited(2, maxW),
                  marginBottom: getWidthPercentMaxLimited(2, maxW),
                  marginRight: getWidthPercentMaxLimited(7, maxW),
                }}
                resizeMode={"contain"}
                source={this.currentMatch.team_home_logo}
              />
              <Image
                style={{
                  width: getWidthPercentMaxLimited(30, maxW),
                  height: getWidthPercentMaxLimited(30, maxW),
                  marginTop: getWidthPercentMaxLimited(2, maxW),
                  marginBottom: getWidthPercentMaxLimited(2, maxW),
                  marginLeft: getWidthPercentMaxLimited(7, maxW),
                }}
                resizeMode={"contain"}
                source={this.currentMatch.team_visitor_logo}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Picker
                selectedValue={this.state.scoreHome}
                onValueChange={this.onScoreHomeChangesValue}
                mode="dropdown"
                style={styles.pickerStyleLeft}
              >
                <Picker.Item label="0" value="0" />
                <Picker.Item label="1" value="1" />
                <Picker.Item label="2" value="2" />
                <Picker.Item label="3" value="3" />
                <Picker.Item label="4" value="4" />
                <Picker.Item label="5" value="5" />
                <Picker.Item label="6" value="6" />
                <Picker.Item label="7" value="7" />
                <Picker.Item label="8" value="8" />
                <Picker.Item label="9" value="9" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
                <Picker.Item label="32" value="32" />
                <Picker.Item label="33" value="33" />
                <Picker.Item label="34" value="34" />
                <Picker.Item label="35" value="35" />
                <Picker.Item label="36" value="36" />
                <Picker.Item label="37" value="37" />
                <Picker.Item label="38" value="38" />
                <Picker.Item label="39" value="39" />
                <Picker.Item label="40" value="40" />
                <Picker.Item label="41" value="41" />
                <Picker.Item label="42" value="42" />
                <Picker.Item label="43" value="43" />
                <Picker.Item label="44" value="44" />
                <Picker.Item label="45" value="45" />
                <Picker.Item label="46" value="46" />
                <Picker.Item label="47" value="47" />
                <Picker.Item label="48" value="48" />
                <Picker.Item label="49" value="49" />
                <Picker.Item label="50" value="50" />
                <Picker.Item label="51" value="51" />
                <Picker.Item label="52" value="52" />
                <Picker.Item label="53" value="53" />
                <Picker.Item label="54" value="54" />
                <Picker.Item label="55" value="55" />
                <Picker.Item label="56" value="56" />
                <Picker.Item label="57" value="57" />
                <Picker.Item label="58" value="58" />
                <Picker.Item label="59" value="59" />
                <Picker.Item label="60" value="60" />
                <Picker.Item label="61" value="61" />
                <Picker.Item label="62" value="62" />
                <Picker.Item label="63" value="63" />
                <Picker.Item label="64" value="64" />
                <Picker.Item label="65" value="65" />
                <Picker.Item label="66" value="66" />
                <Picker.Item label="67" value="67" />
                <Picker.Item label="68" value="68" />
                <Picker.Item label="69" value="69" />
                <Picker.Item label="70" value="70" />
                <Picker.Item label="71" value="71" />
                <Picker.Item label="72" value="72" />
                <Picker.Item label="73" value="73" />
                <Picker.Item label="74" value="74" />
                <Picker.Item label="75" value="75" />
                <Picker.Item label="76" value="76" />
                <Picker.Item label="77" value="77" />
                <Picker.Item label="78" value="78" />
                <Picker.Item label="79" value="79" />
                <Picker.Item label="80" value="80" />
                <Picker.Item label="81" value="81" />
                <Picker.Item label="82" value="82" />
                <Picker.Item label="83" value="83" />
                <Picker.Item label="84" value="84" />
                <Picker.Item label="85" value="85" />
                <Picker.Item label="86" value="86" />
                <Picker.Item label="87" value="87" />
                <Picker.Item label="88" value="88" />
                <Picker.Item label="89" value="89" />
                <Picker.Item label="90" value="90" />
                <Picker.Item label="91" value="91" />
                <Picker.Item label="92" value="92" />
                <Picker.Item label="93" value="93" />
                <Picker.Item label="94" value="94" />
                <Picker.Item label="95" value="95" />
                <Picker.Item label="96" value="96" />
                <Picker.Item label="97" value="97" />
                <Picker.Item label="98" value="98" />
                <Picker.Item label="99" value="99" />
              </Picker>

              <Picker
                selectedValue={this.state.scoreVisitor}
                onValueChange={this.onScoreVisitorChangesValue}
                mode="dropdown"
                style={styles.pickerStyleRight}
              >
                <Picker.Item label="0" value="0" />
                <Picker.Item label="1" value="1" />
                <Picker.Item label="2" value="2" />
                <Picker.Item label="3" value="3" />
                <Picker.Item label="4" value="4" />
                <Picker.Item label="5" value="5" />
                <Picker.Item label="6" value="6" />
                <Picker.Item label="7" value="7" />
                <Picker.Item label="8" value="8" />
                <Picker.Item label="9" value="9" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
                <Picker.Item label="32" value="32" />
                <Picker.Item label="33" value="33" />
                <Picker.Item label="34" value="34" />
                <Picker.Item label="35" value="35" />
                <Picker.Item label="36" value="36" />
                <Picker.Item label="37" value="37" />
                <Picker.Item label="38" value="38" />
                <Picker.Item label="39" value="39" />
                <Picker.Item label="40" value="40" />
                <Picker.Item label="41" value="41" />
                <Picker.Item label="42" value="42" />
                <Picker.Item label="43" value="43" />
                <Picker.Item label="44" value="44" />
                <Picker.Item label="45" value="45" />
                <Picker.Item label="46" value="46" />
                <Picker.Item label="47" value="47" />
                <Picker.Item label="48" value="48" />
                <Picker.Item label="49" value="49" />
                <Picker.Item label="50" value="50" />
                <Picker.Item label="51" value="51" />
                <Picker.Item label="52" value="52" />
                <Picker.Item label="53" value="53" />
                <Picker.Item label="54" value="54" />
                <Picker.Item label="55" value="55" />
                <Picker.Item label="56" value="56" />
                <Picker.Item label="57" value="57" />
                <Picker.Item label="58" value="58" />
                <Picker.Item label="59" value="59" />
                <Picker.Item label="60" value="60" />
                <Picker.Item label="61" value="61" />
                <Picker.Item label="62" value="62" />
                <Picker.Item label="63" value="63" />
                <Picker.Item label="64" value="64" />
                <Picker.Item label="65" value="65" />
                <Picker.Item label="66" value="66" />
                <Picker.Item label="67" value="67" />
                <Picker.Item label="68" value="68" />
                <Picker.Item label="69" value="69" />
                <Picker.Item label="70" value="70" />
                <Picker.Item label="71" value="71" />
                <Picker.Item label="72" value="72" />
                <Picker.Item label="73" value="73" />
                <Picker.Item label="74" value="74" />
                <Picker.Item label="75" value="75" />
                <Picker.Item label="76" value="76" />
                <Picker.Item label="77" value="77" />
                <Picker.Item label="78" value="78" />
                <Picker.Item label="79" value="79" />
                <Picker.Item label="80" value="80" />
                <Picker.Item label="81" value="81" />
                <Picker.Item label="82" value="82" />
                <Picker.Item label="83" value="83" />
                <Picker.Item label="84" value="84" />
                <Picker.Item label="85" value="85" />
                <Picker.Item label="86" value="86" />
                <Picker.Item label="87" value="87" />
                <Picker.Item label="88" value="88" />
                <Picker.Item label="89" value="89" />
                <Picker.Item label="90" value="90" />
                <Picker.Item label="91" value="91" />
                <Picker.Item label="92" value="92" />
                <Picker.Item label="93" value="93" />
                <Picker.Item label="94" value="94" />
                <Picker.Item label="95" value="95" />
                <Picker.Item label="96" value="96" />
                <Picker.Item label="97" value="97" />
                <Picker.Item label="98" value="98" />
                <Picker.Item label="99" value="99" />
              </Picker>
            </View>
            <View style={{ marginTop: getHeightPercent(5) }}>
              <TOButton onClick={() => this.onValidateClicked()} />
            </View>
          </View>
        )}

        {this.currentMatch && this.state.screen === "totalScore" && (
          <View
            style={{
              padding: getHeightPercent(2),
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(3),
                fontWeight: "bold",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("what_is_total_goals_foot")}
            </Text>

            <View
              style={{
                height: 1,
                width: "60%",
                margin: 10,
                alignSelf: "center",
                backgroundColor: "lightgray",
              }}
            />

            <View
              style={{
                marginTop: 30,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 40,
                  marginRight: 40,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                <i>{t("spin_to_select_goals")}</i>
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Picker
                selectedValue={this.state.totalScore}
                onValueChange={this.onTotalScoreChangesValue}
                mode="dropdown"
                style={styles.pickerStyleNoMarginShort}
                itemStyle={styles.itemStyle}
              >
                <Picker.Item label="00" value="0" />
                <Picker.Item label="01" value="1" />
                <Picker.Item label="02" value="2" />
                <Picker.Item label="03" value="3" />
                <Picker.Item label="04" value="4" />
                <Picker.Item label="05" value="5" />
                <Picker.Item label="06" value="6" />
                <Picker.Item label="07" value="7" />
                <Picker.Item label="08" value="8" />
                <Picker.Item label="09" value="9" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
                <Picker.Item label="32" value="32" />
                <Picker.Item label="33" value="33" />
                <Picker.Item label="34" value="34" />
                <Picker.Item label="35" value="35" />
                <Picker.Item label="36" value="36" />
                <Picker.Item label="37" value="37" />
                <Picker.Item label="38" value="38" />
                <Picker.Item label="39" value="39" />
                <Picker.Item label="40" value="40" />
                <Picker.Item label="41" value="41" />
                <Picker.Item label="42" value="42" />
                <Picker.Item label="43" value="43" />
                <Picker.Item label="44" value="44" />
                <Picker.Item label="45" value="45" />
                <Picker.Item label="46" value="46" />
                <Picker.Item label="47" value="47" />
                <Picker.Item label="48" value="48" />
                <Picker.Item label="49" value="49" />
                <Picker.Item label="50" value="50" />
                <Picker.Item label="51" value="51" />
                <Picker.Item label="52" value="52" />
                <Picker.Item label="53" value="53" />
                <Picker.Item label="54" value="54" />
                <Picker.Item label="55" value="55" />
                <Picker.Item label="56" value="56" />
                <Picker.Item label="57" value="57" />
                <Picker.Item label="58" value="58" />
                <Picker.Item label="59" value="59" />
                <Picker.Item label="60" value="60" />
                <Picker.Item label="61" value="61" />
                <Picker.Item label="62" value="62" />
                <Picker.Item label="63" value="63" />
                <Picker.Item label="64" value="64" />
                <Picker.Item label="65" value="65" />
                <Picker.Item label="66" value="66" />
                <Picker.Item label="67" value="67" />
                <Picker.Item label="68" value="68" />
                <Picker.Item label="69" value="69" />
                <Picker.Item label="70" value="70" />
                <Picker.Item label="71" value="71" />
                <Picker.Item label="72" value="72" />
                <Picker.Item label="73" value="73" />
                <Picker.Item label="74" value="74" />
                <Picker.Item label="75" value="75" />
                <Picker.Item label="76" value="76" />
                <Picker.Item label="77" value="77" />
                <Picker.Item label="78" value="78" />
                <Picker.Item label="79" value="79" />
                <Picker.Item label="80" value="80" />
                <Picker.Item label="81" value="81" />
                <Picker.Item label="82" value="82" />
                <Picker.Item label="83" value="83" />
                <Picker.Item label="84" value="84" />
                <Picker.Item label="85" value="85" />
                <Picker.Item label="86" value="86" />
                <Picker.Item label="87" value="87" />
                <Picker.Item label="88" value="88" />
                <Picker.Item label="89" value="89" />
                <Picker.Item label="90" value="90" />
                <Picker.Item label="91" value="91" />
                <Picker.Item label="92" value="92" />
                <Picker.Item label="93" value="93" />
                <Picker.Item label="94" value="94" />
                <Picker.Item label="95" value="95" />
                <Picker.Item label="96" value="96" />
                <Picker.Item label="97" value="97" />
                <Picker.Item label="98" value="98" />
                <Picker.Item label="99" value="99" />
              </Picker>
            </View>
            <View style={{ marginTop: 30 }}>
              <TOButton onClick={() => this.onValidateClicked()} />
            </View>
          </View>
        )}

        {this.currentMatch && this.state.screen === "firstScorer" && (
          <View
            style={{
              padding: getHeightPercent(2),
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: 20,
                fontWeight: "bold",
                margin: getHeightPercent(2),
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("who_is_the_first_scrorer")}
            </Text>

            <View
              style={{
                height: 1,
                width: "60%",
                margin: getHeightPercent(1),
                alignSelf: "center",
                backgroundColor: "lightgray",
              }}
            />

            <View
              style={{
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 40,
                  marginRight: 40,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                <i>{t("click_to_select_scorer")}</i>
              </Text>
            </View>

            <View
              style={{
                marginTop: 10,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.dataList = this.currentMatch.team_home_players.map(
                    (el) => {
                      return {
                        name: el.name,
                        icon: el.image,
                        letter: el.name,
                        player_id: el.player_id,
                      };
                    }
                  );
                  this.setState({ pickData: null, dataSelected: 1 });
                }}
              >
                <Image
                  style={{
                    width: getWidthPercentMaxLimited(30, maxW),
                    height: getWidthPercentMaxLimited(30, maxW),
                    margin: getHeightPercent(2),
                    marginRight: getWidthPercentMaxLimited(7, maxW),
                    opacity: this.state.dataSelected === 1 ? 1 : 0.5,
                  }}
                  resizeMode={"contain"}
                  source={this.currentMatch.team_home_logo}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.dataList =
                    this.currentMatch.team_visitor_players.map((el) => {
                      return {
                        name: el.name,
                        icon: el.image,
                        letter: el.name,
                        player_id: el.player_id,
                      };
                    });
                  this.setState({ pickData: null, dataSelected: 2 });
                }}
              >
                <Image
                  style={{
                    width: getWidthPercentMaxLimited(30, maxW),
                    height: getWidthPercentMaxLimited(30, maxW),
                    margin: getHeightPercent(2),
                    marginLeft: getWidthPercentMaxLimited(7, maxW),
                    opacity: this.state.dataSelected === 2 ? 1 : 0.5,
                  }}
                  resizeMode={"contain"}
                  source={this.currentMatch.team_visitor_logo}
                />
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ListPicker
                ref={(picker) => {
                  this.picker = picker;
                }}
                onChange={(value) => {
                  this.setState({ pickData: value });
                }}
                closeable={true}
                isEmojiable={false}
                key={this.state.dataSelected}
                dataList={this.dataList}
              />
              <View
                style={{
                  width: getWidthPercentMaxLimited(90, maxW),
                  marginTop: getHeightPercent(2),
                  borderRadius: 10,
                  borderColor: "#ccc",
                  borderWidth: 2,
                  height: getWidthPercentMaxLimited(32, maxW),
                  justifyContent: "center",
                  backgroundColor: this.state.pickData
                    ? "#fff"
                    : "rgba(255,255,255,0.1)",
                }}
              >
                <TouchableOpacity onPress={() => this.picker.openModal()}>
                  {this.state.pickData && (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <Image
                        style={{
                          width: 100,
                          height: 100,
                          marginLeft: 20,
                          borderRadius: 50,
                          borderColor: BUTTON_COLOR,
                          borderWidth: 2
                        }}
                        source={this.state.pickData.icon}
                        resizeMode={"contain"}
                      /> */}
                      <View
                        style={{
                          flex: 1,
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: BUTTON_COLOR,
                            fontSize: RF(4),
                            width: "100%",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          {this.state.pickData.name}
                        </Text>
                      </View>
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            </View>
            {!this.state.pickData && (
              <View style={{ marginTop: getHeightPercent(5) }}>
                <TOButton
                  onClick={() => this.picker.openModal()}
                  text={t("select_player")}
                />
              </View>
            )}
            {this.state.pickData && (
              <View style={{ marginTop: getHeightPercent(5) }}>
                <TOButton onClick={() => this.onValidateClicked()} />
              </View>
            )}
          </View>
        )}

        {this.currentMatch && this.state.screen === "minute" && (
          <View
            style={{
              padding: getHeightPercent(2),
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(3),
                fontWeight: "bold",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("what_min_first_score")}
            </Text>

            <View
              style={{
                height: 1,
                width: "60%",
                margin: 10,
                alignSelf: "center",
                backgroundColor: "lightgray",
              }}
            />

            <View
              style={{
                marginTop: 30,
                height: 30,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: TEXT_COLOR,
                  fontSize: 16,
                  fontWeight: "bold",
                  marginLeft: 40,
                  marginRight: 40,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                <i>{t("spin_to_select")}</i>
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Picker
                selectedValue={this.state.minute}
                onValueChange={this.onMinuteChangesValue}
                mode="dropdown"
                style={
                  this.state.minute === "ex1" || this.state.minute === "ex2"
                    ? styles.pickerStyleNoMargin
                    : styles.pickerStyleNoMarginShort
                }
                itemStyle={styles.itemStyle}
              >
                <Picker.Item label="01" value="1" />
                <Picker.Item label="02" value="2" />
                <Picker.Item label="03" value="3" />
                <Picker.Item label="04" value="4" />
                <Picker.Item label="05" value="5" />
                <Picker.Item label="06" value="6" />
                <Picker.Item label="07" value="7" />
                <Picker.Item label="08" value="8" />
                <Picker.Item label="09" value="9" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
                <Picker.Item label="32" value="32" />
                <Picker.Item label="33" value="33" />
                <Picker.Item label="34" value="34" />
                <Picker.Item label="35" value="35" />
                <Picker.Item label="36" value="36" />
                <Picker.Item label="37" value="37" />
                <Picker.Item label="38" value="38" />
                <Picker.Item label="39" value="39" />
                <Picker.Item label="40" value="40" />
                <Picker.Item label="41" value="41" />
                <Picker.Item label="42" value="42" />
                <Picker.Item label="43" value="43" />
                <Picker.Item label="44" value="44" />
                <Picker.Item label="45" value="45" />
                <Picker.Item label="Tps add. 1er MT" value="ex1" />
                <Picker.Item label="46" value="46" />
                <Picker.Item label="47" value="47" />
                <Picker.Item label="48" value="48" />
                <Picker.Item label="49" value="49" />
                <Picker.Item label="50" value="50" />
                <Picker.Item label="51" value="51" />
                <Picker.Item label="52" value="52" />
                <Picker.Item label="53" value="53" />
                <Picker.Item label="54" value="54" />
                <Picker.Item label="55" value="55" />
                <Picker.Item label="56" value="56" />
                <Picker.Item label="57" value="57" />
                <Picker.Item label="58" value="58" />
                <Picker.Item label="59" value="59" />
                <Picker.Item label="60" value="60" />
                <Picker.Item label="61" value="61" />
                <Picker.Item label="62" value="62" />
                <Picker.Item label="63" value="63" />
                <Picker.Item label="64" value="64" />
                <Picker.Item label="65" value="65" />
                <Picker.Item label="66" value="66" />
                <Picker.Item label="67" value="67" />
                <Picker.Item label="68" value="68" />
                <Picker.Item label="69" value="69" />
                <Picker.Item label="70" value="70" />
                <Picker.Item label="71" value="71" />
                <Picker.Item label="72" value="72" />
                <Picker.Item label="73" value="73" />
                <Picker.Item label="74" value="74" />
                <Picker.Item label="75" value="75" />
                <Picker.Item label="76" value="76" />
                <Picker.Item label="77" value="77" />
                <Picker.Item label="78" value="78" />
                <Picker.Item label="79" value="79" />
                <Picker.Item label="80" value="80" />
                <Picker.Item label="81" value="81" />
                <Picker.Item label="82" value="82" />
                <Picker.Item label="83" value="83" />
                <Picker.Item label="84" value="84" />
                <Picker.Item label="85" value="85" />
                <Picker.Item label="86" value="86" />
                <Picker.Item label="87" value="87" />
                <Picker.Item label="88" value="88" />
                <Picker.Item label="89" value="89" />
                <Picker.Item label="90" value="90" />
                <Picker.Item label="Tps add. 2e MT" value="ex2" />
              </Picker>
            </View>
            <View style={{ marginTop: 30 }}>
              <TOButton onClick={() => this.onValidateClicked()} />
            </View>
          </View>
        )}

        {(THEME === "jeutop14" || THEME === "challengefff") && (
          <View
            style={{
              height: 70,
              width: "100%",
              flexDirection: "row",
              // position: "fixed",
              marginTop: 20,
              bottom: 0,
              shadowColor: "#000",
              shadowOpacity: 1,
              shadowRadius: 30,
            }}
          >
            <a
              href="https://www.betclic.fr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: TEXT_COLOR,
                    marginBottom: 5,
                  }}
                >
                  Le concours de pronostics par
                </Text>
                <Image
                  style={{ width: maxW, height: 50 }}
                  source="https://firebasestorage.googleapis.com/v0/b/jeutop14.appspot.com/o/betclic.png?alt=media&token=7ccd915c-ac18-4eaa-9e68-eed9667849f8"
                  resizeMode="contain"
                />
              </View>
            </a>
          </View>
        )}
        {THEME === "girondins-vip" && (
          <View
            style={{
              height: 70,
              width: "100%",
              flexDirection: "row",
              // position: "fixed",
              marginTop: 20,
              bottom: 0,
              shadowColor: "#000",
              shadowOpacity: 1,
              shadowRadius: 30,
            }}
          >
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  textAlign: "center",
                  color: TEXT_COLOR,
                  marginBottom: 5,
                }}
              >
                Le concours de pronostics par
              </Text>
              <Image
                style={{ width: maxW, height: 50 }}
                source="https://storage.googleapis.com/take-over-loges.appspot.com/teams/256768_20211102173727000_Logo%20Mumm.png"
                resizeMode="contain"
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default withTranslation(Forecast);
