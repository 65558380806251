import React, { Component } from 'react'
import withMainLayout from '../../components/MainLayout';
import ResetPassword from '../../components/SignIn/reset';

export class AuthActionScreen extends Component {
  render() {
    return (
        <ResetPassword {...this.props} />
    )
  }
}

export default withMainLayout(AuthActionScreen)
