import React, { Component } from 'react'
import withMainLayout from '../../components/MainLayout';
import { withAuthorization } from "../../components/Session";
import Rugby from '../../components/Forecast/rugby';
export class RugbyScreen extends Component {
  render() {
    return (
        <Rugby {...this.props} />
    )
  }
}
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(RugbyScreen))
