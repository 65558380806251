import React from "react";
import { View, Text, Image, Dimensions } from "react-native";
import RF from "react-native-responsive-fontsize";
import {
  ApplicationProvider,
  IconRegistry,
  Layout,
} from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";

import { default as mapping } from "../../mapping.json";
import * as eva from "@eva-design/eva";
import {
  MAIN_URL,
  PROFILE_LOGE_NUMBER,
  PROFILE_LOGE_UPDATE_BEFORE_EVENT,
  THEME_BACKGROUND_MOBILE,
  TEXT_COLOR,
  BANNER_BOTTOM,
  BANNER_BOTTOM_ENABLE,
  BANNER_BOTTOM_URL,
  BUTTON_COLOR,
  PROFILE_DOB_OBLIGATION,
  PROFILE_DOB,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  PROFILE_COMPANY_OBLIGATION,
  PROFILE_PHONE_OBLIGATION,
  PROFILE_JOB_OBLIGATION,
  PROFILE_ADDRESS,
  PROFILE_ADDRESS_STREET,
  PROFILE_ADDRESS_POSTCODE,
  PROFILE_ADDRESS_CITY,
  PROFILE_ADDRESS_OBLIGATION,
  TEXT_OPTION_2_OBLIGATION,
  TEXT_OPTION_3_OBLIGATION,
  PROFILE_JOB,
  ONLY_ANONYMOUS,
  FORCED_VALIDATION_EMAIL,
  THEME_BACKGROUND_PC,
  FIREBASE_MEASURE_ID,
  THEME,
  APP_MULTILANG,
  APP_NAVTAB,
  MODE_TEST,
  MODE_TEST_EMAILS,
  SECRET_CODE_ENABLED,
  SECRET_CODE,
  USERWAY_ENABLED,
} from "../../constants/const";
import moment from "moment";
import { NavBar } from "../Common/navbar";
import { BottomNavBar } from "../Common/bottomNavBar";
import { isMobile } from "react-device-detect";

import {
  setTranslations,
  setDefaultLanguage,
  setLanguage, t, withTranslation
} from "react-multi-lang";
import en from "../../translations/en.json";
import pt from "../../translations/pt.json";
import { Helmet } from "react-helmet";
import { TouchableOpacity } from "react-native-web";

let fr = null;
if (THEME === 'decathlon-arena') {
  fr = require("../../translations/fr_decathlon.json");
} else {
  fr = require("../../translations/fr.json");
}

const withMainLayout = (Component) => {
  class WithMainLayout extends React.Component {
    bottomNavHeight = APP_NAVTAB ? 57 : 0; //50;
    _isMounted = false;
    intervalId = null;
    calledAsync = false;
    calledSAsync = false;
    profileJSON = {};
    customTheme = {};
    maxHeight = 710; // default
    state = {
      keyMain: 1,
      updateNum: 0,
      authUser: null,
      currentAsyncs: null,
      currentPredictions: null,
      currentSAs: null,
      currentQuiz: { name: "", subtitle: "" },
      currentSurvey: { name: "", subtitle: "" },
      currentDuel: null,
      currentDraw: null,
      offlineWarning: false,
      hideBanner: false,
    };
    constructor(props) {
      super(props);
      // console.log("mapping = ", mapping);
      this.currentSurvey = null;
      this.currentQuiz = null;
      this.currentAsyncs = null;
      this.currentPredictions = null;
      this.currentMatch = null;
      this.currentMatchs = null;
      this.currentDuel = null;
      this.currentDraw = null;
      this.authUser = null;
      this.metaData = require("../../metadata.json");

      //Multi Lang
      setTranslations({ fr, en, pt });
      setDefaultLanguage("fr");

      // console.log('this.props.firebase = ', this.props.firebase);

      // console.log("color bouton = ", button_color);
      if (props.firebase) {
        // get remote config
        props.firebase.remoteConfigs().onSnapshot((configSnapshot) => {
          var lData = configSnapshot.data();
          console.log("loaded new remote config");
          // bind data
          if (lData) {
            // Save all key and value of lData to storage local
            for (var key in lData) {
              if (lData.hasOwnProperty(key)) {
                localStorage.setItem(key, lData[key]);
              }
            }
            //check version of config then refresh if needed

            let _lastVersion = localStorage.getItem("last_version");
            let _currentVersion = localStorage.getItem("VERSION");
            console.log('last_version = ', _lastVersion, ' _currentVersion = ', _currentVersion, ' _check = ', (parseInt(`${_lastVersion}` || "0", 10) < parseInt(`${_currentVersion}` || "0", 10)));

            if (parseInt(`${_lastVersion || "0"}`, 10) < parseInt(`${_currentVersion || "0"}` || "0", 10)) {
              // save and restart
              localStorage.setItem("last_version", _currentVersion);
              window.location.reload();
            }
          }
        });

        //check offline
        const that = this;
        props.firebase.dbrealtime
          .ref(".info/connected")
          .on("value", function (snapshot) {
            if (snapshot.val() === false) {
              // Instead of simply returning, we'll also set Firestore's state
              // to 'offline'. This ensures that our Firestore cache is aware
              // of the switch to 'offline.'
              that.showWarning(true);
              // console.log("offline");
            } else {
              //online
              // console.log("online");
              that.showWarning(false);
            }
          });
      }

      var button_color = props.navigation.state.params
        ? props.navigation.state.params.bc
          ? props.navigation.state.params.bc
          : BUTTON_COLOR
        : BUTTON_COLOR;

      this.customTheme = {
        "color-primary-500": button_color,
        "color-primary-400": "white",
      };

      mapping.components.Button = {
        appearances: {
          filled: {
            mapping: {},
            variantGroups: {
              status: {
                primary: {
                  backgroundColor: `${button_color}`,
                  borderColor: `${button_color}`,
                  state: {
                    focused: {
                      backgroundColor: `${button_color}`,
                      borderColor: `${button_color}`,
                    },
                    hover: {
                      backgroundColor: `${button_color}`,
                      borderColor: `${button_color}`,
                    },
                    active: {
                      backgroundColor: `${button_color}`,
                      borderColor: `${button_color}`,
                    },
                  },
                },
              },
            },
          },
        },
      };
    }

    _getBottomNavHeight = () => {
      return this._shouldEnableBottomNav() ? this.bottomNavHeight : 0;
    };

    _shouldEnableBottomNav = () => {
      if (this.state.authUser) {
        return APP_NAVTAB === true;
      }
      return false;
    };

    _shouldEnableBackButton = () => {
      const routeName = this.props.navigation.state.routeName.toLowerCase();
      // console.log("routeName = ", routeName);
      return (
        routeName === "surveylist" ||
        (routeName === "quizlist" && THEME !== 'paris23-jeux') ||
        routeName === "duel" ||
        routeName === "draw" ||
        (!APP_NAVTAB &&
          (routeName === "rankingtotal" ||
            routeName === "rankingquiz" ||
            routeName === "rankingforecast")) ||
        (APP_NAVTAB &&
          (routeName === "forecast" ||
            routeName === "countdown" ||
            routeName === "quizzes" ||
            routeName === "noquiz" ||
            routeName === "surveys" ||
            routeName === "thankyou" ||
            routeName === "loge" ||
            routeName === "rugby" ||
            routeName === "async" ||
            routeName === "sa" ||
            routeName === "boutique" ||
            routeName === "item" ||
            routeName === "calender"))
      );
    };

    showWarning = (show) => {
      if (this._isMounted) {
        this.setState({ offlineWarning: show });
      }
    };

    registerQuizIn = () => {
      if (this.authUser !== null && this.currentQuiz !== null) {
        this.props.firebase
          .quiz_participants(
            this.currentQuiz.id,
            this.authUser.uid
          )
          .set({
            displayName: this.authUser.displayName,
            email: this.authUser.email,
          });

        // listen to the currentAnswer
      }
    };

    getAsyncByIds = () => {
      //get aids
      console.log("call getAsyncByIds");
      const savedIds = window.localStorage.getItem("aids");
      if (savedIds && !this.calledAsync) {
        var savedIdsJSON = JSON.parse(savedIds);
        if (savedIdsJSON) {
          if (savedIdsJSON.length > 0) {
            this.props.firebase
              .currentAsyncScannedQuery(savedIdsJSON)
              .then((query) => {
                this.processAsyncQuery(query, true);
              })
              .catch((err) => {
                console.log("quiz err:", err);
              });
          }
        }
      }
      return this.calledAsync;
    };

    getAsyncAll = () => {
      this.props.firebase
        .currentAsyncAllQuery()
        .then((query) => {
          this.processAsyncQuery(query, false);
        })
        .catch((err) => {
          console.log("quiz err:", err);
        });
      return this.calledAsync;
    };

    processAsyncQuery = (query, needUpdateNum = false) => {
      query.onSnapshot(
        (snapshot) => {
          // Actually the data should be only 1
          this.calledAsync = true;
          // console.log("savedIdsJSON = ", savedIdsJSON);

          var snapData = [];
          snapshot.forEach((sn) => {
            snapData.push(sn.data());
          });

          // Async
          var snapDataAsync = snapData.filter((el) => {
            return (
              el.hasOwnProperty("quiz_id") &&
              el.hasOwnProperty("type") &&
              el.type === "async"
            );
          });

          if (snapDataAsync.length > 0) {
            snapDataAsync.sort((a, b) => {
              return a.end.seconds - b.end.seconds;
            });

            this.currentAsyncs = snapDataAsync;

            window.localStorage.setItem(
              "currentAsyncs",
              JSON.stringify(this.currentAsyncs)
            );
          } else {
            console.log("No Async Active!");
            window.localStorage.removeItem("currentAsyncs");
            window.localStorage.removeItem("aids");
          }

          this.registerAsyncIn();

          if (this._isMounted) {
            if (needUpdateNum) {
              this.setState({
                currentAsyncs: this.currentAsyncs,
                updateNum: this.state.updateNum + 1,
              });
            } else {
              this.setState({
                currentAsyncs: this.currentAsyncs
              });
            }
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    };

    getSAByIds = () => {
      //get aids
      console.log("call getSAByIds");
      const savedIds = window.localStorage.getItem("sids");

      if (savedIds && !this.calledSAsync) {
        var savedIdsJSON = JSON.parse(savedIds);
        if (savedIdsJSON) {
          if (savedIdsJSON.length > 0) {
            this.props.firebase
              .currentSAScannedQuery(savedIdsJSON)
              .then((query) => {
                this.processSAQuery(query, true);
              })
              .catch((err) => {
                console.log("sa err:", err);
              });
          }
        }
      }
      return this.calledSAsync;
    };

    getSAAll = () => {
      this.props.firebase
        .currentSAAllQuery()
        .then((query) => {
          this.processSAQuery(query, false);
        })
        .catch((err) => {
          console.log("quiz err:", err);
        });
      return this.calledSAsync;
    };

    processSAQuery = (query, needUpdateNum = false) => {
      query.onSnapshot(
        (snapshot) => {
          // Actually the data should be only 1
          this.calledSAsync = true;
          var snapData = [];
          snapshot.forEach((sn) => {
            snapData.push(sn.data());
          });

          // SA
          var snapDataAsync = snapData.filter((el) => {
            return (
              el.hasOwnProperty("survey_id") &&
              el.hasOwnProperty("type") &&
              el.type === "survey_async"
            );
          });

          if (snapDataAsync.length > 0) {
            snapDataAsync.sort((a, b) => {
              return a.end.seconds - b.end.seconds;
            });

            this.currentSAs = snapDataAsync;

            window.localStorage.setItem(
              "currentSAs",
              JSON.stringify(this.currentSAs)
            );
          } else {
            this.currentSAs = [];
            window.localStorage.removeItem("currentSAs");
            window.localStorage.removeItem("sids");
          }

          this.registerSAIn();

          if (this._isMounted) {
            if (needUpdateNum) {
              this.setState({
                currentSAs: this.currentSAs,
                updateNum: this.state.updateNum + 1,
              });
            } else {
              this.setState({
                currentSAs: this.currentSAs,
              });
            }
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    };

    registerSurveyIn = () => {
      if (this.authUser !== null && this.currentSurvey !== null) {
        this.props.firebase
          .survey_participants(
            this.currentSurvey.id + "_" + this.currentSurvey.start.seconds,
            this.authUser.uid
          )
          .set({
            displayName: this.authUser.displayName,
            email: this.authUser.email,
          });
      }
    };

    registerSAIn = () => {
      if (this.authUser !== null && this.currentSAs !== null) {
        for (let i = 0; i < this.currentSAs.length; i++) {
          const currentSA = this.currentSAs[i];
          this.props.firebase
            .survey_participants(
              currentSA.id + "_" + currentSA.start.seconds,
              this.authUser.uid
            )
            .set({
              displayName: this.authUser.displayName,
              email: this.authUser.email,
            });
        }
      }
    };

    registerAsyncIn = () => {
      if (this.authUser !== null && this.currentAsyncs !== null) {
        for (let i = 0; i < this.currentAsyncs.length; i++) {
          const currentAsycn = this.currentAsyncs[i];
          this.props.firebase
            .quiz_participants(
              currentAsycn.id,
              this.authUser.uid
            )
            .set({
              displayName: this.authUser.displayName,
              email: this.authUser.email,
            });
        }
      }
    };

    registerMatchIn = () => {
      if (this.authUser !== null && this.currentMatch !== null) {
        this.props.firebase
          .match_participants(
            this.currentMatch.id + "_" + this.currentMatch.start.seconds,
            this.authUser.uid
          )
          .set({
            displayName: this.authUser.displayName,
            email: this.authUser.email,
          });
      }
    };

    registerDuelIn = () => {
      if (this.authUser !== null && this.currentDuel !== null) {
        this.props.firebase
          .duel_participants(this.currentDuel.id, this.authUser.uid)
          .set({
            displayName: this.authUser.displayName,
            email: this.authUser.email,
          });
      }
    };

    getCurrentMatchPronostic = () => {
      if (this.authUser !== null && this.currentMatchs !== null) {
        var _mIds = this.currentMatchs.map(e => e.id);
        this.props.firebase
          .my_pronostics_responses(_mIds, this.authUser.uid)
          .get()
          .then((docs) => {
            var snapData = [];
            docs.forEach((sn) => {
              snapData.push(sn.data());
            });
            window.localStorage.setItem(
              "match_forecasts",
              JSON.stringify(snapData)
            );
          })
          .catch((err) => {
            console.log("Error getting getCurrentMatchPronostic", err);
          });
      }
    };

    getUserProfile = () => {
      console.log("getUserProfile = ", this.authUser);

      if (this.authUser !== null) {
        const { navigate } = this.props.navigation;
        const routeName = this.props.navigation.state.routeName;


        if (this.props.firebase.analytics) {
          this.props.firebase.analytics.setUserId(this.authUser.uid);
          this.props.firebase.analytics.setUserProperties({
            displayName: this.authUser.displayName,
            email: this.authUser.email
          });
          //log route name
          this.props.firebase.analytics.logEvent(`screen_${routeName}`, { route: routeName });
        }

        if (routeName !== "VerifyEmail") {
          if (!this.authUser.emailVerified) {
            if (FORCED_VALIDATION_EMAIL) {
              navigate("VerifyEmail");
            }
          }
        }

        if (routeName.toLowerCase() !== "profile") {
          this.props.firebase
            .user(this.authUser.uid)
            .get()
            .then((u) => {
              console.log('check profile ', u.exists);
              if (u.exists) {
                if (this._isMounted) {
                  const user = u.data();
                  if (this.checkDidObligation(user)) {
                    navigate("Profile");
                  }

                  this.profileJSON = user;
                  if (user) {
                    if (!this.profileJSON.displayName) {
                      this.profileJSON.displayName = `${this.profileJSON.firstName
                        } ${(this.profileJSON.lastName || " ")
                          .charAt(0)
                          .toUpperCase()}`;
                    }
                    window.localStorage.setItem(
                      "profile",
                      JSON.stringify(this.profileJSON)
                    );

                    if (ONLY_ANONYMOUS) {
                    } else {
                      if (
                        PROFILE_LOGE_NUMBER &&
                        PROFILE_LOGE_UPDATE_BEFORE_EVENT
                      ) {
                        if (!user.logeNumber) {
                          // redirect select loge
                          if (routeName !== "Loge") {
                            navigate("Loge");
                          }
                        } else {
                          if (
                            user.logeNumberDate < moment().format("YYYY-MM-DD")
                          ) {
                            // redirect select loge
                            if (routeName !== "Loge") {
                              navigate("Loge");
                            }
                          }
                        }
                      }
                    }
                  }
                }
              } else {
                // if we have the data

                if (this.authUser?.uid) {
                  // update to firebase
                  this.props.firebase
                    .user(this.authUser.uid).set({
                      ...this.authUser,
                      source: 'sso',
                      theme: 'assejeux',
                      pointTotal: this.props.firebase.FieldValue.increment(0),
                      pointShop: this.props.firebase.FieldValue.increment(0),
                      timeRightMs: this.props.firebase.FieldValue.increment(0)
                    }, { merge: true });
                }

              }
            });
        }
      }
    };

    checkDidObligation = (user) => {
      if (ONLY_ANONYMOUS) {
        return false;
      }

      if (!user) {
        return true;
      }

      if (!user.firstName) {
        return true;
      }

      if (!user.lastName) {
        return true;
      }

      if (TEXT_OPTION_2_OBLIGATION) {
        if (!user.newsletter || user.newsletter === false) {
          return true;
        }
      }

      if (TEXT_OPTION_3_OBLIGATION) {
        if (
          !user.newsletter_commercial ||
          user.newsletter_commercial === false
        ) {
          return true;
        }
      }

      if (PROFILE_DOB_OBLIGATION && PROFILE_DOB) {
        if (!user.dob || user.dob === false) {
          return true;
        }
      }

      if (PROFILE_ADDRESS && PROFILE_ADDRESS_OBLIGATION) {
        if (PROFILE_ADDRESS_STREET) {
          if (!user.address || user.address === "") {
            return true;
          }
        }
        if (PROFILE_ADDRESS_POSTCODE) {
          if (!user.postcode || user.postcode === "") {
            return true;
          }
        }
        if (PROFILE_ADDRESS_CITY) {
          if (!user.city || user.city === "") {
            return true;
          }
        }
      }

      if (PROFILE_JOB_OBLIGATION && PROFILE_JOB) {
        if (!user.job || user.job === "") {
          return true;
        }
      }

      if (PROFILE_PHONE_OBLIGATION && PROFILE_PHONE) {
        if (!user.phone || user.phone === false) {
          return true;
        }
      }

      if (PROFILE_LOGE_NUMBER) {
        if (!user.logeNumber || user.logeNumber === "") {
          return true;
        }
      }

      if (PROFILE_COMPANY_OBLIGATION && PROFILE_COMPANY) {
        if (!user.company || user.company === false) {
          return true;
        }
      }

      return false;
    };

    loadingData = () => {
      if (this.props.firebase) {

        //check theme preview
        const routeName = this.props.navigation.state.routeName;

        if (routeName.toLowerCase() === "selectgame" || routeName.toLowerCase() === "quizzes") {
          if (SECRET_CODE_ENABLED) {
            let _code = window.localStorage.getItem('code');
            if (_code !== SECRET_CODE) {
              this.props.navigation.navigate('SecretCode');
            }
          }
        }

        if (
          routeName &&
          routeName.toLowerCase() !== "home" &&
          routeName.toLowerCase() !== "async" &&
          routeName.toLowerCase() !== "sa" &&
          routeName.toLowerCase() !== "rankingtotal" &&
          routeName.toLowerCase() !== "rankingquiz" &&
          routeName.toLowerCase() !== "rankingforecast" &&
          routeName.toLowerCase() !== "boutique" &&
          routeName.toLowerCase() !== "item" &&
          routeName.toLowerCase() !== "calender" && routeName.toLowerCase() !== "profile"
        ) {
          // get all events if not in mode TEST
          // get email logged 
          var json = window.localStorage.getItem("profile");
          var my_profile = {};
          var emailsTestArr = [];
          try {
            my_profile = json != null ? JSON.parse(json) : {};
            emailsTestArr = MODE_TEST_EMAILS.split(",").map((e) => {
              return e.trim();
            });
          } catch {
            console.log('error emailsTestArr or my_profile, keep going ');
          }
          console.log("emailsTestArr");
          console.log((MODE_TEST && emailsTestArr.includes(my_profile.email)) || !MODE_TEST);
          if ((MODE_TEST && emailsTestArr.includes(my_profile.email)) || !MODE_TEST)
            this.props.firebase.currentEventsQuery()
              .then((query) => {
                query.onSnapshot(
                  (snapshot) => {
                    // Actually the data should be only 1
                    // var needUpdate = true;
                    var snapData = [];
                    snapshot.forEach((sn) => {
                      snapData.push(sn.data());
                    });

                    console.log('snapData currentEventsQuery = ', snapData);

                    // Quiz
                    var snapDataQuiz = snapData.filter((el) => {
                      return (
                        el.hasOwnProperty("quiz_id") &&
                        el.hasOwnProperty("type") &&
                        el.type === "quiz"
                      );
                    });

                    if (snapDataQuiz.length > 0) {
                      snapDataQuiz.sort((a, b) => {
                        return a.end.seconds - b.end.seconds;
                      });
                      // for quiz
                      this.currentQuiz = snapDataQuiz[0];
                      // this.currentQuiz.start_org = this.currentQuiz.start;
                      if (this.currentQuiz.start_delayed) {
                        this.currentQuiz.start_display = this.currentQuiz.start;
                        this.currentQuiz.start = this.currentQuiz.start_delayed;
                      }

                      var cQ = window.localStorage.getItem("currentQuiz");
                      if (cQ !== null) {
                        var savedQ = JSON.parse(cQ);
                        if (savedQ !== null) {
                          const savedQ_id = `${savedQ.id}_${savedQ.start.seconds}`;
                          const current_id = `${this.currentQuiz.id}_${this.currentQuiz.start.seconds}`;
                          if (savedQ_id !== current_id) {
                            window.localStorage.removeItem("currentResults");
                          }
                        }
                      }
                      window.localStorage.setItem(
                        "currentQuiz",
                        JSON.stringify(this.currentQuiz)
                      );
                      this.registerQuizIn();
                    } else {
                      console.log("No Quiz Active!");
                      window.localStorage.removeItem("currentQuiz");
                      // window.localStorage.removeItem("currentResults");
                      this.currentQuiz = null;
                    }

                    // Survey
                    var snapDataSurvey = snapData.filter((el) => {
                      return el.hasOwnProperty("survey_id");
                    });

                    if (snapDataSurvey.length > 0) {
                      snapDataSurvey.sort((a, b) => {
                        return a.end.seconds - b.end.seconds;
                      });
                      this.currentSurvey = snapDataSurvey[0];
                      if (this.currentSurvey.start_delayed) {
                        this.currentSurvey.start_display =
                          this.currentSurvey.start;
                        this.currentSurvey.start =
                          this.currentSurvey.start_delayed;
                      }
                      window.localStorage.setItem(
                        "currentSurvey",
                        JSON.stringify(this.currentSurvey)
                      );

                      this.registerSurveyIn();
                    } else {
                      console.log("No Survey Active!");
                      window.localStorage.removeItem("currentSurvey");
                      this.currentSurvey = null;
                    }

                    //For DUEL
                    var snapDataDuel = snapData.filter((el) => {
                      return el.hasOwnProperty("duel_id");
                    });

                    if (snapDataDuel.length > 0) {
                      snapDataDuel.sort((a, b) => {
                        return a.end.seconds - b.end.seconds;
                      });
                      this.currentDuel = snapDataDuel[0];
                      this.currentDuel.start_display = this.currentDuel.start;
                      if (this.currentDuel.start_delayed) {
                        this.currentDuel.start = this.currentDuel.start_delayed;
                      }
                      window.localStorage.setItem(
                        "currentDuel",
                        JSON.stringify(this.currentDuel)
                      );

                      this.registerDuelIn();
                    } else {
                      window.localStorage.removeItem("currentDuel");
                      this.currentDuel = null;
                    }

                    //For DRAW
                    var snapDataDraw = snapData.filter((el) => {
                      return el.type === 'draw';
                    });

                    if (snapDataDraw.length > 0) {
                      snapDataDraw.sort((a, b) => {
                        return a.end.seconds - b.end.seconds;
                      });
                      // TODO: should get all ?
                      this.currentDraw = snapDataDraw[0];
                      this.currentDraw.start_display = this.currentDraw.start;
                      if (this.currentDraw.start_delayed) {
                        this.currentDraw.start =
                          this.currecurrentDrawntDuel.start_delayed;
                      }
                      window.localStorage.setItem(
                        "currentDraw",
                        JSON.stringify(this.currentDraw)
                      );
                    } else {
                      window.localStorage.removeItem("currentDraw");
                      this.currentDraw = null;
                    }

                    // Match
                    var snapDataMatch = snapData.filter((el) => {
                      return el.hasOwnProperty("match_id");
                    });

                    if (snapDataMatch.length > 0) {
                      snapDataMatch.sort((a, b) => {
                        return a.end.seconds - b.end.seconds;
                      });
                      for (let i = 0; i < snapDataMatch.length; i++) {
                        const e = snapDataMatch[i];
                        if (!e.visible || e.visible === 'all_access') {
                          this.currentMatch = e;
                          break;
                        }
                      }
                      this.currentMatchs = snapDataMatch;
                      window.localStorage.setItem(
                        "currentMatch",
                        JSON.stringify(this.currentMatch)
                      );
                      window.localStorage.setItem(
                        "currentMatchs",
                        JSON.stringify(snapDataMatch)
                      );
                      this.registerMatchIn();
                      this.getCurrentMatchPronostic();
                    } else {
                      console.log("No Match Active!");
                      window.localStorage.removeItem("currentMatch");
                      window.localStorage.removeItem("match_forecasts");
                      this.currentMatch = null;
                      this.currentMatchs = null;
                    }

                    // Prediction
                    var snapDataPredicts = snapData.filter((el) => {
                      return el.type === 'prediction';
                    });

                    if (snapDataPredicts.length > 0) {
                      snapDataPredicts.sort((a, b) => {
                        return a.end.seconds - b.end.seconds;
                      });

                      this.currentPredictions = snapDataPredicts;
                      window.localStorage.setItem(
                        "currentPredictions",
                        JSON.stringify(this.currentPredictions)
                      );
                    } else {
                      console.log("No Match Active!");
                      window.localStorage.removeItem("currentPredictions");
                      this.currentPredictions = null;
                    }

                    if (this._isMounted) {
                      this.setState({
                        currentQuiz: this.currentQuiz,
                        currentSurvey: this.currentSurvey,
                        currentMatch: this.currentMatch,
                        currentMatchs: this.currentMatchs,
                        currentDuel: this.currentDuel,
                        currentDraw: this.currentDraw,
                        currentPredictions: this.currentPredictions,
                        updateNum: this.state.updateNum + 1,
                      });
                    }
                  },
                  (err) => {
                    console.log(`Encountered error: ${err}`);
                  }
                );
              })
              .catch((err) => {
                console.log("quiz err:", err);
              });

          // Update the logged in user
          this.props.firebase.auth.onAuthStateChanged((authUser) => {
            console.log("onAuthStateChanged (authUser) ", authUser);
            if (authUser) {
              // console.log("authUser = ", authUser);

              if (FORCED_VALIDATION_EMAIL) {
                const ls = window.localStorage.getItem("lastSent");
                var lastSent = 0;
                console.log("ls = ", ls);
                if (ls) {
                  lastSent = parseInt(ls);
                }
                const ns = window.localStorage.getItem("needSendVerification");
                console.log("ns = ", ns);
                if (ns) {
                  if (!authUser.emailVerified) {
                    const now = new Date(Date.now()).getTime();
                    if (now - lastSent > 3000) {
                      // 3s
                      window.localStorage.removeItem("needSendVerification");
                      window.localStorage.setItem("lastSent", now);
                      authUser
                        .sendEmailVerification({
                          url: `https://${MAIN_URL}/AuthAction`,
                          handleCodeInApp: true,
                        })
                        .then(function () {
                          // Email sent.
                          console.log("sent email to : ", authUser.email);
                        })
                        .catch(function (err) {
                          // An error happened.
                          console.log(err);
                        });
                    }
                  }
                }
              }
              this.authUser = authUser;
              this.getUserProfile();
              if (this._isMounted) {
                this.registerQuizIn();
                this.registerMatchIn();
                this.registerSurveyIn();
                this.registerDuelIn();
                this.getCurrentMatchPronostic();
                this.setState({
                  authUser,
                  checkUser: true,
                  updateNum: this.state.updateNum + 1,
                });
              }
            } else {
              if (this._isMounted) {
                this.setState({
                  authUser,
                  checkUser: true,
                  updateNum: this.state.updateNum + 1,
                });
              }
            }
          });

        } else {
          // Update the logged in user
          this.props.firebase.auth.onAuthStateChanged((authUser) => {
            if (authUser) {
              console.log('this._isMounted = ', this._isMounted);
              if (this._isMounted) {
                this.authUser = authUser;
                this.setState({
                  authUser,
                  checkUser: true,
                  updateNum: this.state.updateNum + 1,
                });
              }
              this.getUserProfile();
            } else {
              if (this._isMounted) {
                this.setState({
                  authUser,
                  checkUser: true,
                  updateNum: this.state.updateNum + 1,
                });
              }
            }
          });
        }
      }
    }

    componentDidMount() {
      this._isMounted = true;

      this.loadingData();

      this.intervalId = setInterval(() => {
        this.checkEndTime();
      }, 1000);

      var self = this; // Store `this` component outside the callback
      if ("onorientationchange" in window) {
        window.addEventListener(
          "orientationchange",
          function () {
            // `this` is now pointing to `window`, not the component. So use `self`.
            setTimeout(() => {
              self.setState({
                keyMain: self.state.keyMain + 1,
              });
              console.log("onorientationchang with ", self.state.keyMain);
            }, 200);
          },
          false
        );
      }

      let currentLanguage = window.localStorage.getItem("lang");
      if (currentLanguage) {
        if (currentLanguage === "fr" || currentLanguage === "en" || currentLanguage === "pt") {
          setLanguage(currentLanguage);
        }
      }

    }

    componentWillUnmount() {
      if (this.intervalId) {
        clearInterval(this.state.intervalId);
      }
      this._isMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
      const routeName = nextProps.navigation.state.routeName;

      var _maxNum = 1;
      if (
        routeName &&
        routeName.toLowerCase() !== "async" &&
        routeName.toLowerCase() !== "sa" &&
        routeName.toLowerCase() !== "rankingtotal" &&
        routeName.toLowerCase() !== "rankingquiz" &&
        routeName.toLowerCase() !== "rankingforecast" &&
        routeName.toLowerCase() !== "boutique" &&
        routeName.toLowerCase() !== "item" &&
        routeName.toLowerCase() !== "calender"
      ) {
        _maxNum = 2;
      }

      // console.log("shouldComponentUpdate", nextProps, nextState);
      if (nextState.updateNum < _maxNum) {
        return false;
      }
      return true;
    }

    checkEndTime() {
      // console.log("checkEndTime");
      // Check for QUIZ
      // NOTE: check diff time config
      const currentNow = Date.now();
      // const lastNow = window.localStorage.getItem("currentNow");
      // if (lastNow) {
      //   const lastNowValue = parseInt(lastNow, 10);
      //   if (Math.abs(currentNow - lastNowValue) > 1500) {
      //     window.location.reload(true);
      //   }
      // }
      // window.localStorage.setItem("currentNow", currentNow);

      const now = Math.floor(
        (currentNow + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      if (this.props.navigation.state.routeName === "Thankyou") {
        if (this.currentSurvey !== null) {
          // console.log(timeLeft);
          if (now > this.currentSurvey.end.seconds) {
            // console.log("looking for next Survey");
            window.location.replace("https://" + MAIN_URL + "/SelectGame");
          }
        }
      }
      if (this.props.navigation.state.routeName === "Ranking") {
        if (this.currentQuiz !== null) {
          // console.log(timeLeft);
          if (now > this.currentQuiz.end.seconds) {
            // console.log("looking for next quiz");
            window.location.replace("https://" + MAIN_URL + "/SelectGame");
          }
        }
      }
      // console.log("ended checkEndTime");
    }

    _renderMainCore(
      routeName,
      should_enable_banner,
      profileJSON,
      maxWidth,
      maxBorder
    ) {
      return (
        <View
          key={this.state.keyMain}
          style={{
            alignSelf: "center",
            position: "relative",
            width: maxWidth,
            height: this.maxHeight,
            overflowY: "auto",
            ...maxBorder,
            minHeight: "-webkit-fill-available",
          }}
        >
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${FIREBASE_MEASURE_ID}`}
            ></script>
            <script>
              {`window.dataLayer = window.dataLayer || []; function gtag()
              {dataLayer.push(arguments);}
              gtag('js', new Date()); gtag('config', '${FIREBASE_MEASURE_ID}');`}
            </script>
          </Helmet>

          {USERWAY_ENABLED && <Helmet>
            <script>{`
                (function(d){
                  var s = d.createElement("script");
                  /* uncomment the following line to override default position*/
                  s.setAttribute("data-position", 3);
                  /* uncomment the following line to override default size (values: small, large)*/
                  s.setAttribute("data-size", "small");
                  /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
                  s.setAttribute("data-language", "${window.localStorage.getItem("lang") || 'fr'}");
                  /* uncomment the following line to override color set via widget */
                  /* s.setAttribute("data-color", "${BUTTON_COLOR}"); */
                  /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
                  /* s.setAttribute("data-type", "1"); */
                  /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
                  /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility")";*/
                  /* uncomment the following line to override support on mobile devices*/
                  s.setAttribute("data-mobile", true);
                  /* uncomment the following line to set custom trigger action for accessibility menu*/
                  /* s.setAttribute("data-trigger", "triggerId")*/
                  s.setAttribute("data-account", "UMwGVP1izR");
                  s.setAttribute("src", "https://cdn.userway.org/widget.js");
                  (d.body || d.head).appendChild(s);
                })(document)
            `}</script>
          </Helmet>}
          <Image
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              // filter: "brightness(90%)",
            }}
            source={
              this.props.navigation.state.params
                ? this.props.navigation.state.params.bgm
                  ? this.props.navigation.state.params.bgm
                  : THEME_BACKGROUND_MOBILE
                : THEME_BACKGROUND_MOBILE
            }
            resizeMode={THEME === "jeutop14" ? "stretch" : "cover"}
          />
          <View
            style={{
              left: 0,
              top: THEME === "jeutop14" ? 66 : 56,
              position: "fixed",
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              bottom: 0,
              paddingBottom: should_enable_banner
                ? 70 + this._getBottomNavHeight()
                : 0 + this._getBottomNavHeight(),
              alignItems: "center",
            }}
          >
            <Component
              {...this.props}
              key={`${this.state.updateNum}_${this.state.currentAsyncs ? this.state.currentAsyncs.length : ''}`}
              userData={this.profileJSON}
              authUser={this.state.authUser || this.authUser}
              checkUser={this.state.checkUser}
              currentAsyncs={this.state.currentAsyncs || this.currentAsyncs}
              currentQuiz={this.state.currentQuiz || this.currentQuiz}
              currentSurvey={this.state.currentSurvey || this.currentSurvey}
              currentMatch={this.state.currentMatch || this.currentMatch}
              currentMatchs={this.state.currentMatchs || this.currentMatchs}
              currentPredictions={this.state.currentPredictions || this.currentPredictions}
              currentDuel={this.state.currentDuel || this.currentDuel}
              currentDraw={this.state.currentDraw || this.currentDraw}
              currentSAs={this.state.currentSAs || this.currentSAs}
              calledSAsync={this.calledSAsync || this.currentAsyncs}
              getAsyncByIds={this.getAsyncByIds}
              getAsyncAll={this.getAsyncAll}
              getSAByIds={this.getSAByIds}
              getSAAll={this.getSAAll}
              maxHeight={this.maxHeight}
              maxWidth={maxWidth}
            />
          </View>

          {should_enable_banner && !this.state.hideBanner && (
            <View
              style={{
                height: 70,
                width: "100%",
                flexDirection: "row",
                position: "fixed",
                bottom: this._getBottomNavHeight(),
                shadowColor: "#000",
                shadowOpacity: 1,
                shadowRadius: 30,
                backgroundColor: "#00000099",
              }}
            >
              {BANNER_BOTTOM_URL() !== "#" && (
                <a
                  href={BANNER_BOTTOM_URL()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    style={{ width: maxWidth, height: 70 }}
                    source={BANNER_BOTTOM()}
                    resizeMode="contain"
                  />
                </a>
              )}

              {BANNER_BOTTOM_URL() === "#" && (
                <Image
                  style={{ width: maxWidth, height: 70 }}
                  source={BANNER_BOTTOM()}
                  resizeMode="contain"
                />
              )}
              <TouchableOpacity

                onPress={() => {
                  this.setState({ hideBanner: true });
                }}
              // accessoryRight={CloseIcon}

              ><Text style={{ position: "absolute", right: 2, top: 0, borderRadius: 15, padding: 0, width: 20, height: 20, backgroundColor: 'white', textAlign: 'center' }}>X</Text></TouchableOpacity>
            </View>
          )}

          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              color: this.props.navigation.state.params
                ? this.props.navigation.state.params.tc
                  ? this.props.navigation.state.params.tc
                  : TEXT_COLOR
                : TEXT_COLOR,
              position: "absolute",
              bottom: should_enable_banner
                ? 70 + this._getBottomNavHeight()
                : 0 + this._getBottomNavHeight(),
              right: 0,
            }}
          >
            WM {this.metaData.version || "1.1.0"}-{this.metaData.build || "01"}
          </Text>

          {this.props.navigation.state.routeName.toLowerCase() !==
            "language" && (
              <>
                <NavBar
                  backable={this._shouldEnableBackButton()}
                  withMenuBurger={APP_NAVTAB ? false : true}
                  authUser={this.state.authUser || this.authUser}
                  userData={{ ...this.state.authUser, ...this.profileJSON }}
                  navigation={this.props.navigation}
                  firebase={this.props.firebase}
                  t_already_have_account={t("already_have_account")}
                  t_signin={t("signin")}
                />

                {this._shouldEnableBottomNav() && (
                  <BottomNavBar
                    authUser={this.state.authUser || this.authUser}
                    firebase={this.props.firebase}
                    userData={{ ...this.state.authUser, ...this.profileJSON }}
                    navigation={this.props.navigation}
                  />
                )}
              </>
            )}
          {this.state && this.state.offlineWarning && (
            <View
              style={{
                backgroundColor: "yellow",
                height: 60,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                position: "absolute",
                top: 0,
              }}
            >
              <Text
                style={{
                  color: "red",
                  fontSize: RF(1.5),
                  fontWeight: "bold",
                }}
              >
                Pas de connexion internet.
              </Text>
            </View>
          )}

        </View>
      );
    }

    _renderMainLayout() {
      const maxWidth = isMobile ? Dimensions.get("window").width : 400;
      this.maxHeight = isMobile ? "100vh" : 710;
      const maxBorder = isMobile
        ? {}
        : {
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
          border: "none",
          borderRadius: 8,
          transition: "transform 0.2s ease-in-out, opacity 0.2s ease-in-out",
        };
      const routeName = this.props.navigation.state.routeName;
      var should_enable_banner = this.props.navigation.state.params
        ? this.props.navigation.state.params.hasOwnProperty("banner")
          ? this.props.navigation.state.params.banner === "true"
          : BANNER_BOTTOM_ENABLE
        : BANNER_BOTTOM_ENABLE &&
        routeName.toLowerCase() !== "profile" &&
        routeName.toLowerCase() !== "signup" &&
        routeName.toLowerCase() !== "signin";
      // routeName.toLowerCase() !== "async" &&
      // routeName.toLowerCase() !== "quizzes" &&
      // routeName.toLowerCase() !== "forecast" &&
      // routeName.toLowerCase() !== "surveys" &&
      // routeName.toLowerCase() !== "rugby" &&
      // routeName.toLowerCase() !== "sa" &&
      // routeName.toLowerCase() !== "duel";

      // console.log("routeName = ", routeName);
      console.log(
        "check =",
        window.localStorage.getItem("lang") !== "en" &&
        window.localStorage.getItem("lang") !== "fr"
      );

      if (THEME === "wizink") {
        window.localStorage.setItem("lang", "pt");
      } else {
        if (APP_MULTILANG) {
          if (
            window.localStorage.getItem("lang") !== "en" &&
            window.localStorage.getItem("lang") !== "fr" &&
            routeName.toLowerCase() !== "language"
          ) {
            console.log("should go to select language");
            this.props.navigation.navigate("Language");
          }
        } else {
          // setLanguage("fr");
          window.localStorage.setItem("lang", "fr");
        }
      }



      var json = window.localStorage.getItem("profile");
      try {
        this.profileJSON = json != null ? JSON.parse(json) : {};
      } catch {
        window.localStorage.removeItem("profile");
      }

      return isMobile ? (
        this._renderMainCore(
          routeName,
          should_enable_banner,
          this.profileJSON,
          maxWidth,
          maxBorder
        )
      ) : (
        <View
          style={{
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              position: "fixed",
              // filter: "blur(5px)",
            }}
            source={
              this.props.navigation.state.params
                ? this.props.navigation.state.params.bgm
                  ? this.props.navigation.state.params.bgm
                  : THEME_BACKGROUND_PC
                : THEME_BACKGROUND_PC
            }
            resizeMode={"cover"}
          />

          <Layout
            style={{
              overflow: "hidden",
              position: "relative",
              display: "flex",
              height: this.maxHeight,
              transform: "translate(0px, 0)",
              transition: "left 0.2s ease-in-out, transform 0.2s ease-in-out",
            }}
          >
            {this._renderMainCore(
              routeName,
              should_enable_banner,
              this.profileJSON,
              maxWidth,
              maxBorder
            )}
          </Layout>
        </View>
      );
    }

    render() {
      return (
        <>
          <IconRegistry icons={EvaIconsPack} />
          <ApplicationProvider
            {...eva}
            customMapping={mapping}
            theme={{ ...eva.dark, ...this.customTheme }}
          >
            {this._renderMainLayout()}
          </ApplicationProvider>
        </>
      );
    }
  }

  return withTranslation(WithMainLayout);
};

export default withMainLayout;
