import React, { Component } from "react";
import { Text, View, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import { getHeightFromHD } from "../../../libs/list_picker/ratio";
import { TEXT_TV_COLOR } from "../../../constants/const";

export class NumberOnline extends Component {
  state = {
    participants : 0
  };
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    if (this.props.firebase) {
      if(this.props.type === 'quiz'){
        this.props.firebase
        .quiz_participants_count(this.props.currentQuiz.id)
        .onSnapshot(snapshot => {
          if(this._isMounted){
            this.setState({ participants: snapshot.size });
          }
        });
      }else if(this.props.type === 'duel'){
        this.props.firebase
        .duel_participants_count(this.props.currentDuel.id)
        .onSnapshot(snapshot => {
          if(this._isMounted){
            this.setState({ participants: snapshot.size });
          }
        });
      }else if(this.props.type === 'survey'){
        this.props.firebase
        .survey_participants_count(this.props.currentSurvey.id + "_" + this.props.currentSurvey.start.seconds)
        .onSnapshot(snapshot => {
          if(this._isMounted){
            this.setState({ participants: snapshot.size });
          }
        });
      }else if(this.props.type === 'match'){
        this.props.firebase
        .match_participants_count(this.props.currentMatch.id + "_" + this.props.currentMatch.start.seconds)
        .onSnapshot(snapshot => {
          if(this._isMounted){
            this.setState({ participants: snapshot.size });
          }
        });
      }
      
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <View
        style={{
          height: getHeightFromHD(160),
          borderRadius: getHeightFromHD(160)/2,
          borderColor: "#cccccc60",
          borderWidth: 4,
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 30,
          paddingRight: 30,
          ...this.props.style
        }}
      >
        {this.props.logeName && (this.props.mode !== 2) && (
          <Text
            style={{
              color: TEXT_TV_COLOR,
              textAlignVertical: "center",
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: RF(5)
            }}
          >
            {this.props.logeName}
          </Text>
        )}
        <Image
          style={{
            width: RF(12),
            height: RF(12),
            marginLeft: 10
          }}
          resizeMode="contain"
          source={require("../../../assets/images/result.png")}
        />
        <Text
          style={{
            color: TEXT_TV_COLOR,
            textAlignVertical: "center",
            textAlign: "center",
            fontSize: RF(9),
            fontWeight: "bold",
            flex: 1,
          }}
        >
          {this.state.participants}
        </Text>
      </View>
    );
  }
}

export default NumberOnline;
