import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Animated,
  Easing,
  Dimensions,
  Image,
} from "react-native";
import {
  MAIN_COLOR,
  MAIN_URL,
  EMAILS_RESULTS,
  BG_TELE,
  THEME_BANNER_FULL,
  THEME_BACKGROUND_TELE,
  TEXT_TV_COLOR,
  BORDER_COLOR,
  BORDER_WIDTH,
  SHOW_TUTO,
  HIDE_QRCODE,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  PROFILE_LOGE_NUMBER,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  PROFILE_JOB,
  PROFILE_DOB,
  PROFILE_ADDRESS,
  ONLY_ANONYMOUS,
  FORECAST_FOOT_WINNER,
  FORECAST_FOOT_FIRST_SCORER,
  FORECAST_RUGBY_SCORE_GAP,
  FORECAST_FOOT_FIRST_SCORE_MINUTE,
  FORECAST_RUGBY_FIRST_SCORE,
  FORECAST_RUGBY_WINNER,
  FORECAST_FOOT_TOTAL_SCORE,
  FORECAST_FOOT_SCORE,
  FORECAST_RUGBY_TOTAL_SCORE,
  FORECAST_WINNER_POINT,
  FORECAST_SCORE_POINT,
  FORECAST_TOTAL_SCORE_POINT,
  FORECAST_FIRST_SCORER_POINT,
  FORECAST_SCORE_GAP_POINT,
  FORECAST_FIRST_SCORE_POINT,
  FORECAST_FIRST_SCORE_MINUTE_POINT,
  THEME,
  QRCODE_FOREGROUND_COLOR,
  QRCODE_BACKGROUND_COLOR,
  MAIN_DYNAMIC_LINK,
  SHOW_NULBER_ONLINE
} from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import {
  getWidthPercent,
  getHeightPercent,
  getWidthFromHDMargin,
  getHeightFromHDMargin,
} from "../../libs/list_picker/ratio";
import MatchPronostic from "./matchPronostic";
import TutorTele from "./Tutor/tutor";
import ScaleView from "../Anim/scaleView";
import FadeView from "../Anim/FadeView";

export class TeleMatchHome3 extends Component {
  springSize = new Animated.Value(3);
  setted = false;
  _isMounted = false;
  pronosticsOrigin = [];
  pronosticsSorted = [];
  lastFirst100s = [];
  winners = [];

  constructor(props) {
    super(props);

    this.state = {
      timeLeft: props.timeLeft,
      currentMatch: props.currentMatch,
      animate: false,
      showTuto: false,
      showFocus: false,
      blinkAnimation: new Animated.Value(0.2),
      animations: {
        bgWidth: new Animated.Value(80),
        bgMarginTop: new Animated.Value(100),
        bgMarginBottom: new Animated.Value(200),
        textMarginBottom: new Animated.Value(90),
        fadeOut: new Animated.Value(1),
      },
      screen: "countdown",
      timeEnd: 0,
      liveTime: "00 : 00",
      liveTimeValue: 0,
      pronostics: [],
      liveData: {},
      updateNum: 0,
    };
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);

    if (props.firebase && props.currentMatch) {
      props.firebase
        .pronostics_responses_query(props.currentMatch.id)
        .onSnapshot((snapshot) => {
          if (snapshot.size <= 0) {
            window.localStorage.removeItem("pronostics");
          }
          // Actually the data should be only 1
          var arrData = [];
          snapshot.forEach((data) => {
            arrData.push(data.data());
          });
          window.localStorage.setItem("pronostics", JSON.stringify(arrData));
          // calculate results
          // bind data
          // if (this._isMounted) {
          //   this.setState({
          //     pronostics: arrData,
          //     updateNum: this.state.updateNum + 1
          //   });
          // }
          this.pronosticsOrigin = arrData;
          this._calculatePronostics();
        });
    }
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut,
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
    ]);

  callbackTimerForEnd(val) {
    if (this.props.callbackTimerForEnd) {
      this.props.callbackTimerForEnd(val);
    }
  }

  callbackTimer(val) {
    // console.log("callbackTimer =>", val);
    // console.log("this.props.callbackTimer =>", this.props.timeLeft);
    //correct the timeLeft
    // if (this.props.callbackTimer) {
    //   this.props.callbackTimer(val);
    // }
    //Do animation
    if (val <= 0) {
      this.setState({ animate: true });
      const {
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut,
      } = this.state.animations;

      this.doAnimation({
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut,
      }).start(() => {
        // Do stuff after animations
        var intervalId = setInterval(() => {
          this.checkEndTime();
        }, 1000);
        this.setState({ intervalId: intervalId, screen: "live" });
        this.syncDataLive();
      });
    }

    if (val % 45 === 0 && val > 60) {
      this.setState({ showTuto: true });
      setTimeout(
        function () {
          this.setState({ showTuto: false });
        }.bind(this),
        30000
      );
    }

    if (val % 20 === 0 && val > 0) {
      if (this._isMounted) {
        this.setState({ showFocus: true });
        setTimeout(
          function () {
            Animated.sequence([
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
            ]).start();
          }.bind(this),
          1000
        );

        setTimeout(
          function () {
            if (this._isMounted) {
              this.setState({ showFocus: false });
            }
          }.bind(this),
          4000
        );
      }
    }
  }

  syncDataLive = () => {
    if (this.props.firebase && this.props.currentMatch) {
      this.props.firebase
        .match_lives(this.props.currentMatch.match_id)
        .onSnapshot((docSnapshot) => {
          // Actually the data should be only 1
          var lData = docSnapshot.data();
          // bind data
          if (lData) {
            if (this._isMounted) {
              this.setState(
                {
                  liveData: lData,
                  liveTimeValue:
                    (lData.minute ? lData.minute : 0) * 60 +
                    (lData.injury_time ? lData.injury_time : 0) * 60 +
                    parseInt(lData.second, 10),
                  updateNum: this.state.updateNum + 1,
                },
                () => {
                  this.checkEndTime();
                }
              );
            }
          }
        });
    }
  };

  checkEndTime = () => {
    if (this.props.currentMatch) {
      const time = this.state.liveTimeValue;
      const min = Math.floor(time / 60);
      const sec = time - min * 60;
      const minDisplay = min < 10 ? `0${min}` : `${min}`;
      const secDisplay = sec < 10 ? `0${sec}` : `${sec}`;
      if (this.state.liveData.status) {
        //calculate the result pronostic
        this._calculatePronostics();
        if (this.state.liveData.status === "NS") {
          this.setState({ liveTime: `Pas commencé` });
        } else if (this.state.liveData.status === "HT") {
          this.setState({ liveTime: `Mi-temps` });
        } else if (this.state.liveData.status === "FT") {
          // save the winner
          this._saveTheWinners(this.winners.length === 1);
          this.setState({ liveTime: `Terminé` }, () => {
            if (this.props.callbackMatch) {
              //send email if only 1 winner
              this.props.callbackMatch(
                this.winners,
                this.state.liveData,
                this.pronosticsSorted
              );
            }
          });
        } else {
          this.setState({
            liveTime: `${minDisplay} : ${secDisplay}`,
            liveTimeValue: time + 1,
          });
        }
      } else {
        this.setState({
          liveTime: `${minDisplay} : ${secDisplay}`,
          liveTimeValue: time + 1,
        });
      }
    }
  };

  _saveTheWinners = (sendingEmail) => {
    if (this.props.firebase && this.state.liveTime !== "Terminé") {
      //Save batch for < 550 each time
      const toSaveLength = this.pronosticsSorted.length;
      var lastIndex = 0;
      var theRestLength = toSaveLength;
      var max = 450;
      var loopDone = 0;
      while (theRestLength > 0) {
        var batch = this.props.firebase.db.batch();
        var match = this.props.firebase.match_winner(
          this.props.currentMatch.id + "_" + this.props.currentMatch.match_id
        );
        batch.set(match, this.props.currentMatch);
        for (let i = lastIndex; i < lastIndex + max; i++) {
          if (i < toSaveLength) {
            const el = this.pronosticsSorted[i];
            var doc = this.props.firebase.match_winner_ranking(
              this.props.currentMatch.id +
              "_" +
              this.props.currentMatch.match_id,
              i + 1
            );
            batch.set(doc, el);
          }
        }
        loopDone += 1;
        theRestLength = theRestLength - max;
        lastIndex = loopDone * max;
        const restLength = theRestLength;
        // Commit the batch
        const that = this;
        batch.commit().then(() => {
          console.log("updated winner then send email");
          if (restLength <= 0 && sendingEmail === true) {
            that._callSendEmailRanking();
          }
        });
      }
    }
  };

  _callSendEmailRanking = () => {
    //send email:
    const sendMailMatchWinners = this.props.firebase.functions.httpsCallable(
      "sendMailMatchWinners"
    );
    sendMailMatchWinners({
      info: {
        theme: THEME,
        rapid: ONLY_ANONYMOUS,
        loge: PROFILE_LOGE_NUMBER,
        phone: PROFILE_PHONE,
        company: PROFILE_COMPANY,
        job: PROFILE_JOB,
        dob: PROFILE_DOB,
        address: PROFILE_ADDRESS,
      },
      currentMatch: this.props.currentMatch,
      to: EMAILS_RESULTS,
    });
  };

  _getScoreDirection = (home, visitor) => {
    if (parseInt(home, 10) > parseInt(visitor, 10)) {
      return 1;
    } else if (parseInt(home, 10) === parseInt(visitor, 10)) {
      return 0;
    } else if (parseInt(home, 10) < parseInt(visitor, 10)) {
      return -1;
    } else {
      return 0;
    }
  };

  _calculatePronostics = () => {
    if (this.state.liveData.status && this.pronosticsOrigin.length > 0) {
      const homeScore = this.state.liveData.home_score || 0;
      const visitorScore = this.state.liveData.visitor_score || 0;
      const firstScore = this.state.liveData.first_score || "nil";
      const valScore = homeScore - visitorScore;
      var teamWonValue =
        valScore > 0 ? "home" : valScore < 0 ? "visitor" : "nil";
      var pronosticUpdate = this.pronosticsOrigin.map((el) => {
        const firstScorerName =
          this.state.liveData.first_scorer.player_name || "";
        const firstScorerId = this.state.liveData.first_scorer.player_id || 1;
        var firstScoreMinute = this.state.liveData.first_score_minute;
        const firstScoreMinuteNumber = this.state.liveData.first_score_minute;

        const scoreDirection = this._getScoreDirection(homeScore, visitorScore);
        const scoreAbs =
          Math.abs(parseInt(el.scoreHome, 10) - parseInt(homeScore, 10)) +
          Math.abs(parseInt(el.scoreVisitor, 10) - parseInt(visitorScore, 10));
        if (
          firstScoreMinute === 45 &&
          this.state.liveData.first_score_minute_extra > 0
        ) {
          firstScoreMinute = "ex1";
        }
        if (
          firstScoreMinute === 90 &&
          this.state.liveData.first_score_minute_extra > 0
        ) {
          firstScoreMinute = "ex2";
        }

        var firstScoreMinuteValue = el.firstScoreMinute || 0;
        if (el.firstScoreMinute === "ex1") {
          firstScoreMinuteValue = 40.5;
        }
        if (el.firstScoreMinute === "ex2") {
          firstScoreMinuteValue = 90.5;
        }

        el.type = this.props.currentMatch.type;

        // default
        el.correctTeamWon = false;
        el.scoreGapDistant = 0;
        el.totalScoreDistant = 0;
        el.firstScoreMinuteDistant = false;
        el.scoreDistant = 0;
        el.scoreDirection = this._getScoreDirection(
          el.scoreHome,
          el.scoreVisitor
        );

        el.correct_direction = false;
        el.correct_score = false;
        el.correct_scorer = false;
        el.correct_minute = false;
        el.correct_first_score = false;

        //Points reset
        el.pointForecast = 0;
        var pForecast = 0;
        if (el.type === "match") {
          if (FORECAST_FOOT_WINNER) {
            el.teamWonName =
              el.teamWon === "home"
                ? this.props.currentMatch.team_home
                : (el.teamWon === "visitor" ? this.props.currentMatch.team_visitor : "Match nul");
            el.correctTeamWon = el.teamWon === teamWonValue;
            if (el.correctTeamWon) {
              pForecast = pForecast + FORECAST_WINNER_POINT;
            }
          }
          if (FORECAST_FOOT_SCORE) {
            el.scoreDistant =
              parseInt(el.scoreHome, 10) >= 0 &&
                parseInt(el.scoreVisitor, 10) >= 0
                ? scoreAbs
                : 0; // === 1 ;//&& parseInt(el.scoreHome, 10) >= homeScore && parseInt(el.scoreVisitor, 10) >= visitorScore;
            el.correct_direction = el.scoreDirection === scoreDirection;
            el.correct_score =
              parseInt(el.scoreHome, 10) === homeScore &&
              parseInt(el.scoreVisitor, 10) === visitorScore;
            if (el.correct_score) {
              pForecast = pForecast + FORECAST_SCORE_POINT;
            }
          }
          if (FORECAST_FOOT_TOTAL_SCORE) {
            el.totalScoreDistant =
              parseInt(el.totalScore, 10) >= 0
                ? Math.abs(
                  parseInt(el.totalScore, 10) -
                  (parseInt(homeScore, 10) + parseInt(visitorScore, 10))
                )
                : 0;
            el.correct_total_score = el.totalScoreDistant === 0;
            if (el.correct_total_score) {
              pForecast = pForecast + FORECAST_TOTAL_SCORE_POINT;
            }
          }
          if (FORECAST_FOOT_FIRST_SCORER) {
            el.correct_scorer =
              (el.firstScorer
                ? el.firstScorer.name.trim() === firstScorerName.trim()
                : false) ||
              (el.firstScorer
                ? el.firstScorer.player_id === `${firstScorerId}`
                : false);
            if (el.correct_scorer) {
              pForecast = pForecast + FORECAST_FIRST_SCORER_POINT;
            }
          }
          if (FORECAST_FOOT_FIRST_SCORE_MINUTE) {
            el.firstScoreMinuteDistant =
              Math.abs(firstScoreMinuteValue - firstScoreMinuteNumber) || false;
            el.correct_minute = el.firstScoreMinute === firstScoreMinute;
            if (el.correct_minute) {
              pForecast = pForecast + FORECAST_FIRST_SCORE_MINUTE_POINT;
            }
          }
        }

        if (el.type.includes("rugby")) {
          if (FORECAST_RUGBY_WINNER) {
            el.correctTeamWon = el.teamWon === teamWonValue;
            if (el.correctTeamWon) {
              pForecast = pForecast + FORECAST_WINNER_POINT;
            }
          }
          if (FORECAST_RUGBY_TOTAL_SCORE) {
            el.totalScoreDistant =
              parseInt(el.totalScore, 10) >= 0
                ? Math.abs(
                  parseInt(el.totalScore, 10) -
                  (parseInt(homeScore, 10) + parseInt(visitorScore, 10))
                )
                : 0;

            if (el.totalScoreDistant === 0) {
              pForecast = pForecast + FORECAST_TOTAL_SCORE_POINT;
            }
          }
          if (FORECAST_RUGBY_SCORE_GAP) {
            el.scoreGapDistant =
              parseInt(el.scoreGap, 10) >= 0
                ? Math.abs(
                  parseInt(el.scoreGap, 10) -
                  Math.abs(
                    parseInt(homeScore, 10) - parseInt(visitorScore, 10)
                  )
                )
                : 0;

            if (el.scoreGapDistant === 0) {
              pForecast = pForecast + FORECAST_SCORE_GAP_POINT;
            }
          }
          if (FORECAST_RUGBY_FIRST_SCORE) {
            el.correct_first_score = el.firstScore === firstScore;
            if (el.correct_first_score) {
              pForecast = pForecast + FORECAST_FIRST_SCORE_POINT;
            }
          }
        }

        el.pointForecast = pForecast;

        el.point =
          (el.correctTeamWon === true ? FORECAST_WINNER_POINT : 0) +
          (el.scoreGapDistant >= 0
            ? FORECAST_SCORE_GAP_POINT - el.scoreGapDistant
            : 0) +
          (el.totalScoreDistant >= 0
            ? FORECAST_TOTAL_SCORE_POINT - el.totalScoreDistant
            : 0) +
          (el.correct_first_score === true ? FORECAST_FIRST_SCORE_POINT : 0) +
          // (el.correct_direction === true ? 4 : 0) +
          (el.correct_score === true ? FORECAST_SCORE_POINT : 0);

        // +
        // (el.correct_scorer === true ? 4 : 0) +
        // (el.correct_minute === true ? 2 : 0) - el.scoreDistant
        el.color = "default";

        return el;
      });

      // sorting with points
      this.pronosticsSorted = pronosticUpdate.slice(0);
      this.pronosticsSorted.sort((a, b) => {
        return (
          b.correctTeamWon - a.correctTeamWon ||
          a.scoreGapDistant - b.scoreGapDistant ||
          b.point - a.point ||
          a.scoreDistant - b.scoreDistant ||
          b.correct_scorer - a.correct_scorer ||
          b.correct_minute - a.correct_minute ||
          a.firstScoreMinuteDistant - b.firstScoreMinuteDistant
        );
      });

      const firstPlace = this.pronosticsSorted[0];

      // if (firstPlace.point >= 0) {
      var winnerPlaces = this.pronosticsSorted.filter((el) => {
        return (
          el.point === firstPlace.point &&
          el.correct_first_score === firstPlace.correct_first_score &&
          el.correct_scorer === firstPlace.correct_scorer &&
          el.correct_minute === firstPlace.correct_minute &&
          el.scoreDistant === firstPlace.scoreDistant &&
          el.firstScoreMinuteDistant === firstPlace.firstScoreMinuteDistant
        );
      });
      // if (homeScore === 0 && visitorScore === 0) {
      //   winnerPlaces = this.pronosticsSorted.filter(el => {
      //     return (
      //       parseInt(el.scoreHome, 10) === 0 &&
      //       parseInt(el.scoreVisitor, 10) === 0
      //     );
      //   });
      // }
      this.winners = winnerPlaces;
      // console.log("winnerPlaces => ", winnerPlaces);
      // console.log("firstPlace => ", firstPlace);

      var first100 = this.pronosticsSorted.slice(0, 42);
      // console.log("first100 => ", first100);
      //check if not same elements last time
      if (this.lastFirst100s.length === 0) {
        first100 = this._array_shuffle(first100);
        this.lastFirst100s = first100.slice(0);
      } else {
        if (!this._array_same_elements(first100, this.lastFirst100s)) {
          // then
          first100 = this._array_shuffle(first100);
          this.lastFirst100s = first100.slice(0);
        }
      }
      var thirdPoint = null;
      var thirdDistant = null;
      var thirdScorer = null;
      var thirdMinute = null;
      var thirdScoreDistant = null;
      var third1stScore = null;
      // Score !== 0 - 0
      if (winnerPlaces.length >= 3) {
        // found 3 firsts
        pronosticUpdate = this.lastFirst100s.map((el) => {
          if (homeScore === 0 && visitorScore === 0) {
            if (
              el.point === firstPlace.point &&
              el.scoreDistant === firstPlace.scoreDistant
            ) {
              el.color = "1st";
            } else {
              el.color = "red";
            }
          } else {
            if (
              el.point === firstPlace.point &&
              el.correct_first_score === firstPlace.correct_first_score &&
              el.correct_scorer === firstPlace.correct_scorer &&
              el.correct_minute === firstPlace.correct_minute &&
              el.scoreDistant === firstPlace.scoreDistant &&
              el.firstScoreMinuteDistant === firstPlace.firstScoreMinuteDistant
            ) {
              el.color = "1st";
            } else {
              el.color = "red";
            }
          }
          return el;
        });
      } else if (winnerPlaces.length === 2) {
        // found 2 firsts , next should be 3rd
        if (this.state.pronostics.length > 2) {
          const thirdPlace = this.pronosticsSorted[2];
          // console.log("thirdPlace => ", thirdPlace);
          thirdPoint = thirdPlace.point;
          thirdDistant = thirdPlace.firstScoreMinuteDistant;
          thirdScoreDistant = thirdPlace.scoreDistant;
          thirdScorer = thirdPlace.correct_scorer;
          thirdMinute = thirdPlace.correct_minute;
          third1stScore = thirdPlace.correct_first_score;
        }
        pronosticUpdate = this.lastFirst100s.map((el) => {
          if (homeScore === 0 && visitorScore === 0) {
            if (
              el.point === firstPlace.point &&
              el.scoreDistant === firstPlace.scoreDistant
            ) {
              el.color = "1st";
            } else if (
              el.point === thirdPoint &&
              el.scoreDistant === thirdScoreDistant
            ) {
              el.color = "3rd";
            } else {
              el.color = "red";
            }
          } else {
            if (
              el.point === firstPlace.point &&
              el.correct_first_score === firstPlace.correct_first_score &&
              el.correct_scorer === firstPlace.correct_scorer &&
              el.correct_minute === firstPlace.correct_minute &&
              el.scoreDistant === firstPlace.scoreDistant &&
              el.firstScoreMinuteDistant === firstPlace.firstScoreMinuteDistant
            ) {
              el.color = "1st";
            } else if (
              el.point === thirdPoint &&
              el.correct_first_score === third1stScore &&
              el.correct_scorer === thirdScorer &&
              el.correct_minute === thirdMinute &&
              el.scoreDistant === thirdScoreDistant &&
              el.firstScoreMinuteDistant === thirdDistant
            ) {
              el.color = "3rd";
            } else {
              el.color = "red";
            }
          }

          return el;
        });
      } else {
        //if (winnerPlaces.length === 1) {
        // found 1 first , next should be 2rd and 3rd
        var secondPoint = null;
        var secondDistant = null;
        var secondScoreDistant = null;
        var secondScorer = null;
        var secondMinute = null;
        var second1stScore = null;
        thirdPoint = null;
        thirdDistant = null;
        thirdScoreDistant = null;
        thirdScorer = null;
        thirdMinute = null;
        if (this.state.pronostics.length > 1) {
          const secondPlace = this.pronosticsSorted[1];
          if (secondPlace) {
            secondPoint = secondPlace.point || 0;
            secondDistant = secondPlace.firstScoreMinuteDistant;
            secondScoreDistant = secondPlace.scoreDistant;
            secondScorer = secondPlace.correct_scorer;
            secondMinute = secondPlace.correct_minute;
            second1stScore = secondPlace.correct_first_score;
          }
        }
        if (secondPoint !== null) {
          //check howmany of 2nd places
          const secondWinnerPlaces = this.pronosticsSorted.filter((el) => {
            if (homeScore === 0 && visitorScore === 0) {
              return (
                el.point === secondPoint &&
                el.scoreDistant === secondScoreDistant
              );
            } else {
              return (
                el.point === secondPoint &&
                el.correct_first_score === second1stScore &&
                el.correct_scorer === secondScorer &&
                el.correct_minute === secondMinute &&
                el.scoreDistant === secondScoreDistant &&
                el.firstScoreMinuteDistant === secondDistant
              );
            }
          });
          if (secondWinnerPlaces.length > 1) {
            // found 2nd and 3rd
          } else {
            // need to find 3rd
            if (this.state.pronostics.length > 2) {
              const thirdPlace = this.pronosticsSorted[2];
              if (thirdPlace) {
                thirdPoint = thirdPlace.point || 0;
                thirdDistant = thirdPlace.firstScoreMinuteDistant;
                thirdScoreDistant = thirdPlace.scoreDistant;
                thirdScorer = thirdPlace.correct_scorer;
                thirdMinute = thirdPlace.correct_minute;
                third1stScore = thirdPlace.correct_first_score;
              }
            }
          }
        }

        pronosticUpdate = this.lastFirst100s.map((el) => {
          if (homeScore === 0 && visitorScore === 0) {
            if (
              el.point === firstPlace.point &&
              el.scoreDistant === firstPlace.scoreDistant
            ) {
              el.color = "1st";
            } else if (
              el.point === secondPoint &&
              el.scoreDistant === secondScoreDistant
            ) {
              el.color = "2nd";
            } else if (
              el.point === thirdPoint &&
              el.scoreDistant === thirdScoreDistant
            ) {
              el.color = "3rd";
            } else {
              el.color = "red";
            }
          } else {
            if (
              el.point === firstPlace.point &&
              el.correct_first_score === firstPlace.correct_first_score &&
              el.correct_scorer === firstPlace.correct_scorer &&
              el.correct_minute === firstPlace.correct_minute &&
              el.scoreDistant === firstPlace.scoreDistant &&
              el.firstScoreMinuteDistant === firstPlace.firstScoreMinuteDistant
            ) {
              el.color = "1st";
            } else if (
              el.point === secondPoint &&
              el.correct_first_score === second1stScore &&
              el.correct_scorer === secondScorer &&
              el.correct_minute === secondMinute &&
              el.scoreDistant === secondScoreDistant &&
              el.firstScoreMinuteDistant === secondDistant
            ) {
              el.color = "2nd";
            } else if (
              el.point === thirdPoint &&
              el.correct_first_score === third1stScore &&
              el.correct_scorer === thirdScorer &&
              el.correct_minute === thirdMinute &&
              el.scoreDistant === thirdScoreDistant &&
              el.firstScoreMinuteDistant === thirdDistant
            ) {
              el.color = "3rd";
            } else {
              el.color = "red";
            }
          }

          return el;
        });
      }
      // }
      // console.log(pronosticUpdate);

      if (this._isMounted) {
        this.setState({
          pronostics: pronosticUpdate,
          updateNum: this.state.updateNum + 1,
        });
      }
    }
  };

  // assumes array elements are primitive types
  _array_same_elements = (a1, a2) => {
    let superSet = {};
    for (let i = 0; i < a1.length; i++) {
      const e = a1[i].userId;
      superSet[e] = 1;
    }
    for (let i = 0; i < a2.length; i++) {
      const e = a2[i].userId;
      if (!superSet[e]) {
        return false;
      }
      superSet[e] = 2;
    }
    for (let e in superSet) {
      if (superSet[e] === 1) {
        return false;
      }
    }
    return true;
  };

  _array_shuffle = (array) => {
    var m = array.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  headerTextLeft = () => {
    var out = "";
    if (this.props.currentMatch.type === "match") {
      if (FORECAST_FOOT_FIRST_SCORER) {
        out = `PREMIER BUTEUR :${this.state.liveData.first_scorer
          ? this.state.liveData.first_scorer.player_name
            ? this.state.liveData.first_scorer.player_name
            : " -- "
          : " -- "
          }`;
      }
    } else {
      if (FORECAST_RUGBY_SCORE_GAP) {
        out = `ÉCART DE PTS :  ${Math.abs(
          (this.state.liveData.home_score
            ? this.state.liveData.home_score
            : 0) -
          (this.state.liveData.visitor_score
            ? this.state.liveData.visitor_score
            : 0)
        )}`;
      }
    }
    return out;
  };

  headerTextCenter = () => {
    var out = "";
    if (this.props.currentMatch.type === "match") {
      if (FORECAST_FOOT_TOTAL_SCORE) {
        out = `NOMBRE DE BUTS AU TOTAL : ${(this.state.liveData.home_score
          ? this.state.liveData.home_score
          : 0) +
          (this.state.liveData.visitor_score
            ? this.state.liveData.visitor_score
            : 0)
          }`;
      }
    } else {
      if (FORECAST_RUGBY_TOTAL_SCORE) {
        out = `NOMBRE DE POINTS AU TOTAL : ${(this.state.liveData.home_score
          ? this.state.liveData.home_score
          : 0) +
          (this.state.liveData.visitor_score
            ? this.state.liveData.visitor_score
            : 0)
          }`;
      }
    }
    return out;
  };

  headerTextRight = () => {
    var out = "";
    if (this.props.currentMatch.type === "match") {
      if (FORECAST_FOOT_FIRST_SCORE_MINUTE) {
        out = `MINUTE DU PREMIER BUT : ${this.state.liveData.first_score_minute
          ? this.state.liveData.first_score_minute
          : " -- "
          }`;
      }
    } else {
      if (FORECAST_RUGBY_FIRST_SCORE) {
        out = `1ERS POINTS :  ${this.state.liveData.first_score === "home"
          ? this.props.currentMatch.team_home
          : this.state.liveData.first_score === "visitor"
            ? this.props.currentMatch.team_visitor
            : "Match nul"
          }`;
      }
    }
    return out;
  };

  render() {
    const { animations, animate } = this.state;

    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    const should_hide_qrcode = this.props.navigation.state.params
      ? this.props.navigation.state.params.hasOwnProperty("hideqr")
        ? this.props.navigation.state.params.hideqr === "true"
        : HIDE_QRCODE
      : HIDE_QRCODE;

    return (
      <View
        style={{
          backgroundColor: MAIN_COLOR,
          flex: 1,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
      >
        {this.state.screen === "countdown" && (
          <View
            style={{
              flex: 1,
              alignSelf: "center",
              width: "100%",
            }}
          >
            <ImageBackground
              style={{
                flex: 1,
                flexDirection: "row",
                backgroundColor: this.props.navigation.state.params
                  ? this.props.navigation.state.params.bgc
                    ? this.props.navigation.state.params.bgc
                    : BG_TELE
                  : BG_TELE,
                justifyContent: 'space-between'
              }}
              source={
                this.props.navigation.state.params
                  ? this.props.navigation.state.params.bgtele
                    ? this.props.navigation.state.params.bgtele
                    : THEME_BACKGROUND_TELE
                  : THEME_BACKGROUND_TELE
              }
              resizeMode={"cover"}
            >
              {this.props.currentMatch && (<View
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: "column",
                  height: screenHeight,
                  paddingLeft: 30,
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* {THEME_HEADER_LEFT && <Image
                  style={{
                    marginTop: 30,
                    alignSelf: "center",
                    width: getWidthFromHDMargin(
                      300,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    height: getHeightFromHDMargin(
                      200,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                  }}
                  resizeMode={"contain"}
                  source={
                    this.props.navigation.state.params
                      ? this.props.navigation.state.params.hl
                        ? this.props.navigation.state.params.hl
                        : THEME_HEADER_LEFT
                      : THEME_HEADER_LEFT
                  }
                />} */}


                {/* <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    height: screenHeight,
                  }}
                > */}
                  {/* <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",

                      }}
                    >
                      <Animated.Text
                        style={{
                          color: TEXT_TV_COLOR,
                          textAlign: "center",
                          fontSize: getHeightFromHDMargin(
                            80,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                          fontWeight: "bold",
                        }}
                      >
                        {this.props.currentMatch.title
                          ? this.props.currentMatch.title
                          : "PRONOSTICS"}
                      </Animated.Text>
                      <Animated.View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",

                          height: getHeightFromHDMargin(
                            120,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                          width: getWidthFromHDMargin(
                            290,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                          justifyContent: "space-between",
                          zIndex: 1000,
                        }}
                      >
                        {this.props.currentMatch.team_home_logo && (
                          <Image
                            style={{
                              width: getWidthFromHDMargin(
                                120,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                              height: getHeightFromHDMargin(
                                120,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                            }}
                            source={this.props.currentMatch.team_home_logo}
                            resizeMode="contain"
                          />
                        )}
                        {this.props.currentMatch.team_visitor_logo && (
                          <Image
                            style={{
                              width: getWidthFromHDMargin(
                                120,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                              height: getHeightFromHDMargin(
                                120,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                            }}
                            source={this.props.currentMatch.team_visitor_logo}
                            resizeMode="contain"
                          />
                        )}
                      </Animated.View>
                    </View> */}
                  <Animated.View
                    style={{
                      marginRight: getWidthFromHDMargin(
                        40,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      marginTop: getWidthFromHDMargin(
                        130,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      opacity: animate ? animations.fadeOut : 1,
                      alignSelf: 'stretch'
                    }}
                  >
                    {/* {this.props.currentMatch && SHOW_MINUTER &&
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <View
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 10,
                              backgroundColor: "#0091FF",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: 5,
                              marginBottom: 5,
                            }}
                          >
                            <Image
                              style={{
                                width: 30,
                                height: 30,
                              }}
                              source={this.props.currentMatch.type === "match_rugby" ? require("../../assets/images/menu/rugby.svg") : require("../../assets/images/menu/football.svg")}
                            />
                          </View>

                          <Text
                            style={{
                              color: TEXT_TV_COLOR,
                              fontSize: RF(3),
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginBottom: 10,
                            }}
                          >
                            Début du match dans
                          </Text>
                        </View>} */}
                    {this.props.currentMatch &&
                      <TeleCountDown
                        type={2}
                        timeLeft={this.props.timeLeft}
                        callbackTimer={this.callbackTimer}
                      />}

                    {this.props.currentMatch && SHOW_NULBER_ONLINE && (
                      <NumberOnline
                        type="match"
                        mode={2}
                        logeName={this.props.currentMatch.subtitle}
                        {...this.props}
                        style={{ top: '20px' } }
                      />
                    )}
                  </Animated.View>
                {/* </View> */}



              </View>)}

              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  height: screenHeight,
                  // width: getWidthFromHDMargin(1080, mtop, mright, mbottom, mleft)
                }}
              >
                {should_hide_qrcode && (
                  <Animated.View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: 25,
                      width: getWidthFromHDMargin(
                        740,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      height: getHeightFromHDMargin(
                        350,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "94%",
                        }}
                      >
                        <Text
                          style={{
                            color: MAIN_COLOR,
                            fontSize: RF(4),
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: 20,
                          }}
                        >
                          Connectez-vous sur :
                        </Text>
                        <Text
                          style={{
                            color: THEME === "ogcnice-stade" ? "#fff" : "#000",
                            fontSize:
                              (getWidthFromHDMargin(
                                700,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) /
                                MAIN_URL.length) *
                              1.65,
                            fontWeight: "bold",
                          }}
                        >
                          {MAIN_URL.toUpperCase()}
                        </Text>
                      </View>
                    </View>
                  </Animated.View>
                )}

                {!should_hide_qrcode && (
                  <Animated.View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: QRCODE_BACKGROUND_COLOR,
                          height: screenHeight,
                          width: screenHeight,
                          borderRadius: 30,
                        }}
                      >
                        {this.state.showFocus && (
                          <ScaleView
                            style={{
                              position: "absolute",
                              backgroundColor: "green",
                              opacity: this.state.blinkAnimation,
                            }}
                            delay={500}
                            duration={500}
                            size={screenHeight}
                          ></ScaleView>
                        )}
                        {this.state.showFocus && (
                          <FadeView
                            style={{ position: "absolute" }}
                            delay={1000}
                            duration={300}
                          >
                            <Image
                              style={{
                                width: screenHeight,
                                height: screenHeight,
                              }}
                              source={require("../../assets/tutor/focus.png")}
                              resizeMode="contain"
                            />
                          </FadeView>
                        )}

                        <QRCode
                          value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (this.props.currentMatch.type === "match_rugby" ? "/Rugby" : "/Forecast") + "/?mid=" + this.props.currentMatch.id)}
                          fgColor={QRCODE_FOREGROUND_COLOR}
                          bgColor={QRCODE_BACKGROUND_COLOR}
                          size={screenHeight * 0.95}
                        />
                      </View>
                    </View>
                  </Animated.View>
                )}
              </View>
            </ImageBackground>
          </View>
        )}

        {this.state.screen === "live" && this.props.currentMatch && (
          <View style={styles.liveContainer}>
            {this.props.currentMatch.type === "match_rugby" &&
              (this.state.liveData.status === "NS" ||
                this.state.liveData.status === "HT" ||
                this.state.liveData.status === "FT") && (
                <View style={styles.liveTime} key={this.state.liveTime}>
                  <Text style={styles.liveTimeText}>{this.state.liveTime}</Text>
                </View>
              )}
            {this.props.currentMatch.type === "match_rugby" &&
              this.state.liveData.status !== "NS" &&
              this.state.liveData.status !== "HT" &&
              this.state.liveData.status !== "FT" && (
                <View style={styles.liveTime} key={this.state.liveTime}>
                  <Text style={styles.liveTimeText}>• LIVE</Text>
                </View>
              )}
            {this.props.currentMatch.type === "match" && (
              <View style={styles.liveTime} key={this.state.liveTime}>
                <Text style={styles.liveTimeText}>{this.state.liveTime}</Text>
              </View>
            )}
            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveText}>
                {this.state.liveData.home_score
                  ? this.state.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveText}>-</Text>
              <Text style={styles.liveText}>
                {this.state.liveData.visitor_score
                  ? this.state.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>
            <View key={this.state.updateNum} style={styles.pronosticContainer}>
              <View style={styles.pronosticHeader}>
                {this.headerTextLeft().length > 0 && (
                  <Text style={styles.pronosticTextLeft}>
                    {this.headerTextLeft()}
                  </Text>
                )}
                {this.headerTextCenter().length > 0 && (
                  <Text style={styles.pronosticTextLeft}>
                    {this.headerTextCenter()}
                  </Text>
                )}
                {this.headerTextRight().length > 0 && (
                  <Text style={styles.pronosticTextRight}>
                    {this.headerTextRight()}
                  </Text>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <MatchPronostic
                  tall={screenHeight * 0.66}
                  key={this.state.pronostics.length}
                  data={this.state.pronostics}
                />
              </View>
            </View>
          </View>
        )}

        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1,
          }}
          source={THEME_BANNER_FULL}
          resizeMode={"stretch"}
        />

        {this.state.showTuto &&
          (this.props.navigation.state.params
            ? this.props.navigation.state.params.stuto
              ? this.props.navigation.state.params.stuto === "true"
              : SHOW_TUTO
            : SHOW_TUTO) === true && (
            <View
              style={{
                width: screenWidth,
                height: screenHeight,
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TutorTele {...this.props} />
            </View>
          )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  liveContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  liveTime: {
    marginTop: getHeightPercent(5),
    justifyContent: "center",
    alignItems: "center",
    borderColor: BORDER_COLOR,
    borderWidth: BORDER_WIDTH,
    borderRadius: getHeightPercent(2),
  },
  liveTimeText: {
    fontSize: getHeightPercent(2),
    textAlign: "center",
    color: TEXT_TV_COLOR,
    marginTop: getHeightPercent(1),
    marginBottom: getHeightPercent(1),
    marginLeft: getHeightPercent(2),
    marginRight: getHeightPercent(2),
  },
  liveLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: getWidthPercent(80),
  },
  liveLogo: {
    width: getWidthPercent(7),
    height: getWidthPercent(7),
    margin: getWidthPercent(1),
  },
  liveText: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(5),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },
  liveTeamTextLeft: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(20),
  },
  liveTeamTextRight: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(20),
  },
  liveTextScorer: {
    color: TEXT_TV_COLOR,
    fontSize: getWidthPercent(4),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1),
  },
  pronosticContainer: {
    width: getWidthPercent(90),
  },
  pronosticHeader: {
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    flex: 1,
    paddingBottom: getHeightPercent(1),
  },
  pronosticTextLeft: {
    color: "#ccc",
    textTransform: "capitalize",
    fontSize: getWidthPercent(1),
  },
  pronosticTextRight: {
    color: "#ccc",
    textTransform: "capitalize",
    fontSize: getWidthPercent(1),
  },
});

export default TeleMatchHome3;
