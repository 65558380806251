import React from "react";
import { TIME_PAUSE, TIME_EXTRA_ANIM } from "../../constants/const";
import {
  OPTIONS_NO_TV_RESULT_MOBILE,
  OPTIONS_QUESTIONS_TV_AND_RESULTS,
  OPTIONS_NO_COUNTER
} from "../../constants/routes";

export class BaseSondage extends React.Component {
  _isMounted = false;
  timeoutId = null;
  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuestion = null;
    this.currentAnswers = null;
    this.surveyResults = null;
    this.timePast = 0;
    this.state = {
      screen: 'CountDown',
      shouldShow: true,
      currentSurvey: null,
      currentQuestion: null,
      displayResult: false,
      timeLeft: 0,
      updateNum: 0
    };
  }

  checkSurveyAndQuestion = forceNext => {
    var currentSurveyJSON = null;
    if(this.props.currentSA && (this.props.mode === 'async')){
      currentSurveyJSON = this.props.currentSA;
    }else{
      const currentSurvey = window.localStorage.getItem("currentSurvey");
      currentSurveyJSON = JSON.parse(currentSurvey);
    }
    
    if (currentSurveyJSON !== null) {
      this.currentSurvey = currentSurveyJSON
      // console.log("this.currentSurvey = ", this.currentSurvey);
      // Process the time line for survey
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      const timeLeft = this.currentSurvey.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentSurvey;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });

        var currentOrder = this.currentQuestion !== null ? this.currentQuestion.order : 0;
        this.currentQuestion = null;
        
        //continue last
        if(this.currentSurvey.display_type === OPTIONS_NO_COUNTER){
          const surveyTempo = window.localStorage.getItem('surveyTempo');
          if(surveyTempo){
            const surveyTempoJSON = JSON.parse(surveyTempo);
            // console.log(surveyTempoJSON);
            if(surveyTempoJSON.surveyId === this.currentSurvey.id){
              const lastVer = surveyTempoJSON.version ? surveyTempoJSON.version : 0;
              const currentVer = this.currentSurvey.version ? this.currentSurvey.version : 0;
              if(lastVer < currentVer){
                window.localStorage.removeItem('surveyTempo');
              }else{
                if(surveyTempoJSON.status === 'done'){
                  this.setState({
                    screen: "Thankyou",
                    currentSurvey: this.currentSurvey,
                    updateNum: this.state.updateNum + 1
                  });
                  return true;
                }else{
                  currentOrder = surveyTempoJSON.last_order;
                }
              }  
            }
          }
        }

        if (forceNext === true || (currentOrder > 0 && this.currentSurvey.display_type === OPTIONS_NO_COUNTER)) {
          // console.log("forceNext = ", forceNext);
          if (currentOrder >= questions.length) {
          } else {
            // console.log("should go to " + (currentOrder + 1));
            const question = questions.filter(e => {
              return e.order === currentOrder + 1;
            })[0];
            if (question) {
              this.processDataWithQuestion(question, currentOrder);
            }
          }
        } else {
          this.timePast = this.currentSurvey.start.seconds;
          for (let i = 0; i < questions.length; i++) {
            const question = questions.filter(e => {
              return e.order === i + 1;
            })[0];

            if (question) {
              const out = this.processDataWithQuestion(question, i);
              if (out === true) {
                break;
              }
            }
          }
        }

        if (this.currentQuestion === null) {
          if (this.props.navigation.state.routeName !== "Thankyou") {
            if(this.props.mode === 'async'){
              this.setState({
                screen: "Thankyou",
                currentSurvey: this.currentSurvey,
                updateNum: this.state.updateNum + 1
              });
            }else{
              this.props.navigation.navigate("Thankyou");
            }
          }
        }

      } else {
        console.log('set screen countdown')
        this.setState({
          screen: this.props.mode !== 'async' ? "CountDown" : "CountDownAsync",
          timeLeft: timeLeft,
          currentSurvey: this.currentSurvey,
          updateNum: this.state.updateNum + 1
        });
      }
    } else {
      // navigate to screen no SURVEY
      // console.log("this.props.mode = ", this.props.mode);
      if(this.props.mode !== 'async'){
        this.props.navigation.navigate("Home");
      }
    }
  };

  processDataWithQuestion = (question, index) => {
    // console.log('question =' , question);
    if (question === null) {
      return false;
    }
    // console.log("question",question);
    // console.log("index",index);
    var time_pause = 0;
    if (
      this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
      this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
    ) {
      time_pause = TIME_PAUSE;
    }
    // console.log("time_pause",time_pause);
    const qTime = question ? question.time : 0;
    // console.log("qTime",qTime);
    if (index === 0) {
      this.timePast = this.timePast + qTime + TIME_EXTRA_ANIM;
    } else {
      this.timePast = this.timePast + time_pause + qTime + TIME_EXTRA_ANIM;
    }
    const timePastWithResult = this.timePast + time_pause + TIME_EXTRA_ANIM;
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );

    if (
      now <= timePastWithResult ||
      this.currentSurvey.display_type === OPTIONS_NO_COUNTER
    ) {
      this.currentQuestion = question;
      this.currentQuestion.timeLeft = this.timePast - now; //>  this.currentQuestion.time ? this.currentQuestion.time : (timePast - now);
      this.currentQuestion.displayResult =
        now > this.timePast && time_pause > 0;
      this.currentQuestion.displayResultTimeLeft = timePastWithResult - now;
      this.currentQuestion.timeEnd = this.timePast;
      if (this.currentQuestion.displayResult === true) {
        if (
          this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
          this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
        ) {
          this.recalculateRanking();
        }
      }
      if (this.currentQuestion !== null) {
        if (this.state.currentQuestion === null) {
          this.bindData();
        } else if (this.state.currentQuestion !== null) {
          if (
            this.currentQuestion !== null &&
            this.currentQuestion.id !== this.state.currentQuestion.id
          ) {
            this.bindData();
          } else if (
            this.currentQuestion.id === this.state.currentQuestion.id &&
            this.currentQuestion.displayResult !==
              this.state.currentQuestion.displayResult
          ) {
            this.bindData();
          }
        }
      }
      return true;
    }

    return false;
  };

  bindData = () => {
    this.setState({
      screen: "Question",
      currentSurvey: this.currentSurvey,
      currentQuestion: this.currentQuestion,
      displayResult: this.currentQuestion.displayResult,
      timeLeft: this.currentQuestion.displayResult
        ? this.currentQuestion.displayResultTimeLeft
        : this.currentQuestion.timeLeft
    });
  };

  recalculateRanking = () => {
    // const currentAnswers = window.localStorage.getItem("currentSurveyAnwsers");
    if (this.surveyResults !== null && this.currentSurvey !== null) {
      
      if (this.currentQuestion !== null) {
        var currentQuestionResult = null ;
        
        currentQuestionResult = this.surveyResults.filter(q => {
          return q.id === this.currentQuestion.id;
        });

        if (currentQuestionResult.length > 0) {
          currentQuestionResult = currentQuestionResult[0];
          currentQuestionResult.displayResult = true;
          if (
            this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
            this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
          ) {
            currentQuestionResult.displayResultTimeLeft = TIME_PAUSE;
          }else{
            currentQuestionResult.displayResultTimeLeft = 0;
          }
          
          //re-calculate the percent
          for (let i = 0; i < currentQuestionResult.answers.length; i++) {
            const res = currentQuestionResult.answers[i];
            
            currentQuestionResult.answers[i].withResult = true;
            currentQuestionResult.answers[i].percent =
              currentQuestionResult.total_answered > 0
                ? parseInt(currentQuestionResult[`total_answered_${res.id}`], 10) /
                  currentQuestionResult.total_answered
                : 0;
          }
        }else{
          currentQuestionResult = this.currentQuestion;
          for (let i = 0; i < currentQuestionResult.answers.length; i++) {
            currentQuestionResult.answers[i].withResult = true;
            currentQuestionResult.answers[i].percent = 0;
          }
        }
        this.currentQuestion = currentQuestionResult;
      }
    }

  };

  componentDidMount() {
    this._isMounted = true;
    this.checkSurveyAndQuestion();
    if (this.currentSurvey !== null) {
      if (
        this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
        this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
      ) {
        this.props.firebase
          .currentResponeseQuery(`${this.currentSurvey.id}`)
          .onSnapshot(
            snapshot => {
              // console.log("snapshot.size=", snapshot);
              if (snapshot.size <= 0) {
                window.localStorage.removeItem("currentSurveyAnwsers");
                window.localStorage.removeItem("currentSurveyResults");
              }else{
                var arrDataQ = [];
                snapshot.forEach(data => {
                  arrDataQ.push(data.data());
                });
                window.localStorage.setItem(
                  "currentSurveyResults",
                  JSON.stringify(arrDataQ)
                );
                this.surveyResults = arrDataQ;
                this.recalculateRanking();
              }
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
      }
      
      // wakeup
      const calculateSurvey = this.props.firebase.functions.httpsCallable(
        "calculateSondage"
      );
      calculateSurvey({
        surveyId: this.currentSurvey.id,
        questionId: "wakeup",
        userData: {
          answerId : "wakeup"
        }
      }).then(re => {
        console.log("calculateSurvey wakeup");
      });

    }
    this.timeoutId = setTimeout(() => {
      this.setState({ shouldShow: true });
    }, 1000);
  }
  componentWillUnmount() {
    if(this.timeoutId){
      clearTimeout(this.timeoutId);
    }
    this._isMounted = false;
  }
}

export default BaseSondage;
