import React, { Component } from "react";
import {
  Text,
  View,
  ImageBackground,
  Animated,
  Easing,
  Dimensions,
  Image,
} from "react-native";
import {
  MAIN_COLOR,
  MAIN_URL,
  BG_TELE,
  THEME_BANNER_FULL,
  THEME_BACKGROUND_TELE,
  THEME_HEADER_LEFT,
  TEXT_TV_COLOR,
  SHOW_TUTO,
  HIDE_QRCODE,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  BUTTON_COLOR,
  SHOW_QRCODE_DUEL,
  EMAILS_RESULTS,
  QRCODE_FOREGROUND_COLOR,
  QRCODE_BACKGROUND_COLOR,
  ONLY_ANONYMOUS,
  MAIN_DYNAMIC_LINK,
  SHOW_NULBER_ONLINE,
  SHOW_MINUTER,
} from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import {
  getWidthFromHDMargin,
  getHeightFromHDMargin,
} from "../../libs/list_picker/ratio";

import TutorTele from "./Tutor/tutor";
import ScaleView from "../Anim/scaleView";
import FadeView from "../Anim/FadeView";
import CountDownCircle from "../CountDown/circle";
import FadeSlideView from "../Anim/FadeSlideView";
import TeleHeader from "./Elements/header";

export class DuelHome extends Component {
  springSize = new Animated.Value(3);
  setted = false;
  _isMounted = false;
  pronosticsOrigin = [];
  pronosticsSorted = [];
  lastFirst100s = [];
  winners = [];

  constructor(props) {
    super(props);
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
      1000
    );
    this.state = {
      timeLeft: props.currentDuel.start.seconds - now,
      timeLeftVote: props.timeLeft + parseInt(props.currentDuel.duration, 10),
      currentDuel: props.currentDuel,
      animate: false,
      showTuto: false,
      showFocus: false,
      blinkAnimation: new Animated.Value(0.2),
      animations: {
        bgWidth: new Animated.Value(80),
        bgMarginTop: new Animated.Value(100),
        bgMarginBottom: new Animated.Value(200),
        textMarginBottom: new Animated.Value(90),
        fadeOut: new Animated.Value(1),
        widthLeft: new Animated.Value(0),
        widthRight: new Animated.Value(0),
      },
      screen: "countdown",
      timeEnd: 0,
      liveData: {},
      updateNum: 0,
      totalVotes: 0,
      vote1: 0,
      vote2: 0,
      vote1percent: 0.5,
      vote2percent: 0.5,
    };
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);


  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut,
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 550,
        delay: 0,
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0,
      }),
    ]);

  callbackTimerForEnd(val) {
    if (this.props.callbackTimerForEnd) {
      this.props.callbackTimerForEnd(val);
    }
  }

  callbackTimer(val) {
    // console.log("callbackTimer =>", val);
    //correct the timeLeft
    if (this.props.callbackTimer) {
      this.props.callbackTimer(val);
    }
    //Do animation
    if (val <= 0) {
      this.setState({ animate: true });
      const {
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut,
      } = this.state.animations;
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
        1000
      );
      this.doAnimation({
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut,
      }).start(() => {
        this.setState({
          screen: "live",
          timeLeftVote:
            this.state.currentDuel.start.seconds +
            parseInt(this.state.currentDuel.duration, 10) -
            now,
        });
        this.syncDataLive();
      });
    }

    if (val % 45 === 0 && val > 60) {
      this.setState({ showTuto: true });
      setTimeout(
        function () {
          this.setState({ showTuto: false });
        }.bind(this),
        30000
      );
    }

    if (val % 20 === 0 && val > 0) {
      if (this._isMounted) {
        this.setState({ showFocus: true });
        setTimeout(
          function () {
            Animated.sequence([
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0,
                duration: 200,
              }),
              Animated.timing(this.state.blinkAnimation, {
                toValue: 0.2,
                duration: 200,
              }),
            ]).start();
          }.bind(this),
          1000
        );

        setTimeout(
          function () {
            if (this._isMounted) {
              this.setState({ showFocus: false });
            }
          }.bind(this),
          4000
        );
      }
    }
  }

  syncDataLive = () => {
    if (this.props.firebase && this.props.currentDuel) {
      this.props.firebase
        .duel_lives(this.props.currentDuel.id)
        .onSnapshot((docSnapshot) => {
          // Actually the data should be only 1
          var lData = docSnapshot.data();
          // console.log(lData);
          // bind data
          if (lData) {
            if (this._isMounted) {
              const totalV = lData.total_voted_1 + lData.total_voted_2;
              const v1 = lData.total_voted_1;
              const v2 = lData.total_voted_2;

              var v1percent = totalV > 0 ? (v1 / totalV).toFixed(2) : 0;
              var v2percent = totalV > 0 ? (1 - v1percent).toFixed(2) : 0;

              if (v1 === 0 && v2 === 0) {
                v1percent = 0.5;
                v2percent = 0.5;
              }

              this.setState(
                {
                  totalVotes: totalV,
                  vote1: v1,
                  vote2: v2,
                  vote1percent: v1percent,
                  vote2percent: v2percent,
                  updateNum: this.state.updateNum + 1,
                },
                () => {
                  Animated.parallel([
                    Animated.timing(this.state.animations.widthLeft, {
                      toValue: getWidthFromHDMargin(
                        (SHOW_QRCODE_DUEL ? 1250 : 1800) *
                        Math.min(this.state.vote1percent, 0.99),
                        TELE_MARGIN_TOP,
                        TELE_MARGIN_RIGHT,
                        TELE_MARGIN_BOTTOM,
                        TELE_MARGIN_LEFT
                      ),
                      easing: Easing.elastic(),
                      duration: 550,
                      delay: 0,
                    }),
                    Animated.timing(this.state.animations.widthRight, {
                      toValue: getWidthFromHDMargin(
                        (SHOW_QRCODE_DUEL ? 1250 : 1800) *
                        Math.min(this.state.vote2percent, 0.99),
                        TELE_MARGIN_TOP,
                        TELE_MARGIN_RIGHT,
                        TELE_MARGIN_BOTTOM,
                        TELE_MARGIN_LEFT
                      ),
                      easing: Easing.elastic(),
                      duration: 550,
                      delay: 0,
                    }),
                  ]).start();
                }
              );
            }
          }
        });
    }
  };

  finishCountDownCallback = (val) => {
    console.log("finishCountDownCallback = ", val);
    this.setState(
      {
        screen: "live",
        timeLeftVote: -1,
      },
      () => {
        if (this.props.firebase) {
          const sendMailDuel = this.props.firebase.functions.httpsCallable(
            "sendMailDuel"
          );
          sendMailDuel({
            to: EMAILS_RESULTS,
            duelId: this.props.currentDuel.id,
          }).then((re) => {
            console.log("sendMailDuel done = ", re);
          });
        }
      }
    );
  };

  componentDidMount() {
    this._isMounted = true;
    // wake up firebase
    if (this.props.firebase && this.props.currentDuel) {

      const calDuelResult = this.props.firebase.functions.httpsCallable(
        "calculateDuelResult"
      );
      calDuelResult({
        duelId: this.props.currentDuel.id,
        answerId: "wakeup",
      }).then((re) => {
        console.log("calculateDuel wakeup = ", re);
      });

      if (this.state.timeLeftVote < 0) {
        this.finishCountDownCallback();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  render() {
    const { animations, animate } = this.state;

    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;

    const screenHeight =
      Math.round(Dimensions.get("window").height) - mtop - mbottom;
    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;

    const should_hide_qrcode = this.props.navigation.state.params
      ? this.props.navigation.state.params.hasOwnProperty("hideqr")
        ? this.props.navigation.state.params.hideqr === "true"
        : HIDE_QRCODE
      : HIDE_QRCODE;

    // console.log("this.state = ", this.state);
    return (

      <View
        style={{
          backgroundColor: MAIN_COLOR,
          flex: 1,
          marginBottom: mbottom,
          marginLeft: mleft,
          marginRight: mright,
          marginTop: mtop,
        }}
      >
        <ImageBackground
          style={{
            flex: 1,
            flexDirection: "row",
            backgroundColor: this.props.navigation.state.params
              ? this.props.navigation.state.params.bgc
                ? this.props.navigation.state.params.bgc
                : BG_TELE
              : BG_TELE,
          }}
          source={
            this.props.navigation.state.params
              ? this.props.navigation.state.params.bgtele
                ? this.props.navigation.state.params.bgtele
                : THEME_BACKGROUND_TELE
              : THEME_BACKGROUND_TELE
          }
          resizeMode={"cover"}
        >
          {this.state.screen === "countdown" && (
            <View
              style={{
                flex: 1,
                alignSelf: "center",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  width: getWidthFromHDMargin(
                    510,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  height: screenHeight,
                }}
              >
                <Image
                  style={{
                    width: getWidthFromHDMargin(
                      350,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    height: getHeightFromHDMargin(
                      200,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                  }}
                  resizeMode={"contain"}
                  source={THEME_HEADER_LEFT}
                />
              </View>
              {this.props.currentDuel && (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    height: screenHeight,
                  }}
                >
                  <Animated.Text
                    style={{
                      color: TEXT_TV_COLOR,
                      textAlign: "center",
                      fontSize: RF(7),
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.currentDuel.name}
                  </Animated.Text>

                  <Animated.View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",

                      height: getHeightFromHDMargin(
                        120,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      width: getWidthFromHDMargin(
                        290,
                        mtop,
                        mright,
                        mbottom,
                        mleft
                      ),
                      justifyContent: "space-between",
                      zIndex: 1000,
                    }}
                  >
                    {this.props.currentDuel.image_1 && (
                      <Image
                        style={{
                          width: getWidthFromHDMargin(
                            120,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                          height: getHeightFromHDMargin(
                            120,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                        }}
                        source={this.props.currentDuel.image_1}
                        resizeMode="contain"
                      />
                    )}
                    {this.props.currentDuel.image_2 && (
                      <Image
                        style={{
                          width: getWidthFromHDMargin(
                            120,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                          height: getHeightFromHDMargin(
                            120,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                        }}
                        source={this.props.currentDuel.image_2}
                        resizeMode="contain"
                      />
                    )}
                  </Animated.View>

                  {should_hide_qrcode === true && (
                    <Animated.View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: 25,
                        width: getWidthFromHDMargin(
                          740,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        height: getHeightFromHDMargin(
                          350,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        marginBottom: getHeightFromHDMargin(
                          250,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        marginTop: getHeightFromHDMargin(
                          20,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "94%",
                          }}
                        >
                          <Text
                            style={{
                              color: MAIN_COLOR,
                              fontSize: RF(4),
                              fontWeight: "bold",
                              textAlign: "center",
                              marginBottom: 20,
                            }}
                          >
                            Connectez-vous sur :
                          </Text>
                          <Text
                            style={{
                              color: "#e02020",
                              fontSize:
                                (getWidthFromHDMargin(
                                  700,
                                  mtop,
                                  mright,
                                  mbottom,
                                  mleft
                                ) /
                                  MAIN_URL.length) *
                                1.65,
                              fontWeight: "bold",
                            }}
                          >
                            {MAIN_URL.toUpperCase()}
                          </Text>
                        </View>
                      </View>
                    </Animated.View>
                  )}

                  {should_hide_qrcode === false && (
                    <Animated.View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        width: getWidthFromHDMargin(
                          740,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        height: getHeightFromHDMargin(
                          700,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            marginTop: getHeightFromHDMargin(
                              10,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            color: "#fff",
                            fontSize:
                              (getWidthFromHDMargin(
                                700,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) /
                                MAIN_URL.length) *
                              1.65,
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                          numberOfLines={1}
                          ellipsizeMode="middle"
                        >
                          {MAIN_URL}
                        </Text>
                        <View
                          style={{
                            marginTop: getHeightFromHDMargin(
                              30,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            alignItems: "center",
                            justifyContent: "center",
                            paddingBottom: getHeightFromHDMargin(
                              30,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                          }}
                        >
                          {this.state.showFocus && (
                            <ScaleView
                              style={{
                                position: "absolute",
                                backgroundColor: "green",
                                opacity: this.state.blinkAnimation,
                              }}
                              delay={550}
                              duration={550}
                              size={
                                getWidthFromHDMargin(
                                  550,
                                  mtop,
                                  mright,
                                  mbottom,
                                  mleft
                                ) +
                                50 -
                                mtop -
                                mbottom
                              }
                            ></ScaleView>
                          )}
                          {this.state.showFocus && (
                            <FadeView
                              style={{ position: "absolute" }}
                              delay={1000}
                              duration={300}
                            >
                              <Image
                                style={{
                                  width:
                                    getWidthFromHDMargin(
                                      550,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ) +
                                    50 -
                                    mtop -
                                    mbottom,
                                  height:
                                    getWidthFromHDMargin(
                                      550,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ) +
                                    50 -
                                    mtop -
                                    mbottom,
                                }}
                                source={require("../../assets/tutor/focus.png")}
                                resizeMode="contain"
                              />
                            </FadeView>
                          )}

                          <QRCode
                            value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (ONLY_ANONYMOUS ? "/quickSignUp" : "/SignUp"))}
                            fgColor={QRCODE_FOREGROUND_COLOR}
                            bgColor={QRCODE_BACKGROUND_COLOR}
                            size={
                              getWidthFromHDMargin(
                                550,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ) -
                              mtop -
                              mbottom
                            }
                          />
                        </View>
                      </View>
                    </Animated.View>
                  )}
                </View>
              )}
              <View
                style={{
                  flexDirection: "column",
                  width: getWidthFromHDMargin(
                    510,
                    mtop,
                    mright,
                    mbottom,
                    mleft
                  ),
                  height: screenHeight,
                }}
              >
                <Animated.View
                  style={{
                    marginRight: getWidthFromHDMargin(
                      40,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    marginTop: getWidthFromHDMargin(
                      45,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    opacity: animate ? animations.fadeOut : 1,
                  }}
                >
                  {this.props.currentDuel && SHOW_MINUTER &&
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: "#860008",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                        marginBottom: 5,
                      }}
                    >
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        source={require("../../assets/images/menu/duel.svg")}
                      />
                    </View>

                    <Text
                      style={{
                        color: TEXT_TV_COLOR,
                        fontSize: RF(3.5),
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginBottom: 10,
                      }}
                    >
                      Début Du Duel dans
                    </Text>
                  </View>}

                  {this.props.currentDuel &&
                    <TeleCountDown
                      type={2}
                      timeLeft={this.props.timeLeft}
                      callbackTimer={this.callbackTimer}
                    />}

                  {this.props.currentDuel && (
                    <Text
                      style={{
                        color: TEXT_TV_COLOR,
                        fontSize: RF(3.5),
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: getWidthFromHDMargin(
                          105,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        marginBottom: 10,
                      }}
                    >
                      {this.props.currentDuel.subtitle}
                    </Text>
                  )}
                  {this.props.currentDuel && SHOW_NULBER_ONLINE && (
                    <NumberOnline
                      type="duel"
                      mode={2}
                      logeName={this.props.currentDuel.subtitle}
                      {...this.props}
                    />
                  )}
                </Animated.View>
              </View>
            </View>
          )}

          {this.state.screen === "live" &&
            this.state.timeLeftVote > 0 &&
            this.props.currentDuel && (
              <View
                style={{
                  flex: 1,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: Math.round(screenWidth),
                  height: Math.round(screenHeight),
                }}
              >
                <TeleHeader
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    width: screenWidth,
                    maxHeight: "20%",
                    top: 0,
                    position: "absolute",
                  }}
                />
                {this.state.timeLeftVote > 0 && (
                  <FadeSlideView
                    direction="down"
                    style={{
                      position: "fixed",
                      top:
                        getHeightFromHDMargin(
                          20,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ) + mtop,
                      left: Math.round(
                        screenWidth / 2 -
                        getWidthFromHDMargin(
                          90,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ) +
                        mleft
                      ),
                    }}
                  >
                    <ImageBackground
                      style={{
                        width: getWidthFromHDMargin(
                          180,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        height: getWidthFromHDMargin(
                          180,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        ),
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      resizeMode={"contain"}
                      source={require("../../assets/images/circle.png")}
                    >
                      <CountDownCircle
                        seconds={this.state.timeLeftVote}
                        radius={getWidthFromHDMargin(
                          80,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        )}
                        borderWidth={getWidthFromHDMargin(
                          20,
                          mtop,
                          mright,
                          mbottom,
                          mleft
                        )}
                        color="red"
                        bgColor="#fff"
                        textStyle={{
                          fontSize: RF(5),
                        }}
                        finishCallback={() => this.finishCountDownCallback()}
                      />
                    </ImageBackground>
                  </FadeSlideView>
                )}
                <View
                  style={{
                    position: "absolute",
                    flexDirection: "row",
                    height: Math.round((screenHeight * 5) / 6),
                    top: Math.round(screenHeight / 6),
                  }}
                >
                  {SHOW_QRCODE_DUEL && (
                    <Animated.View
                      style={{
                        width: Math.round(screenWidth / 3),
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          height: "20%",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                          borderTopLeftRadius: 25,
                          borderTopRightRadius: 25,
                          width: "90%",
                          height: "80%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Text
                            style={{
                              marginTop: getHeightFromHDMargin(
                                10,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                              color: "#fff",
                              fontSize:
                                ((0.9 * Math.round(screenWidth / 3)) /
                                  MAIN_URL.length) *
                                1.35,
                              fontWeight: "bold",
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                            numberOfLines={1}
                            ellipsizeMode="middle"
                          >
                            {MAIN_URL}
                          </Text>
                          <View
                            style={{
                              marginTop: getHeightFromHDMargin(
                                30,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                              alignItems: "center",
                              justifyContent: "center",
                              paddingBottom: getHeightFromHDMargin(
                                30,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                            }}
                          >
                            {this.state.showFocus && (
                              <ScaleView
                                style={{
                                  position: "absolute",
                                  backgroundColor: "green",
                                  opacity: this.state.blinkAnimation,
                                }}
                                delay={550}
                                duration={550}
                                size={Math.round(screenHeight / 2.2)}
                              ></ScaleView>
                            )}
                            {this.state.showFocus && (
                              <FadeView
                                style={{ position: "absolute" }}
                                delay={1000}
                                duration={300}
                              >
                                <Image
                                  style={{
                                    width: Math.round(screenHeight / 2.2),
                                    height: Math.round(screenHeight / 2.2),
                                  }}
                                  source={require("../../assets/tutor/focus.png")}
                                  resizeMode="contain"
                                />
                              </FadeView>
                            )}

                            <QRCode
                              value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (ONLY_ANONYMOUS ? "/quickSignUp" : "/SignUp"))}
                              fgColor={QRCODE_FOREGROUND_COLOR}
                              bgColor={QRCODE_BACKGROUND_COLOR}
                              size={Math.round(screenHeight / 2.2)}
                            />
                          </View>
                        </View>
                      </View>
                    </Animated.View>
                  )}

                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FadeSlideView delay={150}>
                      <Text
                        style={{
                          color: TEXT_TV_COLOR,
                          fontSize: RF(8),
                          fontWeight: "bold",
                          textAlign: "center",
                          width: SHOW_QRCODE_DUEL
                            ? Math.round((screenWidth * 2) / 3)
                            : getWidthFromHDMargin(
                              1680,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                        }}
                      >
                        {this.props.currentDuel.name}
                      </Text>
                    </FadeSlideView>
                    <FadeSlideView delay={500} direction="up">
                      <View
                        style={{
                          marginTop: Math.round(screenHeight / 42),
                          flex: 1,
                          alignItems: "stretch",
                          alignContent: "stretch",
                          alignSelf: "stretch",
                        }}
                        key={this.state.updateNum}
                      >
                        <Animated.View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: SHOW_QRCODE_DUEL
                              ? Math.round((screenWidth * 2) / 3)
                              : getWidthFromHDMargin(
                                1800,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                            height: getHeightFromHDMargin(
                              150,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            marginTop: getHeightFromHDMargin(
                              50,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            flex: 1,
                            justifyContent: "space-between",
                            zIndex: 1000,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {this.props.currentDuel.image_1 && (
                              <Image
                                style={{
                                  width: getWidthFromHDMargin(
                                    150,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ),
                                  height: getHeightFromHDMargin(
                                    150,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ),
                                }}
                                source={this.props.currentDuel.image_1}
                                resizeMode="contain"
                              />
                            )}
                            <Text
                              style={{
                                color: TEXT_TV_COLOR,
                                fontSize: RF(8),
                                fontWeight: "900",
                                textAlign: "center",
                                marginLeft: 10,
                              }}
                            >
                              {this.state.vote1percent * 100}%
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: TEXT_TV_COLOR,
                                fontSize: RF(8),
                                fontWeight: "900",
                                textAlign: "center",
                                marginRight: 10,
                              }}
                            >
                              {this.state.vote2percent * 100}%
                            </Text>
                            {this.props.currentDuel.image_2 && (
                              <Image
                                style={{
                                  width: getWidthFromHDMargin(
                                    150,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ),
                                  height: getHeightFromHDMargin(
                                    150,
                                    mtop,
                                    mright,
                                    mbottom,
                                    mleft
                                  ),
                                }}
                                source={this.props.currentDuel.image_2}
                                resizeMode="contain"
                              />
                            )}
                          </View>
                        </Animated.View>
                        <Animated.View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: SHOW_QRCODE_DUEL
                              ? Math.round((screenWidth * 2) / 3)
                              : getWidthFromHDMargin(
                                1810,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                            height: getHeightFromHDMargin(
                              150,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            marginTop: getHeightFromHDMargin(
                              50,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            flex: 1,
                            zIndex: 1000,
                            justifyContent: "space-between",
                          }}
                        >
                          <Animated.View
                            style={{
                              backgroundColor: MAIN_COLOR,
                              borderRadius: 10,
                              borderColor: "white",
                              borderWidth: 5,
                              width: animations.widthLeft,
                              height: getHeightFromHDMargin(
                                150,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></Animated.View>
                          <Animated.View
                            style={{
                              borderRadius: 10,
                              borderColor: "white",
                              borderWidth: 5,
                              width: animations.widthRight,
                              height: getHeightFromHDMargin(
                                150,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                              backgroundColor: BUTTON_COLOR,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></Animated.View>
                        </Animated.View>
                        <Animated.View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: SHOW_QRCODE_DUEL
                              ? Math.round((screenWidth * 2) / 3)
                              : getWidthFromHDMargin(
                                1810,
                                mtop,
                                mright,
                                mbottom,
                                mleft
                              ),
                            height: getHeightFromHDMargin(
                              150,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            marginTop: getHeightFromHDMargin(
                              50,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            flex: 1,
                            zIndex: 1000,
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: TEXT_TV_COLOR,
                              fontSize: RF(8),
                              fontWeight: "900",
                              textAlign: "center",
                              marginLeft: 10,
                            }}
                          >
                            {this.state.totalVotes}{" "}
                            {this.state.totalVotes > 1 ? "VOTES" : "VOTE"}
                          </Text>
                        </Animated.View>
                      </View>
                    </FadeSlideView>
                  </View>
                </View>
              </View>
            )}

          {this.state.screen === "live" &&
            this.state.timeLeftVote < 0 &&
            this.props.currentDuel && (
              <View
                style={{
                  flex: 1,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: Math.round(screenWidth),
                  height: Math.round(screenHeight),
                }}
              >
                <TeleHeader
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    width: screenWidth,
                    maxHeight: "20%",
                    top: 0,
                    position: "absolute",
                  }}
                />

                <View
                  style={{
                    position: "absolute",
                    flexDirection: "row",
                    height: Math.round((screenHeight * 5) / 6),
                    top: Math.round(screenHeight / 6),
                  }}
                >
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FadeSlideView delay={150}>
                      <Text
                        style={{
                          color: TEXT_TV_COLOR,
                          fontSize: RF(8),
                          fontWeight: "bold",
                          textAlign: "center",
                          width: getWidthFromHDMargin(
                            1680,
                            mtop,
                            mright,
                            mbottom,
                            mleft
                          ),
                        }}
                      >
                        {this.props.currentDuel.name}
                      </Text>
                    </FadeSlideView>
                    <FadeSlideView delay={500} direction="up">
                      <View
                        style={{
                          marginTop: Math.round(screenHeight / 42),
                          flex: 1,
                          alignItems: "stretch",
                          alignContent: "stretch",
                          alignSelf: "stretch",
                        }}
                        key={this.state.updateNum}
                      >
                        <Animated.View
                          style={{
                            flexDirection: "row",
                            width: screenWidth,
                            marginTop: getHeightFromHDMargin(
                              100,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            alignItems: "center",
                            justifyContent:
                              parseFloat(this.state.vote1percent) === 0.5
                                ? "space-between"
                                : "center",
                          }}
                        >
                          {this.state.vote1percent >= 0.5 && (
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {this.props.currentDuel.image_1 && (
                                <Image
                                  style={{
                                    width: getWidthFromHDMargin(
                                      400,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ),
                                    height: getHeightFromHDMargin(
                                      400,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ),
                                  }}
                                  source={this.props.currentDuel.image_1}
                                  resizeMode="contain"
                                />
                              )}
                              <Text
                                style={{
                                  color: TEXT_TV_COLOR,
                                  fontSize: RF(16),
                                  fontWeight: "900",
                                  textAlign: "center",
                                  marginLeft: 10,
                                }}
                              >
                                {this.state.vote1percent * 100}%
                              </Text>
                            </View>
                          )}
                          {this.state.vote2percent >= 0.5 && (
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  color: TEXT_TV_COLOR,
                                  fontSize: RF(16),
                                  fontWeight: "900",
                                  textAlign: "center",
                                  marginRight: 10,
                                }}
                              >
                                {this.state.vote2percent * 100}%
                              </Text>
                              {this.props.currentDuel.image_2 && (
                                <Image
                                  style={{
                                    width: getWidthFromHDMargin(
                                      400,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ),
                                    height: getHeightFromHDMargin(
                                      400,
                                      mtop,
                                      mright,
                                      mbottom,
                                      mleft
                                    ),
                                  }}
                                  source={this.props.currentDuel.image_2}
                                  resizeMode="contain"
                                />
                              )}
                            </View>
                          )}
                        </Animated.View>
                        <Animated.View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: screenWidth,
                            height: getHeightFromHDMargin(
                              150,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            marginTop: getHeightFromHDMargin(
                              50,
                              mtop,
                              mright,
                              mbottom,
                              mleft
                            ),
                            flex: 1,
                            zIndex: 1000,
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: TEXT_TV_COLOR,
                              fontSize: RF(8),
                              fontWeight: "900",
                              textAlign: "center",
                              marginLeft: 10,
                            }}
                          >
                            {this.state.totalVotes}{" "}
                            {this.state.totalVotes > 1 ? "VOTES" : "VOTE"}
                          </Text>
                        </Animated.View>
                      </View>
                    </FadeSlideView>
                  </View>
                </View>
              </View>
            )}
        </ImageBackground>
        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1,
          }}
          source={THEME_BANNER_FULL}
          resizeMode={"stretch"}
        />

        {this.state.showTuto &&
          (this.props.navigation.state.params
            ? this.props.navigation.state.params.stuto
              ? this.props.navigation.state.params.stuto === "true"
              : SHOW_TUTO
            : SHOW_TUTO) === true && (
            <View
              style={{
                width: screenWidth,
                height: screenHeight,
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TutorTele {...this.props} />
            </View>
          )}
      </View>
    );
  }
}

export default DuelHome;
