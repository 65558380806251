import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { Text, View, TouchableOpacity, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeView from "../../components/Anim/FadeView";
import { withAuthorization } from "../../components/Session";
import {
  TEXT_MENU,
  TEXT_COLOR,
  GEOLOCATION,
  GEOLOCATION_ONE_LAT,
  GEOLOCATION_ONE_LON,
  GEOLOCATION_OBLIGATION_TYPE,
  GEOLOCATION_DISTANCE,
  LOGE_NUMBERS,
  GEOLOCATION_TIMEOUT,
  MAIN_URL,
} from "../../constants/const";
import {
  getWidthPercentMaxLimited,
  getWidthPercent,
} from "../../libs/list_picker/ratio";
import MenuSurvey from "../../components/Menu/menu_survey";
import TOButton from "../../libs/to_button";
import Geolocation from "react-geolocation";
import moment from "moment";
import MenuSA from "../../components/Menu/menu_sa";
import { withTranslation, t } from "react-multi-lang";

export class SurveyListScreen extends Component {
  state = { forecasted: false, finaldata: false, showGeoInfo: 0 };
  _isMounted = false;
  intervalId = null;
  timeoutId = null;

  currentQuiz = null;
  currentAsyncs = null;
  currentSAs = null;
  currentSurvey = null;
  currentMatch = null;
  currentDuel = null;

  selectedAsyncId = null;

  constructor(props) {
    super(props);
    if (props.authUser) {
      if (props.getSAAll()) {
        // already called
        console.log("already async call ");
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.authUser) {
      // this.intervalId = setInterval(() => {
      this.checkTimeDisplay();
      // }, 1000);
      this.timeoutId = setTimeout(() => {
        if (this._isMounted) {
          this.setState({ finaldata: true });
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    // if(this.intervalId){
    //   clearInterval(this.intervalId);
    // }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this._isMounted = false;
  }

  checkTimeDisplay() {
    // console.log("checkTimeDisplay");
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    var cQ = window.localStorage.getItem("currentQuiz");
    if (cQ !== null) {
      var savedQ = JSON.parse(cQ);
      if (savedQ !== null) {
        this.currentQuiz = savedQ;
      }
    }

    var cS = window.localStorage.getItem("currentSurvey");
    if (cS !== null) {
      var savedS = JSON.parse(cS);
      if (savedS !== null) {
        this.currentSurvey = savedS;
      }
    }
    var cM = window.localStorage.getItem("currentMatch");
    if (cM !== null) {
      var savedM = JSON.parse(cM);
      if (savedM !== null) {
        this.currentMatch = savedM;
      }
    }
    var cD = window.localStorage.getItem("currentDuel");
    if (cD !== null) {
      var savedD = JSON.parse(cD);
      if (savedD !== null) {
        this.currentDuel = savedD;
      }
    }

    if (this.props.currentSAs) {
      this.currentSAs = this.props.currentSAs;
    }
    // const currentSurvey = window.localStorage.getItem("currentSurvey");
    if (this.props.currentSurvey !== null && this.props.currentSurvey.start) {
      this.currentSurvey = this.props.currentSurvey;
    }

    if (this.currentSurvey) {
      // console.log(now - this.currentSurvey.end.seconds);
      if (now > this.currentSurvey.end.seconds) {
        console.log("looking for next survey");
        window.localStorage.removeItem("currentSurvey");
        window.location.reload(true);
      }
    }
  }

  calculDistance = (lat1, lon1, lat2, lon2) => {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344 * 1000; // m
      return dist;
    }
  };

  isGoodDistance(lat1, lon1) {
    var distance = 0;
    if (GEOLOCATION_OBLIGATION_TYPE === "BY_GROUP") {
      var arrList = LOGE_NUMBERS();
      for (let i = 0; i < arrList.length; i++) {
        var loge = arrList[i];
        //Get from data with geolocation
        var arrloge = loge.split(";");
        var logeLat = 0;
        var logeLon = 0;
        var logeName = arrloge[0];
        if (arrloge.length > 1) {
          logeLat = arrloge[1];
        }
        if (arrloge.length > 2) {
          logeLon = arrloge[2];
        }
        distance = this.calculDistance(lat1, lon1, logeLat, logeLon);
        console.log(`${logeName} distance == `, distance);
        if (distance <= GEOLOCATION_DISTANCE) {
          const { firebase } = this.props;
          firebase
            .user(this.props.authUser.uid)
            .set(
              {
                logeNumber: logeName,
                logeNumberDate: moment().format("YYYY-MM-DD"),
                lat: lat1,
                lon: lon1,
                geoDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              },
              { merge: true }
            )
            .catch((error) => {
              console.log(error);
            });
          return true;
        }
      }
    } else {
      // BY_ONE
      distance = this.calculDistance(
        lat1,
        lon1,
        GEOLOCATION_ONE_LAT,
        GEOLOCATION_ONE_LON
      );
      console.log("distance == ", distance);
      if (distance > GEOLOCATION_DISTANCE) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  _renderMenuSA = (async, type) => {
    return (
      <MenuSA
        key={async.id}
        {...this.props}
        currentSA={async}
        navigation={this.props.navigation}
        callbackClicked={
          type === "sa" ? this._callbackSAClicked : this._callbackSurveyClicked
        }
      />
    );
  };

  _renderMenuSurvey = () => {
    return (
      <MenuSurvey
        key="survey"
        {...this.props}
        callbackClicked={this._callbackSurveyClicked}
      />
    );
  };

  _isGeoUnderTimeoutAndGood = () => {
    const lp = window.localStorage.getItem("lp");
    if (lp) {
      const lpJson = JSON.parse(lp);
      if (lpJson) {
        const lastGeo = lpJson.date || 0;
        const lastLat = lpJson.lat || 0;
        const lastLon = lpJson.lon || 0;
        const timeout = parseInt(GEOLOCATION_TIMEOUT, 10) || 0;
        // console.log('lastGeo + (timeout * 60 * 1000) - Date.now() = ', lastGeo + (timeout * 60 * 1000) - Date.now());
        if (lastGeo + timeout * 60 * 1000 >= Date.now()) {
          if (this.isGoodDistance(lastLat, lastLon)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  _callbackSAClicked = (aid) => {
    console.log("SA clicked = ", aid);
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectSA(aid);
      } else {
        this.selectedSAId = aid;
        this.setState({ showGeoInfo: 1, shouldOpen: "sa" });
      }
    } else {
      this._redirectSA(aid);
    }
  };

  _redirectSA = (aid) => {
    // window.location.replace(`http://localhost:3000/sa/?sid=${aid}`);
    window.location.replace(`https://${MAIN_URL}/sa/?sid=${aid}`);
  };

  _callbackSurveyClicked = () => {
    console.log("Survey clicked");
    // show warning.
    if (GEOLOCATION) {
      if (this._isGeoUnderTimeoutAndGood()) {
        this._redirectSurvey();
      } else {
        this.setState({ showGeoInfo: 1, shouldOpen: "survey" });
      }
    } else {
      this._redirectSurvey();
    }
  };

  _redirectSurvey = () => {
    const { navigate } = this.props.navigation;
    navigate("Surveys");
  };

  _renderMenu = () => {
    var out = [];
    var outRender = [];

    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        start: this.props.currentSurvey.start.seconds,
        data: this.props.currentSurvey,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({ type: "sa", start: a.start.seconds, data: a });
        }
      }
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });

    if (out.length > 0) {
      for (let i = 0; i < out.length; i++) {
        const event = out[i];
        switch (event.type) {
          case "survey":
            outRender.push(this._renderMenuSA(event.data, "survey"));
            break;
          case "sa":
            outRender.push(this._renderMenuSA(event.data, "sa"));
            break;
          default:
            break;
        }
      }
    }

    return outRender;
  };

  render() {
    const maxWidth = this.props.maxWidth;
    return (
      <View
        style={{
          alignItems: "center",
        }}
        key={this.props.toString()}
      >
        <View
          style={{
            padding: 10,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          {this.state.msgSA && (
            <Text
              style={{
                color: this.props.navigation.state.params
                  ? this.props.navigation.state.params.tc
                    ? this.props.navigation.state.params.tc
                    : TEXT_COLOR
                  : TEXT_COLOR,
                fontSize: 16,
                fontWeight: "normal",
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              {this.state.msgSA}
            </Text>
          )}

          <Text
            style={{
              color: this.props.navigation.state.params
                ? this.props.navigation.state.params.tc
                  ? this.props.navigation.state.params.tc
                  : TEXT_COLOR
                : TEXT_COLOR,
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            {this.props.navigation.state.params
              ? this.props.navigation.state.params.mt
                ? this.props.navigation.state.params.mt
                : TEXT_MENU()
              : TEXT_MENU()}
          </Text>
        </View>

        <FadeView
          style={{
            padding: 20,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
          }}
          duration={200}
        >
          {this._renderMenu()}

          {this.state.finaldata &&
            !this.props.currentMatch &&
            !this.props.currentDuel &&
            !(this.props.currentQuiz && this.props.currentQuiz.name !== "") &&
            !this.props.currentAsyncs &&
            !this.props.currentSAs &&
            !this.props.currentNote &&
            !(
              this.props.currentSurvey && this.props.currentSurvey.name !== ""
            ) && (
              <Text
                style={{
                  fontSize: RF(2.5),
                  color: this.props.navigation.state.params
                    ? this.props.navigation.state.params.tc
                      ? this.props.navigation.state.params.tc
                      : TEXT_COLOR
                    : TEXT_COLOR,
                }}
              >
                {t("no_events_come_back_soon")}
              </Text>
            )}
        </FadeView>

        {/* SHOW INFO GEOLOCATION */}
        {this.state.showGeoInfo === 1 && (
          <Geolocation
            lazy
            onSuccess={(res) => {
              // console.log("Success = ", res);
              // Check the distance
              window.localStorage.setItem(
                "lp",
                JSON.stringify({
                  lat: res.coords.latitude,
                  lon: res.coords.longitude,
                  date: Date.now(),
                })
              );

              if (
                this.isGoodDistance(res.coords.latitude, res.coords.longitude)
              ) {
                // After ok should open the target
                switch (this.state.shouldOpen) {
                  case "match":
                    this._redirectMatch();
                    break;
                  case "survey":
                    this._redirectSurvey();
                    break;
                  case "quiz":
                    this._redirectQuiz();
                    break;
                  case "async":
                    this._redirectAsync(this.selectedAsyncId);
                    break;
                  case "sa":
                    this._redirectSA(this.selectedSAId);
                    break;
                  case "duel":
                    this._redirectDuel();
                    break;
                  default:
                    break;
                }
              } else {
                this.setState({ showGeoInfo: 5 });
              }
            }}
            onError={(err) => {
              console.log("Error = ", err);
              if (err) {
                if (err.code === 1) {
                  // PERMISSION_DENIED
                  this.setState({ showGeoInfo: 2 });
                } else {
                  // POSITION_UNAVAILABLE
                  this.setState({ showGeoInfo: 4 });
                }
              }
            }}
            render={({ getCurrentPosition, fetchingPosition }) => (
              <View
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(0,0,0, 0.8)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    flexDirection: "column",
                    width:
                      getWidthPercent(90) < 320 ? 320 : getWidthPercent(90),

                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!fetchingPosition && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          textAlignVertical: "center",
                          padding: 10,
                          fontSize: RF(3),
                          color: "#555",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        {`GÉOLOCALISATION

Pour participer, il est indispensable de vous trouver dans un lieu où se déroule l’événement.

Acceptez-vous d’être géolocalisé ?`}
                      </Text>
                      <View style={{ flexDirection: "row" }}>
                        <TOButton
                          onClick={() => {
                            this.setState({ showGeoInfo: 0 });
                            // console.log(this.state);
                          }}
                          text="PLUS TARD"
                        />
                        <TOButton onClick={getCurrentPosition} text="OUI" />
                      </View>
                    </View>
                  )}

                  {fetchingPosition && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          textAlignVertical: "center",
                          padding: 10,
                          fontSize: RF(3),
                          color: "#555",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        {`GÉOLOCALISATION EN COURS`}
                      </Text>
                      <Image
                        style={{
                          width: 100,
                          height: 100,
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                        source={require("../../assets/images/pin.svg")}
                      ></Image>
                      <Text
                        style={{
                          textAlign: "center",
                          textAlignVertical: "center",
                          padding: 10,
                          fontSize: RF(2),
                          color: "#555",
                          marginBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        {`Merci de patienter quelques instants`}
                      </Text>
                    </View>
                  )}

                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      left: 10,
                      top: 10,
                    }}
                    onPress={() => {
                      this.setState({ showGeoInfo: 0 });
                      // console.log(this.state);
                    }}
                  >
                    <Image
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      source={require("../../libs/list_picker/android-close.png")}
                    ></Image>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          />
        )}
        {/* GEOLOCATION PERMISSION_DENIED */}
        {this.state.showGeoInfo === 2 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: getWidthPercent(90) < 320 ? 320 : getWidthPercent(90),

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: RF(3),
                    color: "#555",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {`GÉOLOCALISATION INDISPENSABLE

Pour participer, il est indispensable de vous trouver dans un lieu où se déroule l’événement.

Pour permettre la géolocalisation, vous devez désormais l’autoriser depuis les réglages systeme.`}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <TOButton
                  onClick={() => {
                    this.setState({ showGeoInfo: 3 });
                    // console.log(this.state);
                  }}
                  text="VOIR COMMENT ?"
                />
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                  // console.log(this.state);
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* GIF HELP */}
        {this.state.showGeoInfo === 3 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: getWidthPercent(90) < 320 ? 320 : getWidthPercent(90),

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 300,
                    height: 400,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  source={require("../../assets/images/chien.gif")}
                ></Image>
              </View>

              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                  // console.log(this.state);
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* ERROR CAN NOT GET LOCATION */}
        {this.state.showGeoInfo === 4 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: getWidthPercent(90) < 320 ? 320 : getWidthPercent(90),

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: RF(3),
                    color: "#555",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {`GÉOLOCALISATION IMPOSSIBLE

Nous ne parvenons pas à vous géolocaliser. Nous vous invitons à ressayer plus tard ou depuis un autre lieu.`}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <TOButton
                  onClick={() => {
                    this.setState({ showGeoInfo: 0 });
                  }}
                  text="FERMER"
                />
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {/* FAR FROM SPOT */}
        {this.state.showGeoInfo === 5 && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0, 0.8)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderRadius: 10,
                backgroundColor: "#fff",
                flexDirection: "column",
                width: getWidthPercent(90) < 320 ? 320 : getWidthPercent(90),

                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    textAlignVertical: "center",
                    padding: 10,
                    fontSize: RF(3),
                    color: "#555",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  {`LIEU INCOMPATIBLE

Malheureusement, vous n’êtes pas présent dans le lieu où se déroule l’évenement.

Vous pourrez participer dès lors que vous vous trouverez sur place.  Merci !`}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <TOButton
                  onClick={() => {
                    this.setState({ showGeoInfo: 0 });
                  }}
                  text="FERMER"
                />
              </View>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  left: 10,
                  top: 10,
                }}
                onPress={() => {
                  this.setState({ showGeoInfo: 0 });
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  source={require("../../libs/list_picker/android-close.png")}
                ></Image>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(withMainLayout(withTranslation(SurveyListScreen)));
