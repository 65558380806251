import React from "react";
import { Animated, Text, View, TouchableOpacity } from "react-native";
import RF from "react-native-responsive-fontsize";
import { APP_NAVTAB, BANNER_BOTTOM_ENABLE, BUTTON_COLOR, SHOW_GAMES, SHOW_MINUTER_MOBILE, TEXT_COLOR } from "../../constants/const";
import {
  withTranslation,
  t
} from "react-multi-lang";

export class CountDown extends React.Component {
  _isMounted = false;
  intervalId = null;

  constructor(props) {
    super(props);
    this.springValue = new Animated.Value(0.3);
    this.startValue = props.timeLeft || 1;
  }

  _displayDate = (timeLeft) => {
    const secLeft = timeLeft % 60;
    const minLeft = Math.floor(timeLeft / 60);
    const hourLeft = Math.floor(minLeft / 60);
    const dayLeft = Math.floor(hourLeft / 24);
    const day = dayLeft > 0 ? dayLeft + "j " : "";
    const hourDisplay = hourLeft % 24;
    const hour = hourDisplay < 10 ? "0" + hourDisplay : hourDisplay;
    const minDisplay = minLeft % 60;
    const min = minDisplay < 10 ? "0" + minDisplay : minDisplay;
    const sec = secLeft < 10 ? "0" + secLeft : secLeft;
    return day + " " + hour + " : " + min + " : " + sec;
  };

  componentDidMount() {
    this._isMounted = true;
    this.intervalId = setInterval(() => {
      if (this.startValue >= 0) {
        if (this._isMounted) {
          this.setState({ currentCount: this.startValue }, () => {
            this.spring();
          });
        }
      } else {
        if (this.props.mode === "insider") {
        } else {
          clearInterval(this.intervalId);
          // console.log("should start quiz");
          if (this.props.act === "surveys") {
            this.props.navigation.navigate("Surveys");
          } else {
            this.props.navigation.navigate("Quizzes");
          }
        }
      }
      this.startValue = this.startValue - 1;
      if (this.props.callbackTimer) {
        this.props.callbackTimer(this.startValue);
      }
    }, 1000);
  }

  spring() {
    this.springValue.setValue(2);
    Animated.spring(this.springValue, {
      toValue: 1,
      bounciness: 12,
    }).start();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    const { navigate } = this.props.navigation;
    return (
      <View style={{
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center", alignItems: "center"
      }}>
        {SHOW_MINUTER_MOBILE && <Text
          style={{
            color: TEXT_COLOR,
            fontSize: this.props.header_text_font
              ? this.props.header_text_font
              : RF(2),
            fontStyle: "bold",
            textAlign: "center",
            textShadowOffset: { width: 5, height: 5 },
            textShadowColor: "rgba(255, 255, 255, 0.5)",
            textShadowRadius: 20,
            margin: 20,
          }}
        >
          {this.props.header_text
            ? this.props.header_text
            : t("event_starts_in")}
        </Text>}

        {this.state && this.state.currentCount > 60 && SHOW_MINUTER_MOBILE && (
          <View>
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(5.5),
                fontStyle: "bold",
                textAlign: "center",
                textShadowOffset: { width: 5, height: 5 },
                textShadowColor: "rgba(255, 255, 255, 0.5)",
                textShadowRadius: 20,
              }}
            >
              {this._displayDate(this.state.currentCount)}
            </Text>
          </View>
        )}


        <View style={{
          // position: "fixed",
          justifyContent: "center",
          alignSelf: "center",
          flex: 1,
          flexDirection: "column",
          alignItems: "center"
          // bottom: 200
        }} >

          {this.state && this.state.currentCount <= 60 && (
            <View
              style={{
                height: 200,
                width: this.props.maxWidth
              }}
            >
              <Animated.View
                style={{
                  width: 227,
                  height: 200,
                  alignSelf: "center",
                  transform: [{ scale: this.springValue }],
                }}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: RF(12),
                    fontStyle: "bold",
                    textAlign: "center",
                    textShadowOffset: { width: 5, height: 5 },
                    textShadowColor: "#000",
                    textShadowRadius: 30,
                  }}
                >
                  {this.state && this.state.currentCount}
                </Text>
              </Animated.View>
            </View>
          )}


          {this.props.msgWaiting &&
            <Text
              style={{
                color: TEXT_COLOR
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `${this.props.msgWaiting}`,
                }}
              ></div>
            </Text>}


        </View>

        <View style={{
          position: 'fixed',
          bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
          width: '100%',
          padding: 15
        }}>
          <TouchableOpacity
            onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate("SelectGame") : navigate("Home")}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                margin: 50,
                fontSize: 14,
                backgroundColor: BUTTON_COLOR,
                textAlign: "center",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 25
              }}
            >
              <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
            </Text>
          </TouchableOpacity>
        </View>



      </View>
    );
  }
}

export default withTranslation(CountDown);
