import React from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import { Text, View, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import RankingGeneralComponent from "../../components/Ranking/ranking_general";
import axios from "axios";
import { TEXT_COLOR, THEME_ID } from "../../constants/const";
import { Layout, SelectItem } from "@ui-kitten/components";
import {
  withTranslation,
  t
} from "react-multi-lang";
export class RankingForecastScreen extends React.Component {
  _isMounted = false;
  arrRanking = [];
  arrCompetitions = [{ id: -1, name: t("all_competitions") }];
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      currentPlayer: null,
      selectedIndex: null,
      selectedValue: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.authUser) {
      this.getRanking();
      // let _now = moment(new Date()).toISOString();
      axios
        .get(
          `https://ws.take-over.io/competitions?type_in=foot&type_in=foot_quiz&loge_id=${THEME_ID}`
        )
        .then((res) => {
          if (res.data) {
            if (res.data.length > 0) {
              this.arrCompetitions = [
                { id: -1, name: t("all_competitions") },
                ...res.data,
              ];
            }
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderCompetitionItems = () => {
    var items = [];
    for (let i = 0; i < this.arrCompetitions.length; i++) {
      const item = this.arrCompetitions[i];
      items.push(
        <SelectItem key={item.id} title={item.name} name={item.name} />
      );
    }
    return items;
  };

  onSelectCompetition = (indexPath) => {
    // console.log(indexPath);
    var selectedCom = this.arrCompetitions[indexPath.row];
    if (selectedCom) {
      if (selectedCom.id === -1) {
        this.getRanking();
      } else {
        this.getRankingFromCompetition(selectedCom.id);
      }
    }
    this.setState({
      selectedIndex: indexPath,
      selectedValue: selectedCom.name,
    });
  };

  getRankingFromCompetition = (comId) => {
    if (this.props.firebase) {
      const that = this;
      this.props.firebase.rankingForecastCompetition(comId).then((query) => {
        query.get().then((snapshot) => {
          var snapData = [];
          snapshot.forEach((sn) => {
            snapData.push(sn.data());
          });
          // add data to array
          that.arrRanking = snapData;
          // console.log("that.arrRanking = ", that.arrRanking);
          // for current user
          var currentPlayer = null;
          if (that.props.authUser) {
            for (let i = 0; i < that.arrRanking.length; i++) {
              const element = that.arrRanking[i];
              if (that.props.authUser.uid === element.uid) {
                element.rank = i + 1;
                currentPlayer = element;
                break;
              }
            }
          }
          if (that._isMounted) {
            that.setState({
              data: that.arrRanking,
              authUser: that.props.authUser,
              currentPlayer: currentPlayer,
            });
          }
        });
      });
    }
  };

  getRanking = () => {
    if (this.props.firebase) {
      this.props.firebase.rankingForecast().then((query) => {
        query.get().then((snapshot) => {
          var snapData = [];
          snapshot.forEach((sn) => {
            snapData.push(sn.data());
          });
          // add data to array
          this.arrRanking = snapData;
          // for current user
          var currentPlayer = null;
          if (this.props.authUser) {
            for (let i = 0; i < this.arrRanking.length; i++) {
              const element = this.arrRanking[i];
              if (this.props.authUser.uid === element.uid) {
                element.rank = i + 1;
                currentPlayer = element;
                break;
              }
            }
          }
          if (this._isMounted) {
            this.setState({
              data: this.arrRanking,
              authUser: this.props.authUser,
              currentPlayer: currentPlayer,
            });
          }
        });
      });
    }
  };

  render() {
    return (
      <View style={{ width: this.props.maxWidth, justifyContent: "center", alignItems: "center" }}>
        <Layout
          level="1"
          style={{
            backgroundColor: "#0091FF",
            width: "100%",
            height: 90,
            justifyContent: "center",
            alignItems: "center",
            shadowColor: "#000",
            shadowOpacity: 1,
            shadowRadius: 30,
          }}
        >
          <Text
            style={{
              color: TEXT_COLOR,
              textAlign: "center",
              textAlignVertical: "center",
              margin: 10,
              fontSize: RF(2.2),
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {t("rankings_forecast")}
          </Text>
          {/* <Select
            placeholder={t("select_competition")}
            status="control"
            selectedIndex={this.state.selectedIndex}
            onSelect={this.onSelectCompetition}
            value={this.state.selectedValue}
            style={{ width: 300 }}
          >
            {this.renderCompetitionItems()}
          </Select> */}
        </Layout>
        <View style={{ marginBottom: RF(2) + 56 }}>
          <RankingGeneralComponent
            data={this.state.data}
            authUser={this.state.authUser}
            pointField="pointForecast"
            final={true}
            maxWidth={this.props.maxWidth}
          />
        </View>

        {this.state.currentPlayer && (
          <View
            style={{
              position: "fixed",
              width: "100%",
              height: 44,
              bottom: 0,
            }}
          >
            <View
              style={{
                backgroundColor: "dimgray",
                position: "absolute",
                height: "100%",
                width: "100%"
              }}
            />

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignContent: "stretch",
                alignItems: "flex-start",
                justifyContent: "space-between",
                borderBottomColor: "gray",
                borderBottomWidth: 0.5,
                paddingBottom: 5,
                paddingTop: 5,
              }}
            >
              {this.state.currentPlayer.rank === 1 && (
                <View>
                  <Image
                    style={{
                      width: 80,
                      height: 15,
                      maginBottom: 5,
                      alignSelf: "center",
                    }}
                    source={require("../../assets/images/first.png")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      width: 80,
                      textAlignVertical: "center",
                      color: "lightgray",
                      maginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    {`${this.state.currentPlayer.pointForecast}`}
                  </Text>
                </View>
              )}
              {this.state.currentPlayer.rank > 1 && (
                <View>
                  <Text
                    style={{
                      fontSize: RF(2),
                      textAlign: "center",
                      width: 80,
                      textAlignVertical: "center",
                      color: "white",
                    }}
                  >
                    {`${this.state.currentPlayer.rank}`}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      width: 80,
                      textAlignVertical: "center",
                      color: "lightgray",
                    }}
                  >
                    {`${this.state.currentPlayer.pointForecast}`}
                  </Text>
                </View>
              )}

              <Text
                style={{
                  color: TEXT_COLOR,
                  textAlign: "left",
                  textAlignVertical: "center",
                  alignSelf: "center",
                  flex: 1,
                  marginLeft: 8,
                  fontSize: RF(2.2),
                }}
              >
                {`${this.state.currentPlayer.firstName
                  } ${this.state.currentPlayer.lastName
                    .charAt(0)
                    .toUpperCase()}.`}
              </Text>

              <Text
                style={{
                  fontSize: RF(2),
                  color: TEXT_COLOR,
                  textAlign: "right",
                  textAlignVertical: "center",
                  width: 80,
                  paddingBottom: 5,
                  paddingTop: 5,
                  paddingRight: 8,
                  alignSelf: "center",
                }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {this.state.currentPlayer.logeNumber
                  ? this.state.currentPlayer.logeNumber
                  : ""}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(
  withMainLayout(withTranslation(RankingForecastScreen))
);
