function isEmpty(str) {
    return (!str || 0 === str.length);
}

export function isEn() {
    return window.localStorage.getItem("lang") === "en";
}

function getConfigByKey(key, defaultValue) {
    return window.localStorage.getItem(key) || defaultValue;
}

const IMAGE_TRANSPARENT = "https://storage.googleapis.com/take-over-football/b63e9dbaa6744484844b433bde847a64/transparent.png";

export const COLOR_LINE_PAGING = "#4DE696";//"red"
export const COLOR_GOOD = "#E0CDA5";//"limegreen"
export const COLOR_WRONG = "#F0EAEA";//"white"

export const BG_GOOD = "https://storage.googleapis.com/take-over-loges.appspot.com/asse_bonne_reponse_95ed91fdce/asse_bonne_reponse_95ed91fdce.png";//"limegreen"
export const BG_WRONG = "https://storage.googleapis.com/take-over-loges.appspot.com/asse_reponse_5cc53baf2d/asse_reponse_5cc53baf2d.png";//"white"

// export const API_TAKEOVER = "http://localhost:1337";
// export const ASSE_REDIRECT_URL = "http://localhost:3000";
export const API_TAKEOVER = "https://ws.take-over.io";
export const ASSE_REDIRECT_URL = "https://quiz.asse.fr";


export const THEME = process.env.REACT_APP_THEME;
export const THEME_ID = process.env.REACT_APP_THEME_ID;
export const MAIN_URL = process.env.REACT_APP_THEME === 'assejeux' ? 'quiz.asse.fr' : process.env.REACT_APP_MAIN_URL;

export const MAIN_DYNAMIC_LINK = getConfigByKey('MAIN_DYNAMIC_LINK', process.env.REACT_APP_MAIN_DYNAMIC_LINK ) || '';

export const THEME_HEADER_RIGHT = getConfigByKey('THEME_HEADER_RIGHT', process.env.REACT_APP_THEME_HEADER_RIGHT) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_HEADER_RIGHT', process.env.REACT_APP_THEME_HEADER_RIGHT);
export const THEME_HEADER_LEFT = getConfigByKey('THEME_HEADER_LEFT', process.env.REACT_APP_THEME_HEADER_LEFT) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_HEADER_LEFT', process.env.REACT_APP_THEME_HEADER_LEFT);
export const THEME_MENU_LOGO = getConfigByKey('THEME_MENU_LOGO', process.env.REACT_APP_THEME_MENU_LOGO) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_MENU_LOGO', process.env.REACT_APP_THEME_MENU_LOGO);
export const THEME_BACKGROUND_MOBILE = getConfigByKey('THEME_BACKGROUND_MOBILE', process.env.REACT_APP_THEME_BACKGROUND_MOBILE) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_BACKGROUND_MOBILE', process.env.REACT_APP_THEME_BACKGROUND_MOBILE);
export const THEME_BACKGROUND_PC = getConfigByKey('THEME_BACKGROUND_PC', process.env.REACT_APP_THEME_BACKGROUND_PC) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_BACKGROUND_PC', process.env.REACT_APP_THEME_BACKGROUND_PC);

export const THEME_VIDEO_BACKGROUND = getConfigByKey('THEME_VIDEO_BACKGROUND', process.env.REACT_APP_THEME_VIDEO_BACKGROUND) === "" ? false : getConfigByKey('THEME_VIDEO_BACKGROUND', process.env.REACT_APP_THEME_VIDEO_BACKGROUND);

export const THEME_BACKGROUND_TELE = getConfigByKey('THEME_BACKGROUND_TELE', process.env.REACT_APP_THEME_BACKGROUND_TELE) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_BACKGROUND_TELE', process.env.REACT_APP_THEME_BACKGROUND_TELE);
export const THEME_BACKGROUND_TELE_WINNER = getConfigByKey('THEME_BACKGROUND_TELE_WINNER', process.env.REACT_APP_THEME_BACKGROUND_TELE_WINNER) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_BACKGROUND_TELE_WINNER', process.env.REACT_APP_THEME_BACKGROUND_TELE_WINNER);
export const THEME_BANNER_FULL = getConfigByKey('THEME_BANNER_FULL', process.env.REACT_APP_THEME_BANNER_FULL) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_BANNER_FULL', process.env.REACT_APP_THEME_BANNER_FULL);
export const THEME_TUTO = getConfigByKey('THEME_TUTO', process.env.REACT_APP_THEME_TUTO) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('THEME_TUTO', process.env.REACT_APP_THEME_TUTO);

export const BANNER_BOTTOM_ENABLE = getConfigByKey('BANNER_BOTTOM_ENABLE', process.env.REACT_APP_BANNER_BOTTOM_ENABLE) === "true";
export function BANNER_BOTTOM() { return isEn() ? (getConfigByKey('BANNER_BOTTOM_EN', process.env.REACT_APP_BANNER_BOTTOM_EN) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('BANNER_BOTTOM_EN', process.env.REACT_APP_BANNER_BOTTOM_EN)) : (getConfigByKey('BANNER_BOTTOM', process.env.REACT_APP_BANNER_BOTTOM) === "false" ? IMAGE_TRANSPARENT : getConfigByKey('BANNER_BOTTOM', process.env.REACT_APP_BANNER_BOTTOM)); }
export function BANNER_BOTTOM_URL() { return isEn() ? getConfigByKey('BANNER_BOTTOM_URL_EN', process.env.REACT_APP_BANNER_BOTTOM_URL_EN) : getConfigByKey('BANNER_BOTTOM_URL', process.env.REACT_APP_BANNER_BOTTOM_URL); }

export const HIDE_QRCODE = getConfigByKey('HIDE_QRCODE', process.env.REACT_APP_HIDE_QRCODE) === "true";
export const SHOW_QRCODE_DUEL = getConfigByKey('SHOW_QRCODE_DUEL', process.env.REACT_APP_SHOW_QRCODE_DUEL) === "true";

export const FORCED_VALIDATION_EMAIL = getConfigByKey('FORCED_VALIDATION_EMAIL', process.env.REACT_APP_FORCED_VALIDATION_EMAIL) === "true";

export const FORECAST_FOOT_WINNER = getConfigByKey('FORECAST_FOOT_WINNER', process.env.REACT_APP_FORECAST_FOOT_WINNER) === "true";
export const FORECAST_FOOT_SCORE = getConfigByKey('FORECAST_FOOT_SCORE', process.env.REACT_APP_FORECAST_FOOT_SCORE) === "true";
export const FORECAST_FOOT_TOTAL_SCORE = getConfigByKey('FORECAST_FOOT_TOTAL_SCORE', process.env.REACT_APP_FORECAST_FOOT_TOTAL_SCORE) === "true";
export const FORECAST_FOOT_FIRST_SCORER = getConfigByKey('FORECAST_FOOT_FIRST_SCORER', process.env.REACT_APP_FORECAST_FOOT_FIRST_SCORER) === "true";
export const FORECAST_FOOT_FIRST_SCORE_MINUTE = getConfigByKey('FORECAST_FOOT_FIRST_SCORE_MINUTE', process.env.REACT_APP_FORECAST_FOOT_FIRST_SCORE_MINUTE) === "true";

export const FORECAST_RUGBY_WINNER = getConfigByKey('FORECAST_RUGBY_WINNER', process.env.REACT_APP_FORECAST_RUGBY_WINNER) === "true";
export const FORECAST_RUGBY_SCORE_GAP = getConfigByKey('FORECAST_RUGBY_SCORE_GAP', process.env.REACT_APP_FORECAST_RUGBY_SCORE_GAP) === "true";
export const FORECAST_RUGBY_TOTAL_SCORE = getConfigByKey('FORECAST_RUGBY_TOTAL_SCORE', process.env.REACT_APP_FORECAST_RUGBY_TOTAL_SCORE) === "true";
export const FORECAST_RUGBY_FIRST_SCORE = getConfigByKey('FORECAST_RUGBY_FIRST_SCORE', process.env.REACT_APP_FORECAST_RUGBY_FIRST_SCORE) === "true";

export const FORECAST_BASKET_WINNER = getConfigByKey('FORECAST_BASKET_WINNER', process.env.REACT_APP_FORECAST_BASKET_WINNER) === "true";
export const FORECAST_BASKET_SCORE_GAP = getConfigByKey('FORECAST_BASKET_SCORE_GAP', process.env.REACT_APP_FORECAST_BASKET_SCORE_GAP) === "true";
export const FORECAST_BASKET_TOTAL_SCORE = getConfigByKey('FORECAST_BASKET_TOTAL_SCORE', process.env.REACT_APP_FORECAST_BASKET_TOTAL_SCORE) === "true";
export const FORECAST_BASKET_BEST_SCORER = getConfigByKey('FORECAST_BASKET_BEST_SCORER', process.env.REACT_APP_FORECAST_BASKET_BEST_SCORER) === "true";

export const GEOLOCATION = getConfigByKey('GEOLOCATION', process.env.REACT_APP_GEOLOCATION) === "true";
export const GEOLOCATION_DISTANCE = getConfigByKey('GEOLOCATION_DISTANCE', process.env.REACT_APP_GEOLOCATION_DISTANCE) || 1000; // m
export const GEOLOCATION_OBLIGATION_TYPE = getConfigByKey('GEOLOCATION_OBLIGATION_TYPE', process.env.REACT_APP_GEOLOCATION_OBLIGATION_TYPE) || "BY_ONE"; // BY_ONE , BY_GROUP
export const GEOLOCATION_ONE_LON = getConfigByKey('GEOLOCATION_ONE_LON', process.env.REACT_APP_GEOLOCATION_LON) || '2.333333';
export const GEOLOCATION_ONE_LAT = getConfigByKey('GEOLOCATION_ONE_LAT', process.env.REACT_APP_GEOLOCATION_LAT) || '48.866667';
export const GEOLOCATION_TIMEOUT = getConfigByKey('GEOLOCATION_TIMEOUT', process.env.REACT_APP_GEOLOCATION_TIMEOUT) || 0; // min

export const MAIN_COLOR = getConfigByKey('MAIN_COLOR', process.env.REACT_APP_MAIN_COLOR);
export const BUTTON_COLOR = getConfigByKey('BUTTON_COLOR', process.env.REACT_APP_BUTTON_COLOR);
export const MENU_COLOR = getConfigByKey('MENU_COLOR', process.env.REACT_APP_MENU_COLOR);

export const TEXT_COLOR = getConfigByKey('TEXT_COLOR', process.env.REACT_APP_TEXT_COLOR);

export const TV_TEMPLATE = getConfigByKey('TV_TEMPLATE', process.env.REACT_APP_TV_TEMPLATE || "1") || "1";
export const TEXT_TV_COLOR = getConfigByKey('TEXT_TV_COLOR', process.env.REACT_APP_TEXT_TV_COLOR);
export const QRCODE_FOREGROUND_COLOR = getConfigByKey('QRCODE_FOREGROUND_COLOR', process.env.REACT_APP_QRCODE_FOREGROUND_COLOR);
export const QRCODE_BACKGROUND_COLOR = getConfigByKey('QRCODE_BACKGROUND_COLOR', process.env.REACT_APP_QRCODE_BACKGROUND_COLOR);
export const BORDER_COLOR = getConfigByKey('BORDER_COLOR', process.env.REACT_APP_BORDER_COLOR);
export const BORDER_WIDTH = parseInt(getConfigByKey('BORDER_WIDTH', process.env.REACT_APP_BORDER_WIDTH) || 0, 10);

export const FORECAST_WINNER_POINT = parseInt(getConfigByKey('FORECAST_WINNER_POINT', process.env.REACT_APP_FORECAST_WINNER_POINT), 10) || 10;
export const FORECAST_SCORE_GAP_POINT = parseInt(getConfigByKey('FORECAST_SCORE_GAP_POINT', process.env.REACT_APP_FORECAST_SCORE_GAP_POINT), 10) || 10;
export const FORECAST_SCORE_POINT = parseInt(getConfigByKey('FORECAST_SCORE_POINT', process.env.REACT_APP_FORECAST_SCORE_POINT), 10) || 10;
export const FORECAST_TOTAL_SCORE_POINT = parseInt(getConfigByKey('FORECAST_TOTAL_SCORE_POINT', process.env.REACT_APP_FORECAST_TOTAL_SCORE_POINT), 10) || 10;
export const FORECAST_FIRST_SCORE_POINT = parseInt(getConfigByKey('FORECAST_FIRST_SCORE_POINT', process.env.REACT_APP_FORECAST_FIRST_SCORE_POINT), 10) || 10;
export const FORECAST_FIRST_SCORER_POINT = parseInt(getConfigByKey('FORECAST_FIRST_SCORER_POINT', process.env.REACT_APP_FORECAST_FIRST_SCORER_POINT), 10) || 10;
export const FORECAST_FIRST_SCORE_MINUTE_POINT = parseInt(getConfigByKey('FORECAST_FIRST_SCORE_MINUTE_POINT', process.env.REACT_APP_FORECAST_FIRST_SCORE_MINUTE_POINT), 10) || 10;
export const FORECAST_BEST_SCORER_POINT = parseInt(getConfigByKey('FORECAST_BEST_SCORER_POINT', process.env.REACT_APP_FORECAST_BEST_SCORER_POINT), 10) || 10;

export const QUIZ_EASY_POINT = parseInt(getConfigByKey('QUIZ_ASYNC_EASY_POINT', process.env.REACT_APP_QUIZ_ASYNC_EASY_POINT), 10) || 5;
export const QUIZ_MEDIUM_POINT = parseInt(getConfigByKey('QUIZ_ASYNC_MEDIUM_POINT', process.env.REACT_APP_QUIZ_ASYNC_MEDIUM_POINT), 10) || 10;
export const QUIZ_HARD_POINT = parseInt(getConfigByKey('QUIZ_ASYNC_HARD_POINT', process.env.REACT_APP_QUIZ_ASYNC_HARD_POINT), 10) || 20;
export const ASYNC_COUNT_DOWN = getConfigByKey('QUIZ_ASYNC_COUNT_DOWN', process.env.REACT_APP_QUIZ_ASYNC_COUNT_DOWN) === "true";
export const ASYNC_SKIP_NEXT = getConfigByKey('QUIZ_ASYNC_SKIP_NEXT', process.env.REACT_APP_QUIZ_ASYNC_SKIP_NEXT) === "true";

export function THEME_TEXT_MENU() { return isEn() ? decodeURIComponent(getConfigByKey('THEME_MENU_TEXT_EN', process.env.REACT_APP_THEME_MENU_TEXT_EN) || '') : decodeURIComponent(getConfigByKey('THEME_MENU_TEXT', process.env.REACT_APP_THEME_MENU_TEXT) || ''); }
export function TEXT_LOGIN() { return isEn() ? decodeURIComponent(getConfigByKey('TEXT_LOGIN_EN', process.env.REACT_APP_TEXT_LOGIN_EN) || "") : decodeURIComponent(getConfigByKey('TEXT_LOGIN', process.env.REACT_APP_TEXT_LOGIN) || ""); }
export function TEXT_HOME_1() { return isEn() ? decodeURIComponent(getConfigByKey('TEXT_HOME_1_EN', process.env.REACT_APP_TEXT_HOME_1_EN) || "") : decodeURIComponent(getConfigByKey('TEXT_HOME_1', process.env.REACT_APP_TEXT_HOME_1) || ""); }
export function TEXT_HOME_2() { return isEn() ? decodeURIComponent(getConfigByKey('TEXT_HOME_2_EN', process.env.REACT_APP_TEXT_HOME_2_EN) || "") : decodeURIComponent(getConfigByKey('TEXT_HOME_2', process.env.REACT_APP_TEXT_HOME_2) || ""); }
export function TEXT_MENU() { return isEn() ? decodeURIComponent(getConfigByKey('TEXT_MENU_EN', process.env.REACT_APP_TEXT_MENU_EN) || "") : decodeURIComponent(getConfigByKey('TEXT_MENU', process.env.REACT_APP_TEXT_MENU) || "") };
export function TEXT_UNDER_MENU() { return isEn() ? decodeURIComponent(getConfigByKey('TEXT_UNDER_MENU_EN', process.env.REACT_APP_TEXT_UNDER_MENU_EN) || "") : decodeURIComponent(getConfigByKey('TEXT_UNDER_MENU', process.env.REACT_APP_TEXT_UNDER_MENU) || ""); }

export const THEME_MENU_LOGO_URL = getConfigByKey('THEME_MENU_LOGO_URL', process.env.REACT_APP_THEME_MENU_LOGO_URL);

export const USERWAY_ENABLED = getConfigByKey('USERWAY_ENABLED', process.env.REACT_APP_USERWAY_ENABLED) === "true";
export const MENU_V2_ENABLED = getConfigByKey('MENU_V2_ENABLED', process.env.REACT_APP_MENU_V2_ENABLED) === "true";

export const SECRET_CODE_ENABLED = getConfigByKey('SECRET_CODE_ENABLED', process.env.REACT_APP_SECRET_CODE_ENABLED) === "true";
export const SECRET_CODE = getConfigByKey('SECRET_CODE', process.env.REACT_APP_SECRET_CODE);

export const LOGE_NAME = getConfigByKey('LOGE_NAME', process.env.REACT_APP_LOGE_NAME);
export const URL_RULES = getConfigByKey('URL_RULES', process.env.REACT_APP_URL_RULES);

export const PROFILE_CIVIL = getConfigByKey('PROFILE_CIVIL', process.env.REACT_APP_PROFILE_CIVIL) === "true";
export const PROFILE_COMPANY = getConfigByKey('PROFILE_COMPANY', process.env.REACT_APP_PROFILE_COMPANY) === "true";
export const PROFILE_COMPANY_OBLIGATION = getConfigByKey('PROFILE_COMPANY_OBLIGATION', process.env.REACT_APP_PROFILE_COMPANY_OBLIGATION) === "true";
export const PROFILE_PHONE = getConfigByKey('PROFILE_PHONE', process.env.REACT_APP_PROFILE_PHONE) === "true";
export const PROFILE_PHONE_OBLIGATION = getConfigByKey('PROFILE_PHONE_OBLIGATION', process.env.REACT_APP_PROFILE_PHONE_OBLIGATION) === "true";

export const TEXT_OPTION_1 = isEn() ? (isEmpty(getConfigByKey('TEXT_OPTION_1_EN')) ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_1_EN'))) : (isEmpty(getConfigByKey('TEXT_OPTION_1')) || getConfigByKey('TEXT_OPTION_1') === "null" ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_1'))); 
export const TEXT_OPTION_2 = isEn() ? (isEmpty(getConfigByKey('TEXT_OPTION_2_EN')) ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_2_EN'))) : (isEmpty(getConfigByKey('TEXT_OPTION_2')) || getConfigByKey('TEXT_OPTION_2') === "null" ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_2'))); 
export const TEXT_OPTION_3 = isEn() ? (isEmpty(getConfigByKey('TEXT_OPTION_3_EN')) ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_3_EN'))) : (isEmpty(getConfigByKey('TEXT_OPTION_3')) || getConfigByKey('TEXT_OPTION_3') === "null" ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_3'))); 
export const TEXT_OPTION_4 = isEn() ? (isEmpty(getConfigByKey('TEXT_OPTION_4_EN')) ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_4_EN'))) : (isEmpty(getConfigByKey('TEXT_OPTION_4')) || getConfigByKey('TEXT_OPTION_4') === "null" ? null : decodeURIComponent(getConfigByKey('TEXT_OPTION_4'))); 

export const LOGE_TEXT = isEn() ? decodeURIComponent(getConfigByKey('LOGE_TEXT_EN', process.env.REACT_APP_LOGE_TEXT_EN)) : decodeURIComponent(getConfigByKey('LOGE_TEXT', process.env.REACT_APP_LOGE_TEXT)); 
export function LOGE_NUMBERS() {
    try {
        return isEn() ? JSON.parse(getConfigByKey('LOGE_NUMBERS_EN', process.env.REACT_APP_LOGE_NUMBERS_EN) || "[]") : JSON.parse(getConfigByKey('LOGE_NUMBERS', process.env.REACT_APP_LOGE_NUMBERS) || "[]");
    } catch (error) {
        return [];
    }
}

export const TEXT_TUTO = decodeURIComponent(getConfigByKey('TEXT_TUTO', process.env.REACT_APP_TEXT_TUTO));

export const PROFILE_JOB_TEXT = isEn() ? decodeURIComponent(getConfigByKey('PROFILE_JOB_TEXT_EN', process.env.REACT_APP_PROFILE_JOB_TEXT_EN)) : decodeURIComponent(getConfigByKey('PROFILE_JOB_TEXT', process.env.REACT_APP_PROFILE_JOB_TEXT)); 
export function PROFILE_JOB_ITEMS() {
    try {
        return isEn() ? JSON.parse(getConfigByKey('PROFILE_JOB_ITEMS_EN', process.env.REACT_APP_PROFILE_JOB_ITEMS_EN) || "[]") : JSON.parse(getConfigByKey('PROFILE_JOB_ITEMS', process.env.REACT_APP_PROFILE_JOB_ITEMS) || "[]");
    } catch (error) {
        return [];
    }
}

export const TEXT_OPTION_2_OBLIGATION = getConfigByKey('TEXT_OPTION_2_OBLIGATION', process.env.REACT_APP_TEXT_OPTION_2_OBLIGATION) === "true";
export const TEXT_OPTION_3_OBLIGATION = getConfigByKey('TEXT_OPTION_3_OBLIGATION', process.env.REACT_APP_TEXT_OPTION_3_OBLIGATION) === "true";

export const SHOW_GAMES = getConfigByKey('SHOW_GAMES', process.env.REACT_APP_SHOW_GAMES) === "true";
export const GO_HOME_INSIDE = getConfigByKey('GO_HOME_INSIDE', process.env.REACT_APP_GO_HOME_INSIDE) === "true";
export const SHOW_RANKING = getConfigByKey('SHOW_RANKING', process.env.REACT_APP_SHOW_RANKING) === "true";
export const SHOW_CALENDER = getConfigByKey('SHOW_CALENDER', process.env.REACT_APP_SHOW_CALENDER) === "true";
export const SHOW_SHOP = getConfigByKey('SHOW_SHOP', process.env.REACT_APP_SHOW_SHOP) === "true";

export const APP_NAVTAB = getConfigByKey('NAVTAB', process.env.REACT_APP_NAVTAB) === "true";
export const APP_MULTILANG = getConfigByKey('MULTILANG', process.env.REACT_APP_MULTILANG) === "true";
export const APP_RANKING_3_WINNER_TV = getConfigByKey('RANKING_3_WINNER_TV', process.env.RANKING_3_WINNER_TV) === "true";
export const APP_RANKING_TOTAL_TV = getConfigByKey('RANKING_TOTAL_TV', process.env.RANKING_TOTAL_TV) === "true";
export const APP_RANKING_TOTAL = getConfigByKey('RANKING_TOTAL', process.env.REACT_APP_RANKING_TOTAL) === "true";
export const APP_RANKING_QUIZ = getConfigByKey('RANKING_QUIZ', process.env.REACT_APP_RANKING_QUIZ) === "true";
export const APP_RANKING_FORECAST = getConfigByKey('RANKING_FORECAST', process.env.REACT_APP_RANKING_FORECAST) === "true";

export const SHOW_NULBER_ONLINE = getConfigByKey('SHOW_NULBER_ONLINE', process.env.REACT_APP_SHOW_NULBER_ONLINE) === "true";
export const SHOW_MINUTER = getConfigByKey('SHOW_MINUTER', process.env.REACT_APP_SHOW_MINUTER) === "true";
export const SHOW_MINUTER_MOBILE = getConfigByKey('SHOW_MINUTER_MOBILE', process.env.REACT_APP_SHOW_MINUTER_MOBILE) === "true";

export const SHOW_TUTO = getConfigByKey('SHOW_TUTO', process.env.REACT_APP_SHOW_TUTO) === "true";
export const SHOW_TUTO_GIFT = getConfigByKey('SHOW_TUTO_GIFT', process.env.REACT_APP_SHOW_TUTO_GIFT) === "true";

export const DISPLAY_NAME = getConfigByKey('DISPLAY_NAME', process.env.REACT_APP_DISPLAY_NAME) || 'SHORT';

export const EMAILS_RESULTS = getConfigByKey('EMAILS_RESULTS', process.env.REACT_APP_EMAILS_RESULTS);
export const MODE_TEST = getConfigByKey('MODE_TEST', process.env.MODE_TEST) === "true";
export const MODE_TEST_EMAILS = getConfigByKey('MODE_TEST_EMAILS', []) ;

export const PROFILE_LOGE_NUMBER = getConfigByKey('PROFILE_LOGE_NUMBER', process.env.REACT_APP_PROFILE_LOGE_NUMBER) === "true";
export const PROFILE_LOGE_UPDATE_BEFORE_EVENT = getConfigByKey('PROFILE_LOGE_UPDATE_BEFORE_EVENT', process.env.REACT_APP_PROFILE_LOGE_UPDATE_BEFORE_EVENT) === "true";


export const PROFILE_DOB = getConfigByKey('PROFILE_DOB', process.env.REACT_APP_PROFILE_DOB) === "true";
export const PROFILE_DOB_OBLIGATION = getConfigByKey('PROFILE_DOB_OBLIGATION', process.env.REACT_APP_PROFILE_DOB_OBLIGATION) === "true";
export const PROFILE_ADDRESS_OBLIGATION = getConfigByKey('PROFILE_ADDRESS_OBLIGATION', process.env.REACT_APP_PROFILE_ADDRESS_OBLIGATION) === "true";
export const PROFILE_ADDRESS = getConfigByKey('PROFILE_ADDRESS', process.env.REACT_APP_PROFILE_ADDRESS) === "true";
export const PROFILE_ADDRESS_STREET = getConfigByKey('PROFILE_ADDRESS_STREET', process.env.REACT_APP_PROFILE_ADDRESS_STREET) === "true";
export const PROFILE_ADDRESS_POSTCODE = getConfigByKey('PROFILE_ADDRESS_POSTCODE', process.env.REACT_APP_PROFILE_ADDRESS_POSTCODE) === "true";
export const PROFILE_ADDRESS_CITY = getConfigByKey('PROFILE_ADDRESS_CITY', process.env.REACT_APP_PROFILE_ADDRESS_CITY) === "true";
export const PROFILE_ADDRESS_COUNTRY = getConfigByKey('PROFILE_ADDRESS_COUNTRY', process.env.REACT_APP_PROFILE_ADDRESS_COUNTRY) === "true";
export const PROFILE_JOB = getConfigByKey('PROFILE_JOB', process.env.REACT_APP_PROFILE_JOB) === "true";

export const PROFILE_JOB_OBLIGATION = getConfigByKey('PROFILE_JOB_OBLIGATION', process.env.REACT_APP_PROFILE_JOB_OBLIGATION) === "true";
export const PROFILE_JOB_SELECT = getConfigByKey('PROFILE_JOB_SELECT', process.env.REACT_APP_PROFILE_JOB_SELECT) === "true";


export const TIME_PAUSE = parseInt(getConfigByKey('TIME_PAUSE', process.env.REACT_APP_TIME_PAUSE), 10);
export const TIME_DELAY = parseInt(getConfigByKey('TIME_DELAY', process.env.REACT_APP_TIME_DELAY), 10);
export const TIME_EXTRA_ANIM = parseInt(getConfigByKey('TIME_EXTRA_ANIM', process.env.REACT_APP_TIME_EXTRA_ANIM), 10);

export const TELE_MARGIN_TOP = parseInt(getConfigByKey('TELE_MARGIN_TOP', process.env.REACT_APP_TELE_MARGIN_TOP), 10) || 0;
export const TELE_MARGIN_RIGHT = parseInt(getConfigByKey('TELE_MARGIN_RIGHT', process.env.REACT_APP_TELE_MARGIN_RIGHT), 10) || 0;
export const TELE_MARGIN_BOTTOM = parseInt(getConfigByKey('TELE_MARGIN_BOTTOM', process.env.REACT_APP_TELE_MARGIN_BOTTOM), 10) || 0;
export const TELE_MARGIN_LEFT = parseInt(getConfigByKey('TELE_MARGIN_LEFT', process.env.REACT_APP_TELE_MARGIN_LEFT), 10) || 0;


export const ONLY_ANONYMOUS = getConfigByKey('ONLY_ANONYMOUS', process.env.REACT_APP_ONLY_ANONYMOUS) === "true";
export const BG_TELE = getConfigByKey('BG_TELE', process.env.REACT_APP_BG_TELE);

export const FIREBASE_APP_API_KEY = process.env.REACT_APP_FIREBASE_APP_API_KEY;
export const FIREBASE_APP_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_APP_AUTH_DOMAIN;
export const FIREBASE_APP_DATABASE_URL = process.env.REACT_APP_FIREBASE_APP_DATABASE_URL;
export const FIREBASE_APP_PROJECT_ID = process.env.REACT_APP_FIREBASE_APP_PROJECT_ID;
export const FIREBASE_APP_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_APP_STORAGE_BUCKET;
export const FIREBASE_APP_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASURE_ID = process.env.REACT_APP_FIREBASE_MEASURE_ID;