import React from "react";
import {  ASYNC_COUNT_DOWN } from "../../constants/const";
import moment from "moment";

export class BasePrediction extends React.Component {
  _isMounted = false;
  screen = ASYNC_COUNT_DOWN ? "CountDown" : "Question";
  constructor(props) {
    super(props);
    console.log("constructor BasePrediction");
    this.alreadyCount = false;
    this.currentPredictionId = null;
    this.currentPrediction = null;
    this.currentQuestion = null;
    this.currentQuestionIndex = 0;
    this.currentPoints = 0;
    this.currentPointGap = 0;
    this.currentSetQuestions = null;
    this.currentQuestionResult = null;
    this.currentResults = null;
    this.playersRanking = [];
    this.snapshotQuestion = null;
    this.timeStartQA = null;
    this.updatedPlayed = false;
    this.playerInfo = null;
    this.state = {
      screen: this.screen,
      shouldShow: true,
      currentPrediction: null,
      currentQuestion: null,
      currentSetQuestions: null,
      currentResults: null,
      displayResult: false,
      timeLeft: ASYNC_COUNT_DOWN ? 5 : 0,
      answered: false,
      updateNum: 0,
      responses: {}
    };
  }

  checkTheParams = () => {
    console.log("called checkTheParams = ", this.props.currentPredictions);
    if (this.props.navigation) {
      if (
        this.props.navigation.state.params &&
        this.props.navigation.state.params.pid
      ) {
        this.currentPredictionId = this.props.navigation.state.params.pid;
        if (this.props.currentPredictions) {
          const ca = this.props.currentPredictions.filter((e) => {
            return e.id === this.currentPredictionId;
          });
          if (ca && ca.length > 0) {
            this.currentPrediction = ca[0];
            this.checkCurrentResponses();
          }
        } 
        return true;
      } else {
        this.props.navigation.navigate("Home");
      }
    }
    return false;
  };

  checkQuizAndQuestions = (isNext) => {
    if (
      this.currentPrediction !== null &&
      this.currentPrediction.start &&
      this.props.authUser
    ) {
      console.log("checkQuizAndQuestions with isNext = ", isNext);
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      const nowMs = Math.floor(
        Date.now() + parseInt(window.localStorage.getItem("delay"), 10)
      );
      const timeLeft = this.currentPrediction.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        if (this.currentSetQuestions === null) {
          var { questions } = this.currentPrediction;
          // const currentSetQuestions = questions;
          this.currentSetQuestions = questions;
        }
        //check the count down before start
        if (ASYNC_COUNT_DOWN) {
          console.log("this.alreadyCount = ", this.alreadyCount);
          if (!this.alreadyCount) {
            if (this._isMounted) {
              this.setState({
                screen: "CountDown",
                timeLeft: 5,
                currentPrediction: this.currentPrediction,
                updateNum: this.state.updateNum + 1,
              }, () => {
                this.alreadyCount = true;
              });
            }
            return;
          }
        }
        // console.log("this.currentSetQuestions = ", this.currentSetQuestions);
        if (isNext) {
          this.currentQuestionIndex += 1;
        }
        if (this.currentQuestionIndex < this.currentSetQuestions.length) {
          this.currentQuestion = this.currentSetQuestions[
            this.currentQuestionIndex
          ];

          const qTime = this.currentQuestion ? this.currentQuestion.time : 0;
          this.timeStartQA = now;
          if (this.currentQuestionIndex === 0) {
            this.timeStartQA = this.timeStartQA + qTime;
          } else {
            this.timeStartQA =
              // TIME_EXTRA_ANIM +
              this.timeStartQA +
              // TIME_PAUSE +
              qTime
            // + TIME_EXTRA_ANIM;
          }
          // const timePastWithResult =
          //   this.timeStartQA + TIME_PAUSE + TIME_EXTRA_ANIM;

          this.currentQuestion.timeLeft = this.timeStartQA - now;
          this.currentQuestion.timeLeftMs = this.timeStartQA * 1000 - nowMs;
          // this.currentQuestion.displayResult = now > this.timeStartQA;
          // this.currentQuestion.displayResultTimeLeft = timePastWithResult - now;
          this.currentQuestion.timeEnd = this.timeStartQA;
          // update question played

          this.bindData();
        } else {
          if (this._isMounted) {
            this.setState({
              screen: "finished",
              updateNum: this.state.updateNum + 1,
            });
          }
        }
      } else {
        console.log("set screen countdown");
        if (this._isMounted) {
          this.setState({
            screen: "CountDown",
            timeLeft: timeLeft,
            currentPrediction: this.currentPrediction,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    } else {
      // navigate to screen no QUIZ
      this.props.navigation.navigate("Home");
    }
  };

  bindData = () => {
    if (this._isMounted) {
      this.setState(
        {
          screen: 'Question',
          currentPrediction: this.currentPrediction,
          currentQuestion: this.currentQuestion,
          currentQuestionIndex: this.currentQuestionIndex,
          // displayResult: this.currentQuestion.displayResult,
          updateNum: this.state.updateNum + 1,
          // timeLeft: this.currentQuestion.displayResult
          //   ? this.currentQuestion.displayResultTimeLeft
          //   : this.currentQuestion.timeLeft,
        },
        () => {
          if (this.props.authUser) {
            if (this.updatedPlayed === false) {
              this.updatedPlayed = true;
              this.props.firebase
                .predictionsResults(
                  `${this.currentPrediction.id}/players/${this.props.authUser.uid}`
                )
                .set(
                  {
                    played: this.props.firebase.FieldValue.increment(1),
                    questionsPlayed: this.props.firebase.FieldValue.arrayUnion(this.currentQuestion.id),
                    lastUpdate: moment().format("YYYY-MM-DD"),
                  },
                  { merge: true }
                );
            } else {
              this.props.firebase
                .predictionsResults(
                  `${this.currentPrediction.id}/players/${this.props.authUser.uid}`
                )
                .set(
                  {
                    questionsPlayed: this.props.firebase.FieldValue.arrayUnion(
                      this.currentQuestion.id
                    ),
                  },
                  { merge: true }
                );
            }
          }
        }
      );
    }
  };

  componentDidMount() {
    // Get questions disponible
    this._isMounted = true;
    if (this.props.authUser !== null) {
      this.checkTheParams();
    }
  }

  checkCurrentResponses = () => {
    if (
      this.currentPrediction !== null &&
      this.props.currentPredictions &&
      this.playerInfo === null
    ) {
      //Check the questions déjà jouer
      //get info played questions
      console.log("componentDidMount called");
      this.props.firebase
        .predictionsResults(`${this.currentPrediction.id}/players/${this.props.authUser.uid}`)
        .get()
        .then((player) => {
          if (player.exists) {
            this.playerInfo = player.data();
          } else {
            this.playerInfo = { played: 0, questionsPlayed: [], lastUpdate: moment().format("YYYY-MM-DD") };
            this.props.firebase
              .predictionsResults(
                `${this.currentPrediction.id}/players/${this.props.authUser.uid}`
              )
              .set(
                {
                  played: 0,
                  questionsPlayed: [],
                  lastUpdate: moment().format("YYYY-MM-DD"),
                },
                { merge: true }
              );
          }
          console.log("checkQuizAndQuestions from get player");
          this.checkQuizAndQuestions();
        })
        .catch((err) => {
          console.log("Can not get player info ", err);
          // this.checkQuizAndQuestions();
        });
    }
  }



  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default BasePrediction;
