import { Dimensions } from "react-native";

const { height, width } = Dimensions.get("window");

// Remove the status bar height since the modal view does not cover this area
const getHeight = () =>  height;
export const getWidthPercent = percentage => (width * percentage) / 100;
export const getWidthPercentMaxLimited = (percentage, max) => {return width > max ? (max * percentage)/100 : (width * percentage) / 100};
export const getHeightPercent = percentage => (height * percentage) / 100;
export const getPercent = percentage => (((getHeight() + width) / 2) * percentage) / 100;

export const getWidthFromHD = value => (width * value) / 1920;
export const getHeightFromHD = value => (height * value) / 1080;


export const getWidthFromHDMargin = (value, mtop, mright, mbottom, mleft) => ((width - mright - mleft) * value) / (1920 - mtop - mbottom);
export const getHeightFromHDMargin = (value, mtop, mright, mbottom, mleft) => ((height - mtop - mbottom) * value) / (1080 - mright - mleft);