import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import Duel from "../../components/Duel";

export class DuelScreen extends Component {
  render() {
    return (
      <Duel {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(DuelScreen));
