import React, { Component } from "react";
import { Text, View, Image, Animated} from "react-native";
import {
  getWidthPercent,
  getHeightPercent,
  getWidthFromHDMargin,
  getHeightFromHDMargin
} from "../../../libs/list_picker/ratio";
import RF from "react-native-responsive-fontsize";
import QRCode from "react-qr-code/lib/components/QRCode";
import {
  MAIN_URL,
  TEXT_TUTO,
  THEME_TUTO,
  SHOW_TUTO_GIFT,
  ONLY_ANONYMOUS,
  MAIN_DYNAMIC_LINK,
  QRCODE_FOREGROUND_COLOR,
  QRCODE_BACKGROUND_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT
} from "../../../constants/const";
import FadeSlideView from "../../Anim/FadeSlideView";
import FadeView from "../../Anim/FadeView";
import ScaleView from "../../Anim/scaleView";

export class TutorTele extends Component {
  state = {
    widthAnimation: new Animated.Value(getWidthPercent(40)),
    heightAnimation: new Animated.Value(getHeightPercent(95)),
    bottomAnimation: new Animated.Value(getHeightPercent(0))
  };
  componentDidMount() {
    setTimeout(() => {
      Animated.parallel([
        Animated.timing(
          // Animate over time
          this.state.widthAnimation, // The animated value to drive
          {
            toValue: getWidthPercent(60), // Animate to opacity: 1 (opaque)
            duration: 1000 // Make it take a while
          }
        ),
        Animated.timing(
          // Animate over time
          this.state.heightAnimation, // The animated value to drive
          {
            toValue: getHeightPercent(160), // Animate to opacity: 1 (opaque)
            duration: 1000 // Make it take a while
          }
        ),
        Animated.timing(
          // Animate over time
          this.state.bottomAnimation, // The animated value to drive
          {
            toValue: -getHeightPercent(72), // Animate to opacity: 1 (opaque)
            duration: 1000 // Make it take a while
          }
        )
      ]).start(); // Starts the animation
    }, 15000);
  }

  render() {
    
    const mtop = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mtop
          ? parseInt(this.props.navigation.state.params.mtop, 10)
          : TELE_MARGIN_TOP
        : TELE_MARGIN_TOP
      : TELE_MARGIN_TOP;
    const mright = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mright
          ? parseInt(this.props.navigation.state.params.mright, 10)
          : TELE_MARGIN_RIGHT
        : TELE_MARGIN_RIGHT
      : TELE_MARGIN_RIGHT;
    const mbottom = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mbottom
          ? parseInt(this.props.navigation.state.params.mbottom, 10)
          : TELE_MARGIN_BOTTOM
        : TELE_MARGIN_BOTTOM
      : TELE_MARGIN_BOTTOM;
    const mleft = this.props.navigation
      ? this.props.navigation.state.params
        ? this.props.navigation.state.params.mleft
          ? parseInt(this.props.navigation.state.params.mleft, 10)
          : TELE_MARGIN_LEFT
        : TELE_MARGIN_LEFT
      : TELE_MARGIN_LEFT;

    return (
      <FadeView
        delay={23000}
        out={true}
        style={{
          width: getWidthPercent(100),
          height: getHeightPercent(100),
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {(this.props.navigation.state.params
            ? this.props.navigation.state.params.sgift
              ? this.props.navigation.state.params.sgift === "true"
              : SHOW_TUTO_GIFT
            : SHOW_TUTO_GIFT) === true && (
          <FadeSlideView
            delay={1000}
            direction="right"
            style={{
              position: "absolute",
              width: getWidthPercent(95),
              height: getHeightPercent(95),
              shadowColor: "#000000",
              shadowRadius: 120,
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.8,
              elevation: 80,
              backgroundColor: "white",
              borderRadius: getHeightPercent(3),
              // padding: getHeightPercent(3),
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <canvas
              style={{ position: "absolute" }}
              className="canvas-player"
            ></canvas>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <FadeSlideView delay={2000} direction="left">
                <Image
                  style={{
                    width: getWidthFromHDMargin(
                      800,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    ),
                    height: getHeightFromHDMargin(
                      1000,
                      mtop,
                      mright,
                      mbottom,
                      mleft
                    )
                  }}
                  source={
                    this.props.navigation.state.params
                      ? this.props.navigation.state.params.tuto
                        ? this.props.navigation.state.params.tuto
                        : THEME_TUTO
                      : THEME_TUTO
                  }
                  resizeMode="contain"
                />
              </FadeSlideView>
              <View
                style={{
                  flexDirection: "column",
                  maxWidth: getWidthPercent(50),
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text
                  style={{
                    color: "#cccccc",
                    textTransform: "uppercase",
                    fontSize: RF(3),
                    textAlign: "center",
                    marginBottom: RF(3)
                  }}
                >
                  DANS UN INSTANT...
                </Text>
                <Text
                  style={{
                    textTransform: "uppercase",
                    fontSize: RF(8),
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {this.props.navigation.state.params
                    ? this.props.navigation.state.params.tutot
                      ? this.props.navigation.state.params.tutot
                      : TEXT_TUTO
                    : TEXT_TUTO}
                </Text>

                <FadeSlideView
                  delay={2000}
                  direction="left"
                  style={{
                    marginTop: getHeightPercent(10),
                    shadowColor: "black",
                    shadowRadius: 50,
                    shadowOffset: { width: 0, height: 0 },
                    shadowOpacity: 0.6,
                    elevation: 10,
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: getHeightPercent(3)
                  }}
                >
                  <QRCode
                    value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (ONLY_ANONYMOUS ? "/quickSignUp" : "/SignUp"))}
                    fgColor={QRCODE_FOREGROUND_COLOR}
                    bgColor={QRCODE_BACKGROUND_COLOR}
                    size={getHeightPercent(25)}
                  />
                </FadeSlideView>
              </View>
            </View>
            <Image
              style={{
                width: getWidthPercent(2),
                height: getHeightPercent(2),
                position: "absolute",
                bottom: getHeightPercent(2),
                right: getHeightPercent(2)
              }}
              source={require("../../../assets/tutor/wisslmedia.png")}
              resizeMode="contain"
            />
          </FadeSlideView>
        )}
        {/* 2e screen */}

        <FadeSlideView
          delay={8000}
          direction="Left"
          style={{
            position: "absolute",
            width: getWidthPercent(95),
            height: getHeightPercent(95),
            shadowColor: "#000000",
            shadowRadius: 120,
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.8,
            elevation: 80,
            backgroundColor: "white",
            borderRadius: getHeightPercent(3),
            padding: getHeightPercent(3),
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: getWidthPercent(40),
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FadeSlideView
                delay={9000}
                direction="left"
                style={{
                  shadowColor: "black",
                  shadowRadius: 50,
                  shadowOffset: { width: 0, height: 0 },
                  shadowOpacity: 0.6,
                  elevation: 10,
                  backgroundColor: "white",
                  borderRadius: 10,
                  padding: getHeightPercent(3),
                  marginRight: getWidthPercent(9),
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <ScaleView
                  style={{
                    position: "absolute",
                    backgroundColor: "green",
                    opacity: 0.2
                  }}
                  delay={12000}
                  duration={500}
                  size={getHeightPercent(28)}
                ></ScaleView>
                <FadeView
                  style={{ position: "absolute" }}
                  delay={11000}
                  duration={300}
                >
                  <Image
                    style={{
                      width: getHeightPercent(29),
                      height: getHeightPercent(29)
                    }}
                    source={require("../../../assets/tutor/focus.png")}
                    resizeMode="contain"
                  />
                </FadeView>
                <QRCode
                  value={MAIN_DYNAMIC_LINK.includes('http') ? MAIN_DYNAMIC_LINK : ("https://" + MAIN_URL + (ONLY_ANONYMOUS ? "/quickSignUp" : "/SignUp"))}
                  fgColor={QRCODE_FOREGROUND_COLOR}
                  bgColor={QRCODE_BACKGROUND_COLOR}
                  size={getHeightPercent(25)}
                />
              </FadeSlideView>
            </View>
            <View
              style={{
                flexDirection: "column",
                maxWidth: getWidthPercent(50),
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{
                  color: "#cccccc",
                  textTransform: "uppercase",
                  fontSize: RF(3),
                  textAlign: "center",
                  marginBottom: RF(3)
                }}
              >
                inscrivez vous gratuitement
              </Text>
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: RF(8),
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                Participez en scannant
              </Text>
            </View>
          </View>
          <Image
            style={{
              width: getWidthPercent(2),
              height: getHeightPercent(2),
              position: "absolute",
              bottom: getHeightPercent(2),
              right: getHeightPercent(2)
            }}
            source={require("../../../assets/tutor/wisslmedia.png")}
            resizeMode="contain"
          />
        </FadeSlideView>

        {/* 3e screen */}

        <FadeSlideView
          delay={15000}
          duration={1500}
          direction="Left"
          style={{
            position: "absolute",
            width: getWidthPercent(95),
            height: getHeightPercent(95),
            shadowColor: "#000000",
            shadowRadius: 120,
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.8,
            elevation: 80,
            backgroundColor: "white",
            borderRadius: getHeightPercent(3),
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              width: getWidthPercent(95)
            }}
          >
            <View
              style={{
                width: getWidthPercent(50),
                height: getHeightPercent(95),
                left: 0
              }}
            >
              <View
                style={{
                  width: getWidthPercent(26.5),
                  height: getHeightPercent(8),
                  backgroundColor: "#cccccc",
                  borderRadius: getHeightPercent(4),
                  top: getHeightPercent(30),
                  left: getWidthPercent(13),
                  position: "absolute"
                }}
              >
                <p className="text-typing anim-typewriter">{MAIN_URL}</p>
              </View>
            </View>
            <View
              style={{
                flexDirection: "column",
                maxWidth: getWidthPercent(45),
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{
                  color: "#cccccc",
                  textTransform: "uppercase",
                  fontSize: RF(3),
                  textAlign: "center",
                  marginBottom: RF(3)
                }}
              >
                inscrivez vous gratuitement
              </Text>
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: RF(8),
                  fontWeight: "bold",
                  textAlign: "center"
                }}
              >
                {`Ou à
l’adresse`}
              </Text>
              <View
                style={{
                  width: getWidthPercent(26.5),
                  height: getHeightPercent(8),
                  backgroundColor: "#ffffff",
                  borderRadius: getHeightPercent(4)
                }}
              >
                <p className="text-typing anim-typewriter">{MAIN_URL}</p>
              </View>
            </View>
          </View>
          <Image
            style={{
              width: getWidthPercent(2),
              height: getHeightPercent(2),
              position: "absolute",
              bottom: getHeightPercent(2),
              right: getHeightPercent(2)
            }}
            source={require("../../../assets/tutor/wisslmedia.png")}
            resizeMode="contain"
          />
        </FadeSlideView>

        {/* The phone */}
        <FadeSlideView
          style={{ position: "absolute", bottom: 0, left: getHeightPercent(5) }}
          delay={11000}
          direction="down"
          distance={-getHeightPercent(95)}
        >
          <Animated.Image
            style={{
              width: this.state.widthAnimation,
              height: this.state.heightAnimation,
              marginBottom: this.state.bottomAnimation
            }}
            source={require("../../../assets/tutor/scan_phone.png")}
            resizeMode="contain"
          />
        </FadeSlideView>
      </FadeView>
    );
  }
}

export default TutorTele;
