import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Surveys from "../../components/Sondages/sondages";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import {
  OPTIONS_LOGIN_NOT_REQUIRED,
  OPTIONS_LOGIN_REQUIRED,
} from "../../constants/routes";
import { APP_NAVTAB, BANNER_BOTTOM_ENABLE, MENU_COLOR, SHOW_GAMES, TEXT_COLOR } from "../../constants/const";
import RF from "react-native-responsive-fontsize";
import {
  withTranslation,
  t
} from "react-multi-lang";
import { Image } from "react-native-web";
export class SurveyAsyncScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: "survey",
      currentSA: null,
      updateNum: 0,
      loginNotRequired: false,
    };
  }

  componentDidMount() {
    // Get questions disponible
    this._isMounted = true;
    console.log("this.props = ", this.props);
    if (this.props.checkUser) {
      this.checkTheParams();
    } else {
      //save the id
      if (this.props.navigation) {
        // save the sid
        this.saveAsycnId();
      }
    }
  }

  saveAsycnId = () => {
    this.currentSAId = this.props.navigation.state.params.sid;
    var savedIdsJSON = [];
    //save the id loaded:
    const savedIds = window.localStorage.getItem("sids");
    if (savedIds) {
      savedIdsJSON = JSON.parse(savedIds);
      if (savedIdsJSON.indexOf(this.currentSAId) < 0) {
        savedIdsJSON.push(this.currentSAId);
      }
    } else {
      savedIdsJSON = [this.currentSAId];
    }
    window.localStorage.setItem("sids", JSON.stringify(savedIdsJSON));
    window.localStorage.setItem(
      "needOpenAsync",
      JSON.stringify({
        type: "sa",
        id: this.currentSAId,
      })
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkTheParams = () => {
    console.log("called checkTheParams");
    if (this.props.navigation) {
      if (
        this.props.navigation.state.params &&
        this.props.navigation.state.params.sid
      ) {
        this.saveAsycnId();

        if (this.props.currentSAs) {
          // we have SAs
          const ca = this.props.currentSAs.filter((e) => {
            return e.id === this.currentSAId;
          });
          if (ca && ca.length > 0) {
            this.currentSA = ca[0];
            if (this.currentSA.login === OPTIONS_LOGIN_REQUIRED) {
              if (!this.props.authUser) {
                this.props.navigation.navigate("Home");
                return false;
              }
            }
            this.setState({
              currentSA: this.currentSA,
              loginNotRequired:
                this.currentSA.login === OPTIONS_LOGIN_NOT_REQUIRED,
              updateNum: this.state.updateNum + 1,
            });
            window.localStorage.removeItem("needOpenAsync");
          } else {
            console.log("001 - doesnotexist");
            this.setState({
              screen: "doesnotexist",
              updateNum: this.state.updateNum + 1,
            });
          }
        } else {
          if (this.props.getSAByIds) {
            if (this.props.getSAByIds()) {
              if (!this.props.currentSAs) {
                console.log("002 - doesnotexist");
                this.setState({
                  screen: "doesnotexist",
                  updateNum: this.state.updateNum + 1,
                });
              }
            }
          }
        }
        return true;
      } else {
        this.props.navigation.navigate("Home");
      }
    }
    return false;
  };

  render() {
    
    return (
      <>
        {this.state.screen === "doesnotexist" && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: TEXT_COLOR,
                textAlign: "center",
                textAlignVertical: "center",
                flex: 1,
                marginTop: 50,
                marginLeft: 20,
                marginRight: 20,
                fontSize: RF(2.6),
                paddingBottom: 5,
                textTransform: "uppercase",
              }}
            >
              {t("event_not_available")}
            </Text>
            <View style={{
              position: 'fixed',
              bottom: BANNER_BOTTOM_ENABLE ? 70 : 0,
              width: '100%',
              backgroundColor: MENU_COLOR,
              padding: 15
            }}>
              <TouchableOpacity
                onPress={() => SHOW_GAMES || APP_NAVTAB ? this.props.navigation.navigate("SelectGame") : this.props.navigation.navigate("Home")}
              >
                <View style={{
                  flexDirection: "row",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Image
                    style={{ position: 'absolute', left: 0, width: 20, height: 20, margin: 3 }}
                    source={require("../../assets/images/back.svg")}
                    resizeMode="contain"
                  />
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    {SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {this.state.screen === "survey" && (
          <Surveys
            key={this.state.updateNum}
            {...this.props}
            currentSA={this.state.currentSA}
            mode="async"
            loginNotRequired={this.state.loginNotRequired}
          />
        )}
      </>
    );
  }
}

const condition = (authUser) => {
  return true;
}; // always
export default withAuthorization(condition)(withMainLayout(withTranslation(SurveyAsyncScreen)));
