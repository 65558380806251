import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import AnonymousSignUp from "../../components/SignUp/anonymous";

export class QuickSignUpScreen extends Component {
  render() {
    return <AnonymousSignUp {...this.props} />;
  }
}

export default withMainLayout(QuickSignUpScreen);
