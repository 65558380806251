module.exports = {
  countries: [
    {
      "title": "France",
      "code": "FR"
    },
    {
      "title": "Allemagne",
      "code": "DE"
    },
    {
      "title": "États-Unis",
      "code": "US"
    },
    {
      "title": "Suisse",
      "code": "CH"
    },
    {
      "title": "Andorre",
      "code": "AD"
    },
    {
      "title": "Émirats Arabes Unis",
      "code": "AE"
    },
    {
      "title": "Afghanistan",
      "code": "AF"
    },
    {
      "title": "Antigua-Et-Barbuda",
      "code": "AG"
    },
    {
      "title": "Anguilla",
      "code": "AI"
    },
    {
      "title": "Albanie",
      "code": "AL"
    },
    {
      "title": "Arménie",
      "code": "AM"
    },
    {
      "title": "Angola",
      "code": "AO"
    },
    {
      "title": "Région Asie/Pacifique",
      "code": "AP"
    },
    {
      "title": "Antarctique",
      "code": "AQ"
    },
    {
      "title": "Argentine",
      "code": "AR"
    },
    {
      "title": "Samoa Américaines",
      "code": "AS"
    },
    {
      "title": "Autriche",
      "code": "AT"
    },
    {
      "title": "Australie",
      "code": "AU"
    },
    {
      "title": "Aruba",
      "code": "AW"
    },
    {
      "title": "Îles Åland",
      "code": "AX"
    },
    {
      "title": "Azerbaïdjan",
      "code": "AZ"
    },
    {
      "title": "Bosnie-Herzégovine",
      "code": "BA"
    },
    {
      "title": "Barbad",
      "code": "BB"
    },
    {
      "title": "Bangladesh",
      "code": "BD"
    },
    {
      "title": "Belgique",
      "code": "BE"
    },
    {
      "title": "Burkina Faso",
      "code": "BF"
    },
    {
      "title": "Bulgarie",
      "code": "BG"
    },
    {
      "title": "Bahreïn",
      "code": "BH"
    },
    {
      "title": "Burundi",
      "code": "BI"
    },
    {
      "title": "Bénin",
      "code": "BJ"
    },
    {
      "title": "Saint-Barthélemy",
      "code": "BL"
    },
    {
      "title": "Bermudes",
      "code": "BM"
    },
    {
      "title": "Brunei Darussalam",
      "code": "BN"
    },
    {
      "title": "État Plurinational De Bolivie",
      "code": "BO"
    },
    {
      "title": "Bonaire, Saint-Eustache Et Saba",
      "code": "BQ"
    },
    {
      "title": "Brésil",
      "code": "BR"
    },
    {
      "title": "Bahamas",
      "code": "BS"
    },
    {
      "title": "Bhoutan",
      "code": "BT"
    },
    {
      "title": "Île Bouvet",
      "code": "BV"
    },
    {
      "title": "Botswana",
      "code": "BW"
    },
    {
      "title": "Biélorussie",
      "code": "BY"
    },
    {
      "title": "Belize",
      "code": "BZ"
    },
    {
      "title": "Canada",
      "code": "CA"
    },
    {
      "title": "Îles Cocos",
      "code": "CC"
    },
    {
      "title": "République Démocratique Du Congo",
      "code": "CD"
    },
    {
      "title": "République Centrafricaine",
      "code": "CF"
    },
    {
      "title": "Congo",
      "code": "CG"
    },

    {
      "title": "Côte D'Ivoire",
      "code": "CI"
    },
    {
      "title": "Îles Cook",
      "code": "CK"
    },
    {
      "title": "Chili",
      "code": "CL"
    },
    {
      "title": "Cameroun",
      "code": "CM"
    },
    {
      "title": "Chine",
      "code": "CN"
    },
    {
      "title": "Colombie",
      "code": "CO"
    },
    {
      "title": "Costa Rica",
      "code": "CR"
    },
    {
      "title": "Cuba",
      "code": "CU"
    },
    {
      "title": "Cap-Vert",
      "code": "CV"
    },
    {
      "title": "Curaçao",
      "code": "CW"
    },
    {
      "title": "Île Christmas",
      "code": "CX"
    },
    {
      "title": "Chypre",
      "code": "CY"
    },
    {
      "title": "République Tchèque",
      "code": "CZ"
    },

    {
      "title": "Djibouti",
      "code": "DJ"
    },
    {
      "title": "Denmark",
      "code": "DK"
    },
    {
      "title": "Dominique",
      "code": "DM"
    },
    {
      "title": "République Dominicaine",
      "code": "DO"
    },
    {
      "title": "Algérie",
      "code": "DZ"
    },
    {
      "title": "Équateur",
      "code": "EC"
    },
    {
      "title": "Estonie",
      "code": "EE"
    },
    {
      "title": "Égypte",
      "code": "EG"
    },
    {
      "title": "Sahara Occidental",
      "code": "EH"
    },
    {
      "title": "Érythrée",
      "code": "ER"
    },
    {
      "title": "Espagne",
      "code": "ES"
    },
    {
      "title": "Éthiopie",
      "code": "ET"
    },
    {
      "title": "Europe",
      "code": "EU"
    },
    {
      "title": "Finlande",
      "code": "FI"
    },
    {
      "title": "Fidji",
      "code": "FJ"
    },
    {
      "title": "Îles Malouines",
      "code": "FK"
    },
    {
      "title": "États Fédérés De Micronésie",
      "code": "FM"
    },
    {
      "title": "Îles Féroé",
      "code": "FO"
    },
    
    {
      "title": "Gabon",
      "code": "GA"
    },
    {
      "title": "Royaume-Uni",
      "code": "GB"
    },
    {
      "title": "Grenade",
      "code": "GD"
    },
    {
      "title": "Géorgie",
      "code": "GE"
    },
    {
      "title": "Guyane",
      "code": "GF"
    },
    {
      "title": "Guernesey",
      "code": "GG"
    },
    {
      "title": "Ghana",
      "code": "GH"
    },
    {
      "title": "Gibraltar",
      "code": "GI"
    },
    {
      "title": "Groenland",
      "code": "GL"
    },
    {
      "title": "Gambie",
      "code": "GM"
    },
    {
      "title": "Guinée",
      "code": "GN"
    },
    {
      "title": "Guadeloupe",
      "code": "GP"
    },
    {
      "title": "Guinée Équatoriale",
      "code": "GQ"
    },
    {
      "title": "Grèce",
      "code": "GR"
    },
    {
      "title": "Géorgie Du Sud-Et-Les Îles Sandwich Du Sud",
      "code": "GS"
    },
    {
      "title": "Guatemala",
      "code": "GT"
    },
    {
      "title": "Guam",
      "code": "GU"
    },
    {
      "title": "Guinée-Bissau",
      "code": "GW"
    },
    {
      "title": "Guyana",
      "code": "GY"
    },
    {
      "title": "Hong Kong",
      "code": "HK"
    },
    {
      "title": "Îles Heard-Et-MacDonald",
      "code": "HM"
    },
    {
      "title": "Honduras",
      "code": "HN"
    },
    {
      "title": "Croatie",
      "code": "HR"
    },
    {
      "title": "Haïti",
      "code": "HT"
    },
    {
      "title": "Hongrie",
      "code": "HU"
    },
    {
      "title": "Indonésie",
      "code": "ID"
    },
    {
      "title": "Irlande",
      "code": "IE"
    },
    {
      "title": "Israël",
      "code": "IL"
    },
    {
      "title": "Île De Man",
      "code": "IM"
    },
    {
      "title": "Inde",
      "code": "IN"
    },
    {
      "title": "Territoire Britannique De L'océan Indien",
      "code": "IO"
    },
    {
      "title": "Irak",
      "code": "IQ"
    },
    {
      "title": "République Islamique D'Iran",
      "code": "IR"
    },
    {
      "title": "Islande",
      "code": "IS"
    },
    {
      "title": "Italie",
      "code": "IT"
    },
    {
      "title": "Jersey",
      "code": "JE"
    },
    {
      "title": "Jamaïque",
      "code": "JM"
    },
    {
      "title": "Jordanie",
      "code": "JO"
    },
    {
      "title": "Japon",
      "code": "JP"
    },
    {
      "title": "Kenya",
      "code": "KE"
    },
    {
      "title": "Kirghizistan",
      "code": "KG"
    },
    {
      "title": "Cambodge",
      "code": "KH"
    },
    {
      "title": "Kiribati",
      "code": "KI"
    },
    {
      "title": "Comores",
      "code": "KM"
    },
    {
      "title": "Saint-Christophe-et-Niévès",
      "code": "KN"
    },
    {
      "title": "République Populaire Démocratique De Corée",
      "code": "KP"
    },
    {
      "title": "République De Corée",
      "code": "KR"
    },
    {
      "title": "Koweït",
      "code": "KW"
    },
    {
      "title": "Îles Caïmans",
      "code": "KY"
    },
    {
      "title": "Kazakhstan",
      "code": "KZ"
    },
    {
      "title": "République Démocratique Populaire Lao",
      "code": "LA"
    },
    {
      "title": "Liban",
      "code": "LB"
    },
    {
      "title": "Sainte-Lucie",
      "code": "LC"
    },
    {
      "title": "Liechtenstein",
      "code": "LI"
    },
    {
      "title": "Sri Lanka",
      "code": "LK"
    },
    {
      "title": "Liberia",
      "code": "LR"
    },
    {
      "title": "Lesotho",
      "code": "LS"
    },
    {
      "title": "Lituanie",
      "code": "LT"
    },
    {
      "title": "Luxembourg",
      "code": "LU"
    },
    {
      "title": "Lettonie",
      "code": "LV"
    },
    {
      "title": "Libye",
      "code": "LY"
    },
    {
      "title": "Maroc",
      "code": "MA"
    },
    {
      "title": "Monaco",
      "code": "MC"
    },
    {
      "title": "République De Moldavie",
      "code": "MD"
    },
    {
      "title": "Monténégro",
      "code": "ME"
    },
    {
      "title": "Saint-Martin (Partie Française)",
      "code": "MF"
    },
    {
      "title": "Madagascar",
      "code": "MG"
    },
    {
      "title": "Îles Marshall",
      "code": "MH"
    },
    {
      "title": "Macédoine",
      "code": "MK"
    },
    {
      "title": "Mali",
      "code": "ML"
    },
    {
      "title": "Birmanie",
      "code": "MM"
    },
    {
      "title": "Mongolie",
      "code": "MN"
    },
    {
      "title": "Macao",
      "code": "MO"
    },
    {
      "title": "Îles Mariannes Du Nord",
      "code": "MP"
    },
    {
      "title": "Martinique",
      "code": "MQ"
    },
    {
      "title": "Mauritanie",
      "code": "MR"
    },
    {
      "title": "Montserrat",
      "code": "MS"
    },
    {
      "title": "Malte",
      "code": "MT"
    },
    {
      "title": "Maurice",
      "code": "MU"
    },
    {
      "title": "Maldives",
      "code": "MV"
    },
    {
      "title": "Malawi",
      "code": "MW"
    },
    {
      "title": "Mexique",
      "code": "MX"
    },
    {
      "title": "Malaisie",
      "code": "MY"
    },
    {
      "title": "Mozambique",
      "code": "MZ"
    },
    {
      "title": "Namibie",
      "code": "NA"
    },
    {
      "title": "Nouvelle-Calédonie",
      "code": "NC"
    },
    {
      "title": "Niger",
      "code": "NE"
    },
    {
      "title": "Île Norfolk",
      "code": "NF"
    },
    {
      "title": "Nigéria",
      "code": "NG"
    },
    {
      "title": "Nicaragua",
      "code": "NI"
    },
    {
      "title": "Pays-Bas",
      "code": "NL"
    },
    {
      "title": "Norvège",
      "code": "NO"
    },
    {
      "title": "Népal",
      "code": "NP"
    },
    {
      "title": "Nauru",
      "code": "NR"
    },
    {
      "title": "Niue",
      "code": "NU"
    },
    {
      "title": "Nouvelle-Zélande",
      "code": "NZ"
    },
    {
      "title": "Oman",
      "code": "OM"
    },
    {
      "title": "Panama",
      "code": "PA"
    },
    {
      "title": "Pérou",
      "code": "PE"
    },
    {
      "title": "Polynésie Française",
      "code": "PF"
    },
    {
      "title": "Papouasie-Nouvelle-Guinée",
      "code": "PG"
    },
    {
      "title": "Philippines",
      "code": "PH"
    },
    {
      "title": "Pakistan",
      "code": "PK"
    },
    {
      "title": "Pologne",
      "code": "PL"
    },
    {
      "title": "Saint-Pierre-Et-Miquelon",
      "code": "PM"
    },
    {
      "title": "Pitcairn",
      "code": "PN"
    },
    {
      "title": "Porto Rico",
      "code": "PR"
    },
    {
      "title": "Territoires Palestiniens Occupés",
      "code": "PS"
    },
    {
      "title": "Portugal",
      "code": "PT"
    },
    {
      "title": "Palaos",
      "code": "PW"
    },
    {
      "title": "Paraguay",
      "code": "PY"
    },
    {
      "title": "Qatar",
      "code": "QA"
    },
    {
      "title": "Réunion",
      "code": "RE"
    },
    {
      "title": "Roumanie",
      "code": "RO"
    },
    {
      "title": "Serbie",
      "code": "RS"
    },
    {
      "title": "Fédération De Russie",
      "code": "RU"
    },
    {
      "title": "Rwanda",
      "code": "RW"
    },
    {
      "title": "Arabie Saoudite",
      "code": "SA"
    },
    {
      "title": "Îles Salomon",
      "code": "SB"
    },
    {
      "title": "Seychelles",
      "code": "SC"
    },
    {
      "title": "Soudan",
      "code": "SD"
    },
    {
      "title": "Suède",
      "code": "SE"
    },
    {
      "title": "Singapour",
      "code": "SG"
    },
    {
      "title": "Sainte-Hélène",
      "code": "SH"
    },
    {
      "title": "Slovénie",
      "code": "SI"
    },
    {
      "title": "Svalbard Et Jan Mayen",
      "code": "SJ"
    },
    {
      "title": "Slovaquie",
      "code": "SK"
    },
    {
      "title": "Sierra Leone",
      "code": "SL"
    },
    {
      "title": "Saint-Marin",
      "code": "SM"
    },
    {
      "title": "Sénégal",
      "code": "SN"
    },
    {
      "title": "Somalie",
      "code": "SO"
    },
    {
      "title": "Suriname",
      "code": "SR"
    },
    {
      "title": "Soudan Du Sud",
      "code": "SS"
    },
    {
      "title": "Sao Tomé-Et-Principe",
      "code": "ST"
    },
    {
      "title": "République Du Salvador",
      "code": "SV"
    },
    {
      "title": "Saint-Martin (Partie Néerlandaise)",
      "code": "SX"
    },
    {
      "title": "République Arabe Syrienne",
      "code": "SY"
    },
    {
      "title": "Swaziland",
      "code": "SZ"
    },
    {
      "title": "Îles Turks-Et-Caïcos",
      "code": "TC"
    },
    {
      "title": "Tchad",
      "code": "TD"
    },
    {
      "title": "Terres Australes Françaises",
      "code": "TF"
    },
    {
      "title": "Togo",
      "code": "TG"
    },
    {
      "title": "Thaïlande",
      "code": "TH"
    },
    {
      "title": "Tadjikistan",
      "code": "TJ"
    },
    {
      "title": "Tokelau",
      "code": "TK"
    },
    {
      "title": "Timor-Leste",
      "code": "TL"
    },
    {
      "title": "Turkménistan",
      "code": "TM"
    },
    {
      "title": "Tunisie",
      "code": "TN"
    },
    {
      "title": "Tonga",
      "code": "TO"
    },
    {
      "title": "Turquie",
      "code": "TR"
    },
    {
      "title": "Trinité-Et-Tobago",
      "code": "TT"
    },
    {
      "title": "Tuvalu",
      "code": "TV"
    },
    {
      "title": "Taïwan",
      "code": "TW"
    },
    {
      "title": "République-Unie De Tanzanie",
      "code": "TZ"
    },
    {
      "title": "Ukraine",
      "code": "UA"
    },
    {
      "title": "Ouganda",
      "code": "UG"
    },
    {
      "title": "Îles Mineures Éloignées Des États-Unis",
      "code": "UM"
    },

    {
      "title": "Uruguay",
      "code": "UY"
    },
    {
      "title": "Ouzbékistan",
      "code": "UZ"
    },
    {
      "title": "Saint-Siège (État De La Cité Du Vatican)",
      "code": "VA"
    },
    {
      "title": "Saint-Vincent-Et-Les Grenadines",
      "code": "VC"
    },
    {
      "title": "Venezuela",
      "code": "VE"
    },
    {
      "title": "Îles Vierges Britanniques",
      "code": "VG"
    },
    {
      "title": "Îles Vierges Des États-Unis",
      "code": "VI"
    },
    {
      "title": "Viet Nam",
      "code": "VN"
    },
    {
      "title": "Vanuatu",
      "code": "VU"
    },
    {
      "title": "Wallis Et Futuna",
      "code": "WF"
    },
    {
      "title": "Samoa",
      "code": "WS"
    },
    {
      "title": "Yémen",
      "code": "YE"
    },
    {
      "title": "Mayotte",
      "code": "YT"
    },
    {
      "title": "Afrique Du Sud",
      "code": "ZA"
    },
    {
      "title": "Zambie",
      "code": "ZM"
    },
    {
      "title": "Zimbabwe",
      "code": "ZW"
    }
  ]
};
