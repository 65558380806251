import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import RF from "react-native-responsive-fontsize";
import { Text, View } from "react-native";
import { TEXT_COLOR } from "../../constants/const";

export class NoQuizScreen extends Component {
  render() {
    return (
      <View
        style={{
          height: 200,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center"
        }}
      >
        <Text style={{ fontSize: RF(3), color: TEXT_COLOR}}>
          NON QUIZ DISPONIBLE
        </Text>
      </View>
    );
  }
}

export default withMainLayout(NoQuizScreen);
