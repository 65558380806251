import React, { Component } from "react";
import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeSlideView from "../Anim/FadeSlideView";
import PercentResult from "./percentResult";
import LottieView from "react-native-web-lottie";
import {
  getWidthFromHDMargin,
  getHeightFromHDMargin,
} from "../../libs/list_picker/ratio";
import {
  TEXT_COLOR,
  TELE_MARGIN_TOP,
  TELE_MARGIN_RIGHT,
  TELE_MARGIN_BOTTOM,
  TELE_MARGIN_LEFT,
  isEn,
  THEME,
  COLOR_GOOD,
  COLOR_WRONG,
  BG_GOOD,
  BG_WRONG,
} from "../../constants/const";

export class ResponseTextItem extends Component {
  _keyExtractor = (item, index) => `${index}`;

  _renderOptionText = (index) => {
    var optionText = "";
    switch (index) {
      case 2:
        optionText = "B";
        break;
      case 3:
        optionText = "C";
        break;
      case 4:
        optionText = "D";
        break;
      case 5:
        optionText = "E";
        break;
      case 6:
        optionText = "F";
        break;
      case 7:
        optionText = "G";
        break;
      case 8:
        optionText = "H";
        break;
      case 9:
        optionText = "I";
        break;
      case 10:
        optionText = "J";
        break;
      default:
        optionText = "A";
        break;
    }
    return optionText;
  };

  _renderItem = ({ item, index }) => {
    console.log('this.props = ', this.props);
    if (this.props.question.withImage) {
      return this._renderItemImage({ item, index });
    } else {
      return this._renderItemText({ item, index, maxWidth: this.props.maxWidth });
    }
  };

  _renderItemImage = ({ item, index }) => {
    const mtop = TELE_MARGIN_TOP;
    const mright = TELE_MARGIN_RIGHT;
    const mbottom = TELE_MARGIN_BOTTOM;
    const mleft = TELE_MARGIN_LEFT;

    const screenWidth =
      Math.round(Dimensions.get("window").width) - mleft - mright;
    return (
      <FadeSlideView
        delay={index * 50}
        style={{
          flexDirection: this.props.modeTele ? "row" : "column",
          width: this.props.modeTele
            ? this.props.withResult
              ? getWidthFromHDMargin(290, mtop, mright, mbottom, mleft)
              : getWidthFromHDMargin(340, mtop, mright, mbottom, mleft)
            : 160,
          marginLeft: this.props.modeTele ? Math.round(screenWidth / 60) : 0,
          marginRight: this.props.modeTele ? Math.round(screenWidth / 60) : 0,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (this.props.firebase && this.props.firebase.analytics) {
              this.props.firebase.analytics.logEvent(`button_question_${this.props.question.currentQuestionId}_answer_${item.id}`, {
                name: 'quiz',
              });
            }
            if (
              !(
                this.props.answered ||
                this.props.withResult === true ||
                this.props.modeTele
              )
            ) {
              this.props.onPressButton(index, item.id);
              this.setState({ selected: true });
            }
          }}
          disabled={
            this.props.answered ||
            this.props.withResult === true ||
            this.props.modeTele
          }
        >
          <View >
            <Image
              style={{
                width: this.props.modeTele
                  ? this.props.withResult
                    ? getWidthFromHDMargin(290, mtop, mright, mbottom, mleft)
                    : getWidthFromHDMargin(340, mtop, mright, mbottom, mleft)
                  : 150,
                height: this.props.modeTele
                  ? this.props.withResult
                    ? getHeightFromHDMargin(290, mtop, mright, mbottom, mleft)
                    : getHeightFromHDMargin(480, mtop, mright, mbottom, mleft)
                  : 150,
                borderRadius: this.props.modeTele ? 15 : 10,
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor: this.props.withResult
                  ? this.props.mode === "async"
                    ? item.id === this.props.rightId
                      ? COLOR_GOOD
                      : "transparent"
                    : "transparent"
                  : "transparent",
                borderColor: "transparent",
                borderWidth: 1,
                opacity: this.props.modeTele
                  ? 1
                  : this.props.withResult
                    ? item.id === this.props.rightId
                      ? 1
                      : 0.5
                    : this.props.answered
                      ? item.answered
                        ? 1
                        : 0.5
                      : 1
              }}
              source={{
                uri: this.props.modeTele
                  ? this.props.withResult
                    ? item.mobile
                      ? item.mobile
                      : item.answer
                    : item.answer
                  : item.mobile
                    ? item.mobile
                    : item.answer
              }}
            />
            {this.props.withResult && this.props.mode !== "async"  && THEME !== 'decathlon-arena' && (
              <PercentResult
                type={item.isRight ? "right" : "wrong"}
                value={Number.parseFloat(item.percent * 100).toFixed(0)}
                modeTele={this.props.modeTele}
                mode={item.text ? "image_text" : "image"}
                questionType={this.props.type}
              />
            )}
            {item.text && (
              <Text
                style={{
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(0, 0, 0, 0.9)",
                  textShadowRadius: 20,
                  fontSize: this.props.modeTele ? RF(4) : RF(2),
                  color: TEXT_COLOR,
                  textAlign: "center",
                  textTransform: "uppercase",
                  marginBottom: 15,
                  width: this.props.modeTele
                    ? this.props.withResult
                      ? getWidthFromHDMargin(290, mtop, mright, mbottom, mleft)
                      : getWidthFromHDMargin(340, mtop, mright, mbottom, mleft)
                    : 150,
                }}
                allowFontScaling={true}
                minimumFontScale={10}
              >
                {item.text}
              </Text>
            )}
            {(item.answered || this.props.answeredId === item.id) && (
              <FadeSlideView
                delay={this.props.withResult ? 1000 : 10}
                style={{
                  position: "absolute",
                  top: this.props.withResult
                    ? item.id === this.props.rightId
                      ? 45
                      : 45
                    : 30,
                  left: this.props.withResult
                    ? item.id === this.props.rightId
                      ? 45
                      : 45
                    : 25,
                }}
              >
                <LottieView
                  style={{
                    height: this.props.withResult
                      ? item.id === this.props.rightId
                        ? 70
                        : 70
                      : 100,
                    width: this.props.withResult
                      ? item.id === this.props.rightId
                        ? 70
                        : 70
                      : 100,
                  }}
                  source={
                    this.props.withResult
                      ? item.id === this.props.rightId
                        ? require("../../assets/anim/tick_green.json")
                        : require("../../assets/anim/tick_red.json")
                      : require("../../assets/anim/tick.json")
                  }
                  autoPlay
                />
              </FadeSlideView>
            )}
          </View>
        </TouchableOpacity>
      </FadeSlideView>
    );
  };

  _marginBottom = (withResult, withText) => {
    return (
      5 +
      (withResult
        ? this.props.modeTele
          ? 0 //Math.round(screenHeight / 23) + (withText ? RF(2) : 0)
          : 30 + (withText ? RF(2) : 0)
        : 5 + (withText ? RF(2) : 0))
    );
  };

  _renderItemText = ({ item, index, maxWidth }) => {
    const mtop = TELE_MARGIN_TOP;
    const mright = TELE_MARGIN_RIGHT;
    const mbottom = TELE_MARGIN_BOTTOM;
    const mleft = TELE_MARGIN_LEFT;
    const screenHeight = this.props.modeTele
      ? Math.round(Dimensions.get("window").height) - mtop - mbottom
      : 700;
    const screenWidth = this.props.modeTele
      ? Math.round(Dimensions.get("window").width) - mleft - mright
      : maxWidth;
    console.log('maxWidth = ', maxWidth);
    return (
      <FadeSlideView delay={index * 50}>
        <TouchableOpacity
          onPress={() => {
            if (this.props.firebase && this.props.firebase.analytics) {
              this.props.firebase.analytics.logEvent(`button_question_${this.props.question.currentQuestionId}_answer_${item.id}`, {
                name: 'quiz',
              });
            }
            if (
              !(
                this.props.answered ||
                this.props.withResult === true ||
                this.props.modeTele
              )
            ) {
              this.props.onPressButton(index, item.id);
              this.setState({ selected: true });
            }
          }}
          disabled={
            this.props.answered ||
            this.props.withResult === true ||
            this.props.modeTele
          }
        >
          <View
            style={{
              paddingRight:
                this.props.withResult && this.props.type === "6"
                  ? this.props.modeTele
                    ? Math.round(screenHeight / 16)
                    : 20
                  : 0,
              alignItems: "flex-end",
            }}
          >
            <View
              style={{
                height: this.props.modeTele
                  ? Math.round(screenHeight / 7.8)
                  : 60,
                width: this.props.modeTele
                  ? (this.props.type === "6"
                    ? Math.round(screenHeight / 8)
                    : (this.props.type === "7" ? Math.round(screenWidth / 3) - 30 : Math.round(screenWidth / 2) - 30))
                  : this.props.type === "6"
                    ? 60
                    : Math.round(screenWidth) - 5,
                borderRadius: 5,
                margin:
                  !this.props.withResult && this.props.type === "6"
                    ? Math.round(screenHeight / 32)
                    : 1,
                marginBottom: 10,
                backgroundImage: this.props.withResult
                  ? item.id === this.props.rightId
                    ? `url('${BG_GOOD}')`
                    : `url('${BG_WRONG}')`
                  : `url('${BG_WRONG}')`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                justifyContent:
                  !this.props.withResult && this.props.type === "6"
                    ? "center"
                    : "flex-start",
                alignItems: "center",
                alignSelf: "stretch",
                flexDirection: "row",
                paddingRight: this.props.modeTele
                  ? this.props.withResult
                    ? Math.round(screenHeight / 12)
                    : "auto"
                  : "auto",
                // shadowColor: "#000",
                // shadowOpacity:
                //   this.props.answered ||
                //     this.props.withResult === true ||
                //     this.props.modeTele
                //     ? 0
                //     : 0.5,
                // shadowRadius: 10,
                opacity: this.props.modeTele
                  ? 1
                  : this.props.withResult
                    ? item.id === this.props.rightId
                      ? 1
                      : 0.5
                    : this.props.answered
                      ? item.answered
                        ? 1
                        : 0.5
                      : 1,
              }}
            >
              <View
                style={{
                  minWidth: this.props.modeTele
                    ? Math.round(screenHeight / 10)
                    : 40,
                  height: this.props.modeTele
                    ? Math.round(screenHeight / 10)
                    : 40,
                  borderRadius: this.props.modeTele
                    ? Math.round(screenHeight / 20)
                    : 20,
                  backgroundColor:
                    item.id === this.props.rightId && this.props.withResult
                      ? COLOR_WRONG
                      : (THEME === 'racing92' ? TEXT_COLOR : "lightgrey"),
                  justifyContent: "center",
                  alignItems: "center",
                  margin: this.props.modeTele
                    ? Math.round(screenHeight / 40)
                    : 2,
                  marginRight: this.props.modeTele
                    ? Math.round(screenHeight / 38)
                    : 5,
                  marginLeft: this.props.modeTele
                    ? Math.round(screenHeight / 34)
                    : 12,
                }}
              >
                <Text
                  style={{
                    fontSize: this.props.modeTele
                      ? RF(4)
                      : RF(2),
                    fontWeight: "900",
                    color:
                      item.id === this.props.rightId && this.props.withResult
                        ? COLOR_GOOD
                        : (THEME === 'racing92' ? 'white' : "darkgrey"),
                  }}
                >
                  {this._renderOptionText(item.id)}
                </Text>
              </View>
              {this.props.type !== "6" && (
                <Text
                  style={{
                    width: this.props.modeTele
                      ? (this.props.type === "6"
                        ? Math.round(screenHeight / 8)
                        : (this.props.type === "7" ? Math.round(screenWidth / 3) - 30 : Math.round(screenWidth / 2) - 30))
                      : this.props.type === "6"
                        ? 60
                        : Math.round(screenWidth) - 5,
                    fontSize: this.props.modeTele
                      ? RF(4.2)
                      : RF(2),
                  }}
                  numberOfLines={2}
                  allowFontScaling={true}
                  minimumFontScale={RF(2)}
                >
                  {isEn() ? item.answer_en : item.answer}
                </Text>
              )}
            </View>
            {!this.props.modeTele &&
              (item.answered || this.props.answeredId === item.id) && (
                <FadeSlideView
                  delay={this.props.withResult ? 1000 : 10}
                  style={{
                    alignItems: "flex-end",
                    position: "absolute",
                    bottom: this.props.withResult
                      ? item.id === this.props.rightId
                        ? 5
                        : 5
                      : -10,
                    left: this.props.withResult
                      ? item.id === this.props.rightId
                        ? -11
                        : -11
                      : this.props.type !== "6"
                        ? -25
                        : 0,
                    alignContent: "flex-end",
                  }}
                >
                  <LottieView
                    style={{
                      height: this.props.withResult
                        ? item.id === this.props.rightId
                          ? 70
                          : 70
                        : 100,
                      width: this.props.withResult
                        ? item.id === this.props.rightId
                          ? 70
                          : 70
                        : 100,
                      alignSelf: "flex-end",
                    }}
                    source={
                      this.props.withResult
                        ? item.id === this.props.rightId
                          ? require("../../assets/anim/tick_green.json")
                          : require("../../assets/anim/tick_red.json")
                        : require("../../assets/anim/tick.json")
                    }
                    autoPlay
                  />
                </FadeSlideView>
              )}

            {this.props.withResult && this.props.mode !== "async" && THEME !== 'decathlon-arena' && (
              <PercentResult
                type={item.isRight ? "right" : "wrong"}
                value={Number.parseFloat(item.percent * 100).toFixed(0)}
                modeTele={this.props.modeTele}
                mode="text"
                questionType={this.props.type}
              />
            )}
          </View>
        </TouchableOpacity>
      </FadeSlideView>
    );
  };

  _numColumns = (withImage) => {
    if (this.props.type === "5" || this.props.type === "7" || this.props.type === "8") {
      return 1;
    }
    if (this.props.type === "6") {
      // return this.props.modeTele ? (this.props.withResult ? 4 : 2) : 4;
      return this.props.modeTele ? 2 : 4;
    }
    if (this.props.modeTele) {
      return withImage ? 4 : 2;
      // return withImage
      //   ? this.props.withResult
      //     ? 2
      //     : 4
      //   : this.props.withResult
      //   ? 1
      //   : 2;
    }
    return withImage ? 2 : 1;
  };

  render() {
    return (
      <FlatList
        style={{
          paddingBottom: 5,
          marginTop: this.props.type === "6" ? 30 : 0,
          ...this.props.style,
        }}
        keyExtractor={this._keyExtractor}
        showsHorizontalScrollIndicator={false}
        data={this.props.data}
        extraData={this.state}
        renderItem={this._renderItem}
        numColumns={this._numColumns(this.props.question.withImage)}
      />
    );
  }
}

export default ResponseTextItem;
