import React, { Component } from "react";
import { View, TouchableOpacity } from "react-native";
import {
  Text,
  Input,
  Button,
  CheckBox,
  IndexPath,
  AutocompleteItem,
} from "@ui-kitten/components";
import LottieView from "react-native-web-lottie";
import RF from "react-native-responsive-fontsize";
import {
  THEME,
  BUTTON_COLOR,
  PROFILE_PHONE,
  PROFILE_CIVIL,
  PROFILE_COMPANY,
  TEXT_OPTION_1,
  TEXT_OPTION_2,
  TEXT_OPTION_3,
  URL_RULES,
  ONLY_ANONYMOUS,
  PROFILE_COMPANY_OBLIGATION,
  LOGE_NUMBERS,
  LOGE_TEXT,
  PROFILE_LOGE_NUMBER,
  TEXT_COLOR,
  PROFILE_PHONE_OBLIGATION,
  PROFILE_JOB_TEXT,
  PROFILE_JOB_OBLIGATION,
  PROFILE_JOB_ITEMS,
  PROFILE_DOB,
  PROFILE_DOB_OBLIGATION,
  PROFILE_JOB_SELECT,
  PROFILE_ADDRESS,
  PROFILE_ADDRESS_STREET,
  PROFILE_ADDRESS_POSTCODE,
  PROFILE_ADDRESS_CITY,
  PROFILE_ADDRESS_OBLIGATION,
  TEXT_OPTION_2_OBLIGATION,
  TEXT_OPTION_3_OBLIGATION,
  PROFILE_JOB,
  DISPLAY_NAME,
  PROFILE_ADDRESS_COUNTRY,
  MAIN_URL,
  SHOW_GAMES,
} from "../../constants/const";
import moment from "moment";
import isoCountry from "../../libs/iso3166";
import { withTranslation, t } from "react-multi-lang";

import Filter from "../../helpers/badwords";
import FadeSlideView from "../Anim/FadeSlideView";
import { Picker } from "react-native-web";

const filterBadWords = new Filter();

// const filter = (item, query) =>
//   item.title.toLowerCase().includes(query.toLowerCase());

export class Profile extends Component {
  currentUser = null;
  _isMount = false;
  jobList = [];
  logeNumberList = [];
  countryList = isoCountry.countries;
  needPW = true;
  provider = "";
  state = {
    job: "",
    logeNumber: "",
    jobIndex: new IndexPath(0, 0),
    logeNumberIndex: new IndexPath(0, 0),
    agreement: true,
    changePassword: false,
    confirmDeleteAccount: false,
    shouldRender: false,
  };

  constructor(props) {
    super(props);
    // console.log("profile = ", props.authUser);
    if (props.authUser) {
      const authUser = props.authUser;
      const that = this;
      authUser.providerData.forEach(function (provider) {
        // console.log("provider = ", provider);
        if (
          provider.providerId.includes("google") ||
          provider.providerId.includes("facebook")
        ) {
          that.needPW = false;
          that.provider = provider.providerId;
        }
      });
      if (authUser) {
        props.firebase
          .user(authUser.uid)
          .get()
          .then((u) => {
            if (this._isMount) {
              const user = u.data();
              if (user) {
                user.id = authUser.uid;
              }
              this.setState({ ...authUser, ...user, shouldRender: true });
            }
          });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("shouldComponentUpdate profile = ", nextState);
    if (nextState.shouldRender === true) {
      // nextState.shouldRender = false;
      return true;
    }
    return false;
  }

  componentDidMount() {
    this._isMount = true;

    
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  validInput = () => {
    if (this.state === null) {
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      return false;
    }

    if (!this.state.lastName || this.state.lastName.length === "") {
      return false;
    }

    if (filterBadWords.isProfane(this.state.firstName)) {
      return false;
    }

    if (filterBadWords.isProfane(this.state.lastName)) {
      return false;
    }

    if (!this.state.email || this.state.email.length === "") {
      return false;
    }

    if (
      this.state.changePassword &&
      (!this.state.password || this.state.password.length === "")
    ) {
      return false;
    }

    if (!this.state.agreement || this.state.agreement === false) {
      console.log("agreement");
      return false;
    }

    if (TEXT_OPTION_2_OBLIGATION) {
      if (!this.state.newsletter || this.state.newsletter === false) {
        console.log("newsletter");
        return false;
      }
    }

    if (TEXT_OPTION_3_OBLIGATION) {
      if (
        !this.state.newsletter_commercial ||
        this.state.newsletter_commercial === false
      ) {
        console.log("newsletter_commercial");
        return false;
      }
    }

    if (PROFILE_DOB_OBLIGATION && PROFILE_DOB) {
      if (!this.state.dob || this.state.dob === false) {
        return false;
      }
    }

    if (PROFILE_ADDRESS && PROFILE_ADDRESS_OBLIGATION) {
      if (PROFILE_ADDRESS_STREET) {
        if (!this.state.address || this.state.address === "") {
          console.log("address");
          return false;
        }
      }
      if (PROFILE_ADDRESS_POSTCODE) {
        if (!this.state.postcode || this.state.postcode === "") {
          console.log("postcode");
          return false;
        }
      }
      if (PROFILE_ADDRESS_CITY) {
        if (!this.state.city || this.state.city === "") {
          console.log("city");
          return false;
        }
      }
    }

    if (PROFILE_JOB_OBLIGATION && PROFILE_JOB) {
      if (!this.state.job || this.state.job === "") {
        console.log("job");
        return false;
      }
    }

    if (PROFILE_PHONE_OBLIGATION && PROFILE_PHONE) {
      if (!this.state.phone || this.state.phone === false) {
        console.log("phone");
        return false;
      }
    }

    if (PROFILE_LOGE_NUMBER) {
      if (!this.state.logeNumber || this.state.logeNumber === "") {
        console.log("logeNumber");
        return false;
      }
    }

    if (PROFILE_COMPANY_OBLIGATION && PROFILE_COMPANY) {
      if (!this.state.company || this.state.company === false) {
        console.log("company");
        return false;
      }
    }

    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onValidateClicked = () => {
    console.log(this.state);
    var errMessage = t("field_requires");
    if (this.validInput()) {
      this.setState({ error: null });
      const {
        email,
        password,
        passwordActuel,
        civil,
        firstName,
        lastName,
        company,
        phone,
        address,
        postcode,
        city,
        country,
        job,
        dob,
      } = this.state;
      const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;
      if (this.props.firebase && this.props.firebase.analytics) {
        this.props.firebase.analytics.logEvent("button_validate_inscription_2", {
          data: this.state,
        });
      }

      firebase
        .user(this.props.authUser.uid)
        .set(
          {
            email,
            civil: civil || "",
            firstName,
            lastName,
            company: company || false,
            phone: phone || false,
            newsletter: that.state.newsletter || false,
            newsletter_commercial: that.state.newsletter_commercial || false,
            agreement: true,
            theme: THEME,
            address: address || "",
            postcode: postcode || "",
            city: city || "",
            country: country || "",
            job: job || "",
            dob: dob || "",
            logeNumber: that.state.logeNumber || false,
            logeNumberDate: moment().format("YYYY-MM-DD"),
          },
          { merge: true }
        )
        .then(() => {
          that.setState({ error: null });
          var _displayName =
            DISPLAY_NAME === "SHORT"
              ? `${firstName} ${lastName.charAt(0).toUpperCase()}.`
              : `${firstName} ${lastName}`;
          that.currentUser = {
            uid: this.state.id,
            email: email,
            displayName: _displayName,
          };
          firebase.auth.currentUser
            .updateProfile({
              displayName: _displayName,
            })
            .then(function () {
              //change password if needed
              if (that.state.changePassword) {
                if (that.state.anonymous === true) {
                  // convert anonymous user
                  var credential = firebase.getCredential(email, password);
                  firebase.auth.currentUser
                    .linkAndRetrieveDataWithCredential(credential)
                    .then(
                      (usercred) => {
                        // var user = usercred.user;
                        // console.log("Anonymous account successfully upgraded");
                        firebase
                          .user(that.state.id)
                          .set(
                            {
                              anonymous: false,
                            },
                            { merge: true }
                          )
                          .then(() => {
                            navigate("Home");
                          })
                          .catch((error) => {
                            that.setState({ error });
                            console.log(error);
                          });
                      },
                      (error) => {
                        that.setState({ error });
                        console.log("Error upgrading anonymous account", error);
                      }
                    );
                } else {
                  //change pw for normal users
                  var cred = firebase.getCredential(email, passwordActuel);
                  firebase.auth.currentUser
                    .reauthenticateWithCredential(cred)
                    .then(() => {
                      firebase.auth.currentUser
                        .updatePassword(password)
                        .then(() => {
                          console.log("Password updated!");
                          navigate("Home");
                        })
                        .catch((error) => {
                          that.setState({ error });
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      that.setState({ error });
                      console.log(error);
                    });
                }
              } else {
                // Save data if needed
                that.saveSAIfNeeded(that.currentUser);
                that.savePronoIfNeed(_displayName);
                that.gotoAsyncIfNeeded();
                that._shouldRedirectToEvent();
              }
            })
            .catch(function (error) {
              that.setState({ error });
              console.log(error);
              if (firebase.analytics) {
                firebase.analytics.logEvent(`error_inscription_2_${error}`, { name: 'error' });
              }
            });
        })
        .catch((error) => {
          that.setState({ error });
          console.log(error);
          if (firebase.analytics) {
            firebase.analytics.logEvent(`error_inscription_2_${error}`, { name: 'error' });
          }
        });

      // Go to notice screen for checking email
    } else {
      if (
        filterBadWords.isProfane(this.state.firstName) ||
        filterBadWords.isProfane(this.state.lastName)
      ) {
        errMessage = `${errMessage}\n ${t("keyword_blackedlist")}`;
      }
      this.setState({ error: { message: errMessage } });
    }
  };

  savePronoIfNeed = (_displayName) => {
    const toSave = window.localStorage.getItem("match_forecast_to_save");
    // const { navigate } = this.props.navigation;
    console.log("_displayName savePronoIfNeed = ", _displayName);
    if (toSave) {
      const dataToSave = JSON.parse(toSave);
      // Save to firestore
      this.props.firebase
        .pronostics_responses(
          `${this.currentUser.uid}_${dataToSave.pronosticId}`
        )
        .set({
          ...dataToSave,
          userId: this.currentUser.uid,
          displayName: _displayName,
          email: this.currentUser.email,
        });
      window.localStorage.removeItem("match_forecast_to_save");
      window.localStorage.setItem("match_forecast", JSON.stringify(dataToSave));
    }
    // navigate("Home");
  };

  gotoAsyncIfNeeded = () => {
    const needOpenDataString = window.localStorage.getItem("needOpenAsync");
    if (needOpenDataString) {
      const needOpenData = JSON.parse(needOpenDataString);
      switch (needOpenData.type) {
        case "sa":
          window.location.replace(
            `https://${MAIN_URL}/sa/?sid=${needOpenData.id}`
          );
          break;

        case "async":
          window.location.replace(
            `https://${MAIN_URL}/async/?aid=${needOpenData.id}`
          );
          break;

        default:
          break;
      }
    }
  };

  saveSAIfNeeded = (userData) => {
    const needSaveSAString = window.localStorage.getItem("needSaveSA");
    if (needSaveSAString) {
      const needSaveSA = JSON.parse(needSaveSAString);
      const calculateSurvey =
        this.props.firebase.functions.httpsCallable("calculateSondage");
      for (let i = 0; i < needSaveSA.questions.length; i++) {
        const questionData = needSaveSA.questions[i];
        if (userData) {
          const savedData = {
            ...questionData.savedData,
            userId: userData.uid,
            ...userData,
          };
          const calculData = questionData.calculData;
          calculData.userData = savedData;

          // Save for calcul results
          this.props.firebase
            .surveys_result(`${questionData.collection}${userData.uid}`)
            .set(savedData)
            .then(() => {
              calculateSurvey(calculData).then((re) => {
                console.log("calculateSurvey done");
              });
            });
        }
      }
      //delete needSaveSA
      window.localStorage.removeItem("needSaveSA");
      window.localStorage.setItem("SAmsg", t("your_participation_saved"));
    }
  };

  removeAccount = () => {
    if (
      this.state.confirmDeleteAccount &&
      (this.state.passwordActuel || this.needPW === false)
    ) {
      const { email, passwordActuel } = this.state;
      const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;

      console.log("deleting with password =", this.needPW);
      console.log("firebase.auth.currentUser = ", firebase.auth.currentUser);
      if (this.needPW) {
        var cred = firebase.getCredential(email, passwordActuel);
        firebase.auth.currentUser
          .reauthenticateWithCredential(cred)
          .then(() => {
            firebase.auth.currentUser
              .delete()
              .then(() => {
                console.log("account deleted!");
                //delete all data
                window.localStorage.clear();
                that.props.firebase.doSignOut();
                navigate("Home");
              })
              .catch((error) => {
                that.setState({ error });
                console.log(error);
              });
          })
          .catch(function (error) {
            that.setState({ error });
            console.log(error);
          });
      } else {
        var p = new firebase.GoogleAuthProvider();
        if (this.provider.includes("google")) {
          p = new firebase.GoogleAuthProvider();
        } else {
          p = new firebase.FacebookAuthProvider();
        }

        firebase.auth.currentUser.reauthenticateWithRedirect(p);

        firebase.auth
          .getRedirectResult()
          .then(() => {
            firebase.auth.currentUser
              .delete()
              .then(() => {
                console.log("account deleted!");
                //delete all data
                window.localStorage.clear();
                that.props.firebase.doSignOut();
                navigate("Home");
              })
              .catch((error) => {
                that.setState({ error });
                console.log(error);
              });
          })
          .catch(function (error) {
            that.setState({ error });
            console.log(error);
          });
      }
    }
  };

  _renderOptionComplete = (item, index) => (
    <AutocompleteItem key={index} title={item.title} />
  );

  _renderItem = (value, label) => {
    return (
      <Picker.Item
        // style={{ color: TEXT_COLOR }}
        key={label}
        label={label}
        value={value}
      />
    );
  };

  _pickerItemLogeNumber = () => {
    var out = [];
    out.push(this._renderItem("", `${LOGE_TEXT} *`));
    // merge arr of salon and loges
    var arrList = LOGE_NUMBERS();
    for (let i = 0; i < arrList.length; i++) {
      var loge = arrList[i];
      //Get Name from data with geolocation
      loge = loge.split(";")[0];
      out.push(this._renderItem(loge, loge));
      this.logeNumberList.push(loge);
    }
    return out;
  };

  _pickerItemJobList = () => {
    var out = [];
    out.push(
      this._renderItem(
        "",
        `${PROFILE_JOB_TEXT} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`
      )
    );
    var arrList = PROFILE_JOB_ITEMS();
    for (let i = 0; i < arrList.length; i++) {
      const item = arrList[i];
      out.push(this._renderItem(item, item));
      this.jobList.push(item);
    }
    return out;
  };

  _shouldRedirectToEvent = () => {
    var out = [];
    const { navigate } = this.props.navigation;
    if (this.props.currentMatch) {
      out.push({
        type: "match",
        priority: 0,
        game: this.props.currentMatch.type,
        start: this.props.currentMatch.start.seconds,
      });
    }

    if (this.props.currentQuiz && this.props.currentQuiz.name !== "") {
      out.push({
        type: "quiz",
        priority: 1,
        start: this.props.currentQuiz.start.seconds,
      });
    }
    if (this.props.currentAsyncs) {
      for (let i = 0; i < this.props.currentAsyncs.length; i++) {
        const a = this.props.currentAsyncs[i];
        if (a.id) {
          out.push({
            type: "async",
            priority: 1,
            start: a.start.seconds,
            data: a,
          });
        }
      }
    }
    if (this.props.currentSurvey && this.props.currentSurvey.name !== "") {
      out.push({
        type: "survey",
        priority: 1,
        start: this.props.currentSurvey.start.seconds,
      });
    }
    if (this.props.currentSAs) {
      for (let i = 0; i < this.props.currentSAs.length; i++) {
        const a = this.props.currentSAs[i];
        if (a.id) {
          out.push({
            type: "sa",
            priority: 1,
            start: a.start.seconds,
            data: a,
          });
        }
      }
    }
    if (this.props.currentDuel && this.props.currentDuel.name !== "") {
      out.push({
        type: "duel",
        priority: 1,
        start: this.props.currentDuel.start.seconds,
      });
    }

    if (this.props.currentDraw && this.props.currentDraw.name !== "") {
      out.push({
        type: "draw",
        priority: 1,
        start: this.props.currentDraw.start.seconds,
      });
    }

    out.sort((a, b) => {
      return a.start - b.start;
    });
    if (out.length > 0) {
      const event = out[0];
      switch (event.type) {
        case "match":
          if (event.game === "match_rugby") {
            navigate("Rugby");
          } else {
            navigate("Forecast");
          }
          return;
        case "quiz":
          navigate("Quizzes");
          return;
        case "duel":
          navigate("Duel");
          return;
        case "draw":
          navigate("Draw");
          return;
        case "survey":
          navigate("Surveys");
          return;
        default:
          break;
      }
    }
    if (SHOW_GAMES) {
      navigate("SelectGame");
    } else {
      navigate("HomeInside");
    }
  };

  render() {

    console.log('state = ', this.state);
    return (
      <View style={{ marginHorizontal: 20 }} keyboardShouldPersistTaps="always">
        {this.state.shouldRender === false && (
          <LottieView
            style={{
              height: 50,
              width: 50,
              alignSelf: "center",
            }}
            source={require("../../assets/anim/loader.json")}
            autoPlay
            loop
          />
        )}
        {this.state.confirmDeleteAccount === false && this.state.shouldRender && (
          <View
            style={{
              marginTop: 20,
            }}
          >
            <FadeSlideView delay={200} direction="left">
              <Text
                style={{ marginBottom: 30, textAlign: "center" }}
                category="h3"
              >
                {this.props.navigation.state.params &&
                  this.props.navigation.state.params.m === "edit"
                  ? t("modify_your_profile")
                  : t("my_profile")}
              </Text>
            </FadeSlideView>
            {this.state && this.state.error && (
              <Text status="danger">* {this.state.error.message}</Text>
            )}
            {PROFILE_CIVIL === true && (
              <Picker
                label={'Civilité'}
                placeholder={`Civilité`}
                selectedValue={(this.state && this.state.civil) || ""}
                mode="dropdown"
                style={{
                  marginTop: 4,
                  backgroundColor: "rgba(255, 255, 255, 0.24)",
                  borderColor: "rgba(255, 255, 255, 0.4)",
                  borderRadius: 4,
                  borderWidth: 1,
                  minHeight: 40,
                  padding: 8,
                  // color: TEXT_COLOR,
                  marginBottom: 4,
                  textAlign: "left",
                  textAlignLast: "left",
                }}
                onValueChange={(value, index) => {
                  if (this._isMount) {
                    this.setState({
                      civil: value,
                    });
                  }
                }}
                itemStyle={{ textAlign: "left" }}
              >
                <Picker.Item
                  key={"Monsieur"}
                  label={"Monsieur"}
                  value={"Monsieur"}
                />
                <Picker.Item
                  key={"Madame"}
                  label={"Madame"}
                  value={"Madame"}
                />
                <Picker.Item
                  key={"Non binaire"}
                  label={"Non binaire"}
                  value={"Non binaire"}
                />
              </Picker>
            )}
            <Input
              status="control"
              label={t("firstname")}
              placeholder={t("firstname")}
              placeholderTextColor={"#ddd"}
              onChangeText={(text) => {
                this.setState({ firstName: text });
              }}
              value={(this.state && this.state.firstName) || ""}
              autoComplete="false"
            />
            <Input
              status="control"
              label={t("lastname")}
              placeholder={t("lastname")}
              placeholderTextColor={"#ddd"}
              onChangeText={(text) => {
                this.setState({ lastName: text });
              }}
              value={(this.state && this.state.lastName) || ""}
              autoComplete="false"
            />
            {PROFILE_ADDRESS && (
              <View style={{}}>
                {PROFILE_ADDRESS_STREET && (
                  <Input
                    label={t("address")}
                    status="control"
                    autoComplete="false"
                    placeholder={
                      PROFILE_ADDRESS_OBLIGATION
                        ? `${t("address")} *`
                        : `${t("address")}`
                    }
                    placeholderTextColor={"#ddd"}
                    onChangeText={(text) => {
                      this.setState({ address: text });
                    }}
                    value={(this.state && this.state.address) || ""}
                  />
                )}

                {PROFILE_ADDRESS_POSTCODE && (
                  <Input
                    label={t("postcode")}
                    status="control"
                    autoComplete="false"
                    placeholder={
                      PROFILE_ADDRESS_OBLIGATION
                        ? `${t("postcode")} *`
                        : `${t("postcode")}`
                    }
                    placeholderTextColor={"#ddd"}
                    onChangeText={(text) => {
                      this.setState({ postcode: text });
                    }}
                    value={(this.state && this.state.postcode) || ""}
                  />
                )}
                {PROFILE_ADDRESS_CITY && (
                  <Input
                    label={t("city")}
                    status="control"
                    autoComplete="false"
                    placeholder={
                      PROFILE_ADDRESS_OBLIGATION
                        ? `${t("city")} *`
                        : `${t("city")}`
                    }
                    placeholderTextColor={"#ddd"}
                    onChangeText={(text) => {
                      this.setState({ city: text });
                    }}
                    value={(this.state && this.state.city) || ""}
                  />
                )}
                {PROFILE_ADDRESS_COUNTRY && (
                  <Input
                    status="control"
                    label={
                      PROFILE_ADDRESS_OBLIGATION
                        ? `${t("country")} *`
                        : `${t("country")}`
                    }
                    placeholder={
                      PROFILE_ADDRESS_OBLIGATION
                        ? `${t("country")} *`
                        : `${t("country")}`
                    }
                    placeholderTextColor={"#ddd"}
                    onChangeText={(text) => {
                      this.setState({ country: text });
                    }}
                    value={(this.state && this.state.country) || ""}
                    style={{ marginTop: 15 }}
                  />
                  // <Autocomplete
                  //   label={t("country")}
                  //   status="control"
                  //   placeholder={
                  //     PROFILE_ADDRESS_OBLIGATION
                  //       ? `${t("country")} *`
                  //       : `${t("country")}`
                  //   }
                  //   value={(this.state && this.state.country) || ""}
                  //   onSelect={(index) => {
                  //     this.setState({
                  //       country: isoCountry.countries[index].title,
                  //     });
                  //   }}
                  //   onChangeText={(query) => {
                  //     this.setState({ country: query });
                  //     this.countryList = isoCountry.countries.filter((item) =>
                  //       filter(item, query)
                  //     );
                  //   }}
                  // >
                  //   {this.countryList.map(this._renderOptionComplete)}
                  // </Autocomplete>
                )}
              </View>
            )}
            {PROFILE_PHONE === true && (
              <Input
                status="control"
                label={PROFILE_PHONE_OBLIGATION ? `${t("phone")} *` : `${t("phone")}`}
                placeholder={PROFILE_PHONE_OBLIGATION ? `${t("phone")} *` : `${t("phone")}`}
                placeholderTextColor={"#ddd"}
                keyboardType="phone-pad"
                onChangeText={(text) => {
                  this.setState({ phone: text });
                }}
                value={(this.state && this.state.phone) || ""}
              />
            )}
            {PROFILE_DOB && (
              <View style={{ marginTop: 15 }}>
                <Text category="s2">{PROFILE_DOB_OBLIGATION ? `${t("birthdate")} *` : `${t("birthdate")}`}</Text>
                <input
                  onChange={(event) => {
                    this.setState({ dob: event.target.value });
                  }}
                  value={(this.state && this.state.dob) || ""}
                  autoComplete="false"
                  style={{
                    marginTop: 4,
                    backgroundColor: "rgba(255, 255, 255, 0.24)",
                    borderColor: "rgba(255, 255, 255, 0.4)",
                    borderRadius: 4,
                    borderWidth: 1,
                    height: 40,
                    paddingLeft: 8,
                    paddingRight: 8,
                    color: TEXT_COLOR,
                    marginBottom: 4,
                    textAlign: "left",
                    textAlignLast: "left",
                    marginLeft: 0,
                    marginRight: 0
                  }}
                  type="date"
                  min="1900-01-01"
                  max="2023-12-31"
                />
              </View>
            )}

            {PROFILE_COMPANY === true && (
              <Input
                label={t("company")}
                status="control"
                placeholder={
                  PROFILE_COMPANY_OBLIGATION
                    ? `${t("company")} *`
                    : `${t("company")}`
                }
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ company: text });
                }}
                value={(this.state && this.state.company) || ""}
                autoComplete="false"
                style={{ autoComplete: false }}
              />
            )}
            {PROFILE_JOB && (
              <View style={{}}>
                {PROFILE_JOB_SELECT !== true && (
                  <Input
                    label={`${t("function")} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                    autoComplete="false"
                    status="control"
                    placeholder={`${t("function")} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                    placeholderTextColor={"#ddd"}
                    onChangeText={(text) => {
                      this.setState({ job: text });
                    }}
                    value={(this.state && this.state.job) || ""}
                  />
                )}

                {PROFILE_JOB_SELECT === true && (
                  <Picker
                    label={`${PROFILE_JOB_TEXT} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                    placeholder={`${PROFILE_JOB_TEXT} ${PROFILE_JOB_OBLIGATION ? "*" : ""}`}
                    mode="dropdown"
                    style={{
                      marginTop: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.24)",
                      borderColor: "rgba(255, 255, 255, 0.4)",
                      borderRadius: 4,
                      borderWidth: 1,
                      minHeight: 40,
                      padding: 8,
                      // color: TEXT_COLOR,
                      // background: BUTTON_COLOR,
                      marginBottom: 4,
                      textAlign: "left",
                      textAlignLast: "left",
                    }}
                    status="control"
                    selectedValue={this.state.job}
                    onValueChange={(itemValue, index) => {
                      if (this._isMount) {
                        var _name = this.jobList[0];
                        if (index >= 0) {
                          _name = this.jobList[index - 1];
                        }
                        this.setState({
                          jobIndex: index,
                          job: _name,
                        });
                      }
                    }}
                  >
                    {this._pickerItemJobList()}
                  </Picker>
                )}
              </View>
            )}
            {PROFILE_LOGE_NUMBER === true && (
              <Picker
                label={`${LOGE_TEXT} *`}
                placeholder={`${LOGE_TEXT} *`}
                selectedValue={(this.state && this.state.logeNumber) || ""}
                mode="dropdown"
                style={{
                  marginTop: 4,
                  backgroundColor: "rgba(255, 255, 255, 0.24)",
                  borderColor: "rgba(255, 255, 255, 0.4)",
                  borderRadius: 4,
                  borderWidth: 1,
                  minHeight: 40,
                  padding: 8,
                  // color: TEXT_COLOR,
                  // background: BUTTON_COLOR,
                  marginBottom: 4,
                  textAlign: "left",
                  textAlignLast: "left",
                }}
                onValueChange={(itemValue, index) => {
                  if (this._isMount) {
                    // console.log(index);
                    var _name = this.logeNumberList[0];
                    if (index >= 0) {
                      _name = this.logeNumberList[index - 1];
                    }
                    this.setState({
                      logeNumberIndex: index,
                      logeNumber: _name,
                    });
                  }
                }}
                itemStyle={{ textAlign: "start" }}
              >
                {this._pickerItemLogeNumber()}
              </Picker>
            )}
            <View
              style={{
                height: 1,
                margin: 10,
                alignSelf: "center",
                backgroundColor: "lightgray",
              }}
            />
            <Input
              status="control"
              textContentType="emailAddress"
              placeholder={"Email *"}
              placeholderTextColor={"#ddd"}
              onChangeText={(text) => {
                this.setState({ email: text });
              }}
              disabled={!ONLY_ANONYMOUS}
              value={(this.state && this.state.email) || ""}
            />
            {this.state.changePassword === true && !this.state.anonymous && (
              <Input
                status="control"
                secureTextEntry={true}
                placeholder={t("current_password")}
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ passwordActuel: text });
                }}
                value={(this.state && this.state.passwordActuel) || ""}
                autoComplete="false"
              />
            )}
            {this.state.changePassword === true && (
              <Input
                status="control"
                secureTextEntry={true}
                placeholder={t("new_password")}
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ password: text });
                }}
                value={(this.state && this.state.password) || ""}
                autoComplete="false"
              />
            )}
            {this.needPW &&
              this.state.changePassword === false &&
              (!this.props.navigation.state.params ||
                this.props.navigation.state.params.m !== "edit") && (
                <TouchableOpacity
                  style={{ alignSelf: "flex-end" }}
                  onPress={() => {
                    this.setState({ changePassword: true });
                  }}
                >
                  <Text
                    style={{
                      color: BUTTON_COLOR,
                      fontSize: 13,
                      textAlign: "center",
                      padding: 8,
                      textDecorationLine: "underline",
                    }}
                  >
                    <i>{t("modify_password")}</i>
                  </Text>
                </TouchableOpacity>
              )}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              <CheckBox status="control" checked={true} disabled />
              <Text
                style={{
                  flex: 1,
                  textAlignVertical: "center",
                  color: TEXT_COLOR,
                  fontSize: 13,
                  fontWeight: "normal",
                  marginLeft: 10,
                }}
              >
                {THEME === "criterium" && "j'accepte de participer au quiz"}
                {THEME !== "criterium" && (
                  <span>
                    {t("i_read_and_accept")}&nbsp;
                    <a
                      style={{ color: TEXT_COLOR }}
                      rel="noopener noreferrer"
                      href={URL_RULES}
                      target="_blank"
                    >
                      {t("agreement")}
                    </a>
                    {t("agreement_after")} *
                    {TEXT_OPTION_1 !== "null" && <br />}
                    {TEXT_OPTION_1 !== "null" &&
                      (this.props.navigation.state.params
                        ? this.props.navigation.state.params.opin1
                          ? this.props.navigation.state.params.opin1
                          : TEXT_OPTION_1
                        : TEXT_OPTION_1)}
                  </span>
                )}
              </Text>
            </View>
            {TEXT_OPTION_2 !== null && TEXT_OPTION_2 !== "null" && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <CheckBox
                  status="control"
                  checked={(this.state && this.state.newsletter) || false}
                  onChange={(v) => {
                    this.setState({ newsletter: v });
                  }}
                />
                <Text
                  style={{
                    flex: 1,
                    textAlignVertical: "center",
                    color: TEXT_COLOR,
                    fontSize: 13,
                    fontWeight: "normal",
                    marginLeft: 10,
                  }}
                >
                  {TEXT_OPTION_2}
                </Text>
              </View>
            )}
            {TEXT_OPTION_3 !== null && TEXT_OPTION_3 !== "null" && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <CheckBox
                  status="control"
                  checked={
                    (this.state && this.state.newsletter_commercial) || false
                  }
                  onChange={(v) => {
                    this.setState({ newsletter_commercial: v });
                  }}
                />
                <Text
                  style={{
                    flex: 1,
                    textAlignVertical: "center",
                    color: TEXT_COLOR,
                    fontSize: 13,
                    fontWeight: "normal",
                    marginLeft: 10,
                  }}
                >
                  {TEXT_OPTION_3}
                </Text>
              </View>
            )}
            {this.state && this.state.error && (
              <Text status="danger">* {this.state.error.message}</Text>
            )}
            <Button
              style={{
                marginTop: 10,
                marginLeft: 60,
                marginRight: 60,
                borderRadius: 25,
                textTransform: 'uppercase'
              }}
              onPress={() => {
                this.onValidateClicked();
              }}
            >
              {this.props.navigation.state.params &&
                this.props.navigation.state.params.m === "edit"
                ? t("submit_profile")
                : t("submit")}
            </Button>
          </View>
        )}

        {(!this.props.navigation.state.params ||
          this.props.navigation.state.params.m !== "edit") &&
          this.state.shouldRender && (
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => {
                this.setState({
                  confirmDeleteAccount: !this.state.confirmDeleteAccount,
                });
              }}
            >
              <Text
                style={{
                  color: BUTTON_COLOR,
                  fontSize: 13,
                  textAlign: "center",
                  padding: 8,
                  textDecorationLine: "underline",
                }}
              >
                <i>
                  {this.state.confirmDeleteAccount
                    ? t("profile")
                    : t("delete_my_account")}
                </i>
              </Text>
            </TouchableOpacity>
          )}
        {this.state.confirmDeleteAccount === true && (
          <View style={{}}>
            <Text
              style={{
                color: TEXT_COLOR,
                fontSize: RF(2),
                fontWeight: "normal",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {t("delete_my_account")}
            </Text>
            {this.state && this.state.error && (
              <Text
                style={{
                  color: "red",
                  fontSize: 13,
                  margin: 10,
                  textAlign: "center",
                }}
              >
                * {this.state.error.message}
              </Text>
            )}
            <Text
              style={{
                color: BUTTON_COLOR,
                fontSize: 13,
                textAlign: "center",
                padding: 8,
              }}
            >
              {t("delete_account_and_all_data")}
              {this.needPW === false ? t("confirm_before_delete") : ""}
            </Text>
            {this.needPW && (
              <Input
                status="control"
                secureTextEntry={true}
                placeholder={t("current_password")}
                placeholderTextColor={"#ddd"}
                onChangeText={(text) => {
                  this.setState({ passwordActuel: text });
                }}
                value={(this.state && this.state.passwordActuel) || ""}
                autoComplete="false"
              />
            )}
            <View style={{ marginTop: 20 }}>
              <Button
                style={{ margin: 60, borderRadius: 25, textTransform: 'uppercase' }}
                status="danger"
                onPress={() => {
                  if (window.confirm(t("confirm_delete_account"))) {
                    this.removeAccount();
                  }
                }}
              >
                {t("delete_my_account")}
              </Button>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default withTranslation(Profile);
