import React from "react";
// import ReactGA from "react-ga";
// import { createStackNavigator, createAppContainer } from "react-navigation";
import { createSwitchNavigator } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";

import HomeScreen from "./screens/HomeScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignInScreen from "./screens/SignInScreen";
import ForgotPasswordScreen from "./screens/SignInScreen/forgot";
import AuthActionScreen from "./screens/SignInScreen/authAction";
import ForecastScreen from "./screens/ForecastScreen";
import SelectGameScreen from "./screens/SelectGameScreen";
import CountDownScreen from "./screens/CountdownScreen";
import QuizzesScreen from "./screens/QuizzesScreen";
import NoQuizScreen from "./screens/QuizzesScreen/none";
import RankingScreen from "./screens/RankingScreen";
import RankingTotalScreen from "./screens/RankingScreen/total";
import TeleScreen from "./screens/TeleScreen";
import SurveysScreen from "./screens/SurveysScreen";
import ThankyouScreen from "./screens/ThankyouScreen";
import ProfileScreen from "./screens/ProfileScreen";
import MenuNoLogin from "./screens/SelectGameScreen/menuNoLogin";
import PreForecastScreen from "./screens/ForecastScreen/preForecast";
import QuickSignUpScreen from "./screens/SignUpScreen/quick";
import LogeScreen from "./screens/LogeScreen";
import RugbyScreen from "./screens/ForecastScreen/rugby";
import PreForecastRugbyScreen from "./screens/ForecastScreen/preForecastRugby";
import DuelScreen from "./screens/DuelScreen";
import DrawScreen from "./screens/DrawScreen";
import AsyncScreen from "./screens/AsyncScreen";
import SurveyAsyncScreen from "./screens/SurveyAsyncScreen";
import SurveyListScreen from "./screens/SelectGameScreen/survey_list";
import QuizListScreen from "./screens/SelectGameScreen/quiz_list";
import RankingQuizScreen from "./screens/RankingScreen/quiz";
import RankingForecastScreen from "./screens/RankingScreen/forecast";
import HomeInsideScreen from "./screens/HomeInsideScreen";
import VerifyEmailScreen from "./screens/SignInScreen/verifyEmail";
import ShopScreen from "./screens/ShopScreen";
import ItemDetailScreen  from "./screens/ShopScreen/detail";
import CalenderScreen from "./screens/CalenderScreen";
import SelectLanguageScreen from "./screens/SelectLanguage/language";
import MoreScreen from "./screens/CommonScreen";
import SecretCodeScreen from "./screens/SecretCodeScreen";
import PredictionScreen from "./screens/PredictionScreen";
import ConnexionScreen from "./screens/ConnexionScreen";
import AuthScreen from "./screens/AuthScreen";
import LogoutScreen  from "./screens/ConnexionScreen/logout";

// const AppNavigator = createStackNavigator({
const AppNavigator = createSwitchNavigator({
  SelectGame: SelectGameScreen,
  Home: HomeScreen,
  HomeInside: HomeInsideScreen,
  SignUp: SignUpScreen,
  SignIn: SignInScreen,
  Forecast: ForecastScreen,
  CountDown: CountDownScreen,
  Quizzes: QuizzesScreen,
  NoQuiz: NoQuizScreen,
  Ranking: RankingScreen,
  RankingTotal: RankingTotalScreen,
  RankingQuiz: RankingQuizScreen,
  RankingForecast: RankingForecastScreen,
  Tele: TeleScreen,
  tele: TeleScreen,
  Surveys: SurveysScreen,
  Thankyou: ThankyouScreen,
  Profile: ProfileScreen, 
  VerifyEmail: VerifyEmailScreen,
  Menu: MenuNoLogin,
  PreForecast: PreForecastScreen,
  PreRugby: PreForecastRugbyScreen,
  QuickSignUp: QuickSignUpScreen,
  Loge: LogeScreen,
  Rugby: RugbyScreen,
  ForgotPassword: ForgotPasswordScreen,
  AuthAction: AuthActionScreen,
  Duel: DuelScreen,
  Draw: DrawScreen,
  Async: AsyncScreen,
  sa: SurveyAsyncScreen,
  SurveyList: SurveyListScreen,
  QuizList: QuizListScreen,
  Boutique: ShopScreen,
  Item: ItemDetailScreen,
  Calender: CalenderScreen,
  Language: SelectLanguageScreen,
  More: MoreScreen,
  SecretCode: SecretCodeScreen,
  Prediction: PredictionScreen,
  Connexion: ConnexionScreen,
  Auth: AuthScreen,
  Logout: LogoutScreen
});

const AppContainer = createBrowserApp(AppNavigator);


class AppNative extends React.Component {
  render() {
    return (
      <>
          <AppContainer {...this.props} />
      </>
    );
  }
}

export default AppNative;
