import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import { Prediction } from "../../components/Prediction";

export class PredictionScreen extends Component {
  render() {
    return (
      <Prediction {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(PredictionScreen));
