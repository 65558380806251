import React, { Component } from 'react'
import withMainLayout from '../../components/MainLayout';
import ForgotPassword from '../../components/SignIn/forgot';

export class ForgotPasswordScreen extends Component {
  render() {
    return (
        <ForgotPassword {...this.props} />
    )
  }
}

export default withMainLayout(ForgotPasswordScreen)
