import React from "react";
import {
  ImageBackground,
  View,
  Text,
  Animated,
  Image,
  TouchableOpacity,
  Easing
} from "react-native";
import CountDownCircle from "../CountDown/circle";
import FadeSlideView from "../Anim/FadeSlideView";
import { APP_NAVTAB, BUTTON_COLOR, isEn, MAIN_COLOR, SHOW_GAMES, TEXT_COLOR } from "../../constants/const";
import CountDown from "../CountDown";
import ResponseDuel from "./responseDuel";
import RF from "react-native-responsive-fontsize";
import {
  withTranslation,
  t
} from "react-multi-lang";

export class Duel extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackStartTimer = this.callbackStartTimer.bind(this);
    this.currentQuestion = null;
    var _screen = "Question";
    var tlv = 0;

    if (props.currentDuel) {
      this.currentQuestion = {
        id: props.currentDuel.id,
        question: isEn() ? props.currentDuel.name_en : props.currentDuel.name,
        type: 3,
        answers: [
          { answer: props.currentDuel.image_1, id: 1 },
          { answer: props.currentDuel.image_2, id: 2 },
        ],
        time: parseInt(props.currentDuel.duration, 10),
      };
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      tlv =
        props.currentDuel.start.seconds +
        parseInt(props.currentDuel.duration, 10) -
        now;
      _screen =
        tlv <= parseInt(props.currentDuel.duration, 10)
          ? tlv < 0
            ? "Result"
            : "Question"
          : "CountDown";
    }

    this.state = {
      screen: _screen,
      shouldShow: true,
      currentDuel: props.currentDuel,
      currentQuestion: this.currentQuestion,
      timeLeft: 0,
      updateNum: 0,
      timeLeftVote: tlv,
      disabled: tlv < 0,
      totalVotes: 0,
      vote1: 0,
      vote2: 0,
      vote1percent: 0.5,
      vote2percent: 0.5,
      animations: {
        bgWidth: new Animated.Value(80),
        bgMarginTop: new Animated.Value(100),
        bgMarginBottom: new Animated.Value(200),
        textMarginBottom: new Animated.Value(90),
        fadeOut: new Animated.Value(1),
        widthLeft: new Animated.Value(0),
        widthRight: new Animated.Value(0),
      },
    };
    if (_screen === "Result") {
      this.syncDataLive();
    }
  }

  getStateTimer = () => {
    if (this.state.currentDuel != null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      this.timeLeftSecs = this.state.currentDuel.start.seconds - now;
    }
  };

  callbackStartTimer(val) {
    //Quizzes started
    // console.log(val);
    if (val >= 0) {
      this.checkTimer(val);
    } else {
      if (this._isMounted) {
        if (this.state.currentDuel != null) {
          const now = Math.floor(
            (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) /
            1000
          );
          const tlv =
            this.state.currentDuel.start.seconds +
            parseInt(this.state.currentDuel.duration, 10) -
            now;
          this.setState({
            screen: "Question",
            timeLeftVote: tlv,
            disabled: tlv < 0,
          });
        }
      }
    }
  }

  checkTimer = (val) => {
    this.getStateTimer();
    if (this.currentAction !== null) {
      if (Math.abs(val - this.timeLeftSecs) > 0) {
        if (this._isMounted) {
          // console.log('checkTimer');
          this.setState({
            timeLeft: this.timeLeftSecs,
            updateNum: this.state.updateNum + 1,
          });
        }
      }
    }
  };

  callbackTimer(val) {
    // check again the question
    // check timer and current question time
    if (val < 0) {
      //window.location.reload(true);
    }
  }

  finishCountDownCallback = () => {
    //Show result and time response
    if (this._isMounted) {
      this.syncDataLive();
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  syncDataLive = () => {
    if (this.props.firebase && this.state.currentDuel) {
      this.props.firebase
        .duel_lives(this.state.currentDuel.id)
        .onSnapshot((docSnapshot) => {
          // Actually the data should be only 1
          var lData = docSnapshot.data();
          // console.log(lData);
          // bind data
          if (lData) {
            if (this._isMounted) {
              const totalV = lData.total_voted_1 + lData.total_voted_2;
              const v1 = lData.total_voted_1;
              const v2 = lData.total_voted_2;

              var v1percent = totalV > 0 ? (v1 / totalV).toFixed(2) : 0;
              var v2percent = totalV > 0 ? (1 - v1percent).toFixed(2) : 0;

              if (v1 === 0 && v2 === 0) {
                v1percent = 0.5;
                v2percent = 0.5;
              }
              this.setState({
                totalVotes: totalV,
                vote1: v1,
                vote2: v2,
                vote1percent: v1percent,
                vote2percent: v2percent,
                updateNum: this.state.updateNum + 1,
                disabled: true,
                screen: "Result",
              }, () => {
                Animated.parallel([
                  Animated.timing(this.state.animations.widthLeft, {
                    toValue: (this.props.maxWidth - 20) * Math.min(this.state.vote1percent, 0.99),
                    easing: Easing.elastic(),
                    duration: 550,
                    delay: 0,
                  }),
                  Animated.timing(this.state.animations.widthRight, {
                    toValue: (this.props.maxWidth - 20) * Math.min(this.state.vote2percent, 0.99),
                    easing: Easing.elastic(),
                    duration: 550,
                    delay: 0,
                  }),
                ]).start();
              });
            }
          }
        });
    }
  };

  render() {
    console.log('this.state = ', this.state);
    const { navigate } = this.props.navigation;
    const { animations } = this.state;
    return (
      <View
        style={{
          width: this.props.maxWidth,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {this.state.screen === "CountDown" && (
          <CountDown
            mode="insider"
            key={this.state.updateNum}
            {...this.props}
            timeLeft={this.state.timeLeft}
            msgWaiting={`${isEn() ? this.state.currentDuel?.msg_waiting_en : this.state.currentDuel?.msg_waiting}` || ''}
            callbackTimer={this.callbackStartTimer}
            duelId={this.props.currentDuel ? this.props.currentDuel.duel_id : false}
          />
        )}

        {this.state.screen === "Question" && this.state.currentQuestion && (
          <View>
            <View
              style={{
                marginTop: 10,
                width: this.props.maxWidth,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.state.timeLeftVote >= 0 && (
                <ImageBackground
                  key={this.state.currentQuestion.displayResult}
                  style={{
                    width: 66,
                    height: 66,
                    marginLeft: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: 0
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/circle.png")}
                >
                  <CountDownCircle
                    key={this.state.timeLeft}
                    seconds={this.state.timeLeftVote || 10}
                    radius={28}
                    borderWidth={8}
                    color={"transparent"}
                    bgColor="transparent"
                    textStyle={{ fontSize: 18, color: 'transparent' }}
                    finishCallback={() => this.finishCountDownCallback()}
                    callbackTimer={this.callbackTimer}
                  />
                </ImageBackground>
              )}
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 22,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center",
                    paddingTop: 30,
                    textTransform: "uppercase",
                  }}
                >
                  {this.state.currentQuestion.question}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  margin: 20,
                  height: 1,
                  width: "50%",
                  justifyContent: "center",
                  borderBottomColor: "white",
                  borderBottomWidth: 1,
                }}
              ></View>
              <View style={{ flex: 1, justifyContent: "center" }}>
                <Text
                  style={{
                    color: TEXT_COLOR,
                    fontSize: 18,
                    fontWeight: "bold",
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: "center",
                    paddingTop: 30,
                    // textTransform: "uppercase",
                  }}
                >
                  <i>{t("duel_subtitle")}</i>
                </Text>
              </View>
            </View>

            <View
              style={{
                marginTop: 30,
              }}
              key={this.state.currentQuestion.displayResult}
            >
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <FadeSlideView delay={600} direction="up">
                  <View
                    style={{
                      paddingBottom: 5,
                    }}
                  >
                    <ResponseDuel
                      {...this.props}
                      disabled={this.state.disabled}
                      key={this.state.disabled}
                      alreadyAnsweredCallback={(data) => {
                        this.setState({ alreadyAnsweredId: data.answeredId }, () => {
                          this.syncDataLive();
                        });
                      }}
                      data={{
                        answers: this.state.currentQuestion.answers,
                        currentQuestionId: this.state.currentQuestion.id,
                        currentDuelId: this.state.currentDuel.id,
                        currentDuelSeconds:
                          this.state.currentDuel.start.seconds,
                        withImage: true,
                        withResult: this.state.currentQuestion.displayResult,
                        currentQuestion: this.state.currentQuestion,
                      }}
                    />
                  </View>
                </FadeSlideView>
              </View>
            </View>
          </View>
        )}
        {this.state.screen === "Result" &&
          this.state.currentQuestion &&
          this.state.disabled && (
            <View
              style={{
                width: this.props.maxWidth,
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ justifyContent: "center" }}>
                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 10,
                      fontWeight: "bold",
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center",
                      paddingTop: 20,
                      textTransform: "uppercase",
                    }}
                  >
                    {t("thanks_for_playing")}
                  </Text>

                  <Text
                    style={{
                      color: TEXT_COLOR,
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center",
                      paddingTop: 30,
                      textTransform: "uppercase",
                    }}
                  >
                    {this.state.currentQuestion.question}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 20,
                    height: 1,
                    width: "50%",
                    justifyContent: "center",
                    borderBottomColor: "white",
                    borderBottomWidth: 1,
                  }}
                ></View>
                <FadeSlideView delay={500} direction="up">
                  <View
                    style={{
                      marginTop: 40,
                      flex: 1,
                      alignItems: "stretch",
                      alignContent: "stretch",
                      alignSelf: "stretch",
                    }}
                    key={this.state.updateNum}
                  >
                    <Animated.View
                      style={{
                        width: this.props.maxWidth,
                        flexDirection: "row",
                        marginTop: 10,
                        paddingHorizontal: 20,
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      {this.state.vote1percent >= 0 && (
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {this.state.alreadyAnsweredId === 1 && <Text style={{ color: TEXT_COLOR }}> *Votre vote*</Text>}
                          {this.state.currentDuel.image_1 && (
                            <Image
                              style={{
                                width: 90,
                                height: 90,
                              }}
                              source={this.state.currentDuel.image_1}
                              resizeMode="contain"
                            />
                          )}
                          <Text
                            style={{
                              color: TEXT_COLOR,
                              fontSize: RF(5),
                              fontWeight: "900",
                              textAlign: "center",
                              marginLeft: 2,
                            }}
                          >
                            {this.state.vote1percent * 100}%
                          </Text>
                        </View>
                      )}
                      {this.state.vote2percent >= 0 && (
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {this.state.alreadyAnsweredId === 2 && <Text style={{ color: TEXT_COLOR }}>*Votre vote*</Text>}
                          {this.state.currentDuel.image_2 && (
                            <Image
                              style={{
                                width: 90,
                                height: 90
                              }}
                              source={this.state.currentDuel.image_2}
                              resizeMode="contain"
                            />
                          )}
                          <Text
                            style={{
                              color: TEXT_COLOR,
                              fontSize: RF(5),
                              fontWeight: "900",
                              textAlign: "center",
                              marginRight: 2,
                            }}
                          >
                            {this.state.vote2percent * 100}%
                          </Text>
                        </View>
                      )}
                    </Animated.View>

                    <Animated.View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        alignSelf: 'center',
                        width: this.props.maxWidth - 20,
                        height: 40,
                        marginTop: 20,
                        flex: 1,
                        zIndex: 1000,
                        justifyContent: "space-between",
                      }}
                    >
                      <Animated.View
                        style={{
                          backgroundColor: MAIN_COLOR,
                          borderRadius: 10,
                          borderColor: "white",
                          borderWidth: 5,
                          width: animations.widthLeft,
                          height: 40,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></Animated.View>
                      <Animated.View
                        style={{
                          borderRadius: 10,
                          borderColor: "white",
                          borderWidth: 5,
                          width: animations.widthRight,
                          height: 40,
                          backgroundColor: BUTTON_COLOR,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></Animated.View>
                    </Animated.View>

                    <Animated.View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 50,
                        flex: 1,
                        zIndex: 1000,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: TEXT_COLOR,
                          fontSize: RF(4),
                          fontWeight: "900",
                          textAlign: "center",
                          marginLeft: 10,
                        }}
                      >
                        {this.state.totalVotes}{" "}
                        {this.state.totalVotes > 1 ? "VOTES" : "VOTE"}
                      </Text>
                    </Animated.View>
                  </View>
                </FadeSlideView>
              </View>
              <TouchableOpacity
                style={{
                  // position: "fixed",
                  justifyContent: "center",
                  // bottom: 150,
                }}
                onPress={() => SHOW_GAMES || APP_NAVTAB ? navigate("SelectGame") : navigate("Home")}
              >
                <Text
                  style={{
                    color: TEXT_COLOR,
                    marginTop: 50,
                    fontSize: 14,
                    backgroundColor: BUTTON_COLOR,
                    textAlign: "center",
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 25,
                  }}
                >
                  <i>{SHOW_GAMES || APP_NAVTAB ? t("back_to_events") : t("back_to_home")}</i>
                </Text>
              </TouchableOpacity>
            </View>
          )}

      </View>
    );
  }
}

export default withTranslation(Duel);
